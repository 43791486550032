import React from "react";
import AuthButton from "./AuthButton";
import MyProfileButton from "./MyProfileButton";

export default function NavBarFooter({ showMenu }) {
  return (
    <div
      id="navbar"
      className="flex mt-6 py-4 items-center justify-center h-screen gap-4 border-t border-[#486CAF] max-[301px]:w-full max-[301px]:border-none max-[301px]:mt-0 [301px]:py-0 [&_>_div]:w-1/2 max-[301px]:[&_>_div]:w-auto"
    >
      <MyProfileButton showMenu={showMenu} />
      <AuthButton />
    </div>
  );
}

import BaseUpside from "./BaseUpside";

export default class FinancialInformation extends BaseUpside {
  getMyClass() {
    return FinancialInformation;
  }

  static EMPLOYED_STATUS_FULLTIME = "employed_fulltime";
  static EMPLOYED_STATUS_PARTFULLTIME = "employed_parttime";
  static EMPLOYED_STATUS_MILITARY = "military";
  static EMPLOYED_STATUS_NOTEMPLOYED = "not_employed";
  static EMPLOYED_STATUS_SELFEMPLOYED = "employed_fulltime";
  static EMPLOYED_STATUS_RETIRED = "retired";
  static EMPLOYED_STATUS_OTHER = "other";

  static EMPLOYED_FREQUENCY_WEEKLY = "weekly";
  static EMPLOYED_FREQUENCY_BIWEEKLY = "biweekly";
  static EMPLOYED_FREQUENCY_TWICEMONTHLY = "twice_monthly";
  static EMPLOYED_FREQUENCY_MONTHLY = "monthly";

  constructor(props) {
    super(props);

    this.annualIncome = props.annualIncome ?? 0;
    this.employmentStatus = props.employmentStatus ?? "";
    this.employmentPayFrequency = props.employmentPayFrequency ?? "";
  }

  generate = () => {
    switch (this.employmentPayFrequency.toLowerCase()) {
      case "once every week":
        this.employmentPayFrequency =
          FinancialInformation.EMPLOYED_FREQUENCY_WEEKLY;
        break;
      case "once every other week":
        this.employmentPayFrequency =
          FinancialInformation.EMPLOYED_FREQUENCY_BIWEEKLY;
        break;
      case "twice per month":
        this.employmentPayFrequency =
          FinancialInformation.EMPLOYED_FREQUENCY_TWICEMONTHLY;
        break;
      case "once per month":
        this.employmentPayFrequency =
          FinancialInformation.EMPLOYED_FREQUENCY_MONTHLY;
        break;
    }

    switch (this.employmentStatus.toLowerCase()) {
      case "employed full time":
        this.employmentStatus = FinancialInformation.EMPLOYED_STATUS_FULLTIME;
        break;
      case "employed part time":
        this.employmentStatus =
          FinancialInformation.EMPLOYED_STATUS_PARTFULLTIME;
        break;
      case "military":
        this.employmentStatus = FinancialInformation.EMPLOYED_STATUS_MILITARY;
        break;
      case "not employed":
        this.employmentStatus =
          FinancialInformation.EMPLOYED_STATUS_NOTEMPLOYED;
        break;
      case "self employed":
        this.employmentStatus =
          FinancialInformation.EMPLOYED_STATUS_SELFEMPLOYED;
        break;
      case "retired":
        this.employmentStatus = FinancialInformation.EMPLOYED_STATUS_RETIRED;
        break;
      case "other":
        this.employmentStatus = FinancialInformation.EMPLOYED_STATUS_OTHER;
        break;
      default:
        break;
    }

    this.annualIncome = parseInt(
      this.annualIncome.toString().replace(/[$,]/g, ""),
    );

    return this.process();
  };
}

export enum ProofOfInfertilityDiagnosisType {
  true = "Yes",
  false = "No",
}
export class ProofOfInfertilityDiagnosisFilter {
  data;

  isGrantValid(grant): Boolean {
    return (
      this.data === undefined ||
      (grant.proofOfInfertilityDiagnosis &&
        ProofOfInfertilityDiagnosisType.true) ||
      (!grant.proofOfInfertilityDiagnosis &&
        ProofOfInfertilityDiagnosisType.false)
    );
  }
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { sendPasswordReset, wordPressUrl } from "../api";
import CloseImage from "./CloseButton";
import Store from "../App/Store";
import { useSelector } from "react-redux";
import { setResetPasswordModalState } from "../App/Features/UserSplice";
import { setLoginModalState, setEmailInput } from "../App/Features/UserSplice";
import { emailValidator } from "../utils/validators";

export default function ResetPasswordForm({ pathOnClose }) {
  const email = useSelector((state) => state.userState.emailInput);
  const showResetPasswordModal = useSelector(
    (state) => state.userState.resetPasswordModalState.showResetPasswordModal,
  );
  const loginModalState = useSelector(
    (state) => state.userState.loginModalState,
  );
  const [message, setMessage] = useState(null);
  const [resetSent, setResetSent] = useState(false);

  const openLogin = () => {
    Store.dispatch(
      setLoginModalState({
        showLoginModal: true,
        authRequired: loginModalState.authRequired,
      }),
    );
    Store.dispatch(
      setResetPasswordModalState({ showResetPasswordModal: false }),
    );
  };

  const onResetPassword = () => {
    if (emailValidator(email)) {
      var actionCodeSettings = {
        url: wordPressUrl,
      };
      sendPasswordReset(email, actionCodeSettings)
        .then(() => {
          setMessage(
            "If you have an account with Sunfish, you will receive an email to reset your password.",
          );
          setResetSent(true);
        })
        .catch((error) => {
          setMessage("An error occurred attempting to reset your password.");
        });
    } else {
      setMessage("Please enter a valid email address");
    }
  };

  const onClose = () => {
    Store.dispatch(
      setResetPasswordModalState({ showResetPasswordModal: false }),
    );
  };

  let msgBox = null;
  if (message) {
    msgBox = (
      <div className={resetSent ? "text-blue-800" : "text-red-800"}>
        {message}
      </div>
    );
  }

  return (
    <div
      className={
        showResetPasswordModal
          ? "w-full flex items-center justify-center h-screen fixed top-0 z-50 bg-black/20 backdrop-blur-sm"
          : "hidden"
      }
    >
      <div className="relative w-10/12 md:w-4/12 p-10 flex flex-col items-center bg-white shadow-2xl shadow-black/30 rounded-3xl">
        {pathOnClose ? (
          <Link to={pathOnClose}>
            <CloseImage onClick={onClose} />
          </Link>
        ) : (
          <CloseImage onClick={onClose} />
        )}
        <img src="/assets/logo.png" className="h-6" alt="sunfish-logo" />
        <h1 className="mt-5 text-center font-bold text-2xl">Login</h1>
        <input
          type="email"
          placeholder="Email"
          aria-label="email-input"
          className="bg-gray-100 outline-none rounded-xl p-4 mt-4 w-full"
          onChange={(event) => {
            Store.dispatch(setEmailInput(event.target.value));
            setResetSent(false);
            setMessage("");
          }}
          value={email}
        />
        <button
          onClick={onResetPassword}
          className="bg-blue-900 cursor-pointer text-white rounded-xl p-4 mt-2 w-full"
          disabled={resetSent}
        >
          Send Password Reset
        </button>
        <div className="py-2">{msgBox}</div>
        <h1 className="mt-5  text-center text-sm ">
          Updated your password?{" "}
          <span
            onClick={openLogin}
            className="text-blue-800 font-semibold cursor-pointer"
          >
            Log in
          </span>
        </h1>
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  setSelectedAffiliate,
  affiliateReportStateSetReport,
  affiliateReportStateSetReportType,
  affiliateReportStateSetSelectedAffiliatedUser,
  setStatus,
  affiliateKeyTypes,
  statusTypes,
} from "../../App/Features/AffiliateManagementSlice";
import { getAffiliatePartnerReport } from "../../api";
import AffiliatePartnerComponent from "./AffiliatePartnerComponent";
import SFDropdown from "../Inputs/SFDropdown";
import TabButton from "../Buttons/TabButton";
import LoadingComponent from "./LoadingComponent";

export default function AffiliatePartnerReport() {
  const affiliates = useSelector(
    (state) => state.manageAffiliateState.affiliates
  );
  const report = useSelector(
    (state) => state.manageAffiliateState.affiliateReportState.report
  );
  const reportType = useSelector(
    (state) => state.manageAffiliateState.affiliateReportState.reportType
  );
  const selectedAffiliatedUser = useSelector(
    (state) =>
      state.manageAffiliateState.affiliateReportState.selectedAffiliatedUser
  );
  const affiliateStatus = useSelector(
    (state) => state.manageAffiliateState.status
  );

  return (
    <div>
      <SFDropdown
        title="Affiliate Name"
        handleChange={(e) => {
          Store.dispatch(setStatus("loading"));
          getAffiliatePartnerReport({
            affiliateId: e.target.value,
            reportType: "all_time",
          }).then((res) => {
            Store.dispatch(
              affiliateReportStateSetSelectedAffiliatedUser(undefined)
            );
            Store.dispatch(affiliateReportStateSetReport(res));
            Store.dispatch(setStatus("inProgress"));
          });
          Store.dispatch(
            setSelectedAffiliate({
              affiliateId: affiliates[e.target.value],
            })
          );
        }}
        placeholderLabel="Select an affiliate"
        options={Object.keys(affiliates ?? {})?.map((affiliateKey) => (
          <option value={affiliateKey} className="text-pink-400">
            {!affiliates[affiliateKey].active ? "[Currently Inactive] " : ""}
            {affiliates[affiliateKey].partnerName}
          </option>
        ))}
      />
      {affiliateStatus === "loading" ? (
        <LoadingComponent />
      ) : report ? (
        <div className="flex px-4 flex-col text-left font-semibold">
          <div className="grid grid-cols-3 place-items-center space-x-4 py-2">
            <TabButton
              title={"All Time"}
              onClick={() => {
                Store.dispatch(setStatus("loading"));
                Store.dispatch(affiliateReportStateSetReportType("all_time"));
                getAffiliatePartnerReport({
                  affiliateId: report?.affiliatePartnerId,
                  reportType: "all_time",
                }).then((res) => {
                  Store.dispatch(affiliateReportStateSetReport(res));
                  Store.dispatch(setStatus("inProgress"));
                });
              }}
              className={"h-12 rounded-md px-4 py-2"}
              isSelected={reportType === "all_time"}
            />
            <TabButton
              title={"Monthly"}
              onClick={() => {
                Store.dispatch(setStatus("loading"));
                Store.dispatch(affiliateReportStateSetReportType("monthly"));
                getAffiliatePartnerReport({
                  affiliateId: report?.affiliatePartnerId,
                  reportType: "monthly",
                }).then((res) => {
                  Store.dispatch(affiliateReportStateSetReport(res));
                  Store.dispatch(setStatus("inProgress"));
                });
              }}
              className={"h-12 rounded-md px-4 py-2"}
              isSelected={reportType === "monthly"}
            />
            <TabButton
              title={"Specific Month"}
              onClick={() => {
                Store.dispatch(affiliateReportStateSetReportType("month"));
              }}
              className={"h-12 rounded-md px-4 py-2"}
              isSelected={reportType === "month"}
            />
          </div>
          {reportType === "month" ? (
            <input
              type="month"
              id="bdaymonth"
              name="bdaymonth"
              onChange={(e) => {
                Store.dispatch(setStatus("loading"));
                const year = parseInt(e.target.value.split("-")[0]);
                const month = parseInt(e.target.value.split("-")[1]);
                getAffiliatePartnerReport({
                  affiliateId: report?.affiliatePartnerId,
                  reportType: "month",
                  month: month - 1,
                  year: year,
                }).then((res) => {
                  Store.dispatch(affiliateReportStateSetReport(res));
                  Store.dispatch(setStatus("inProgress"));
                });
              }}
            />
          ) : (
            <></>
          )}
          <p>
            {report.dateRange
              ? `Affiliate report for ${report.dateRange}:`
              : "Affiliate report:"}
          </p>
          {report?.totalAffiliatedUsersCount ||
          report?.totalRegisteredUsersCount ||
          report?.totalFormStartUsersCount ||
          report?.totalFormCompletedUsersCount ||
          report?.totalBookedLoanUsersCount ? (
            <>
              <AffiliatePartnerComponent affiliate={report?.affiliatePartner} />
              <div className="flex px-8 flex-col py-2">
                <p>
                  {reportType === "monthly"
                    ? `New affiliated users for month ${report.dateRange}: `
                    : `New affiliated users: `}
                  {report?.totalAffiliatedUsersCount}
                </p>
                <p>
                  {`Users that Registered: ${report?.totalRegisteredUsersCount}`}
                </p>
                <p>
                  {`Users that started Sunfish Form: ${report?.totalFormStartUsersCount}`}
                </p>
                <p>
                  {`Users that completed Sunfish Form: ${report?.totalFormCompletedUsersCount}`}
                </p>
                <p>
                  {`Users with booked loans: ${report?.totalBookedLoanUsersCount}`}
                </p>
              </div>
              <div className="px-4">
                <SFDropdown
                  title="See a user journey by id/email"
                  handleChange={(e) =>
                    Store.dispatch(
                      affiliateReportStateSetSelectedAffiliatedUser(
                        report?.affiliatedUsers[e.target.value]
                      )
                    )
                  }
                  placeholderLabel="Select a affiliated user"
                  options={Object.keys(report?.affiliatedUsers ?? {})?.map(
                    (affiliateKey) => (
                      <option value={affiliateKey} className="text-pink-400">
                        {affiliateKey}
                        {report?.affiliatedUsers[affiliateKey]?.auth_email
                          ? ` / ${report?.affiliatedUsers[affiliateKey]?.auth_email}`
                          : ""}
                      </option>
                    )
                  )}
                />
              </div>
              {selectedAffiliatedUser ? (
                Object.keys(selectedAffiliatedUser)?.map((e) => (
                  <p className="px-12">{`${affiliateKeyTypes[e]}: ${
                    e === "status"
                      ? statusTypes[selectedAffiliatedUser[e]]
                      : selectedAffiliatedUser[e]
                  }`}</p>
                ))
              ) : (
                <></>
              )}
            </>
          ) : (
            <div className="flex px-4 flex-col">
              <label className="py-2 text-left font-semibold">
                Affiliate has no affiliated users
              </label>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

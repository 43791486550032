import React from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  manageAffilatePartnersStateSetSelectedAffiliate,
  setStatus,
  setAffiliates,
} from "../../App/Features/AffiliateManagementSlice";
import SFInput from "../Inputs/SFInput";
import SFBlueButton from "../Buttons/SFBlueButton";
import SFDropdown from "../Inputs/SFDropdown";
import AffiliatePartnerComponent from "./AffiliatePartnerComponent";
import { updateAffiliate, getAffiliates } from "../../api";
import LoadingComponent from "./LoadingComponent";

export default function ManageAffiliatePartnerForm() {
  const affiliates = useSelector(
    (state) => state.manageAffiliateState.affiliates
  );
  const selectedAffiliate = useSelector(
    (state) =>
      state.manageAffiliateState.manageAffilatePartnersState.selectedAffiliate
  );
  const status = useSelector((state) => state.manageAffiliateState.status);

  return (
    <div>
      {status === "loading" ? (
        <LoadingComponent />
      ) : status === "complete" ? (
        <div className="flex px-4 flex-col">
          <label className="text-center font-semibold">Affiliate updated</label>
        </div>
      ) : (
        <>
          <SFDropdown
            title="Select a affiliate"
            handleChange={(e) =>
              Store.dispatch(
                manageAffilatePartnersStateSetSelectedAffiliate({
                  ...affiliates[e.target.value],
                  affiliateId: e.target.value,
                })
              )
            }
            placeholderLabel="Select a affiliate"
            options={Object.keys(affiliates ?? {})?.map((affiliateKey) => (
              <option value={affiliateKey} className="text-pink-400">
                {!affiliates[affiliateKey].active
                  ? "[Currently Inactive] "
                  : ""}
                {affiliates[affiliateKey].partnerName}
              </option>
            ))}
          />
          {selectedAffiliate === undefined ? (
            <></>
          ) : (
            <>
              <AffiliatePartnerComponent
                affiliate={selectedAffiliate}
                update={true}
              />
              <SFInput
                label="Affiliate Welcome Message"
                handleChange={(e) =>
                  Store.dispatch(
                    manageAffilatePartnersStateSetSelectedAffiliate({
                      ...selectedAffiliate,
                      partnerMessage: e.target.value,
                    })
                  )
                }
                value={selectedAffiliate.partnerMessage}
                placeholderLabel="Affiliate Welcome Message"
              />
              <SFInput
                label="Active"
                handleChange={(e) =>
                  Store.dispatch(
                    manageAffilatePartnersStateSetSelectedAffiliate({
                      ...selectedAffiliate,
                      active: e.target.checked,
                    })
                  )
                }
                type="checkbox"
                defaultStyle=""
                errorStyle=""
                checked={selectedAffiliate?.active}
              />
              <div className="grid place-items-center">
                <SFBlueButton
                  title="Submit"
                  onClick={async () => {
                    Store.dispatch(setStatus("loading"));
                    await updateAffiliate({
                      affiliateId: selectedAffiliate.affiliateId,
                      partnerMessage: selectedAffiliate.partnerMessage,
                      active: selectedAffiliate.active,
                    });
                    const affiliates = await getAffiliates();
                    Store.dispatch(setAffiliates(affiliates));
                    Store.dispatch(setStatus("complete"));
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

import { useState } from "react";

export default function SFIvfReasonInput({
  id,
  label,
  values,
  value,
  onChange,
  onOtherChange,
  onBlur,
  onOtherBlur,
  ariaLabel,
  isRequired,
  error,
}) {
  let [checkedValues, setCheckedValues] = useState(values || []);

  const options = [
    "Polycystic ovaries / Ovulatory disorder",
    "Endometriosis",
    "Male factor infertility",
    "Diminished ovarian reserve",
    "Uterine factor (septum, myoma, intrauterine adhesions, congenital anomalies)",
  ];

  const otherOption = "Other";
  const otherOptionIndex = options.length;

  const unexplainedOption = "Unexplained infertility";
  const unexplainedOptionIndex = options.length + 1;

  const unknownOption = "I don't know / No reason";
  const unknownOptionIndex = options.length + 2;

  const unexplainedAndUnknownOptionsFilter = (item) =>
    item !== unexplainedOption && item !== unknownOption;

  let onCheck = (event, i) => {
    let newCheckedValues = null;
    const newIsChecked = event.target.checked;

    if (i < options.length) {
      const oldIsChecked = checkedValues.includes(options[i]);

      if (!oldIsChecked && newIsChecked) {
        const oldCheckedValues = checkedValues.filter(
          unexplainedAndUnknownOptionsFilter
        );
        newCheckedValues = [options[i], ...oldCheckedValues];
      } else if (oldIsChecked && !newIsChecked) {
        newCheckedValues = checkedValues.filter((item) => item !== options[i]);
      }
    } else if (i === otherOptionIndex) {
      const oldIsChecked = checkedValues.includes(otherOption);

      if (!oldIsChecked && newIsChecked) {
        const oldCheckedValues = checkedValues.filter(
          unexplainedAndUnknownOptionsFilter
        );
        newCheckedValues = [otherOption, ...oldCheckedValues];
      } else if (oldIsChecked && !newIsChecked) {
        newCheckedValues = checkedValues.filter((item) => item !== otherOption);
      }
    } else if (i === unexplainedOptionIndex) {
      const oldIsChecked = checkedValues.includes(unexplainedOption);

      if (!oldIsChecked && newIsChecked) {
        newCheckedValues = [unexplainedOption];
      } else if (oldIsChecked && !newIsChecked) {
        newCheckedValues = [];
      }
    } else if (i === unknownOptionIndex) {
      const oldIsChecked = checkedValues.includes(unknownOption);

      if (!oldIsChecked && newIsChecked) {
        newCheckedValues = [unknownOption];
      } else if (oldIsChecked && !newIsChecked) {
        newCheckedValues = [];
      }
    }

    if (newCheckedValues !== null) {
      setCheckedValues(newCheckedValues);
      onChange(newCheckedValues);
      onBlur();
    }
  };

  return (
    <div key={id} className="flex flex-col py-2 px-4">
      <fieldset>
        <legend className="text-left">
          <p className="mb-1 font-semibold">{label}</p>
        </legend>
        <div className="grid grid-cols-2">
          {options.map((option, i) => (
            <div className="flex flex row gap-x-4">
              <input
                type="checkbox"
                onChange={(e) => onCheck(e, i)}
                checked={checkedValues.includes(option)}
                aria-label={ariaLabel}
              ></input>
              <p aria-label={option} className="text-left">
                {option}
              </p>
            </div>
          ))}
          <div className="flex flex row gap-x-4 items-center">
            <input
              type="checkbox"
              onChange={(e) => onCheck(e, otherOptionIndex)}
              checked={checkedValues.includes(otherOption)}
            />
            <p aria-label={otherOption} className="text-left">
              {otherOption}:&nbsp;
              <input
                name="otherValue"
                type="text"
                value={value}
                onChange={onOtherChange}
                onBlur={onOtherBlur}
                aria-label="Other Value"
                className="rounded-md p-3 border-2 h-10 border-gray-200"
              />
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1">
          <div className="flex flex row gap-x-4">
            <input
              type="checkbox"
              onChange={(e) => onCheck(e, unexplainedOptionIndex)}
              checked={checkedValues.includes(unexplainedOption)}
            />
            <p aria-label="Unexplained Infertility" className="text-left">
              <strong>
                <em>OR</em>
              </strong>{" "}
              {unexplainedOption}
            </p>
          </div>
          <div className="flex flex row gap-x-4">
            <input
              type="checkbox"
              onChange={(e) => onCheck(e, unknownOptionIndex)}
              checked={checkedValues.includes(unknownOption)}
            />
            <p aria-label="I don't know / No reason">
              <strong>
                <em>OR</em>
              </strong>{" "}
              {unknownOption}
            </p>
          </div>
        </div>
      </fieldset>
      {error & !values && isRequired ? (
        <text className="text-red-400">Please select a value </text>
      ) : (
        <div />
      )}
    </div>
  );
}

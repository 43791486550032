export enum MaritalStatusType {
  married = "Married",
  single = "Single",
  notSpecified = "Not Specified",
}

export class MaritalStatusFilter {
  data: MaritalStatusType;

  constructor(data: MaritalStatusType) {
    this.data = data;
  }

  isGrantValid(grant): Boolean {
    var maritalStatus = grant.maritalStatus;
    var searchString = maritalStatus.toLowerCase();
    var anyGrant: Boolean = searchString === "any";
    if (this.data === MaritalStatusType.notSpecified) {
      return true;
    } else if (maritalStatus) {
      var test = this.data.toString().toLowerCase() === searchString;
      return anyGrant || test;
    }
    return anyGrant;
  }
}

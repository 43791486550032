import React, { useEffect, useState } from "react";
import { CheckIcon } from "@heroicons/react/solid";
import { setSignUpModalState } from "../../App/Features/UserSplice";
import Store from "../../App/Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postPartnerConsultationCallEngagement } from "../../api";

export default function Mission() {
  const partner = useSelector((state) => state.partnerState.partner);
  const user = useSelector((state) => state.userState.user);

  return (
    <div className="flex flex-col items-center text-center">
      <div className="w-11/12 lg:w-9/12 lg:mt-24 sm:mt-8 text-left grid gap-10 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
        {/* grid column 1 */}
        <div>
          <div className="lg:text-left sm:text-center">
            <h1 className="text-5xl literata-font text-navyblue lg:pr-20">
              <span className="font-semibold">
                Begin your parenthood journey today
              </span>
              <span className="font-light italic">
                {" "}
                &mdash; and pay over time
              </span>
            </h1>
            <p className="text-gray-500 pt-2 mt-4 lg:pr-20">
              Get your options for low-interest fertility loans for up to $100k
              for IVF, surrogacy and egg-freezing
            </p>
          </div>
          <Card
            title={
              <div>
                Loans <span className="italic">starting at $200/ month*</span>
              </div>
            }
            texts={[
              "Competitive interest rates",
              "Up to $100k",
              "2-7 year terms (range may vary by location)",
              "No prepayment penalties",
              "No origination fees",
              "Several options to compare between",
            ]}
            disclaimer={
              <div className="text-xs">
                *For an example loan of $10,000 at 13% APR over 72 months. Your
                actual terms may vary. Rates range from 6.95 to 35.99%. Loan
                terms offered by Sunfish's lending partners.
              </div>
            }
            link="/our-offerings"
            linkText="See my options"
            bgColor={"bg-secondary"}
            imgSrc={"/assets/finance.svg"}
            ariaLabel={"decorative-misson-icon-1"}
          />
        </div>
        {/* grid column 2 */}
        <div>
          <Card
            title={
              <div className="font-normal mb-2">
                <b>Plan your financial journey</b> <br />
                (with a real human)
              </div>
            }
            texts={[
              <>
                <b>Schedule free consult below,</b> without even signing up
              </>,
              <>
                <b>Estimate and plan for</b> your unique fertility journey
              </>,
              <>
                Learn about <b>grants, discounts and savings</b> opportunities
              </>,
            ]}
            link="https://calendly.com/joinsunfish/financial-consultation"
            onClick={() => {
              if (partner && user) {
                postPartnerConsultationCallEngagement(
                  user.uid,
                  user.email,
                  partner.name
                );
              }
            }}
            linkText="Schedule Free Call"
            linkTarget="_blank"
            margin={false}
            bgColor={"bg-lightgreenblue"}
            imgSrc={"/assets/financial_advocate.svg"}
            ariaLabel={"decorative-misson-icon-2"}
          />
          <Card
            title={
              <div className="font-normal mb-2">
                <b>Network of partners</b>
                <br />{" "}
                <span className="italic">to plan your parenthood journey</span>
              </div>
            }
            texts={[
              <>
                <b>IVF Clinics: </b>Find a local IVF clinic 
              </>,
              <>
                <b>Agencies: </b>Finding an egg donor agency and surrogacy
                agency 
              </>,
              <>
                <b>Grants & Other Financial Support: </b>Connection to
                organizations offering emotional support, financial assistance,
                discounts and grants
              </>,
            ]}
            link="/our-offerings"
            linkText="Get Started"
            bgColor={"bg-amber-50"}
            imgSrc={"/assets/partners.svg"}
            ariaLabel={"decorative-misson-icon-3"}
          />
        </div>
      </div>
    </div>
  );
}

function Card({
  title,
  texts,
  disclaimer,
  bgColor,
  onClick,
  link,
  linkText,
  linkTarget,
  margin = true,
  imgSrc,
  ariaLabel,
}) {
  const user = useSelector((state) => state.userState.user);
  const navigate = useNavigate();
  const [requestedNavigation, setRequestedNavigation] = useState(false);

  useEffect(() => {
    if (user && requestedNavigation) {
      navigate(link, {
        state: link === "/our-offerings" ? { scrollIntoForm: true } : {},
      });
    }
  }, [user, requestedNavigation, navigate, link]);

  const requestNavigation = () => {
    Store.dispatch(
      setSignUpModalState({
        showSignupModal: true,
        authRequired: false,
      })
    );
    setRequestedNavigation(true);
  };
  let theLink = linkTarget ? (
    <a
      href={link}
      target={linkTarget}
      onClick={() => onClick()}
      className="text-navyblue bg-yellow py-3 px-6 mt-5 text-sm font-medium rounded-full"
    >
      {linkText}
    </a>
  ) : (
    <button
      className="text-navyblue bg-yellow py-3 px-6 mt-5 text-sm font-medium rounded-full"
      onClick={() =>
        user
          ? navigate(link, {
              state: link === "/our-offerings" ? { scrollIntoForm: true } : {},
            })
          : requestNavigation()
      }
    >
      {linkText}
    </button>
  );

  return (
    <div
      className={
        margin
          ? `ml-auto mr-auto mt-10 ${bgColor} lg:w-9/12 w-11/12 p-8 rounded-3xl text-gray-700`
          : `ml-auto mr-auto ${bgColor} lg:w-9/12 w-11/12 p-8 rounded-3xl text-gray-700`
      }
    >
      <img
        src={imgSrc}
        className="mb-10"
        aria-label={ariaLabel}
        width="120"
        height="120"
      />
      <h1 className="text-2xl font-bold">{title}</h1>
      <div className="flex flex-col text-gray-600">
        {texts.map((text) => (
          <span className="flex flex-start mt-2">
            <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
              <CheckIcon className="h-full text-white" />
            </div>
            <p>{text}</p>
          </span>
        ))}
      </div>
      <div className="pt-3 px-6 mt-4 text-gray-600">
        {disclaimer}
        {theLink}
      </div>
    </div>
  );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signIn, logAnalyticsEvent } from "../api";
import CloseImage from "./CloseButton";
import { useSelector } from "react-redux";
import {
  setEmailInput,
  setLoginModalState,
  setSignUpModalState,
  setResetPasswordModalState,
} from "../App/Features/UserSplice";
import { resetRouteToHome } from "../App/Features/NavSplice";
import Store from "../App/Store";

export default function Loginform({ pathOnClose }) {
  const routeToHome = useSelector((state) => state.navState.routeToHome);
  const loginModalState = useSelector(
    (state) => state.userState.loginModalState,
  );
  const signUpModalState = useSelector(
    (state) => state.userState.signUpModalState,
  );
  const email = useSelector((state) => state.userState.emailInput);
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);

  const openSignup = () => {
    Store.dispatch(
      setSignUpModalState({
        showSignupModal: true,
        title: signUpModalState.title,
        authRequired: loginModalState?.authRequired,
      }),
    );
  };

  const openResetPassword = () => {
    Store.dispatch(
      setResetPasswordModalState({ showResetPasswordModal: true }),
    );
  };

  const onSignIn = () => {
    signIn(email, password)
      .then((user) => {
        Store.dispatch(
          setLoginModalState({
            showLoginModal: false,
            authRequired: loginModalState.authRequired,
          }),
        );
        window.top.postMessage("exit.fullscreen_mode", "*");
        setMessage(null);
        logAnalyticsEvent("login", {
          method: "password",
        });
      })
      .catch((error) => {
        let msg = error.message;
        if (error.code === "auth/invalid-email") {
          msg = "Invalid e-mail address.";
        } else if (error.code === "auth/wrong-password") {
          msg = "Incorrect e-mail address or password.";
        }

        setMessage(msg);
      });
  };

  const onClose = () => {
    window.top.postMessage("exit.fullscreen_mode", "*");
    if (routeToHome) {
      Store.dispatch(resetRouteToHome());
    }
    Store.dispatch(
      setLoginModalState({
        showLoginModal: false,
        authRequired: false,
      }),
    );
  };

  let msgBox = null;
  if (message) {
    msgBox = <div className="text-red-800">{message}</div>;
  }

  return (
    <div
      className={
        loginModalState?.showLoginModal
          ? "w-full flex items-center justify-center h-screen fixed top-0 z-50 bg-aliceBlue/90 backdrop-blur-sm"
          : "hidden"
      }
    >
      <div className="relative w-10/12 md:w-4/12 p-10 flex flex-col items-center bg-white shadow-2xl shadow-black/30 rounded-3xl">
        {!loginModalState?.authRequired ? (
          <CloseImage onClick={onClose} />
        ) : null}
        <img src="/assets/logo.png" className="h-6" alt="sunfish-logo" />
        <h1 className="mt-5 text-center font-bold text-2xl">
          {loginModalState.title ?? "Login"}
        </h1>
        <input
          type="email"
          placeholder="Email"
          aria-label="email-input"
          className="bg-gray-100 outline-none rounded-xl p-4 mt-4 w-full"
          onChange={(event) =>
            Store.dispatch(setEmailInput(event.target.value))
          }
        />
        <input
          type="password"
          placeholder="Password"
          aria-label="password-input"
          className="bg-gray-100 outline-none rounded-xl p-4 mt-2 w-full"
          onChange={(event) => setPassword(event.target.value)}
        />
        {msgBox}
        <input
          type="button"
          onClick={onSignIn}
          value="Login"
          className="bg-blue-900 cursor-pointer text-white rounded-xl p-4 mt-2 w-full"
        />
        <button
          className="mt-3 text-blue-900 text-center text-sm font-semibold"
          onClick={openResetPassword}
        >
          Forgot password?
        </button>
        <h1 className="mt-5  text-center text-sm ">
          New to Sunfish?{" "}
          <span
            onClick={openSignup}
            className="text-blue-800 font-semibold cursor-pointer"
          >
            Create Account
          </span>
        </h1>
      </div>
    </div>
  );
}

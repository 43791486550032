export default function SFInput({
  id,
  label,
  placeholderLabel,
  value,
  error,
  validationText,
  type = "text",
  defaultStyle = "rounded-md p-3 border-2 border-gray-200 h-12 my-1",
  errorStyle = "rounded-md p-3 border-2 border-red-200 h-12 my-1",
  handleChange,
  innerRef,
  checked = false,
  fileName,
  min,
}) {
  const removeSpecialCharacters = (s) => {
    var digitString = "";
    for (var x = 0; x < s.length; x++) {
      var char = s[x];
      if (char >= "0" && char <= "9") {
        digitString += char;
      }
    }
    return digitString;
  };

  const formatPhoneNumber = (existingPhoneValue, newPhoneValue) => {
    if (newPhoneValue.length > existingPhoneValue.length) {
      var sanatizedString = removeSpecialCharacters(newPhoneValue);
      if (sanatizedString.length < 3) {
        return `(${sanatizedString}`;
      } else if (sanatizedString.length === 3) {
        return `(${sanatizedString}`;
      } else if (sanatizedString.length > 3 && sanatizedString.length < 6) {
        return `(${sanatizedString.substring(
          0,
          3
        )}) ${sanatizedString.substring(3)}`;
      } else if (sanatizedString.length >= 6) {
        return `(${sanatizedString.substring(
          0,
          3
        )}) ${sanatizedString.substring(3, 6)}-${sanatizedString.substring(
          6,
          10
        )}`;
      }
    }
    return newPhoneValue;
  };

  return (
    <div
      className={`flex py-2 px-4 ${
        type === "checkbox" ? "flex-row space-x-4" : "flex-col"
      }`}
    >
      <label className="text-left font-semibold">{label}</label>
      <input
        id={id}
        value={value}
        onChange={
          type === "tel"
            ? (e) => {
                e.target.value = formatPhoneNumber(value, e.target.value);
                handleChange(e);
              }
            : (e) => handleChange(e)
        }
        onKeyUp={(event) => {
          // required for android chrome input validation
          if (
            event.key.toLowerCase() === "unidentified" ||
            !/[0-9]/.test(event.key)
          ) {
            event.preventDefault();
            event.value = handleChange(event);
          }
        }}
        onKeyPress={(event) => {
          if (type === "number" && !/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
          if (
            type === "tel" &&
            (!/[0-9]/.test(event.key) || value.length === 14)
          ) {
            event.preventDefault();
          }
          if (
            type === "currency" &&
            (!/[0-9]/.test(event.key) || value.length === 8)
          ) {
            event.preventDefault();
          }
        }}
        className={error ? errorStyle : defaultStyle}
        placeholder={placeholderLabel ?? label}
        type={type}
        ref={innerRef}
        checked={checked}
        hidden={type === "file"}
        {...(type === "number" || type === "tel" ? (min = { min }) : {})}
      />
      {type === "file" ? (
        <label for={id}>{fileName ?? "Select an image file"}</label>
      ) : (
        <></>
      )}
      <p className="text-red-400">
        {error
          ? validationText ?? `Please enter ${placeholderLabel ?? label}`
          : ""}
      </p>
    </div>
  );
}

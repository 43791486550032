import React from "react";
import DisclosuresBanner from "../Components/Banners/DisclosuresBanner";
import DisclosuresContent from "../Components/Disclosures";

export default function Disclosures() {
  return (
    <div className="flex flex-col items-center">
      <DisclosuresBanner />
      <DisclosuresContent />
    </div>
  );
}

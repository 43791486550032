import React from "react";
import NavBarMenu from "./NavBarMenu";
import NavBarFooter from "./NavBarFooter";

export default function NavBarMenuMobile({ user, usersOffersUrl, showMenu }) {
  return (
    <nav className="h-fit sm:block lg:hidden sticky z-50 w-full top-0 py-5 lg:py-6 font-medium px-5 text-sm bg-navyblue">
      <div className="flex flex-row place-content-end">
        <button
          onClick={showMenu}
          className="block text-blue-900 text-lg font-semibold lg:hidden focus:outline-none"
          aria-label="show-menu"
        >
          <p className="w-20 text-white">
            Close <span className="ml-2">X</span>
          </p>
        </button>
      </div>
      <div
        id="menu"
        className="relative lg:hidden p-1 mt-6 text-3xl text-black rounded-lg flex-auto h-fit"
      >
        <div className="flex flex-col justify-between text-start landscape:mt-0 mt-6 text-white">
          <br className="landscape:hidden" />
          <br className="landscape:hidden" />
          <div className="flex flex-col">
            <NavBarMenu user={user} showMenu={showMenu} />
          </div>
          <div className="flex flex-row place-content-center landscape:mt-0 mt-32">
            <NavBarFooter
              user={user}
              usersOffersUrl={usersOffersUrl}
              showMenu={showMenu}
            />
          </div>
        </div>
      </div>
    </nav>
  );
}

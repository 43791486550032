import { createSlice } from "@reduxjs/toolkit";

export const partnerContactUsSlice = createSlice({
  name: "partnerContactUsSlice",
  initialState: {
    partnerTypes: [
      { title: "IVF Clinic", key: "_ivfClinic" },
      { title: "Surrogacy Agency", key: "_surrogacyAgency" },
      { title: "Egg Bank", key: "_eggBank" },
      { title: "Other", key: "_other" },
    ],
    partnerLead: {},
    currentPartnerType: undefined,
    _surrogacyAgency: {
      completed: false,
      readyToSubmit: false,
      groupIndex: 0,
      groups: [
        {
          groupError: undefined,
          values: {
            agencyName: {
              value: "",
              label: "Agency Name",
              placeholderLabel: "Your Agency Name",
              error: false,
              isRequired: true,
            },
            contactName: {
              value: "",
              label: "Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: true,
            },
            contactTitle: {
              value: "",
              label: "Contact Title",
              placeholderLabel: "Your Contact Title",
              error: false,
              isRequired: true,
            },
            contactEmail: {
              value: "",
              label: "Contact Email",
              placeholderLabel: "Your Contact Email",
              error: false,
              isRequired: true,
              type: "email",
            },
            contactPhone: {
              value: "",
              label: "Contact Phone",
              placeholderLabel: "Your Contact Phone",
              error: false,
              isRequired: true,
              type: "tel",
              min: 0,
            },
            anyAdditionalContact: {
              value: "",
              label: "Any Secondary Contact Person?",
              placeholderLabel: "",
              error: false,
              isRequired: true,
              type: "radio",
              options: ["Yes", "No"],
            },
            additonalContactName: {
              value: "",
              label: "Additional Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactTitle: {
              value: "",
              label: "Additional Contact Title",
              placeholderLabel: "Your Contact Title",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactEmail: {
              value: "",
              label: "Additional Contact Email",
              placeholderLabel: "Your Contact Email",
              error: false,
              isRequired: false,
              hidden: true,
              type: "email",
            },
          },
        },
        {
          groupError: undefined,
          values: {
            primaryServiceArea: {
              value: "",
              label: "What is your primary service area?",
              placeholderLabel: "Your Primary Service Area",
              error: false,
              isRequired: true,
            },
            additionalServiceAreas: {
              value: "",
              label: "Any addtional service areas? (optional)",
              placeholderLabel: "Additional Service Areas",
              error: false,
              isRequired: false,
            },
            numberOfYearsOperating: {
              value: "",
              label: "Number of years operating?",
              placeholderLabel: "Number of years operating?",
              error: false,
              isRequired: true,
              type: "number",
              min: 0,
            },
            memberOfSeeds: {
              value: "",
              label: "Are you a member of SEEDS?",
              placeholderLabel: "Are you a member of SEEDS?",
              error: false,
              isRequired: true,
            },
            numberOfJourneys: {
              value: "",
              label: "How many journeys do you complete a year?",
              placeholderLabel: "How many journeys do you complete a year?",
              error: false,
              isRequired: true,
              type: "number",
              min: 0,
            },
            partnershipGoals: {
              value: "",
              label: "What are your partnership goals?",
              placeholderLabel: "What are your partnership goals?",
              error: false,
              isRequired: true,
            },
            howDidYouHearAboutSunfish: {
              value: "",
              label: "How did you hear about Sunfish?",
              placeholderLabel: "How did you hear about Sunfish?",
              error: false,
              isRequired: true,
            },
          },
        },
      ],
    },
    _eggBank: {
      completed: false,
      readyToSubmit: false,
      groupIndex: 0,
      groups: [
        {
          groupError: undefined,
          values: {
            eggBankName: {
              value: "",
              label: "Egg Bank Name",
              placeholderLabel: "Your Egg Bank Name",
              error: false,
              isRequired: true,
            },
            contactName: {
              value: "",
              label: "Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: true,
            },
            contactTitle: {
              value: "",
              label: "Contact Title",
              placeholderLabel: "Your Contact Title",
              error: false,
              isRequired: true,
            },
            contactEmail: {
              value: "",
              label: "Contact Email",
              placeholderLabel: "Your Contact Email",
              error: false,
              isRequired: true,
              type: "email",
            },
            contactPhone: {
              value: "",
              label: "Contact Phone",
              placeholderLabel: "Your Contact Phone",
              error: false,
              isRequired: true,
              type: "tel",
              min: 0,
            },
            anyAdditionalContact: {
              value: "",
              label: "Any Secondary Contact Person?",
              placeholderLabel: "",
              error: false,
              isRequired: true,
              type: "radio",
              options: ["Yes", "No"],
            },
            additonalContactName: {
              value: "",
              label: "Additional Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactTitle: {
              value: "",
              label: "Additional Contact Title",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactEmail: {
              value: "",
              label: "Additional Contact Email",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
              type: "email",
            },
          },
        },
        {
          groupError: undefined,
          values: {
            primaryServiceArea: {
              value: "",
              label: "What is your primary service area?",
              placeholderLabel: "Your Primary Service Area",
              error: false,
              isRequired: true,
            },
            additionalServiceAreas: {
              value: "",
              label: "Any addtional service areas? (optional)",
              placeholderLabel: "Additional Service Areas",
              error: false,
              isRequired: false,
            },
            numberOfYearsOperating: {
              value: "",
              label: "Number of years operating?",
              placeholderLabel: "Number of years operating?",
              error: false,
              isRequired: true,
              type: "number",
              min: 0,
            },
            memberOfSeeds: {
              value: "",
              label: "Are you a member of SEEDS?",
              placeholderLabel: "Are you a member of SEEDS?",
              error: false,
              isRequired: true,
            },
            numberOfJourneys: {
              value: "",
              label: "How many journeys do you complete a year?",
              placeholderLabel: "How many journeys do you complete a year?",
              error: false,
              isRequired: true,
              type: "number",
              min: 0,
            },
            partnershipGoals: {
              value: "",
              label: "What are your partnership goals?",
              placeholderLabel: "What are your partnership goals?",
              error: false,
              isRequired: true,
            },
            howDidYouHearAboutSunfish: {
              value: "",
              label: "How did you hear about Sunfish?",
              placeholderLabel: "How did you hear about Sunfish?",
              error: false,
              isRequired: true,
            },
          },
        },
      ],
    },
    _ivfClinic: {
      completed: false,
      readyToSubmit: false,
      groupIndex: 0,
      groups: [
        {
          groupError: undefined,
          values: {
            clinicName: {
              value: "",
              label: "Clinic Name",
              placeholderLabel: "Your Clinic Name",
              error: false,
              isRequired: true,
            },
            contactName: {
              value: "",
              label: "Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: true,
            },
            contactTitle: {
              value: "",
              label: "Contact Title",
              placeholderLabel: "Your Contact Title",
              error: false,
              isRequired: true,
            },
            contactEmail: {
              value: "",
              label: "Contact Email",
              placeholderLabel: "Your Contact Email",
              error: false,
              isRequired: true,
              type: "email",
            },
            contactPhone: {
              value: "",
              label: "Contact Phone",
              placeholderLabel: "Your Contact Phone",
              error: false,
              isRequired: true,
              type: "tel",
              min: 0,
            },
            anyAdditionalContact: {
              value: "",
              label: "Any Secondary Contact Person?",
              placeholderLabel: "",
              error: false,
              isRequired: true,
              type: "radio",
              options: ["Yes", "No"],
            },
            additonalContactName: {
              value: "",
              label: "Additional Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactTitle: {
              value: "",
              label: "Additional Contact Title",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactEmail: {
              value: "",
              label: "Additional Contact Email",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
              type: "email",
            },
          },
        },
        {
          groupError: undefined,
          values: {
            primaryServiceArea: {
              value: "",
              label: "What is your primary service area?",
              placeholderLabel: "Your Primary Service Area",
              error: false,
              isRequired: true,
            },
            additionalServiceAreas: {
              value: "",
              label: "Any addtional service areas? (optional)",
              placeholderLabel: "Additional Service Areas",
              error: false,
              isRequired: false,
            },
            numberOfYearsOperating: {
              value: "",
              label: "Number of years operating?",
              placeholderLabel: "Number of years operating?",
              error: false,
              isRequired: true,
              type: "number",
              min: 0,
            },
            monthlyPatients: {
              value: "",
              label:
                "On average, how many monthly patients are completing a cycle at your clinic?",
              placeholderLabel:
                "On average, how many monthly patients are completing a cycle at your clinic?",
              error: false,
              isRequired: true,
              type: "number",
              min: 0,
            },
            partnershipGoals: {
              value: "",
              label: "What are your partnership goals?",
              placeholderLabel: "What are your partnership goals?",
              error: false,
              isRequired: true,
            },
            howDidYouHearAboutSunfish: {
              value: "",
              label: "How did you hear about Sunfish?",
              placeholderLabel: "How did you hear about Sunfish?",
              error: false,
              isRequired: true,
            },
          },
        },
      ],
    },
    _other: {
      completed: false,
      readyToSubmit: false,
      groupIndex: 0,
      groups: [
        {
          groupError: undefined,
          values: {
            companyName: {
              value: "",
              label: "Company Name",
              placeholderLabel: "Your Company Name",
              error: false,
              isRequired: true,
            },
            contactName: {
              value: "",
              label: "Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: true,
            },
            contactTitle: {
              value: "",
              label: "Contact Title",
              placeholderLabel: "Your Contact Title",
              error: false,
              isRequired: true,
            },
            contactEmail: {
              value: "",
              label: "Contact Email",
              placeholderLabel: "Your Contact Email",
              error: false,
              isRequired: true,
              type: "email",
            },
            contactPhone: {
              value: "",
              label: "Contact Phone",
              placeholderLabel: "Your Contact Phone",
              error: false,
              isRequired: true,
              type: "tel",
              min: 0,
            },
            anyAdditionalContact: {
              value: "",
              label: "Any Secondary Contact Person?",
              placeholderLabel: "",
              error: false,
              isRequired: true,
              type: "radio",
              options: ["Yes", "No"],
            },
            additonalContactName: {
              value: "",
              label: "Additional Contact Name",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactTitle: {
              value: "",
              label: "Additional Contact Title",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
            },
            additonalContactEmail: {
              value: "",
              label: "Additional Contact Email",
              placeholderLabel: "Your Contact Name",
              error: false,
              isRequired: false,
              hidden: true,
              type: "email",
            },
          },
        },
        {
          groupError: undefined,
          values: {
            companyDescription: {
              value: "",
              label: "Company Description",
              placeholderLabel: "How would you describe your company?",
              error: false,
              isRequired: true,
              min: 0,
            },
            primaryServiceArea: {
              value: "",
              label: "What is your primary service area?",
              placeholderLabel: "Your Primary Service Area",
              error: false,
              isRequired: true,
            },
            additionalServiceAreas: {
              value: "",
              label: "Any addtional service areas? (optional)",
              placeholderLabel: "Additional Service Areas",
              error: false,
              isRequired: false,
            },
            numberOfYearsOperating: {
              value: "",
              label: "Number of years operating?",
              placeholderLabel: "Number of years operating?",
              error: false,
              isRequired: true,
              type: "number",
              min: 0,
            },
            partnershipGoals: {
              value: "",
              label: "What are your partnership goals?",
              placeholderLabel: "What are your partnership goals?",
              error: false,
              isRequired: true,
            },
            howDidYouHearAboutSunfish: {
              value: "",
              label: "How did you hear about Sunfish?",
              placeholderLabel: "How did you hear about Sunfish?",
              error: false,
              isRequired: true,
            },
          },
        },
      ],
    },
  },
  reducers: {
    setValue: (state, action) => {
      if (action.payload.updateId === "anyAdditionalContact") {
        const anyAdditionalContactHidden = action.payload.updateValue === "No";
        const anyAdditionalContactRequired =
          action.payload.updateValue === "Yes";
        state[state.currentPartnerType.key].groups[
          state[state.currentPartnerType.key].groupIndex
        ].values["additonalContactName"].hidden = anyAdditionalContactHidden;
        state[state.currentPartnerType.key].groups[
          state[state.currentPartnerType.key].groupIndex
        ].values["additonalContactTitle"].hidden = anyAdditionalContactHidden;
        state[state.currentPartnerType.key].groups[
          state[state.currentPartnerType.key].groupIndex
        ].values["additonalContactEmail"].hidden = anyAdditionalContactHidden;
        state[state.currentPartnerType.key].groups[
          state[state.currentPartnerType.key].groupIndex
        ].values["additonalContactName"].isRequired =
          anyAdditionalContactRequired;
        state[state.currentPartnerType.key].groups[
          state[state.currentPartnerType.key].groupIndex
        ].values["additonalContactTitle"].isRequired =
          anyAdditionalContactRequired;
        state[state.currentPartnerType.key].groups[
          state[state.currentPartnerType.key].groupIndex
        ].values["additonalContactEmail"].isRequired =
          anyAdditionalContactRequired;
      }
      state[state.currentPartnerType.key].groups[
        state[state.currentPartnerType.key].groupIndex
      ].values[action.payload.updateId].value = action.payload.updateValue;
      state[state.currentPartnerType.key].groups[
        state[state.currentPartnerType.key].groupIndex
      ].values[action.payload.updateId].error = false;
      state.partnerLead[action.payload.updateId] = action.payload.updateValue;
      state[state.currentPartnerType.key].groups[
        state[state.currentPartnerType.key].groupIndex
      ].groupError = false;
    },
    prevGroup: (state, _) => {
      if (state[state.currentPartnerType.key].groupIndex >= 0) {
        state[state.currentPartnerType.key].groupIndex--;
        state[state.currentPartnerType.key].readyToSubmit =
          state[state.currentPartnerType.key].groupIndex ===
          state[state.currentPartnerType.key].groups.length - 1;
      }
    },
    nextGroup: (state, _) => {
      if (
        state[state.currentPartnerType.key].groupIndex >= 0 &&
        state[state.currentPartnerType.key].groups.length >
          state[state.currentPartnerType.key].groupIndex + 1
      ) {
        state[state.currentPartnerType.key].groupIndex++;
        state[state.currentPartnerType.key].readyToSubmit =
          state[state.currentPartnerType.key].groupIndex ===
          state[state.currentPartnerType.key].groups.length - 1;
      }
    },
    setGroupError: (state, _) => {
      state[state.currentPartnerType.key].groups[
        state[state.currentPartnerType.key].groupIndex
      ].groupError = _formGroupErrorHandler(
        state[state.currentPartnerType.key].groups[
          state[state.currentPartnerType.key].groupIndex
        ].values
      );
    },
    setCurrentPartnerType: (state, action) => {
      state.currentPartnerType = action.payload;
      state.partnerLead = { type: action.payload.title };
    },
    setCompleted: (state, _) => {
      state[state.currentPartnerType.key].completed = true;
    },
  },
});

export const formGroupErrorHandler = (values) => {
  var error = false;
  Object.values(values).forEach((e) => {
    const elementError = formElementErrorHandler(e);
    if (elementError) {
      error = elementError;
      return;
    }
  });
  return error;
};

const _formGroupErrorHandler = (values) => {
  var error = false;
  Object.values(values).forEach((e) => {
    const elementError = formElementErrorHandler(e);
    if (elementError) {
      error = elementError;
      e.error = elementError;
    }
  });
  return error;
};

const formElementErrorHandler = (e) => {
  var error;
  if (e.type === "tel") {
    error = e.value.length !== 14 && e.isRequired;
  } else if (e.type === "email") {
    error =
      !e.value.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      ) && e.isRequired;
  } else {
    error = !e.value && e.isRequired;
  }
  return error;
};

export const {
  setCurrentPartnerType,
  setValue,
  prevGroup,
  nextGroup,
  setCompleted,
  setGroupError,
} = partnerContactUsSlice.actions;

export default partnerContactUsSlice.reducer;

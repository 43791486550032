import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
  name: "app",
  initialState: {
    evenIntegrationEnabled: undefined,
    frbIntegrationEnabled: undefined,
    scrollIntoFormEnabled: undefined,
    reducedFormEnabled: undefined,
    showLoanEstimator: false,
  },
  reducers: {
    setEvenIntegrationEnabled: (state, action) => {
      state.evenIntegrationEnabled = action.evenIntegrationEnabled;
    },
    setFrbIntegrationEnabled: (state, action) => {
      state.frbIntegrationEnabled = action.frbIntegrationEnabled;
    },
    setScrollIntoFormEnabled: (state, action) => {
      state.scrollIntoFormEnabled = action.payload;
    },
    setReducedFormEnabled: (state, action) => {
      state.reducedFormEnabled = action.payload;
    },
    setShowLoanEstimate: (state, action) => {
      state.showLoanEstimator = action.payload;
    },
  },
});

export const {
  setEvenIntegrationEnabled,
  setFrbIntegrationEnabled,
  setScrollIntoFormEnabled,
  setReducedFormEnabled,
  setShowLoanEstimate,
} = appSlice.actions;

export default appSlice.reducer;

import { useCallback, useEffect, useRef, useState } from "react";
import {
  updateOfferRequest,
  createUsersOffersUrl,
  areFeatureFlagsEnabled,
  getMarketingLeadOrganizations,
} from "../../api";
import SFIvfReasonInput from "../../Components/Forms/SFIvfReasonInput";
import SFAmhLevelInput from "../../Components/Forms/SFAmhLevels";
import { usStates } from "../../App/Features/USStates";
import { useSelector, useDispatch } from "react-redux";
import OurOfferingsFormFooter from "./OurOfferingFormFooter";
import OurOfferingsFormHeader from "./OurOfferingFormHeader";
import { OurOfferingsFormBackButton } from "./OurOfferingsFormBackButton";
import Store from "../../App/Store";
import {
  journeyDetailsTitle,
  ivfAndMedication,
  defaultSteps,
  setDashboardVisible,
  setCurrentStep,
  longFormSteps,
  setShowJourneyDetails,
} from "../../App/Features/MyDashboardSlice";
import { setUsersOffersURL } from "../../App/Features/UserSplice";
import LeadSubmissionRequest from "domain/Upside/LeadSubmissionRequest";
import LoanInformation from "domain/Upside/LoanInformation";
import { authorize, lead } from "App/Features/Upside";
import _ from "lodash";
import Autocomplete from "Components/Inputs/Autocomplete";

export default function OurOfferingsForm({
  formCompleted,
  setFormCompletedStatus,
  myDashboardPage = false,
}) {
  const dispatch = useDispatch();
  const reducedFormEnabled = useSelector(
    (state) => state.appState.reducedFormEnabled,
  );
  const isDashboardVisible = useSelector(
    (state) => state.myDashboardState.isDashboardVisible,
  );
  const user = useSelector((state) => state.userState.user);
  const [loadedUser, setLoadedUser] = useState(false);
  const [error, setError] = useState(false);
  const partner = useSelector((state) => state.partnerState.partner);
  const [steps, setSteps] = useState();
  const [includeIvfJourneyQuestions, setIncludeIvfJourneyQuestions] =
    useState(false);
  const [savedFormValues, setSavedFormValues] = useState({});
  const [inputsPending, setInputsPending] = useState({});
  const [marketingOrgs, setMarketingOrgs] = useState([]);
  const currentStep = useSelector(
    (state) => state.myDashboardState.currentStep,
  );

  const isUpdateable = (current) => {
    const currentKeys = Object.keys(current);
    const savedKeys = Object.keys(savedFormValues);
    if (currentKeys.length < savedKeys.length) {
      return !currentKeys.every(
        (field) => savedFormValues[field] === current[field],
      );
    }

    const fields = [...new Set(...savedKeys, ...currentKeys)];
    return !fields.every((field) => savedFormValues[field] === current[field]);
  };

  const handleSingleInputDbUpdate = () => {
    if (isUpdateable(inputsPending)) {
      setInputsPending({});
      handleFullFormDbUpdate();
    }
  };

  const debounceUpdate = useCallback(
    _.debounce(handleSingleInputDbUpdate, 5000),
  );

  const handleFullFormDbUpdate = (values) => {
    const formValues = values ?? generateFormValues();

    // Button is clicked. Stop all pending updates first
    debounceUpdate.cancel();

    updateOfferRequest(user?.uid, formValues);
  };

  // Do not immediately update the DB. As the user can re-focus the input and make changes
  const handleOnBlur = (stepIndex, options) => {
    const { value, id } = options ?? {};
    const useId = id ?? steps[currentStep]?.questions[stepIndex]?.id;
    const useValue = value ?? steps[currentStep]?.questions[stepIndex]?.value;

    if (isUpdateable({ [useId]: useValue })) {
      setInputsPending({ ...inputsPending, [useId]: useValue });
    }
  };

  // User has re-focused the input, stop any pending update
  const handleOnFocus = () => {
    debounceUpdate.cancel();
  };

  useEffect(() => {
    if (Object.keys(inputsPending).length > 0) {
      debounceUpdate(inputsPending);
    }
  }, [inputsPending]);

  useEffect(() => {
    if (reducedFormEnabled) {
      setSteps(
        defaultSteps(user, {
          partner: partner,
          myDashboardPage: myDashboardPage,
        }),
      );
    } else {
      setSteps(
        longFormSteps(user, {
          partner: partner,
          myDashboardPage: myDashboardPage,
        }),
      );
    }
  }, [reducedFormEnabled, myDashboardPage, partner, user]);

  useEffect(() => {
    areFeatureFlagsEnabled(["ivfJourneyDetails"])
      .then((results) => {
        setIncludeIvfJourneyQuestions(results["ivfJourneyDetails"]);
      })
      .catch((error) => {
        setIncludeIvfJourneyQuestions(false);
      });

    getMarketingLeadOrganizations().then((data) => {
      setMarketingOrgs(data ?? []);
    });
  }, []);

  const scrollToFooterRef = useRef(null);

  const textInput = (
    label,
    value,
    index,
    placeholder,
    type,
    min,
    max,
    ariaLabel,
    isRequired,
    id,
  ) => {
    let msgBox = null;
    if (error) {
      let message = null;

      if (!value && value !== 0 && isRequired) {
        message = "Please enter a value";
      } else if (type === "currency") {
        const currency = parseCurrency(value);
        if (currency < min) {
          message = `Value must be at least ${formatCurrency(min)}`;
        } else if (currency > max) {
          message = `Value must be at most ${formatCurrency(max)}`;
        }
      } else if (type === "number") {
        const num = parseInt(value);
        if ((min || min === 0) && num < min) {
          message = `Value must be at least ${min}`;
        } else if ((max || max === 0) && num > max) {
          message = `Value must be at most ${max}`;
        }
      } else if (id === "dateOfBirth") {
        if (new Date(value) >= new Date()) {
          message = "Date must be a valid date of birth";
        }
      }

      if (message && !isRequired) {
        message = `${message} (but is optional)`;
      }

      if (message) {
        msgBox = <text className="text-red-400">{message}</text>;
      }
    }

    return (
      <div key={index} className="flex flex-col py-2 sm:px-1 md:px-4">
        <p className="text-left mb-1 font-semibold">{label}</p>
        <input
          value={value}
          onChange={(e) => handleChange(e, index)}
          className={`rounded-md p-3 border-2 h-12 
            ${type === "date" ? "flex flex-row md:mx-0 sm:mx-auto" : ""}
            ${error && msgBox ? "border-red-200" : "border-gray-200"}`}
          onKeyUp={(event) => {
            // required for android chrome input validation
            if (
              event.key.toLowerCase() === "unidentified" ||
              !/[0-9]/.test(event.key)
            ) {
              event.preventDefault();
              event.value = handleChange(event, index);
            }
          }}
          onKeyPress={(event) => {
            if (type === "number" && !/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
            if (
              type === "tel" &&
              (!/[0-9]/.test(event.key) || value.length === 14)
            ) {
              event.preventDefault();
            }
            if (
              type === "currency" &&
              (!/[0-9]/.test(event.key) || value.length === 8)
            ) {
              event.preventDefault();
            }
          }}
          onBlur={() => handleOnBlur(index)}
          onFocus={() => handleOnFocus()}
          placeholder={placeholder}
          type={type}
          {...(type === "number" || type === "tel" ? (min = { min }) : {})}
          aria-label={ariaLabel}
        />
        {msgBox}
      </div>
    );
  };
  const radioInput = (label, value, index, options, ariaLabel, isRequired) => {
    return (
      <div key={index} className="flex flex-col py-2 px-4">
        <div>
          <div className="text-left">
            <p className="font-semibold">{label}</p>
          </div>
          <div className="grid md:grid-cols-2 sm:grid-cols-1">
            {options.map((option, i) => (
              <div className="flex flex-row gap-x-4 place-items-center">
                <input
                  name={`${steps[currentStep].title} ${label}`}
                  type="radio"
                  onChange={(e) => handleSelectionChange(option, index)}
                  onBlur={() => handleOnBlur(index)}
                  onFocus={() => handleOnFocus()}
                  checked={option === steps[currentStep].questions[index].value}
                  aria-label={ariaLabel}
                  value={value}
                ></input>
                <p aria-label={option} className="text-left">
                  {option}
                </p>
              </div>
            ))}
          </div>
        </div>
        {error & !value && isRequired ? (
          <text className="text-red-400">Please select a value </text>
        ) : (
          <div />
        )}
      </div>
    );
  };
  const dropdownOptionsInput = (
    label,
    value,
    index,
    options,
    placeholder,
    ariaLabel,
    isRequired,
  ) => {
    var optionsList = [];
    if (options) {
      for (var enumValue in options) {
        var optionText = options.valueOf()[enumValue];
        optionsList.push(
          <option key={optionText} value={optionText}>
            {optionText}
          </option>,
        );
      }
    }
    return (
      <div
        key={index}
        className="flex flex-col py-2 sm:px-1 md:px-4 rounded-3xl"
      >
        <p className="text-left mb-1 font-semibold">{label}</p>
        <select
          onChange={(e) => handleChange(e, index)}
          onBlur={() => handleOnBlur(index)}
          onFocus={() => handleOnFocus()}
          className={`p-3 border-2 rounded py-1 h-12 ${
            value ? "text-black" : "text-slate-400"
          } ${
            error && isRequired && !value ? "border-red-200" : "border-gray-200"
          }`}
          value={value}
          aria-label={ariaLabel}
        >
          {[
            <option value="" disabled selected className="invisible">
              {placeholder ? placeholder : label}
            </option>,
            ...optionsList,
          ]}
        </select>
        {error & !value && isRequired ? (
          <text className="text-red-400">Please select a value </text>
        ) : (
          <div />
        )}
      </div>
    );
  };

  const inlineFormField = (formData, index) => {
    const { id, label, value, options, dropDownOptions } = formData;
    const placeholder = formData.placeholder ?? "";
    const type = formData.type ?? "text";
    const min = formData.min ?? 0;
    const max = formData.max ?? Infinity;
    const ariaLabel = formData.ariaLabel ?? "";
    const isRequired = formData.isRequired ?? true;

    if (dropDownOptions) {
      return dropdownOptionsInput(
        label,
        value,
        index,
        dropDownOptions,
        placeholder,
        ariaLabel,
        isRequired,
      );
    } else if (options) {
      return radioInput(label, value, index, options, ariaLabel, isRequired);
    } else if (type === "autocomplete") {
      let items = [];

      if (id === "pharmacy") {
        items = marketingOrgs.filter(({ isPharmacy }) => isPharmacy);
      } else if (id === "agency") {
        items = marketingOrgs.filter(({ isAgency }) => isAgency);
      } else if (id === "ivfClinic") {
        items = marketingOrgs.filter(({ isClinic }) => isClinic);
      }

      if (
        items.length > 0 &&
        ["pharmacy", "agency", "ivfClinic"].includes(id)
      ) {
        items = [
          ...new Set(items.map(({ nameOfBusiness }) => nameOfBusiness).sort()),
        ];
      }

      return (
        <Autocomplete
          inputProps={{
            ...formData,
            index,
          }}
          items={items}
          onSelect={handleChange}
          onBlur={handleOnBlur}
        />
      );
    } else {
      return textInput(
        label,
        value,
        index,
        placeholder,
        type,
        min,
        max,
        ariaLabel,
        isRequired,
        id,
      );
    }
  };

  const gridFormField = (formData, index) => {
    return (
      <div className={formData.spansDouble ? "col-span-2" : "col-span-1"}>
        {inlineFormField(formData, index)}
      </div>
    );
  };

  const ivfReasonField = (formData, index) => {
    return (
      <div className="col-span-2">
        <SFIvfReasonInput
          id={formData.id}
          label={formData.label}
          values={formData.values}
          value={formData.value}
          options={formData.options}
          onChange={(newValues) => handleCheckboxesChange(newValues, index)}
          onOtherChange={(event) => handleChange(event, index)}
          onBlur={() => handleOnBlur(index)}
          onOtherBlur={() =>
            handleOnBlur(index, {
              id: steps[currentStep]?.questions[index]?.otherId,
            })
          }
          ariaLabel={formData.ariaLabel}
          isRequired={formData.isRequired}
          error={error ? "An error occurred" : null}
        />
      </div>
    );
  };

  const amhLevelToDbValue = (amhLevel) => {
    if (amhLevel !== null && typeof amhLevel === "number" && isNaN(amhLevel)) {
      return "I don't know";
    } else {
      return amhLevel;
    }
  };

  const amhLevelField = (formData, index) => {
    return (
      <div className="col-span-2">
        <SFAmhLevelInput
          id={formData.id}
          label={formData.label}
          value={formData.value}
          placeholder={formData.placeholder}
          onChange={(newValue) => handleAmhLevelChange(newValue, index)}
          onBlur={() =>
            handleOnBlur(index, {
              value: amhLevelToDbValue(
                steps[currentStep]?.questions[index]?.value,
              ),
            })
          }
          isRequred={false}
          error={error ? "An error occurred" : null}
        />
      </div>
    );
  };

  const createSunfishForm = (steps, currentStep) => {
    const formFields = [];
    if (steps) {
      for (
        var index = 0;
        index < steps[currentStep].questions.length;
        index++
      ) {
        var currentFormData = steps[currentStep].questions[index];
        if (currentFormData.gridId !== undefined) {
          const gridFormFields = [];
          const gridFormData = steps[currentStep].questions.filter(
            (e) => e.gridId === currentFormData.gridId,
          );
          var gridSize = 0;
          for (
            var gridIndex = 0;
            gridIndex < gridFormData.length;
            gridIndex++
          ) {
            var currentGridFormData = gridFormData[gridIndex];
            gridSize = currentGridFormData.gridSize;
            gridFormFields.push(
              gridFormField(currentGridFormData, gridIndex + index),
            );
          }
          formFields.push(
            <div className="flex flex-col">
              <div className={`grid sm:grid-cols-1 lg:grid-cols-${gridSize}`}>
                {gridFormFields.map((e) => e)}
              </div>
            </div>,
          );
          index += gridFormData.length - 1;
        } else if (currentFormData.type === "ivfReason") {
          formFields.push(ivfReasonField(currentFormData, index));
        } else if (currentFormData.type === "amhLevel") {
          formFields.push(amhLevelField(currentFormData, index));
        } else {
          formFields.push(inlineFormField(currentFormData, index));
        }
      }
    }
    return formFields;
  };

  const findValueById = (searchById) => {
    for (var index = 0; index < steps.length; index++) {
      var value = steps[index].questions.find(
        (v) => v.id === searchById,
      )?.value;
      if (value) return value;
    }
  };

  const findValuesById = (searchById) => {
    for (var index = 0; index < steps.length; index++) {
      var values = steps[index].questions.find(
        (v) => v.id === searchById,
      )?.values;
      if (values) return values;
    }
    return null;
  };

  const generateFormValues = () => {
    const stateId = findValueById("state");
    const state = stateId ? usStates[stateId] : "";

    const telNum = findValueById("telephone");
    const telephone = telNum
      ? telNum
          .replace("(", "")
          .replace(")", "")
          .replace("-", "")
          .replace(" ", "")
      : null;

    let vals = {
      firstName: findValueById("firstName"),
      lastName: findValueById("lastName"),
      loanAmount: parseCurrency(findValueById("loanAmount")),
      loanPurpose: findValueById("loanPurpose") || null,
      loanTimeFrame: findValueById("loanTimeFrame") || null,
      telephone,
      dateOfBirth: findValueById("dateOfBirth") || null,
      ivfClinic: findValueById("ivfClinic") || null,
      howDidYouHearAboutUs: findValueById("howDidYouHearAboutUs") || null,
      householdAssets: findValueById("householdAssets") || null,
      willingnessToMoveAssets: findValueById("willingnessToMoveAssets") || null,
      address1: findValueById("address1") || null,
      address2: findValueById("address2") || "",
      city: findValueById("city") || null,
      state,
      src: "Web",
      email: user?.email,
      pharmacy: findValueById("pharmacy") || null,
      pharmacyCity: findValueById("pharmacyCity") || null,
      pharmacyState: findValueById("pharmacyState") || null,
      usResidencyStatus: findValueById("usResidencyStatus") || null,
      zipCode: findValueById("zipCode") || null,
      agency: findValueById("agency") || null,

      heightInFeet: findValueById("heightInFeet") || null,
      heightInInches: findValueById("heightInInches") || null,
      weight: findValueById("weight") || null,
      amhLevel: amhLevelToDbValue(findValueById("amhLevel") || null),
      ivfReason: findValuesById("ivfReason"),
      ivfReasonOther: findValueById("ivfReason") || null,
      ownOrDonorEggs: findValueById("ownOrDonorEggs") || null,
      howManyTimesUsedIvf: findValueById("howManyTimesUsedIvf") || null,
      gravida: findValueById("gravida") || null,
      priorFullTermBirths: findValueById("priorFullTermBirths") || null,
    };

    if (!reducedFormEnabled) {
      vals = {
        employmentStatus: findValueById("employmentStatus") || null,
        householdIncome: findValueById("householdIncome") || null,
        individualIncome: findValueById("individualIncome") || null,
        highestLevelOfEducation:
          findValueById("highestLevelOfEducation") || null,
        incomeFrequency: findValueById("incomeFrequency") || null,
        address1: findValueById("address1") || null,
        address2: findValueById("address2") || "",
        city: findValueById("city") || null,
        state,
        ownOrRent: findValueById("ownOrRent") || null,
        yearsThereZip: findValueById("yearsThereZip") || null,
        creditScore: findValueById("creditScore") || null,
        ...vals,
      };
    }

    setSavedFormValues(vals);

    return vals;
  };

  const upsideState = useSelector(({ upsideState }) => upsideState);

  const handleUpside = async ({ formValues }) => {
    const leadRequest = new LeadSubmissionRequest({
      leadUuid: user.uid,
      partnerSubAccountUuid: "99aca1db-02cc-47b5-b2d8-b57f90695543", // TODO: Use UUID provided when ready
      allowsRemarketing: true,
      personalInformation: {
        address1: formValues.address1,
        address2: formValues.address2,
        city: formValues.city,
        dateOfBirth: formValues.dateOfBirth,
        email: formValues.email,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        primaryPhone: formValues.telephone,
        ssn: "666015670", // TODO: Use user supplied SSN
        state: formValues.state,
        zipcode: formValues.zipCode,
      },
      financialInformation: {
        annualIncome: formValues.individualIncome,
        employmentStatus: formValues.employmentStatus,
        employmentPayFrequency: formValues.incomeFrequency,
      },
      loanInformation: {
        loanAmount: formValues.loanAmount,
        purpose: LoanInformation.PURPOSE_DEBT_CONSOLIDATION,
      },
      creditInformation: {
        creditRating: formValues.creditScore,
      },
      mortgageInformation: {
        propertyStatus: formValues.ownOrRent,
      },
      educationInformation: {
        educationLevel: formValues.highestLevelOfEducation,
      },
    });

    dispatch(authorize()).then(() => dispatch(lead({ lead: leadRequest })));
  };

  // bypassUpside - Used to not call Upside. Primarily will be used if on Upside API
  // errors after retries or has no offers, bypass it and go to Even.
  const routeToLender = async ({ formValues, options }) => {
    const { bypassUpside, setEvenUrlOnly, setAndOpenEvenUrl } = options;
    const flags = await areFeatureFlagsEnabled(["upside"]);

    if (flags.upside && !bypassUpside) {
      await handleUpside({ formValues });
      return;
    }

    const usersOffersUrl = await createUsersOffersUrl(user?.uid, formValues);

    if (setEvenUrlOnly) {
      dispatch(setUsersOffersURL(usersOffersUrl));
    } else if (setAndOpenEvenUrl) {
      openInSameTab(usersOffersUrl);
      window.top.postMessage({ type: "route", route: usersOffersUrl }, "*");
    }
    return;
  };

  useEffect(() => {
    const { requestId, offers, failed } = upsideState.leadResponse;
    if ((requestId && offers.length === 0) || failed) {
      const formValues = generateFormValues();
      routeToLender({
        formValues,
        options: { bypassUpside: true, setAndOpenEvenUrl: true },
      });
    }
  }, [upsideState.leadResponse]);

  const updateUser = async (formCompletedStatus, nextStep) => {
    const formValues = generateFormValues();
    handleFullFormDbUpdate(formValues);
    if (formCompletedStatus) {
      setFormCompletedStatus(formCompletedStatus);
      routeToLender({
        formValues,
        options: { bypassUpside: myDashboardPage, setEvenUrlOnly: true },
      });
    }
    Store.dispatch(
      setShowJourneyDetails(
        includeIvfJourneyQuestions &&
          formValues.loanPurpose === ivfAndMedication,
      ),
    );
    Store.dispatch(setCurrentStep(nextStep));
  };

  const createUser = () => {
    const formValues = generateFormValues();
    handleFullFormDbUpdate(formValues);
    setFormCompletedStatus(true);

    routeToLender({ formValues, options: { setAndOpenEvenUrl: true } });
  };

  const formatCurrency = (value) => {
    return value.toLocaleString("en-us", {
      style: "currency",
      currency: "usd",
      minimumFractionDigits: 0,
    });
  };

  const parseCurrency = (value) => {
    if (!value) {
      return 0;
    }

    return parseInt(value.substring(1).replace(",", ""));
  };

  useEffect(() => {
    if (user && steps !== undefined && !loadedUser) {
      const newSteps = steps.map((step) => {
        const newQuestions = step.questions.map((question) => {
          let newValue = null;
          let newValues = undefined;
          if (question.id === "loanAmount") {
            newValue = formatCurrency(user?.loanAmount ?? "");
          } else if (question.id === "telephone" && user?.telephone) {
            const areaCode = user?.telephone?.substring(0, 3) ?? "";
            const firstThreeDigits = user?.telephone?.substring(3, 6) ?? "";
            const lastFourDigits = user?.telephone?.substring(6) ?? "";
            newValue = `(${areaCode}) ${firstThreeDigits}-${lastFourDigits}`;
          } else if (question.id === "ivfClinic") {
            var clinicPartnerName = partner?.name ?? "";
            if (clinicPartnerName.length !== 0 && partner?.type === "clinic") {
              newValue = clinicPartnerName;
            } else {
              newValue = user.ivfClinic;
            }
          } else if (question.id === "agency") {
            var agencyPartnerName = partner?.name ?? "";
            if (agencyPartnerName.length !== 0 && partner?.type === "agency") {
              newValue = agencyPartnerName;
            } else {
              newValue = user.agency;
            }
          } else if (question.id === "householdIncome") {
            newValue = formatCurrency(user?.householdIncome ?? "");
          } else if (question.id === "state") {
            Object.keys(usStates).forEach((stateName) => {
              if (usStates[stateName] === user.state) {
                newValue = stateName;
              }
            });
          } else if (question.id === "pharmacy") {
            var pharmacyPartnerName = partner?.name ?? "";
            if (
              pharmacyPartnerName.length !== 0 &&
              partner?.type === "pharmacy"
            ) {
              newValue = pharmacyPartnerName;
            } else {
              newValue = user.pharmacy;
            }
          } else if (question.id === "ivfReason") {
            newValues = user?.ivfReason;
            newValue = user?.ivfReasonOther;
          } else {
            newValue = user[question.id];
          }
          return {
            ...question,
            value: newValue || "",
            values: newValues,
          };
        });
        return {
          ...step,
          questions: newQuestions,
        };
      });
      setSteps(newSteps);
      setLoadedUser(true);
    }
  }, [user, steps, loadedUser, partner]);

  useEffect(() => {
    if (
      Object.keys(savedFormValues).length === 0 &&
      loadedUser &&
      steps &&
      steps.length > 0
    ) {
      setSavedFormValues(generateFormValues());
    }
  }, [user, loadedUser, steps]);

  var removeSpecialCharacters = (s) => {
    var digitString = "";
    for (var x = 0; x < s.length; x++) {
      var char = s[x];
      if (char >= "0" && char <= "9") {
        digitString += char;
      }
    }
    return digitString;
  };

  const formatPhoneNumber = (existingPhoneValue, newPhoneValue) => {
    if (newPhoneValue.length > existingPhoneValue.length) {
      var sanatizedString = removeSpecialCharacters(newPhoneValue);
      if (sanatizedString.length < 3) {
        return `(${sanatizedString}`;
      } else if (sanatizedString.length === 3) {
        return `(${sanatizedString}`;
      } else if (sanatizedString.length > 3 && sanatizedString.length < 6) {
        return `(${sanatizedString.substring(
          0,
          3,
        )}) ${sanatizedString.substring(3)}`;
      } else if (sanatizedString.length >= 6) {
        return `(${sanatizedString.substring(
          0,
          3,
        )}) ${sanatizedString.substring(3, 6)}-${sanatizedString.substring(
          6,
          10,
        )}`;
      }
    }
    return newPhoneValue;
  };

  const handleChange = (event, updateIndex) => {
    setError(false);
    var newQuestions = [...steps[currentStep].questions].map(
      (object, index) => {
        if (updateIndex === index) {
          if (object.label === "Your telephone?") {
            return {
              ...object,
              value: formatPhoneNumber(object.value, event.target.value),
            };
          } else if (object.label === "Your zipcode?") {
            // required for android chrome input validation
            return {
              ...object,
              value: event.target.value.replace(/\D/g, ""),
            };
          } else if (object.type === "currency") {
            var loanValueString = event.target.value;
            if (object.value.length >= 1) {
              loanValueString = loanValueString.substring(1);
            }
            var formattedLoanValue = event.target.value;
            if (loanValueString) {
              var loanValue = parseInt(loanValueString.replace(",", ""));
              formattedLoanValue = formatCurrency(loanValue);
            }
            return {
              ...object,
              value: `${formattedLoanValue}`,
            };
          } else if (
            object.maxLength &&
            event.target.value.length > object.maxLength
          ) {
            return {
              ...object,
              value: event.target.value.substring(0, object.maxLength),
            };
          }
          return {
            ...object,
            value: event.target.value,
          };
        } else return object;
      },
    );
    var newSteps = steps;
    newSteps[currentStep].questions = newQuestions;
    setSteps([...newSteps]);
  };

  const handleSelectionChange = (value, updateIndex) => {
    setError(false);
    var newQuestions = [...steps[currentStep].questions].map(
      (object, index) => {
        if (updateIndex === index) {
          return {
            ...object,
            value: value,
          };
        } else return object;
      },
    );
    var newSteps = steps;
    newSteps[currentStep].questions = newQuestions;
    setSteps([...newSteps]);
  };

  const handleCheckboxesChange = (values, updateIndex) => {
    setError(false);
    var newQuestions = [...steps[currentStep].questions].map(
      (object, index) => {
        if (updateIndex === index) {
          return {
            ...object,
            values: values,
          };
        } else return object;
      },
    );
    var newSteps = steps;
    newSteps[currentStep].questions = newQuestions;
    setSteps([...newSteps]);
  };

  const handleAmhLevelChange = (value, updateIndex) => {
    setError(false);
    var newQuestions = [...steps[currentStep].questions].map(
      (object, index) => {
        if (updateIndex === index) {
          return {
            ...object,
            value,
          };
        } else return object;
      },
    );
    var newSteps = steps;
    newSteps[currentStep].questions = newQuestions;
    setSteps([...newSteps]);
  };

  const validateFormStep = () => {
    var missingFields = false;
    for (var index = 0; index < steps[currentStep].questions.length; index++) {
      const question = steps[currentStep].questions[index];
      const isRequired = question.isRequired ?? true;
      const value = question.value;
      if (isRequired && (value === undefined || value === "")) {
        missingFields = true;
      }
    }
    return missingFields;
  };

  const validateFormStepValues = () => {
    let hasInvalidValues = false;
    for (var index = 0; index < steps[currentStep].questions.length; index++) {
      const question = steps[currentStep].questions[index];

      const isCurrencyQuestion = question.type === "currency";
      const isFloatQuestion = question.type === "amhLevel";
      const isNumericQuestion =
        question.type === "number" || isFloatQuestion || isCurrencyQuestion;

      if (
        isNumericQuestion &&
        (question.min || question.min === 0) &&
        question.max
      ) {
        let value = null;
        if (isFloatQuestion) {
          value = parseFloat(question.value).toFixed(2);
        } else if (isCurrencyQuestion) {
          value = parseCurrency(question.value);
        } else {
          value = parseInt(question.value);
        }

        if (value < question.min || value > question.max) {
          hasInvalidValues = true;
        }
      }

      if (
        !hasInvalidValues &&
        isFloatQuestion &&
        typeof question.value === "number" &&
        !isNaN(question.value)
      ) {
        const numStr = question.value.toString();

        let numDecimalPlaces = 0;
        if (numStr.includes(".")) {
          numDecimalPlaces = numStr.toString().split(".")[1].length;
        }

        if (numDecimalPlaces !== 2) {
          hasInvalidValues = true;
        }
      }

      if (!hasInvalidValues && question.id === "dateOfBirth") {
        hasInvalidValues = new Date(question.value) >= new Date();
      }

      if (hasInvalidValues) {
        // Short-circuit if we found we have invalid values.
        break;
      }
    }

    return hasInvalidValues;
  };

  const openInSameTab = (url) => {
    var win = window.open(url, "_self");
    win.focus();
  };

  const skipJourneyDetailsStep = (nextStep) => {
    if (nextStep.title !== journeyDetailsTitle) {
      return false;
    } else if (!includeIvfJourneyQuestions) {
      return true;
    }

    let loanPurpose = findValueById("loanPurpose");
    if (loanPurpose && loanPurpose !== ivfAndMedication) {
      return true;
    }

    return false;
  };

  let skipPageBlock = null;
  if (!myDashboardPage && steps && steps[currentStep].optional) {
    skipPageBlock = (
      <span
        className="text-navyblue hover:underline hover:cursor-pointer"
        onClick={() =>
          scrollToFooterRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          })
        }
      >
        You may skip this page.
      </span>
    );
  }

  let descriptionBlock = null;
  if (steps && steps[currentStep].description) {
    descriptionBlock = (
      <p className="w-full px-2 text-center">
        {steps[currentStep].description}&nbsp;{skipPageBlock}
        <br />
      </p>
    );
  }

  let title = "";
  if (steps) {
    title = steps[currentStep]?.title;
    if (steps[currentStep]?.optional) {
      title = title.concat(" (Optional)");
    }
  }

  return (
    <div>
      <div
        className={
          myDashboardPage
            ? "bg-lightgreenblue"
            : "bg-aliceBlue py-16 lg:py-20 flex items-center justify-center flex-col"
        }
      >
        <OurOfferingsFormHeader
          formCompleted={formCompleted}
          myDashboardPage={myDashboardPage}
        />
        <div
          className={
            myDashboardPage
              ? "sm:w-full md:w-5/6"
              : "bg-white p-7 py-10 lg:p-10 w-10/12 lg:w-7/12 mt-5 lg:mt-10 rounded-3xl"
          }
        >
          {myDashboardPage ? (
            <></>
          ) : (
            <OurOfferingsFormBackButton
              steps={steps}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              myDashboardPage={myDashboardPage}
            />
          )}
          <div className="flex flex-col">
            {myDashboardPage && !isDashboardVisible ? (
              <button
                onClick={() => Store.dispatch(setDashboardVisible(true))}
                className="lg:hidden sm:block text-blue-900 text-lg font-semibold lg:hidden focus:outline-none"
                aria-label="show-menu"
              >
                <div className="flex flex-row-reverse gap-2 px-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 pt-1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  </svg>
                  <p className="h-6">Manage profile</p>
                </div>
              </button>
            ) : (
              <div> </div>
            )}
            <h3 className="w-full px-2 text-center font-bold text-3xl text-navyblue">
              {title}
            </h3>
            {descriptionBlock}
          </div>
          {createSunfishForm(steps, currentStep).map((e, idx) => (
            <div key={`sunfishFormField${idx}`}>{e}</div>
          ))}
          <OurOfferingsFormFooter
            currentStep={currentStep}
            error={error}
            setError={setError}
            steps={steps}
            skipNextStep={skipJourneyDetailsStep}
            validateFormStep={validateFormStep}
            validateFormStepValues={validateFormStepValues}
            createUser={createUser}
            updateUser={updateUser}
            myDashboardPage={myDashboardPage}
          />
        </div>
        <div ref={scrollToFooterRef} />
      </div>
    </div>
  );
}

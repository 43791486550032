import React, { useState, useRef, useEffect } from "react";
import Store from "../App/Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginModalState } from "../App/Features/UserSplice";
import EvenFundingUploadBanner from "../Components/Banners/EvenFundingUpload";
import { uploadFundedEvenLoansCsv } from "../api";

export default function EvenFundingUploadPage() {
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [message, setMessage] = useState(null);
  const fundingCsvInput = useRef(null);
  const user = useSelector((state) => state.userState.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      Store.dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: true,
        })
      );
    } else if (!user?.acl?.marketplace) {
      navigate("/");
    }
  }, [user, navigate]);

  let messageBox = null;
  if (message) {
    messageBox = <div>{message}</div>;
  }

  function onUploadCsv(event) {
    event.preventDefault();
    setDisableSubmit(true);

    uploadFundedEvenLoansCsv(fundingCsvInput.current.files)
      .then(() => {
        setMessage("Upload completed successfully.");
        setDisableSubmit(false);
      })
      .catch((error) => {
        if (error.message) {
          setMessage(error.message);
        } else {
          setMessage(JSON.stringify(error));
        }
        setDisableSubmit(false);
      });
  }

  return (
    <div>
      <EvenFundingUploadBanner />
      <div className="flex flex-col w-screen items-center justify-center bg-blue-100 py-16">
        <div className="grid place-items-center w-10/12 bg-white p-10 m-5 rounded-3xl">
          <div className="w-5/12">
            {messageBox}
            <form onSubmit={onUploadCsv}>
              <input
                type="file"
                id="fundingCsv"
                name="fundingCsv"
                accept="text/csv"
                ref={fundingCsvInput}
              />
              <input
                type="submit"
                disabled={disableSubmit}
                className="literata-font font-semibold text-white bg-navyblue py-3 my-2 px-6 rounded-full text-center"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

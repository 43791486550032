export default function EachParenthood() {
  return (
    <div className="grid lg:grid-cols-2 md:grid-cols-1 place-items-center space-x-16 lg:px-32 sm:px-2 py-24">
      <div className="font-semibold sm:text-xl md:text-3xl lg:text-6xl text-navyblue literata-font sm:py-5 md:py-10 lg:py-0">
        Each parenthood journey{" "}
        <span className="literata-font font-normal italic">is unique.</span>
      </div>
      <p class="sm:text-center text-md leading-9 text-gray-700">
        Often people need several IVF cycles for pregnancy. As part of the
        Sunfish IVF Program, we use a predictive financial model to predict the
        chance of success of pregnancy based on your family’s unique factors. We
        want to support you on this journey to building your family – whatever
        that might look like for you. <br /> <br />{" "}
        <b>You don’t need to do this alone.</b>
      </p>
    </div>
  );
}

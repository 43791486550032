import React from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  setSelectedPartner,
  setStatus,
} from "../../App/Features/ManagePartnersSlice";
import SFInput from "../Inputs/SFInput";
import SFBlueButton from "../Buttons/SFBlueButton";
import SFDropdown from "../Inputs/SFDropdown";
import { addPartnerMetadata } from "../../api";

export default function AddPartnerForm() {
  const status = useSelector((state) => state.managePartnersState.status);
  const types = ["agency", "clinic", "pharmacy", "strategic"];
  const selectedPartner = useSelector(
    (state) => state.managePartnersState.selectedPartner
  );

  return (
    <div>
      <SFInput
        label={"Name"}
        handleChange={(e) =>
          Store.dispatch(
            setSelectedPartner({ ...selectedPartner, name: e.target.value })
          )
        }
        value={selectedPartner?.name}
        error={status === "error" && !selectedPartner?.name}
      />
      <SFInput
        label={"Message"}
        handleChange={(e) =>
          Store.dispatch(
            setSelectedPartner({
              ...selectedPartner,
              message: e.target.value,
            })
          )
        }
        value={selectedPartner?.message}
        error={status === "error" && !selectedPartner?.message}
      />
      <SFInput
        label={"Route"}
        handleChange={(e) =>
          Store.dispatch(
            setSelectedPartner({
              ...selectedPartner,
              route: e.target.value,
            })
          )
        }
        value={selectedPartner?.route}
        error={
          status === "error" &&
          (selectedPartner?.route?.length < 2 ||
            !selectedPartner?.route?.startsWith("/"))
        }
        validationText={
          selectedPartner?.route?.length < 2 ||
          !selectedPartner?.route?.startsWith("/")
            ? 'Please enter a route e.g. "/hello-world"'
            : undefined
        }
      />
      <SFDropdown
        title="Type"
        handleChange={(e) =>
          Store.dispatch(
            setSelectedPartner({
              ...selectedPartner,
              type: types[e.target.value],
            })
          )
        }
        placeholderLabel="Select a type"
        options={types?.map((type, index) => (
          <option selected={type === selectedPartner?.type} value={index}>
            {type}
          </option>
        ))}
        error={status === "error" && !selectedPartner?.type}
      />
      <div className="grid place-items-center">
        <SFBlueButton
          title={"Submit"}
          onClick={async () => {
            if (
              selectedPartner?.name?.length &&
              selectedPartner?.message?.length &&
              selectedPartner?.route?.length >= 2 &&
              selectedPartner?.route?.startsWith("/") &&
              selectedPartner?.type?.length
            ) {
              addPartnerMetadata({ ...selectedPartner, active: true }).then(
                (value) => {
                  if (value) {
                    Store.dispatch(setStatus("complete"));
                  } else {
                    Store.dispatch(setStatus("error"));
                  }
                }
              );
            } else {
              Store.dispatch(setStatus("error"));
            }
          }}
        />
      </div>
    </div>
  );
}

import { setResponses } from "../../App/Features/Calculator/CalculatorSlice";
import { useSelector } from "react-redux";
import Store from "../../App/Store";

export default function CalculatorUI({ id, question }) {
  const responses = useSelector((state) => state.calculator.responses);

  const handleChange = (id, e) => {
    const newFormData = { ...responses, [id]: parseInt(e.target.value) };
    Store.dispatch(setResponses(newFormData));
  };

  const options = question.options.map((option) => {
    return <option value={option.id}>{option.value}</option>;
  });

  return (
    <div className="grid">
      <p className="font-medium place-self-start">{question.question}</p>
      <div className="bg-white w-full rounded-3xl px-4 mt-2 place-self-end">
        <select
          className="w-full my-2 py-2 outline-none"
          value={responses[id]}
          onChange={(e) => handleChange(id, e)}
        >
          {options}
        </select>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const navSplice = createSlice({
  name: "nav",
  initialState: {
    currentPath: null,
    previousPath: null,
    routeToHome: false,
    privacyPolicyModal: {
      showPrivacyPolicyModal: false,
    },
    eCommunicationModal: {
      showECommunicationModal: false,
    },
  },
  reducers: {
    setECommunicationModal: (state, action) => {
      state.eCommunicationModal = action.payload;
    },
    setPrivacyPolicyModal: (state, action) => {
      state.privacyPolicyModal = action.payload;
    },
    setCurrentPathState: (state, action) => {
      state.currentPath = action.payload;
    },
    setPreviousPathState: (state, action) => {
      state.previousPath = action.payload;
    },
    setRouteToHome: (state, action) => {
      state.routeToHome = true;
    },
    resetRouteToHome: (state, action) => {
      state.routeToHome = false;
    },
  },
});

export const {
  setCurrentPathState,
  setPreviousPathState,
  setRouteToHome,
  resetRouteToHome,
  setECommunicationModal,
  setPrivacyPolicyModal,
} = navSplice.actions;

export default navSplice.reducer;

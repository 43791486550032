export default function ClinicAgencies() {
  return (
    <div className="w-full lg:h-1/2 sm:h-11/12 bg-navyblue py-20">
      <div className="text-center lg:w-1/2 mx-auto text-white">
        <h1 className="text-4xl font-semibold lg:leading-tight lg:text-6xl literata-font py-10">
          <span className="literata-font font-normal italic">
            Clinics, agencies, pharmacies, and other providers
          </span>{" "}
          around the country have joined Sunfish
        </h1>
        <p className="text-2xl"> Become a preferred provider.</p>
      </div>
    </div>
  );
}

export enum ProfessionalGroupType {
  any = "Any",
  military = "Military",
  k12Teachers = "K-12 Teachers",
  registeredNurses = "Registered Nurses",
}

export class ProfessionalGroupFilter {
  data: ProfessionalGroupType;

  constructor(data: ProfessionalGroupType) {
    this.data = data;
  }

  isGrantValid(grant): Boolean {
    var anyGrant: Boolean = grant.professionalGroup.toLowerCase() === "any";
    if (this.data === ProfessionalGroupType.any) {
      return true;
    } else {
      const professionalGroupType = this.data;
      const searchString: string = professionalGroupType
        .toString()
        .toLowerCase();
      const professionalGroup = grant.professionalGroup;
      if (professionalGroup) {
        const grantSearch: string = professionalGroup
          .toString()
          .toLowerCase()
          .trim();
        var value: Boolean = grantSearch.includes(searchString);
        if (value) {
          return anyGrant || true;
        }
      }
    }
    return false;
  }
}

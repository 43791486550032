import OurOfferingsButton from "./OurOfferingsButton";
import {
  progressAffiliatedUser,
  progressPartnerUser,
  progressTrackableLink,
} from "../../api.js";
import { useSelector } from "react-redux";

export default function OurOfferingsFormFooter({
  currentStep,
  error,
  setError,
  steps,
  skipNextStep,
  validateFormStep,
  validateFormStepValues,
  createUser,
  updateUser,
  myDashboardPage = false,
}) {
  const accountInfoFormCompleted = useSelector(
    (state) => state.myDashboardState.accountInfoFormCompleted,
  );
  const affiliateId = useSelector((state) => state.affiliateState.affiliateId);
  const affiliateUserId = useSelector(
    (state) => state.affiliateState.affiliateUserId,
  );
  const partner = useSelector((state) => state.partnerState.partner);
  const partnerUserId = useSelector(
    (state) => state.partnerState.partnerUserId,
  );
  const linkId = useSelector((state) => state.trackableLinkState.link);
  const linkUserId = useSelector((state) => state.trackableLinkState.userId);

  return myDashboardPage ? (
    <div className="flex flex-col place-items-center pt-2">
      <OurOfferingsButton
        title={"SAVE"}
        onClick={() => {
          var missingFields = validateFormStep();
          const hasInvalidValues = validateFormStepValues();
          if (missingFields || hasInvalidValues) {
            setError(true);
          } else {
            setError(false);
            document.documentElement.scrollTo({
              top: 0,
              left: 0,
              behavior: "instant",
            });
            updateUser(true, currentStep);
          }
        }}
      />
      {accountInfoFormCompleted ? (
        <div className="font-bold text-navyblue text-center py-2">
          <text>Your information is updated</text>
        </div>
      ) : (
        <div> </div>
      )}
    </div>
  ) : (
    <div>
      {error ? (
        <text className="text-red-400">Please agree before proceeding</text>
      ) : (
        <div />
      )}
      {currentStep === steps?.length - 1 ||
      (currentStep === steps?.length - 2 &&
        skipNextStep &&
        skipNextStep(steps[currentStep + 1])) ? (
        <div className="flex flex-col place-items-center">
          <div className="flex flex-row">
            <OurOfferingsButton
              title={"SUBMIT"}
              onClick={() => {
                const missingFields = validateFormStep();
                const hasInvalidValues = validateFormStepValues();
                if (missingFields || hasInvalidValues) {
                  setError(true);
                } else {
                  setError(false);
                  createUser();
                  progressAffiliatedUser({
                    affiliateId: affiliateId,
                    affiliateUserId: affiliateUserId,
                    affiliateStatus: "sf_form_completion",
                  });
                  progressPartnerUser({
                    partnerUserId: partnerUserId,
                    partner: partner,
                    affiliateStatus: "sf_form_completion",
                  });
                  progressTrackableLink({
                    link: linkId,
                    linkUserId: linkUserId,
                    affiliateStatus: "sf_form_completion",
                  });
                }
              }}
            />
          </div>
        </div>
      ) : (
        <OurOfferingsButton
          title={"CONTINUE"}
          onClick={() => {
            var missingFields = validateFormStep();
            const hasInvalidValues = validateFormStepValues();
            if (missingFields || hasInvalidValues) {
              setError(true);
            } else {
              setError(false);
              if (currentStep === 0) {
                progressAffiliatedUser({
                  affiliateId: affiliateId,
                  affiliateUserId: affiliateUserId,
                  affiliateStatus: "sf_form_start",
                });
                progressPartnerUser({
                  partnerUserId: partnerUserId,
                  partner: partner,
                  affiliateStatus: "sf_form_start",
                });
                progressTrackableLink({
                  link: linkId,
                  linkUserId: linkUserId,
                  affiliateStatus: "sf_form_start",
                });
              }
              var nextStep = currentStep + 1;
              if (skipNextStep && skipNextStep(steps[nextStep])) {
                nextStep = nextStep + 1;
              }
              updateUser(false, nextStep);
            }
          }}
        />
      )}
    </div>
  );
}

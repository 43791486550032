import React from "react";
import SFBanner, { SFBannerTitle, SFBannerSubTitle } from "../Banners/SFBanner";
import TabButton from "../Buttons/TabButton";
import {
  partnerMangementTypes,
  setSelectedType,
} from "../../App/Features/ManagePartnersSlice";
import { useSelector } from "react-redux";
import Store from "../../App/Store";

export default function PartnersManagementBanner({ bodyRef }) {
  const selectedType = useSelector(
    (state) => state.managePartnersState.selectedType
  );

  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle title="Manage Partner Links" bodyAlignment="center" />
        </div>
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            <div className="grid lg:grid-cols-4 md:grid-cols-1 gap-5 py-10 px-20">
              {Object.keys(partnerMangementTypes).map((key) => (
                <TabButton
                  title={partnerMangementTypes[key].title}
                  onClick={() => {
                    Store.dispatch(setSelectedType(key));
                    bodyRef.current.scrollIntoView();
                  }}
                  isSelected={selectedType === key}
                />
              ))}
            </div>
          }
        />
      }
      bgImage="md:bg-sfBackground bg-sfBackgroundSm bg-cover bg-no-repeat"
      bodyAlignment="center"
    />
  );
}

import { CheckIcon } from "@heroicons/react/solid";
import React from "react";
import SFBanner, {
  SFBannerImage,
  SFBannerSubTitle,
  SFBannerTitle,
  SFBannerWomenWithLaptopLg,
} from "./SFBanner";

export default function EducationalResourcesBanner() {
  const subTitle = (
    <div className="flex flex-col max-w-96 py-5">
      <Check text="Freezing Eggs or Embryos" />
      <Check text="In-vitro Fertilization (IVF)" />
      <Check text="Egg donors &amp; Surrogacy" />
    </div>
  );
  return (
    <div className="w-full">
      <SFBannerWomenWithLaptopLg
        title={
          <SFBannerTitle
            title="Educational "
            titleSpan={
              <div className="font-normal literata-font italic ml-16">
                Resources
              </div>
            }
            bodyAlignment="right"
          />
        }
        subTitle={subTitle}
      />
      <div className="lg:hidden sm:block">
        <SFBanner
          title={
            <SFBannerTitle
              title="Educational "
              titleSpan="Resources"
              bodyAlignment="right"
            />
          }
          subTitle={<SFBannerSubTitle subTitle={subTitle} />}
          img={
            <SFBannerImage
              imgSrc="/assets/educationBg.png"
              imgSrcSm="/assets/educationBgSm.png"
              alt="aspiring_parent_with_laptop"
            />
          }
          bodyAlignment="right"
          bgImage="bg-primary"
        />
      </div>
    </div>
  );
}

function Check({ text }) {
  return (
    <span className="mt-3 flex flex-start">
      <div className="h-5 bg-navyblue rounded mt-1 mr-3">
        <CheckIcon className="h-full text-white" />
      </div>
      <p className="text-lg text-gray-700">{text}</p>
    </span>
  );
}

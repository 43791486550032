export enum SortByGrantSize {
  highToLow = "High to Low",
  lowToHigh = "Low to High",
}
export class SortByGrantSizeFilter {
  data: SortByGrantSize;

  constructor(data: SortByGrantSize) {
    this.data = data;
  }

  isGrantValid(): Boolean {
    return true;
  }
}

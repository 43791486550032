import React from "react";

export default function WeValue() {
  return (
    <div className="py-24 flex justify-center bg-missiongray w-full mt-28">
      <div className="text-center w-11/12 md:w-10/12">
        <h1 className="text-4xl md:text-6xl font-bold">What we value</h1>

        <div className="grid gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-16">
          <Value
            img="/assets/empathy_and_simplicity.svg"
            title="Empathy &amp; simplicity"
            des="We know this process is emotional and complex, so we aim to offer clear and simple
                 solutions and recommendations."
          />
          <Value
            img="/assets/financial_literacy.svg"
            title="Financial literacy"
            des="Transparent tools and advice, always. We strive for auditability and honesty every step of the way."
          />
          <Value
            img="/assets/innovation.svg"
            title="Innovation"
            des="We are an evolving and ever-growing platform, always innovating new offerings to actualize
                 our vision of creating a world with equitable family building and care."
          />
        </div>
      </div>
    </div>
  );
}

function Value({ img, title, des }) {
  return (
    <div className="w-80 flex flex-col items-center ml-auto mr-auto">
      <div className="w-36 h-36 bg-gray-200 rounded-3xl flex items-center justify-center p-3">
        <img className="h-full" src={img} alt={title} />
      </div>
      <h1 className="text-2xl mt-5 font-medium bg-[#FBF9E5] text-navyblue rounded-full py-3 px-6">
        {title}
      </h1>
      <p className="mt-1">{des}</p>
    </div>
  );
}

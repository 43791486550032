export default function PartnerCard({ title, bgColor, iconImage }) {
  return (
    <div className="p-4">
      <div
        className={`place-self-center p-10 w-full rounded-3xl min-h-64 ${bgColor}`}
      >
        <div
          className={`sm:h-12 sm:w-12 md:w-24 rounded-xl bg-no-repeat bg-contain ${iconImage}`}
        ></div>
        <h1 className="pt-5 text-navyblue md:text-left md:text-2xl sm:text-xs font-bold">
          {title}
        </h1>
      </div>
    </div>
  );
}

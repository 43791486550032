import BaseUpside from "./BaseUpside";

export default class Offer extends BaseUpside {
  getMyClass() {
    return Offer;
  }

  constructor(props) {
    super(props);

    this.offerId = props.offerId ?? "";
    this.productSubType = props.productSubType ?? "";
    this.url = props.url ?? "";
    this.preQualifed = props.preQualifed ?? false;
    this.preApproved = props.preApproved ?? false;
    this.amount = props.amount ?? 0;
    this.termLength = props.termLength ?? 0;
    this.apr = Number.parseFloat(props.apr ?? 0);
    this.interestRate = Number.parseFloat(props.interestRate ?? 0);
    this.originationFee = Number.parseFloat(props.originationFee ?? 0);
    this.estimatedMonthlyPayment = Number.parseFloat(
      props.estimatedMonthlyPayment ?? 0,
    );
  }

  get aprString() {
    return this.apr.toFixed(2);
  }

  get interestRateString() {
    return this.interestRate.toFixed(2);
  }

  get originationFeeString() {
    return this.originationFee.toFixed(2);
  }

  get estimatedMonthlyPaymentString() {
    return this.estimatedMonthlyPayment.toFixed(2);
  }
}

import SFBanner, { SFBannerSubTitle, SFBannerTitle } from "./SFBanner";
import TabButton from "../Buttons/TabButton";
import Store from "../../App/Store";
import { setCurrentPartnerType } from "../../App/Features/PartnerContactUsSlice";

export default function PartnerProvidersContactUsBanner({
  partnerTypes,
  currentPartnerType,
}) {
  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle
            title="Which type of service do you offer?"
            bodyAlignment="center"
          />
        </div>
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            <div className="flex lg:flex-row sm:flex-col place-self-center lg:gap-x-10 sm:gap-y-10 p-20">
              {partnerTypes.map((e) => (
                <TabButton
                  title={e.title}
                  onClick={() => Store.dispatch(setCurrentPartnerType(e))}
                  isSelected={currentPartnerType === e}
                />
              ))}
            </div>
          }
        />
      }
      bgImage="md:bg-sfBackground bg-sfBackgroundSm bg-cover bg-no-repeat"
      bodyAlignment="center"
    />
  );
}

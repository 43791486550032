import OurOfferingsForm from "./OurOfferingsForm";
import OurOfferingsResults from "./OurOfferingsResults";
import React, { useEffect } from "react";
import { setSignUpModalState } from "../../App/Features/UserSplice";
import { useSelector } from "react-redux";
import Store from "../../App/Store";

export default function SunfishForm() {
  const user = useSelector((state) => state.userState.user);

  useEffect(() => {
    if (!user) {
      Store.dispatch(
        setSignUpModalState({
          showSignupModal: true,
          authRequired: true,
          onClosePath: "/financing-options",
        }),
      );
    }
  }, [user]);
  return (
    <>
      <OurOfferingsForm
        formCompleted={() => {}}
        setFormCompletedStatus={(e) => {}}
      />
    </>
  );
}

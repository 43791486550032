import CloseImage from "./CloseButton";

export default function SFModal({ title, body, footer, onClose }) {
  return (
    <div className="flex-col">
      <div className="w-full flex items-center justify-center h-screen fixed top-0 z-50 bg-black/20">
        <div className="relative w-10/12 md:w-4/12 p-10 flex flex-col bg-white shadow-2xl shadow-black/30 rounded-3xl">
          <CloseImage onClick={() => onClose()} />
          <div className="flex flex-col place-items-center">
            <img src="/assets/logo.png" className="h-6" alt="sunfish-logo" />
          </div>
          <h1 className="mt-5 text-center font-bold text-2xl">{title}</h1>
          <div className="h-96 bg-slate-100 overflow-y-auto rounded-md my-5">
            {body}
          </div>
          {footer}
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function DiscountsProvidersCards() {
  return (
    <div className="bg-secondary w-full flex items-center justify-center mt-20 py-20">
      <div className="grid md:grid-cols-2 gap-10 w-11/12 md:w-10/12">
        <div className="bg-white p-10 rounded-3xl">
          <h1 className="text-3xl md:text-6xl font-bold text-navyblue">
            Grants <br />& Discounts
          </h1>
          <p className="mt-10 mb-5 text-gray-700">
            <b>Explore our collection of Grants and Discounts.</b>
            <br />
            Check out detailed filters to identify the grants or discounts that
            uniquely apply to you.
          </p>
          <Link
            to="/Grants"
            className="text-navyblue font-bold bg-yellow py-3 px-6 mt-7 rounded-full text-center"
          >
            Get Started
          </Link>
        </div>
        <div className="bg-white p-10 rounded-3xl">
          <h1 className="text-3xl md:text-6xl font-bold text-navyblue">
            For our
            <br /> providers
          </h1>
          <p className="mt-10 mb-5 text-gray-700">
            <b>
              Interested in offering Sunfish at your clinic or for your intended
              parents?{" "}
            </b>
            <br />
            Contact us to learn more.
          </p>
          <a
            href="mailto:info@joinsunfish.com"
            className="text-navyblue font-bold bg-yellow py-3 px-6 mt-7 rounded-full text-center"
          >
            Contact Us
          </a>
        </div>
      </div>
    </div>
  );
}

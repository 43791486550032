import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SFForm from "../SFForm";
import { affiliateMangementTypes } from "../../App/Features/AffiliateManagementSlice";
import CreateTrackableLink from "./CreateTrackableLink";
import ManageTrackableLink from "./ManageTrackableLink";
import TrackableLinkReport from "./TrackableLinkReport";
import { getTrackableLinks } from "../../api";
import { setLinks } from "../../App/Features/TrackableLinkManagementSlice";
import Store from "../../App/Store";

export default function TrackableLinkManagementBody({ bodyRef }) {
  const selectedType = useSelector(
    (state) => state.manageAffiliateState.selectedType
  );
  const links = useSelector(
    (state) => state.trackableLinkManagementState.links
  );
  const getFormBody = () => {
    if (selectedType === "linkReport") {
      return <TrackableLinkReport />;
    } else if (selectedType === "createLink") {
      return <CreateTrackableLink />;
    } else if (selectedType === "manageLink") {
      return <ManageTrackableLink />;
    } else {
      return <></>;
    }
  };

  useEffect(() => {
    if (!links) {
      getTrackableLinks().then((value) => {
        Store.dispatch(setLinks(value));
      });
    }
  }, [links]);

  return (
    <div ref={bodyRef}>
      <SFForm
        title={affiliateMangementTypes[selectedType]?.title}
        body={getFormBody()}
      />
    </div>
  );
}

import React from "react";
import SFBanner, { SFBannerSubTitle, SFBannerTitle } from "./SFBanner";
import { useSelector } from "react-redux";
import BlogsAndEventsCard from "../BlogsAndEventsPage/BlogsAndEventsCard";

export default function BlogsAndEventsBanner() {
  const upcomingEvent = useSelector(
    (state) => state.blogsAndEventsState.upcomingEvent
  );
  const newEventPost = useSelector(
    (state) => state.addBlogsAndEventsState.event
  );
  const verifyModifications = useSelector(
    (state) => state.addBlogsAndEventsState.verify
  );
  const imageFile = useSelector(
    (state) => state.addBlogsAndEventsState.imageFile
  );
  const selectedType = useSelector(
    (state) => state.addBlogsAndEventsState.selectedType
  );

  const verifyEventPost = () =>
    verifyModifications &&
    selectedType === "event" &&
    (upcomingEvent
      ? new Date(upcomingEvent.date) < new Date(newEventPost.date)
      : Date.now() < new Date(newEventPost.date));

  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle
            title="Blogs "
            titleSpan={"& events"}
            titleSpanNewLine={true}
          />
        </div>
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            upcomingEvent || verifyEventPost() ? (
              <div className="text-center bg-white lg:w-7/12 sm:w-full mx-auto my-8 lg:p-16 sm:p-2 rounded-3xl">
                {upcomingEvent ? (
                  <>
                    <h6 className="lg:text-5xl sm:text-xl pb-5 text-center items-center literata-font text-navyblue">
                      <b>Upcoming Webinar: Join us!</b>
                    </h6>
                    <BlogsAndEventsCard
                      {...upcomingEvent}
                      isNew={verifyEventPost() ? false : undefined}
                    />
                  </>
                ) : (
                  <></>
                )}
                {verifyEventPost() ? (
                  <div className="lg:py-10 sm:py-0">
                    <BlogsAndEventsCard
                      {...newEventPost}
                      isNew={true}
                      imagePath={URL.createObjectURL(imageFile)}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )
          }
        />
      }
      bgImage="md:bg-sfBackground bg-sfBackgroundSm bg-no-repeat bg-cover"
      bodyAlignment="center"
    />
  );
}

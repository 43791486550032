import { CheckIcon } from "@heroicons/react/solid";

export default function EachParenthood() {
  return (
    <div className="py-10 grid grid-cols-1 place-items-center lg:px-32 sm:px-2">
      <div className="bg-yellowVectorBg bg-no-repeat bg-contain sm:w-full md:w-3/5 font-semibold sm:text-xl md:text-3xl lg:text-4xl text-navyblue literata-font">
        Many people require several <br />
        IVF attempts{" "}
        <span className="literata-font font-normal italic">
          to achieve pregnancy.
        </span>
      </div>
      <div className="py-5 sm:w-full md:w-3/5 text-lg text-gray-700">
        According to the Society for Assisted Reproductive Technology, the first
        cycle is successful for 36-45% of 37 to 39 year old patients and jumps
        to 62%-73% by the third cycle.<sup>4</sup> Given that most people
        require more than one round of IVF, we created the Sunfish IVF Program
        to help you plan for and afford building your family.
      </div>
      <div className="mt-5 bg-navyblue sm:w-full md:w-4/5 py-24 flex items-center justify-center rounded-3xl">
        <div className="w-10/12">
          <div className="w-full">
            <p className="bg-quoteBg bg-no-repeat top-10 z-10 text-center lg:text-5xl text-4xl literata-font italic text-white font-normal">
              <br /> <br />
              We need to stop thinking of IVF as a single shot at having a
              family, and{" "}
              <span className="literata-font font-semibold">
                think of several cycles as the standard.
              </span>
            </p>
            <div className="w-3/5 mx-auto py-5 flex flex-col lg:flex-row text-sm text-center mt-3">
              <div className="text-navyblue bg-secondary px-4 py-2 lg:py-3 lg:px-6 font-medium rounded-full">
                {"Dr. Scott Nelson"}
              </div>
              <div className="text-navyblue mt-2 lg:mt-0 lg:ml-3  bg-secondary px-4 py-2 lg:py-3 lg:px-6 font-medium rounded-full">
                <div>{"Journal of the American Medical Association"}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:pt-52 sm:pt-24 font-semibold sm:text-xl md:text-3xl lg:text-4xl text-navyblue literata-font">
        How Sunfish Supports You
        <br />
        <span className="literata-font font-normal italic">
          on Your Unique Parenthood Journey
        </span>
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-2 gap-x-10 place-items-start md:w-11/12 sm:w-full h-max">
        <div
          className={`place-self-start text-left mx-auto mt-10 p-10 rounded-3xl bg-secondary min-h-[95%]`}
        >
          <h1 className="text-xl font-bold text-navyblue literata-font leading-10">
            Sunfish IVF Program
          </h1>
          <div className="flex flex-col mt-5 text-gray-700">
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Find and manage care providers:</b> We identify eligible
                medical providers, fertility pharmacies (and egg donor or
                surrogacy agencies if needed) and support in administration of
                the process.
              </p>
            </span>
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Prepare for highest chances of live birth: </b>We guide you
                through the process, including embryo, egg, sperm health and
                testing in collaboration with a medical provider.
              </p>
            </span>
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Custom financial package:</b> Using our predictive model, we
                help you plan financially for your journey by generating a
                custom financial package and refund based on your household’s
                unique inputs.{" "}
                <span className="italic">
                  (Note: Must use a Sunfish-eligible provider and meet all
                  requirements to qualify.)
                </span>
              </p>
            </span>
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Financial and pregnancy support: </b>We provide resources and
                support along your journey through pregnancy.
              </p>
            </span>
          </div>
        </div>
        <div
          className={`place-self-start text-left mx-auto mt-10 p-10 rounded-3xl bg-[#FBF9E5] min-h-[95%]`}
        >
          <h1 className="text-xl font-bold text-navyblue literata-font leading-10">
            Sunfish Customer Benefits.
          </h1>
          <div className="flex flex-col mt-5 text-gray-700">
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Free calls with a financial advocate:</b> We provide up to
                two free calls to help you plan financially for your journey
              </p>
            </span>
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Cost calculator:</b> Estimate your unique costs for IVF,
                surrogacy and / or egg and embryo freezing to help you plan
              </p>
            </span>
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Sunfish loan marketplace:</b> Access and apply for a line of
                credit to fund your parenthood journey or find resources at our
                Sunfish Lending Education Center.
              </p>
            </span>
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Grant and discount database:</b> Find other ways to afford
                your journey to parenthood.
              </p>
            </span>
            <span className="flex flex-start">
              <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
                <CheckIcon className="h-full text-white" />
              </div>
              <p className="leading-8">
                <b>Other cost savings opportunities: </b>We also offer a
                Compassionate Care program (for those who qualify) and a guide
                to navigating workplace benefits.
              </p>
            </span>
          </div>
        </div>
      </div>
      <div className="py-16 font-semibold sm:text-xl md:text-3xl lg:text-4xl text-navyblue literata-font">
        As seen in
      </div>
      <div className="grid sm:grid-cols-1 md:grid-cols-3 gap-10 place-items-center mb-16">
        <img src="/assets/bloomberg.png" alt="bloomberg" />
        <img src="/assets/axios.png" alt="axios" />
        <img src="/assets/techcrunch.png" alt="techcrunch" />
      </div>
    </div>
  );
}

export default function LoadingComponent() {
  return (
    <>
      <div className="loader-backdrop">&nbsp;</div>
      <div className="loader">
        <svg
          className="border-8 border-indigo-200 border-t-indigo-500 rounded-full animate-spin h-32 w-32 mx-auto place-self-center"
          viewBox="0 0 24 24"
        />
      </div>
    </>
  );
}

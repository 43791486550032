export function emailValidator(email) {
  //RFC 2822 standard email validation
  const regex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[-\x21\x23-\x5b\x5d-\x7f]|\\[-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[-\x21-\x5a\x53-\x7f]|\\-\x7f])+)\])/;
  if (regex.test(email)) {
    return true;
  } else {
    return false;
  }
}

import React from "react";
import { useNavigate } from "react-router-dom";
import { setSignUpModalState } from "../../App/Features/UserSplice";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import SFBanner, { SFBannerSubTitle, SFBannerTitle } from "./SFBanner";
import SFYellowButton from "../Buttons/SFYellowButton";

export default function FinancingBanner() {
  const user = useSelector((state) => state.userState.user);
  const navigate = useNavigate();

  return (
    <SFBanner
      title={
        <SFBannerTitle
          title="Explore your options"
          titleSpan={
            <div className="literata-font font-normal italic pt-4">
              (without the commitment)
            </div>
          }
          bodyAlignment="center"
          titleSpanNewLine={true}
        />
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            <div className="w-screen">
              <div className="flex flex-col w-96 max-w-[325px] mx-auto gap-y-5 text-center px-5 py-8">
                <p>
                  See your loan options for IVF, surrogacy and egg freezing –
                  and get personalized financial support on your journey
                </p>
                <SFYellowButton
                  title="Get Started"
                  onClick={() => {
                    if (user) {
                      navigate("/our-offerings", {
                        state: { scrollIntoForm: true },
                      });
                    } else {
                      Store.dispatch(
                        setSignUpModalState({
                          showSignupModal: true,
                          authRequired: true,
                        }),
                      );
                    }
                  }}
                />
              </div>
            </div>
          }
        />
      }
      bgImage="md:bg-offerings sm:bg-offeringsPhone bg-cover"
      bodyAlignment="center"
    />
  );
}

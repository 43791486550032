import React, { useEffect, useRef } from "react";
import Store from "../App/Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginModalState } from "../App/Features/UserSplice";
import PartnersManagementBanner from "../Components/Banners/PartnersManagementBanner";
import PartnersManagementBody from "../Components/PartnersManagementPage/PartnersManagementBody";
import { getPartnersMetadata } from "../api";
import { setPartners } from "../App/Features/ManagePartnersSlice";

export default function PartnersManagement() {
  const user = useSelector((state) => state.userState.user);
  const navigate = useNavigate();
  const bodyRef = useRef();

  useEffect(() => {
    if (!user) {
      Store.dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: true,
        })
      );
    } else if (!user?.acl?.partners) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    getPartnersMetadata().then((value) => {
      Store.dispatch(setPartners(value));
    });
  }, []);

  return (
    <div className="flex flex-col items-center">
      <PartnersManagementBanner bodyRef={bodyRef} />
      <PartnersManagementBody bodyRef={bodyRef} />
    </div>
  );
}

import React from "react";
import SFBanner, { SFBannerTitle } from "../Components/Banners/SFBanner";
import WarrantyQualificationsComponent from "../Components/WarrantyQualifications";

export default function WarrantyQualifications() {
  return (
    <div className="flex flex-col items-center">
      <SFBanner
        title={
          <div className="w-screen">
            <SFBannerTitle title="Warranty Qualifications" />
            <p className="mt-5 text-center">Last Updated: May 1, 2023</p>
          </div>
        }
        bgImage="md:bg-offerings bg-offeringsPhone bg-no-repeat bg-cover"
        bodyAlignment="center"
      />
      <WarrantyQualificationsComponent />
    </div>
  );
}

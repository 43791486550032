export enum IncomeRestrictionType {
  true = "Yes",
  false = "No",
}
export class IncomeRestrictionFilter {
  data;

  isGrantValid(grant): Boolean {
    return (
      this.data === undefined ||
      (grant.incomeRestriction && IncomeRestrictionType.true) ||
      (!grant.incomeRestriction && IncomeRestrictionType.false)
    );
  }
}

import { createSlice } from "@reduxjs/toolkit";

export const hasPartnerId = () =>
  document.cookie.split("; ").find((row) => row.startsWith("p="));

const _getPartnerId = () =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith("p="))
    ?.split("=")[1];

const _setPartnerId = (id) =>
  (document.cookie = `p=${id}; SameSite=Strict; Secure`);

export const hasPartnerUserId = () =>
  document.cookie.split("; ").find((row) => row.startsWith("pu="));

const _getPartnerUserId = () =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith("pu="))
    ?.split("=")[1];

const _setPartnerUserId = (id) =>
  (document.cookie = `pu=${id}; SameSite=Strict; Secure`);

export const partnerSlice = createSlice({
  name: "partner",
  initialState: {
    partner: undefined,
    partnerUserId: undefined,
  },
  reducers: {
    setPartner: (state, action) => {
      const storedPartnerId = _getPartnerId();
      if (
        state?.partner === undefined &&
        storedPartnerId !== undefined &&
        action?.payload?.route === storedPartnerId
      ) {
        state.partner = action?.payload;
      } else if (state?.partner === undefined) {
        _setPartnerId(action?.payload.route);
        state.partner = action?.payload;
      }
    },
    setPartnerUserId: (state, action) => {
      const storedPartnerUserId = _getPartnerUserId();
      if (
        state?.partnerUserId === undefined &&
        storedPartnerUserId !== undefined
      ) {
        state.partnerUserId = storedPartnerUserId;
      } else if (state?.partnerUserId === undefined) {
        _setPartnerUserId(action?.payload);
        state.partnerUserId = action?.payload;
      }
    },
    resetCachedPartner: (state, action) => {
      _setPartnerId(action?.payload);
      state.partner = action?.payload;
    },
    resetCachedPartnerUserId: (state, action) => {
      _setPartnerUserId(action?.payload);
      state.partnerUserId = action?.payload;
    },
  },
});

export const { setPartner, setPartnerUserId } = partnerSlice.actions;

export default partnerSlice.reducer;

import React, { useState, useEffect } from "react";
import {
  TreatmentType,
  TreatmentTypeFilter,
} from "../Filters/TreatmentTypeFilter.ts";
import {
  ProfessionalGroupType,
  ProfessionalGroupFilter,
} from "../Filters/ProfessionalGroupFilter.ts";
import {
  SexualOrientationType,
  SexualOrientationFilter,
} from "../Filters/SexualOrientationFilter.ts";
import {
  MaritalStatusFilter,
  MaritalStatusType,
} from "../Filters/MaritalStatusFilter.ts";
import {
  PreApprovalFilter,
  PreApprovalType,
} from "../Filters/PreApprovalFilter.ts";
import {
  IncomeRestrictionFilter,
  IncomeRestrictionType,
} from "../Filters/IncomeRestrictionFilter.ts";
import {
  CancerPatientOrSurvivorFilter,
  CancerPatientOrSurvivorType,
} from "../Filters/CancerPatientOrSurvivorFilter.ts";
import {
  ProofOfInfertilityDiagnosisFilter,
  ProofOfInfertilityDiagnosisType,
} from "../Filters/ProofOfInfertilityDiagnosisFilter.ts";
import {
  ProviderType,
  ProviderTypeFilter,
} from "../Filters/ProviderTypeFilter.ts";
import { setSignUpModalState } from "../../App/Features/UserSplice";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import SFBanner, { SFBannerTitle } from "./SFBanner";
import SFBlueButton from "../Buttons/SFBlueButton";
import SFDataProcessingDisclaimer from "../Forms/SFDataProcessingDisclaimer";

export default function GrantsBanner(
  updateFilters,
  showMoreFilter,
  setShowMoreFilter,
) {
  const user = useSelector((state) => state.userState.user);
  const [requestedGrants, setRequestedGrants] = useState(false);

  const requestCalculation = () => {
    Store.dispatch(
      setSignUpModalState({
        showSignupModal: true,
        authRequired: false,
        title: "Create an account to see results",
      }),
    );
    setRequestedGrants(true);
  };

  var [filters, setFilters] = useState([]);

  useEffect(() => {
    if (user && requestedGrants) {
      updateFilters(filters);
    }
  }, [user, requestedGrants, filters]);

  const modifyFilters = (value, filterType, remove = false, update = true) => {
    var filter = new filterType(value);
    var currentFilters = filters;
    var containsFilter = listContainsFilter(filter);
    if (!containsFilter) {
      currentFilters.push(filter);
    } else if (remove) {
      currentFilters = currentFilters.filter((element) => {
        return filter.constructor.name !== element.constructor.name;
      });
    } else if (update) {
      currentFilters = currentFilters.map((element) => {
        if (filter.constructor.name === element.constructor.name) {
          return filter;
        }
        return element;
      });
    }
    setFilters(currentFilters);
  };

  const listContainsFilter = (filter) => {
    var currentFilters = filters;
    for (var x = 0; x < currentFilters.length; x++) {
      const element = currentFilters[x];
      if (filter.constructor.name === element.constructor.name) {
        return true;
      }
    }
    return false;
  };

  const filterDropdownUI = (text, options, filterType, placeholder) => {
    var optionsList = [];
    if (options) {
      for (var enumValue in options) {
        var optionText = options.valueOf()[enumValue];
        if (optionText === "Homosexual") {
          optionText = "Gay/Lesbian";
        }
        optionsList.push(
          <option key={enumValue} value={optionText}>
            {optionText}
          </option>,
        );
      }
    }
    return (
      <div className="flex flex-col md:my-2 sm:my-1 md:text-lg sm:text-base">
        <p className="font-semibold">{text}</p>
        <div className="bg-white w-full rounded-3xl px-4 md:mt-2">
          <select
            onChange={(event) => {
              modifyFilters(event.target.value, filterType, false, true);
            }}
            className="w-full md:my-2 sm:my-1 sm:py-0 md:py-2 outline-none"
          >
            {[
              <option value="" disabled selected className="invisible">
                {placeholder ? placeholder : `Select a ${text}`}
              </option>,
              ...optionsList,
            ]}
          </select>
        </div>
      </div>
    );
  };

  const filterComponents = () => (
    <div
      className={`${
        showMoreFilter ? "mt-48" : ""
      } w-full flex flex-col items-center text-center justify-center sm:p-2`}
    >
      <div className="lg:w-5/12 md:w-9/12 sm:w-full px-4 lg:p-10 sm:p-2 bg-lightgreenblue rounded-3xl text-left">
        <p className="md:text-2xl sm:text-base text-left text-navyblue font-bold">
          Search our database
        </p>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 text-left md:pt-4 lg:pt-4 gap-x-4">
          {filterDropdownUI(
            "Treatment type",
            TreatmentType,
            TreatmentTypeFilter,
          )}
          {filterDropdownUI("Provider type", ProviderType, ProviderTypeFilter)}{" "}
          {showMoreFilter &&
            filterDropdownUI(
              "Sexual Orientation",
              SexualOrientationType,
              SexualOrientationFilter,
            )}
          {showMoreFilter &&
            filterDropdownUI(
              "Grants with income restriction",
              IncomeRestrictionType,
              IncomeRestrictionFilter,
              "Select Yes or No",
            )}
          {showMoreFilter &&
            filterDropdownUI(
              "Marital Status",
              MaritalStatusType,
              MaritalStatusFilter,
            )}
          {showMoreFilter &&
            filterDropdownUI(
              "Infertility diagnosis",
              ProofOfInfertilityDiagnosisType,
              ProofOfInfertilityDiagnosisFilter,
              "Select Yes or No",
            )}
          {showMoreFilter &&
            filterDropdownUI(
              "Professional group",
              ProfessionalGroupType,
              ProfessionalGroupFilter,
            )}
          {showMoreFilter &&
            filterDropdownUI(
              "Approval before treatment",
              PreApprovalType,
              PreApprovalFilter,
              "Select Yes or No",
            )}
          {showMoreFilter &&
            filterDropdownUI(
              "Health conditions",
              CancerPatientOrSurvivorType,
              CancerPatientOrSurvivorFilter,
              "Select Yes or No",
            )}
        </div>
        <div className="grid lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1 sm:mr-0 lg:mr-4 md:py-4 sm:py-0 place-items-stretch">
          <div className="place-items-center md:p-3 sm:p-0">
            <p className="font-light text-gray-800">
              There are 9 filters in total.
            </p>
          </div>
          <SFBlueButton
            title={!showMoreFilter ? "All filters" : "Show less filters"}
            onClick={() => {
              setShowMoreFilter(!showMoreFilter);
            }}
          />
        </div>
        <div>
          <div className="grid flex-row sm:pt-1">
            <SFBlueButton
              title="See my options"
              onClick={() =>
                user ? updateFilters(filters) : requestCalculation()
              }
            />
          </div>
          <SFDataProcessingDisclaimer />
        </div>
      </div>
    </div>
  );

  return (
    <SFBanner
      title={
        <div>
          <SFBannerTitle title="Grants & Discounts" titleSpanNewLine={true} />
        </div>
      }
      subTitle={
        <div>
          <div
            className="text-center md:text-lg sm:text-base lg:p-10 lg:px-0 sm:p-0 sm:px-10"
            style={{
              color: "#3A5EA0",
              textAlign: "center",
              fontFamily: "Jost",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "160%",
              maxWidth: "640px",
              margin: "auto",
            }}
          >
            Explore our collection of grants and discounts. Check out our
            detailed filter to identify the grants and discounts that may
            uniquely apply to you.
          </div>
          {filterComponents()}
        </div>
      }
      bgImage="bg-grantsPhone lg:bg-grants bg-cover bg-top lg:bg-bottom"
      bodyAlignment="center"
    />
  );
}

import React from "react";
import SFYellowButton from "../Buttons/SFYellowButton";
import SFBlueButton from "../Buttons/SFBlueButton";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  setComplete,
  setVerify,
} from "../../App/Features/AddBlogsAndEventsSplice";
import BlogsAndEventsBody from "../BlogsAndEventsPage/BlogsAndEventsBody";

import {
  setBlogMetadata,
  setEventsMetadata,
  uploadBlogImage,
  uploadEventImage,
} from "../../api";
import AddBlogsAndEventsComplete from "./AddBlogsAndEventsComplete";
import BlogsAndEventsBanner from "../Banners/BlogsAndEvents";

export default function AddBlogsAndEventsVerify() {
  const imageFile = useSelector(
    (state) => state.addBlogsAndEventsState.imageFile
  );
  const addBlogsAndEventsState = useSelector(
    (state) => state.addBlogsAndEventsState
  );
  const selectedType = useSelector(
    (state) => state.addBlogsAndEventsState.selectedType
  );
  const user = useSelector((state) => state.userState.user);
  return addBlogsAndEventsState.complete ? (
    <AddBlogsAndEventsComplete />
  ) : (
    <div className="grid place-items-center">
      <h1 className="font-bold text-3xl text-navyblue py-8">
        Verify new changes
      </h1>
      <div className="space-x-5">
        <SFYellowButton
          title={"cancel"}
          onClick={async () => {
            Store.dispatch(setVerify(false));
          }}
        />
        <SFBlueButton
          title={"submit"}
          onClick={async () => {
            if (selectedType === "blog") {
              const imagePathValue = await uploadBlogImage(user.uid, imageFile);
              await setBlogMetadata({
                ...addBlogsAndEventsState[selectedType],
                imagePath: imagePathValue,
              });
              Store.dispatch(setComplete(true));
            } else if (selectedType === "event") {
              const imagePathValue = await uploadEventImage(
                user.uid,
                imageFile
              );
              await setEventsMetadata({
                ...addBlogsAndEventsState[selectedType],
                imagePath: imagePathValue,
              });
              Store.dispatch(setComplete(true));
            }
          }}
        />
      </div>
      <BlogsAndEventsBanner />
      <BlogsAndEventsBody />
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function Parenthood() {
  return (
    <div className="flex bg-[#FBF9E5] w-full justify-center py-24 items-center">
      <div className="md:h-screen flex items-center w-10/12">
        <div className="grid gap-10 lg:grid-cols-2 w-full">
          <div className="flex flex-col justify-center">
            <h1 className="text-5xl font-semibold literata-font lg:leading-tight md:text-5xl text-navyblue">
              LGBTQIA+ <br /> Parenthood
            </h1>
            <p className="mt-5 lg:w-10/12 text-gray-600 lg:text-lg">
              We proudly work with groups that offer educational resources and
              support for aspiring LGBTQIA+ parents. Our platform is built on
              the experience of partnering with thousands of families and
              hundreds of clinics across the U.S.
            </p>
            <Link
              className="text-navyblue bg-yellow w-36 mt-10 text-center py-4 font-semibold literata-font lg:leading-tight text-sm rounded-full"
              to="/lgbtqParenthood"
            >
              Learn More
            </Link>
          </div>

          {/* Parenthood Image */}
          <div className="flex justify-center mt-16 lg:mt-0 ">
            <div className="rounded-full bg-lgbtqComp bg-center bg-cover flex justify-center items-center py-10 w-[300px] h-[300px]">
              <img
                className="h-[300px] mb-20"
                src="/assets/welcome_page2_img.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useSelector } from "react-redux";

export default function Nav() {
  const user = useSelector((state) => state.userState.user);
  const usersOffersUrl = useSelector((state) => state.userState.usersOffersURL);
  return (
    <>
      <NavBar user={user} usersOffersUrl={usersOffersUrl} />
      <NavBarMobile user={user} usersOffersUrl={usersOffersUrl} />
    </>
  );
}

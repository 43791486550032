import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { signUp, logAnalyticsEvent } from "../api";
import PasswordStrengthComponent from "./PasswordStrengthComponent";
import zxcvbn from "zxcvbn";
import CloseImage from "./CloseButton";
import {
  setSignUpModalState,
  setLoginModalState,
} from "../App/Features/UserSplice";
import { useSelector, useDispatch } from "react-redux";
import { minPasswordStrength, postOfferRequest } from "../api";
import { updateUserState } from "../App/Features/UserSplice";
import CommonUtils from "../utils/CommonUtils";
import { emailValidator } from "utils/validators";

export default function SignupForm({ pathOnClose }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confPwd, setConfPwd] = useState("");
  const [message, setMessage] = useState(null);
  const [passwordStrength, setPasswordStrength] = useState(undefined);
  const [loanPurpose, setLoanPurpose] = useState(undefined);
  const loanPurposeOptions = [
    "IVF and Medication",
    "Surrogacy",
    "Egg Freezing",
    "Adoption",
    "Egg donor",
    "Other",
  ];
  const [termsCompleted, setTermsCompleted] = useState(false);
  const signUpModalState = useSelector(
    (state) => state.userState.signUpModalState,
  );
  const loginModalState = useSelector(
    (state) => state.userState.loginModalState,
  );
  const affiliateId = useSelector((state) => state.affiliateState.affiliateId);
  const affiliateUserId = useSelector(
    (state) => state.affiliateState.affiliateUserId,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const partner = useSelector((state) => state.partnerState.partner);
  const partnerUserId = useSelector(
    (state) => state.partnerState.partnerUserId,
  );
  const linkId = useSelector((state) => state.trackableLinkState.link);
  const linkUserId = useSelector((state) => state.trackableLinkState.userId);

  const openLogin = () => {
    dispatch(
      setSignUpModalState({
        showSignupModal: false,
        title: signUpModalState.title,
      }),
    );
    if (!loginModalState?.showLoginModal) {
      dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: true,
          title:
            signUpModalState.title === "Create an account to see results"
              ? "Login to see your results"
              : "Login",
        }),
      );
    }
  };

  const onSignUp = () => {
    if (password !== confPwd) {
      setMessage("Passwords do not match.");
      return;
    }

    if (message) {
      setMessage(null);
    }

    signUp(
      email,
      password,
      location.pathname,
      affiliateId,
      affiliateUserId,
      partnerUserId,
      partner,
      linkId,
      linkUserId,
    )
      .then((user) => {
        dispatch(
          setSignUpModalState({
            showSignupModal: false,
          }),
        );
        window.top.postMessage("exit.fullscreen_mode", "*");
        logAnalyticsEvent("sign_up", {
          method: "password",
        });
        const formValues = {
          email: email,
          loanPurpose: loanPurpose,
          agreedToMarketing: termsCompleted,
          signUpLocation: location.pathname,
          signUpSource: "website",
        };
        if (partner) {
          if (partner.type === "clinic") {
            formValues["ivfClinic"] = partner.name;
          } else if (partner.type === "agency") {
            formValues["agency"] = partner.name;
          } else if (partner.type === "pharmacy") {
            formValues["pharmacy"] = partner.name;
          } else if (partner.type === "strategic") {
            formValues["strategicPartner"] = partner.name;
          }
        }
        postOfferRequest(user?.uid, formValues).then((_) => {
          dispatch(updateUserState(formValues));
        });
      })
      .catch((error) => {
        let msg = error.message;
        if (error.code === "auth/invalid-email") {
          msg = "Invalid e-mail address.";
        } else if (error.code === "auth/email-already-in-use") {
          msg = "This e-mail address is already in use.";
        } else if (error.code === "auth/weak-password") {
          msg = "Password should be at least 6 characters.";
        }

        setMessage(msg);
      });
  };

  const handleOnClosePath = (route) => {
    if (CommonUtils.isInIframe()) {
      window.top.postMessage(
        {
          type: "route",
          route,
        },
        "*",
      );

      return;
    }

    navigate(route);
  };

  const onClose = () => {
    const { onClosePath } = signUpModalState;
    if (onClosePath) {
      handleOnClosePath(onClosePath);
    }
    setLoanPurpose(undefined);
    dispatch(
      setSignUpModalState({
        showSignupModal: false,
        authRequired: false,
      }),
    );
  };

  const handleGoTo =
    (event) =>
    ({ route, target }) => {
      event.preventDefault();

      if (CommonUtils.isInIframe()) {
        CommonUtils.routeMessage({ route, target });
      } else if (route && target) {
        window.open(route, target);
      } else {
        navigate(route);
      }

      if (!target) {
        dispatch(
          setSignUpModalState({
            showSignupModal: false,
            authRequired: false,
          }),
        );
      }
    };

  let msgBox = null;
  if (message) {
    msgBox = <div className="text-red-800">{message}</div>;
  }

  const validateEmail = (event) => {
    const val = event.target.value;
    if (emailValidator(val)) {
      setEmail(val);
    } else {
      setEmail("");
    }
  };

  useEffect(() => {
    setPasswordStrength(zxcvbn(password));
  }, [password]);

  useEffect(() => {
    setMessage();
  }, [passwordStrength]);

  useEffect(() => {
    confPwd.length > 0 && password !== confPwd
      ? setMessage("Passwords do not match.")
      : setMessage();
  }, [confPwd, password]);

  return (
    <div
      className={
        signUpModalState.showSignupModal
          ? "w-full flex items-center justify-center h-screen fixed top-0 z-50 bg-aliceBlue/90 overflow-x-hidden overflow-y-auto modal fade"
          : "hidden"
      }
    >
      <div className="relative w-10/12 lg:w-8/12 xl:w-6/12 2xl:w-4/12 p-10 flex flex-col bg-white shadow-2xl shadow-black/30 rounded-3xl modal-dialog modal-dialog-scrollable relative m-auto">
        {!signUpModalState?.authRequired ? (
          <CloseImage onClick={onClose} />
        ) : null}
        <div className="flex flex-col place-items-center">
          <img src="/assets/logo.png" className="h-6" alt="sunfish-logo" />
        </div>
        <h1 className="mt-5 text-center font-bold text-2xl">
          {signUpModalState.title ?? "Create an account"}
        </h1>
        <input
          type="email"
          placeholder="Your email"
          aria-label="email-input"
          className="bg-gray-100 outline-none rounded-xl p-4 mt-4 w-full"
          onChange={validateEmail}
        />
        <input
          type="password"
          placeholder="Add password"
          aria-label="password-input"
          className="bg-gray-100 outline-none rounded-xl p-4 mt-2 w-full"
          onChange={(event) => setPassword(event.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm password"
          aria-label="confirm-password-input"
          className="bg-gray-100 outline-none rounded-xl p-4 mt-2 w-full"
          onChange={(event) => setConfPwd(event.target.value)}
        />
        <div className="bg-gray-100 rounded-xl p-4 mt-2">
          <select
            className={`bg-gray-100 outline-none rounded-xl w-full ${
              loanPurpose ? "text-black" : "text-gray-800"
            }`}
            type="dropdown"
            value={loanPurpose ?? ""}
            onChange={(e) => {
              setLoanPurpose(e.target.value);
            }}
          >
            <option value="" disabled selected className="invisible">
              I am exploring...
            </option>
            {[...loanPurposeOptions].map((e) => (
              <option value={e} key={e}>
                {e}
              </option>
            ))}
          </select>
        </div>
        <label className="text-left font-semibold">
          <div className="flex py-2 px-4 flex-row space-x-4">
            <input
              type="checkbox"
              value={termsCompleted}
              onChange={(e) => setTermsCompleted(Boolean(e.target.checked))}
              aria-label="terms-and-conditions-check-box"
            ></input>
            <text className="ml-3">
              {"By creating an account, you agree to the Sunfish "}
              <a
                className="text-navyblue underline cursor-pointer"
                onClick={(event) =>
                  handleGoTo(event)({
                    route: "/consent-to-electronic-communications",
                    target: "_blank",
                  })
                }
              >
                Consent to Electronic Communications
              </a>
              {", "}
              <a
                className="text-navyblue underline cursor-pointer"
                onClick={(event) =>
                  handleGoTo(event)({
                    route: "/terms-of-service",
                    target: "_blank",
                  })
                }
              >
                Terms of Service
              </a>
              {", and "}
              <a
                className="text-navyblue underline cursor-pointer"
                onClick={(event) =>
                  handleGoTo(event)({
                    route: "/privacy-policy",
                    target: "_blank",
                  })
                }
              >
                Privacy Policy
              </a>
            </text>
          </div>
        </label>
        {msgBox}
        {password && (
          <PasswordStrengthComponent passwordStrength={passwordStrength} />
        )}
        <input
          type="button"
          value="Create an account"
          className={
            "cursor-pointer text-white rounded-xl p-4 mt-2 w-full bg-blue-900 disabled:bg-primary disabled:cursor-not-allowed"
          }
          onClick={onSignUp}
          disabled={
            email.length <= 0 ||
            passwordStrength?.score < minPasswordStrength ||
            !termsCompleted ||
            password !== confPwd
          }
        />
        <h1 className="mt-10  text-center text-sm ">
          Already have an account?{" "}
          <span
            onClick={openLogin}
            className="text-blue-800 font-semibold cursor-pointer"
          >
            Login
          </span>
        </h1>
      </div>
    </div>
  );
}

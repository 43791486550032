import { setECommunicationModal } from "../App/Features/NavSplice";
import ECommConsentContent from "./ECommConsent";
import Store from "../App/Store";
import SFModal from "./SFModal";

export default function ECommunicationModal() {
  const onClose = () => {
    Store.dispatch(
      setECommunicationModal({
        showECommunicationModal: false,
      })
    );
  };

  return (
    <SFModal
      title="Consent to Electronic Communications"
      body={<ECommConsentContent className="m-5" />}
      onClose={onClose}
    />
  );
}

import { CheckIcon } from "@heroicons/react/solid";

export default function HowSunfishWorks() {
  const checkList = (text) => (
    <span className="flex flex-start mt-2">
      <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
        <CheckIcon className="h-full text-white" />
      </div>
      <p>{text}</p>
    </span>
  );

  const card = (step, title, body, imgSrc, imgPadding = "p-4") => (
    <div>
      <div className="items-center flex justify-between py-10">
        <span className="bg-secondary text-navyblue text-xl font-bold flex items-center justify-center w-16 h-16 pt-2 rounded-lg text-center">
          {step}
        </span>
        <img src="/assets/dashline.png" className="w-full" alt="" />
      </div>

      <div className="lg:pr-12 sm:pr-2">
        <div className="h-72 lg:w-auto md:w-96 sm:w-96 mx-auto max-w-[325px] bg-primary my-2 grid rounded-3xl">
          <img
            src={imgSrc}
            className={`${imgPadding} h-72 place-self-center`}
            alt={title}
          />
        </div>
        <h1 className="text-2xl text-navyblue mt-5">{title}</h1>
        <h1 className="text-gray-600 mt-4">{body}</h1>
      </div>
    </div>
  );

  return (
    <div className="lg:px-20 sm:px-4 w-screen flex flex-col mt-16">
      <div className="flex flex-row text-left">
        <h1 className="font-bold text-4xl text-navyblue">
          How Sunfish
          <span className="font-light text-4xl text-navyblue">{" works"}</span>
        </h1>
      </div>
      <div className="grid lg:grid-cols-3 md:grid-cols-1 mt-10">
        {card(
          1,
          <>
            <b>Plan for your fertility journey</b>
            <br />
            <div className="italic">with our free resources</div>
          </>,
          <>
            Our <b>free</b> resources include:
            {checkList("Chat with a Sunfish Financial Advocate")}
            {checkList("Cost Estimator – IVF, Surrogacy or Egg Freezing")}
            {checkList("Grant database")}
          </>,
          "assets/howSFWorks1.png"
        )}
        {card(
          2,
          <>
            <b>Get your options</b>
            <br /> <div className="italic"> (without the commitment)</div>
          </>,
          <>
            How it works
            {checkList("Create a Sunfish account")}
            {checkList("Provide personal information (for credit checks)")}
            {checkList("Get several loan options from our bank partners")}
          </>,
          "assets/howSFWorks2.png",
          "py-16 px-4"
        )}
        {card(
          3,
          <>
            <b>Apply directly with banks </b>
            <br />
            <div className="italic"> and begin your parenthood journey</div>
          </>,
          <>
            Finalizing financing:
            {checkList("Apply directly with banks")}
            {checkList(
              "Contact your Sunfish Financial Advocate for support on your journey"
            )}
          </>,
          "assets/howSFWorks3.png"
        )}
      </div>
    </div>
  );
}

import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import FinancingBanner from "../Components/Banners/FinancingBanner";
import Mission from "../Components/FinancingOptionsPage/Mission";
import { useLocation } from "react-router-dom";
import HowSunfishWorks from "../Components/FinancingOptionsPage/HowSunfishWorks";
import Testmonail from "../Components/Testmonail";

export default function FinancingOptions() {
  const scrollRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const locationState = location.state;
    if (locationState !== undefined && locationState !== null) {
      const scrollToOptions = locationState?.scrollToOptions;
      if (scrollToOptions) {
        scrollRef.current.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <meta
          name="description"
          content="Sunfish allows you to find loans and lines of credit for IVF, surrogacy and egg freezing – and get personalized financial support on your journey."
        />
      </Helmet>
      <FinancingBanner />
      <div className="py-10" ref={scrollRef}>
        <Mission />
      </div>
      <Testmonail />
      <HowSunfishWorks />
    </div>
  );
}

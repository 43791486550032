export enum SexualOrientationType {
  hetrosexual = "Heterosexual",
  homosexual = "Homosexual",
  bisexual = "Bisexual",
  asexual = "Asexual",
  notSpecified = "Not Specified",
}

export class SexualOrientationFilter {
  data: SexualOrientationType;

  constructor(data: SexualOrientationType) {
    this.data = data;
  }

  isGrantValid(grant): Boolean {
    var anyGrant: Boolean = grant.sexualOrientation.toLowerCase() === "any";
    if (this.data === SexualOrientationType.notSpecified) {
      return true;
    } else {
      const sexualOrientationType = this.data;
      const searchString: string = sexualOrientationType
        .toString()
        .toLowerCase();
      const sexualOrientation = grant.sexualOrientation;
      if (sexualOrientation) {
        const grantSearch: string = sexualOrientation.toString().toLowerCase();
        var value: Boolean = grantSearch.includes(searchString);
        if (value) {
          return anyGrant || true;
        }
      }
    }
    return anyGrant || false;
  }
}

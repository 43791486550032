import { createSlice } from "@reduxjs/toolkit";

export const blogsAndEvents = createSlice({
  name: "blogsAndEvents",
  initialState: {
    blogs: undefined,
    events: undefined,
    upcomingEvent: undefined,
    showMoreBlogsButton: false,
    showMoreEventsButton: false,
  },
  reducers: {
    setBlogs: (state, action) => {
      state.blogs = action.payload;
      state.showMoreBlogsButton = action.payload?.length % 6 === 0;
    },
    setEvents: (state, action) => {
      state.events = action.payload;
      const dateNow = Date.now();
      const latestEvent = action.payload.reduce((a, b) => {
        return new Date(a.date) > new Date(b.date) ? a : b;
      });
      const upcomingEvent =
        dateNow < new Date(latestEvent.date) ? latestEvent : undefined;
      if (upcomingEvent) {
        state.upcomingEvent = upcomingEvent;
        state.events = state.events.splice(1);
      }
      state.showMoreEventsButton = action.payload?.length % 6 === 0;
    },
  },
});

export const { setBlogs, setEvents } = blogsAndEvents.actions;

export default blogsAndEvents.reducer;

import { createSlice } from "@reduxjs/toolkit";

export const affiliateMangementTypes = {
  affiliateReport: {
    title: "Affiliate Report",
  },
  createAffilatePartner: {
    title: "Create New Affiliate Partner",
  },
  manageAffilatePartners: {
    title: "Manage Existing Affiliate Partner",
  },
  updateAffiliatedUser: {
    title: "Update Affiliated User Booked Loan",
  },
};

export const affiliateKeyTypes = {
  auth_email: "Auth Email",
  auth_uid: "Auth Id",
  status: "Status",
  timeOfAffiliation: "Time of Affiliation",
  timeOfBookedLoan: "Time of Booked Loan",
  timeOfFormCompletion: "Time of Form Completion",
  timeOfFormStart: "Time of Form Start",
  timeOfRegistration: "Time of Registration",
  previousAffiliateId: "Previous Affiliate  Id",
};

export const statusTypes = {
  affiliated: "Affiliated",
  registered: "Registered",
  sf_form_start: "Started Sunfish Form",
  sf_form_completion: "Completed Sunfish Form",
  sf_form_booked_loan: "Loan Booked",
};

export const partnerMangementStatuses = ["error", "inProgress", "complete"];

export const manageAffilatePartnersSlice = createSlice({
  name: "manageAffilatePartners",
  initialState: {
    selectedType: "affiliateReport",
    status: "inProgress",
    affiliates: undefined,
    selectedAffiliate: undefined,
    affiliateReportState: {
      report: undefined,
      selectedAffiliatedUser: undefined,
      reportType: "all_time",
    },
    manageAffilatePartnersState: {
      selectedAffiliate: undefined,
    },
    updateAffiliatedUserState: {
      users: undefined,
      selectedUser: undefined,
    },
    createAffilatePartnerState: {
      affiliatePartnerName: undefined,
      affiliatePartnerMessage: undefined,
      submittedAffiliate: undefined,
    },
  },
  reducers: {
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
      state.selectedAffiliate = undefined;
      state.status = "inProgress";
      state.affiliateReportState = {
        report: undefined,
        selectedAffiliatedUser: undefined,
        reportType: "all_time",
      };
      state.updateAffiliatedUserState = {
        users: undefined,
        selectedUser: undefined,
      };
      state.createAffilatePartnerState = {
        affiliatePartnerName: undefined,
        affiliatePartnerMessage: undefined,
        submittedAffiliate: undefined,
        affiliateQueryUrl: undefined,
      };
      state.manageAffilatePartnersState = {
        selectedAffiliate: undefined,
      };
    },
    setAffiliates: (state, action) => {
      state.affiliates = action.payload;
    },
    setSelectedAffiliate: (state, action) => {
      state.selectedAffiliate = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    affiliateReportStateSetReport: (state, action) => {
      state.affiliateReportState.report = action.payload;
    },
    affiliateReportStateSetReportType: (state, action) => {
      state.affiliateReportState.reportType = action.payload;
    },
    affiliateReportStateSetSelectedAffiliatedUser: (state, action) => {
      state.affiliateReportState.selectedAffiliatedUser = action.payload;
    },
    createAffilatePartnerStateSetPartnerName: (state, action) => {
      state.createAffilatePartnerState.affiliatePartnerName = action.payload;
    },
    createAffilatePartnerStateSetPartnerMessage: (state, action) => {
      state.createAffilatePartnerState.affiliatePartnerMessage = action.payload;
    },
    createAffilatePartnerStateSetAffiliateQueryUrl: (state, action) => {
      state.createAffilatePartnerState.affiliateQueryUrl = action.payload;
    },
    createAffilatePartnerStateSetSubmittedAffiliate: (state, action) => {
      state.createAffilatePartnerState.submittedAffiliate = action.payload;
    },
    manageAffilatePartnersStateSetSelectedAffiliate: (state, action) => {
      state.manageAffilatePartnersState.selectedAffiliate = action.payload;
    },
    updateAffiliatedSetUsers: (state, action) => {
      state.updateAffiliatedUserState.users = action.payload;
    },
    updateAffiliatedSetSelectedUser: (state, action) => {
      state.updateAffiliatedUserState.selectedUser = action.payload;
    },
  },
});

export const {
  setSelectedType,
  setAffiliates,
  setStatus,
  setSelectedAffiliate,
  affiliateReportStateSetReport,
  affiliateReportStateSetReportType,
  affiliateReportStateSetSelectedAffiliatedUser,
  updateAffiliatedSetUsers,
  updateAffiliatedSetSelectedUser,
  createAffilatePartnerStateSetPartnerName,
  createAffilatePartnerStateSetAffiliateQueryUrl,
  createAffilatePartnerStateSetPartnerMessage,
  createAffilatePartnerStateSetSubmittedAffiliate,
  manageAffilatePartnersStateSetAffiliates,
  manageAffilatePartnersStateSetSelectedAffiliate,
} = manageAffilatePartnersSlice.actions;

export default manageAffilatePartnersSlice.reducer;

import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

export default function Testmonail() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const chip = (user, date) => (
    <div className=" flex flex-col lg:flex-row text-sm text-center mt-3">
      <div className="text-navyblue bg-secondary px-4 py-2 lg:py-3 lg:px-6 font-medium rounded-full">
        {user}
      </div>
      <div className="text-navyblue mt-2 lg:mt-0 lg:ml-3  bg-secondary px-4 py-2 lg:py-3 lg:px-6 font-medium rounded-full">
        <div>{date}</div>
      </div>
    </div>
  );

  const testmonailCard = (title, body, chip) => (
    <div className="lg:px-7 w-full">
      <div className="bg-white rounded-3xl py-8 p-7 lg:px-16 w-full">
        <h1 className="text-3xl font-semibold">{title}</h1>
        <p className="text-2xl mt-2">{body}</p>
        {chip}
      </div>
    </div>
  );

  const testmonails = [
    testmonailCard(
      "Egg Freezing was a rough process for me, and Sunfish helped.",
      "I really underestimated the intensity of it all. Sunfish has helped me build my future family, which is the most profound life's work you can do.",
      chip("Karry, Egg Freezing Journey", "March 2022")
    ),
    testmonailCard(
      "Talking to Sunfish gave us a lot of hope that we could afford our journey to become parents.",
      "We weren't sure if there were any options available to us and Sunfish helped us learn more about what is out there for couples like us. We're now much more hopeful for our future.",
      chip("David, intended father through Surrogacy", "Nov 2022")
    ),
    testmonailCard(
      "Thank you so much for helping me understanding the costs.",
      "Sunfish helped me get started with a surrogacy journey. You are so awesome! I felt really overwhelmed trying to figure out how much everything would cost until I found Sunfish.",
      chip("Veronica, intended mother through Surrogacy", "Aug 2022")
    ),
  ];
  const increment = () => {
    var nextIndex = currentIndex + 1;
    if (nextIndex === testmonails.length) {
      nextIndex = 0;
    }
    setCurrentIndex(nextIndex);
  };

  const prevButton = () => (
    <button
      className="w-12 h-12"
      onClick={() => {
        var nextIndex = currentIndex - 1;
        if (nextIndex === -1) {
          nextIndex = testmonails.length - 1;
        }
        setCurrentIndex(nextIndex);
      }}
      aria-label="previous-testominal"
    >
      <img src="/assets/left-icon.png" alt="previous-testomial" />
    </button>
  );

  return (
    <div className="bg-navyblue w-full py-24 flex items-center justify-center">
      <div className="w-10/12 lg:w-7/12">
        <div className="flex flex-col items-center w-full">
          <div className="w-full flex justify-between">
            <h1 className="lg:text-5xl text-4xl literata-font text-white font-semibold">
              What our clients <br />{" "}
              <span className="font-normal literata-font italic lg:ml-28">
                {" "}
                say about us
              </span>
            </h1>
            <text className="hidden font-semibold lg:block lg:text-5xl md:text-4xl sm:text-2xl">
              <span className="text-white">{`0${currentIndex + 1}`}</span>
              <span className="text-orange-400">{`/0${testmonails.length}`}</span>
            </text>
          </div>
          <div className="w-full">
            <img
              src="assets/quote.png"
              className="h-20"
              alt="decorative-quote-icon"
            />
          </div>
          <div className="flex w-full items-center justify-center lg:my-5 lg:px-0 md:px-2">
            <div className="flex flex-col items-center justify-center relative lg:p-10 w-full">
              <div className="z-10 w-full">{testmonails[currentIndex]}</div>
              <div className="flex items-center justify-between w-full lg:absolute mt-5 lg:mt-0">
                {prevButton()}
                <text className="lg:hidden font-semibold text-3xl">
                  <span className="text-white">{`0${currentIndex + 1}`}</span>
                  <span className="text-orange-400">{`/0${testmonails.length}`}</span>
                </text>
                <button
                  onClick={() => increment()}
                  className="w-12 h-12"
                  aria-label="next-testominal"
                >
                  <img src="/assets/right-icon.png" alt="next-testomial" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const trackableLinkManagementType = {
  linkReport: {
    title: "Link Report",
  },
  createLink: {
    title: "Create New Link",
  },
  manageLink: {
    title: "Manage Existing Link",
  },
};

export const affiliateKeyTypes = {
  auth_email: "Auth Email",
  auth_uid: "Auth Id",
  status: "Status",
  timeOfAffiliation: "Time of Affiliation",
  timeOfBookedLoan: "Time of Booked Loan",
  timeOfFormCompletion: "Time of Form Completion",
  timeOfFormStart: "Time of Form Start",
  timeOfRegistration: "Time of Registration",
  previousAffiliateId: "Previous Affiliate  Id",
};

export const statusTypes = {
  affiliated: "Affiliated",
  registered: "Registered",
  sf_form_start: "Started Sunfish Form",
  sf_form_completion: "Completed Sunfish Form",
  sf_form_booked_loan: "Loan Booked",
};

export const partnerMangementStatuses = ["error", "inProgress", "complete"];

export const manageAffilatePartnersSlice = createSlice({
  name: "manageAffilatePartners",
  initialState: {
    selectedType: "affiliateReport",
    status: "inProgress",
    links: undefined,
    selectedAffiliate: undefined,
    affiliateReportState: {
      report: undefined,
      selectedAffiliatedUser: undefined,
      reportType: "all_time",
    },
    manageTrackableLinkState: {
      selectedLink: undefined,
    },
    createTrackableLinkState: {
      affiliatePartnerName: undefined,
      affiliatePartnerMessage: undefined,
      submittedAffiliate: undefined,
    },
  },
  reducers: {
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
      state.selectedAffiliate = undefined;
      state.status = "inProgress";
      state.affiliateReportState = {
        report: undefined,
        selectedAffiliatedUser: undefined,
        reportType: "all_time",
      };
      state.updateAffiliatedUserState = {
        users: undefined,
        selectedUser: undefined,
      };
      state.createTrackableLinkState = {
        linkName: undefined,
        queryUrl: undefined,
        submittedLink: undefined,
        trafficSource: undefined,
      };
      state.manageTrackableLinkState = {
        selectedLink: undefined,
      };
    },
    setLinks: (state, action) => {
      state.links = action.payload;
    },
    setSelectedAffiliate: (state, action) => {
      state.selectedAffiliate = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    affiliateReportStateSetReport: (state, action) => {
      state.affiliateReportState.report = action.payload;
    },
    affiliateReportStateSetReportType: (state, action) => {
      state.affiliateReportState.reportType = action.payload;
    },
    affiliateReportStateSetSelectedAffiliatedUser: (state, action) => {
      state.affiliateReportState.selectedAffiliatedUser = action.payload;
    },
    createTrackableLinkLinkName: (state, action) => {
      state.createTrackableLinkState.linkName = action.payload;
    },
    createTrackableLinkQueryName: (state, action) => {
      state.createTrackableLinkState.queryUrl = action.payload;
    },
    createTrackableLinkSubmittedLink: (state, action) => {
      state.createTrackableLinkState.submittedLink = action.payload;
    },
    createTrackableLinkTrafficSource: (state, action) => {
      state.createTrackableLinkState.trafficSource = action.payload;
    },
    manageTrackableLinkStateSetSelectedLink: (state, action) => {
      state.manageTrackableLinkState.selectedLink = action.payload;
    },
    updateAffiliatedSetUsers: (state, action) => {
      state.updateAffiliatedUserState.users = action.payload;
    },
    updateAffiliatedSetSelectedUser: (state, action) => {
      state.updateAffiliatedUserState.selectedUser = action.payload;
    },
  },
});

export const {
  setSelectedType,
  setLinks,
  setStatus,
  setSelectedAffiliate,
  affiliateReportStateSetReport,
  affiliateReportStateSetReportType,
  affiliateReportStateSetSelectedAffiliatedUser,
  createTrackableLinkLinkName,
  createTrackableLinkQueryName,
  createTrackableLinkSubmittedLink,
  createTrackableLinkTrafficSource,
  manageAffilatePartnersStateSetAffiliates,
  manageTrackableLinkStateSetSelectedLink,
} = manageAffilatePartnersSlice.actions;

export default manageAffilatePartnersSlice.reducer;

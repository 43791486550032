import React from "react";

export default function Believe() {
  return (
    <div className="w-full lg:bg-believe bg-cover bg-center flex items-center justify-center sm:pt-10 md:py-32">
      <div className="grid md:grid-cols-2 gap-10 w-11/12 md:w-9/12">
        <div className="p-2 md:p-10 text-navyblue">
          <h1 className="text-3xl md:text-5xl font-bold literata-font">
            What we <span className="font-light">believe</span>
          </h1>
          <p className="text-gray-500 lg:w-8/12 mt-7 lg:ml-16">
            <b className="text-gray-600">
              Understanding your fertility now gives you more options later.
            </b>{" "}
            Our healthcare system is inherently reactive, and fertility is no
            different. <br />
            "Just wait and see" isn't good enough.
          </p>
        </div>
        <div>
          <Card
            number="1."
            title="Reproductive freedom"
            des="We believe freedom includes reproductive freedom - that means
               we have a responsibility to help provide information, access,
               and affordability to family building."
          />
          <Card
            number="2."
            title="Financial fluency &amp; literacy"
            des="We believe in financial fluency &amp; literacy.  We help everyone
               understand the costs, the options, and the road to parenthood."
          />
          <Card
            number="3."
            title="Getting results"
            des="Some intended parents may find themselves at the end of costly
               &mdash; emotionally and financially &mdash; processes with an
               empty wallet and an empty cradle.  The current system isn't
               always aligned with outcomes."
          />
        </div>
      </div>
    </div>
  );
}

function Card({ title, des, number }) {
  return (
    <div className="bg-secondary/90 flex rounded-3xl p-10 mb-5">
      <div className="md:mr-10">
        <h1 className="text-xl text-navyblue md:text-2xl font-bold">{title}</h1>
        <p className="mt-2 text-sm text-gray-600">{des}</p>
      </div>
      <div className="h-16 ">
        <h1 className="text-verySoftOrange text-2xl ml-5 md:text-4 xl lg:text-6xl font-bold">
          {number}
        </h1>
      </div>
    </div>
  );
}

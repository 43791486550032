import React from "react";
import SFBanner, {
  SFBannerSubTitle,
  SFBannerImage,
  SFBannerTitle,
} from "./SFBanner";

export default function LGBTQParenthoodBanner() {
  return (
    <SFBanner
      title={
        <div className="place-items-start md:w-screen pb-8 lg:pt-16 md:pt-8 sm:pt-0">
          <SFBannerTitle
            title={
              <>
                LGBTQIA+
                <br />
                Parenthood
              </>
            }
            bodyAlignment="center"
          />
        </div>
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            <div className="flex flex-col place-items-center pt-8 text-lg w-3/4 mx-auto">
              <div className="text-center">
                Sunfish aims to make the parenthood journey easy
                <br className="sm:hidden md:block" /> and affordable for all
                types of families.
              </div>
            </div>
          }
        />
      }
      img={
        <div className="lg:w-6/12 md:mx-auto lg:mt-8 lg:bg-lightBlue lg:p-10 md:w-10/12 rounded-3xl place-self-center">
          <SFBannerImage
            imgSrc="/assets/lgbtqBG.png"
            imgSrcSm="/assets/lgbtqBGSm.png"
            fixedHeight={true}
            alt="couple_holding_hands"
          />
        </div>
      }
      bodyAlignment="center"
      bgImage="bg-primary lg:bg-lgbtq bg-no-repeat bg-center bg-contain"
    />
  );
}

import { useSelector } from "react-redux";
import Store from "../../App/Store";
import { setSignUpModalState } from "../../App/Features/UserSplice";

export default function BlogsAndEventsCard({
  date,
  description,
  footerLinkText,
  footerLinkUrl,
  footerText,
  imagePath,
  includeFooter,
  title,
  titleLinkUrl,
  titleLinkUrlAuthRequired,
  isNew,
  isCompact,
}) {
  const user = useSelector((state) => state.userState.user);
  const displayCompactDate = () => {
    if (date) {
      const dateElements = date.split("-");
      if (dateElements?.length === 3) {
        const dateObject = new Date(
          dateElements[0],
          dateElements[1] - 1,
          dateElements[2]
        );
        const dateObjectFormat = new Intl.DateTimeFormat("en-US", {
          month: "short",
          year: "numeric",
        }).format(dateObject);
        return dateObjectFormat;
      }
    }
    return date;
  };
  const displayDate = () => {
    if (date) {
      const dateElements = date.split("-");
      if (dateElements?.length === 3) {
        const dateObject = new Date(
          dateElements[0],
          dateElements[1] - 1,
          dateElements[2]
        );
        return new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        }).format(dateObject);
      }
    }
    return date;
  };

  // footerText: The full amount of text in the footer, e.g. "Register here."
  // footerLinkText: The subset of text we want linked, e.g. "here"
  // footerLinkUrl: Where the link should go, e.g. "https://joinsunfish.com"
  //
  // The above three arguments will generate:
  // <span>Register <a href="https://joinsunfish.com">here</a>.</span>
  //
  // If footerText is blank, returns <span></span>.
  // If footerLinkUrl is blank, this returns <span>{footerText}</span>
  // If footerLinkText is blank, this returns <span><a href={footerLinkUrl}>{footerText}</a>
  const createFooter = () => {
    if (!footerText) {
      return <span></span>;
    }

    if (!footerLinkUrl) {
      // Just return the footer text - there is no URL to link
      return <span>{footerText}</span>;
    }

    if (footerLinkText) {
      const footerLinkIndex = footerText.indexOf(footerLinkText);
      if (
        footerLinkIndex > -1 &&
        (footerLinkIndex > 0 || footerText.length > footerLinkText.length)
      ) {
        // footerText contains footerLinkText AND footerLinkText doesn't comprise the entire footerText.
        const footerLinkTextEndIndex = footerLinkIndex + footerLinkText.length;

        let prelude = null;
        let linkText = null;
        let epilogue = null;

        if (footerLinkIndex > 0) {
          prelude = footerText.substring(0, footerLinkIndex);
        }

        linkText = footerText.substring(
          footerLinkIndex,
          footerLinkTextEndIndex
        );

        if (footerLinkTextEndIndex < footerText.length) {
          epilogue = footerText.substring(footerLinkTextEndIndex);
        }

        return (
          <span>
            {prelude}
            <a
              href={footerLinkUrl}
              target="_blank"
              rel="noreferrer"
              className="underline text-navyblue"
            >
              {linkText}
            </a>
            {epilogue}
          </span>
        );
      }
    }

    // There is no footerLinkText OR footerLinkText is not a substring of footerText OR footerLinkText === footerText.
    return (
      <span>
        <a
          href={footerLinkUrl}
          target="_blank"
          rel="noreferrer"
          className="underline text-navyblue"
        >
          {footerText}
        </a>
      </span>
    );
  };

  const enableTitleUrl =
    titleLinkUrl &&
    (titleLinkUrlAuthRequired === undefined ||
      !titleLinkUrlAuthRequired ||
      user);

  return (
    <div
      className={
        isNew === undefined ? "" : isNew ? "bg-teal-400" : "bg-red-600"
      }
    >
      {isCompact ? (
        <img
          src={imagePath}
          alt={title}
          style={{ height: "230px", width: "100%" }}
        />
      ) : (
        <></>
      )}
      <div className={isCompact ? "" : "grid md:grid-cols-2 gap-10"}>
        {isCompact ? (
          <></>
        ) : (
          <img
            src={imagePath}
            className="rounded-3xl place-self-center banner-image"
            alt={title}
          />
        )}
        <div className="flex flex-col justify-center">
          <h1
            className={
              isCompact
                ? "text-xl font-bold literata-font mt-5 text-navyblue"
                : "text-2xl font-bold literata-font mb-1 mt-4 text-navyblue"
            }
          >
            {enableTitleUrl ? (
              <a href={titleLinkUrl} target="_blank" rel="noreferrer">
                {title}
              </a>
            ) : (
              <p
                onClick={() => {
                  if (!user) {
                    Store.dispatch(
                      setSignUpModalState({
                        showSignupModal: true,
                        authRequired: false,
                      })
                    );
                  }
                }}
              >
                {title}
              </p>
            )}
          </h1>
          <p className={isCompact ? "mt-2 text-sm" : "text-gray-700"}>
            {description}
          </p>
          <p className="mt-2 text-gray-500 text-sm">
            {isCompact ? displayCompactDate() : displayDate()}
          </p>
          {includeFooter ? createFooter() : <></>}
        </div>
      </div>
    </div>
  );
}

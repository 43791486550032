import React from "react";
import { Link } from "react-router-dom";
import NavBarMenu from "./NavBarMenu";
import NavBarFooter from "./NavBarFooter";

export default function NavBar({ user, usersOffersUrl }) {
  return (
    <nav className="lg:block sm:hidden sticky z-50 top-0 bg-primary py-5 lg:py-6 font-medium px-5 text-sm">
      <div className="flex flex-row">
        <div className="w-1/4">
          <Link to="/">
            <img
              src="/assets/logo.png"
              className="h-8"
              alt="sunfish-logo-nav"
            />
          </Link>
        </div>
        <div className="place-self-center w-full">
          <NavBarMenu user={user} />
        </div>
        <div className="max-w-[300px] max-h-[49px] [&_>_div]:!h-auto [&_>_div]:mt-[-20px]">
          <NavBarFooter user={user} usersOffersUrl={usersOffersUrl} />
        </div>
      </div>
    </nav>
  );
}

import React, { useState, useEffect, useCallback } from "react";
import {
  getCostCalculatorQuestions,
  calculateCosts,
  postPartnerCostCalculatorEngagement,
} from "../../api";
import { setSignUpModalState } from "../../App/Features/UserSplice";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  setConditionalQuestions,
  addResponses,
  setQuestions,
  setResults,
} from "../../App/Features/Calculator/CalculatorSlice";
import CalculatorUI from "./CalculatorUI";
import SFBlueButton from "../Buttons/SFBlueButton";
import SFDataProcessingDisclaimer from "../Forms/SFDataProcessingDisclaimer";

export default function SurrogacyComponent() {
  const user = useSelector((state) => state.userState.user);
  const results = useSelector((state) => state.calculator.results);
  const responses = useSelector((state) => state.calculator.responses);
  const questions = useSelector((state) => state.calculator.questions);
  const conditionalQuestions = useSelector(
    (state) => state.calculator.conditionalQuestions
  );
  const [requestedCalculation, setRequestedCalculation] = useState(false);
  const partner = useSelector((state) => state.partnerState.partner);

  const requestCalculation = () => {
    Store.dispatch(
      setSignUpModalState({
        showSignupModal: true,
        authRequired: false,
        title: "Create an account to see results",
      })
    );
    setRequestedCalculation(true);
  };

  useEffect(() => {
    Store.dispatch(setResults(undefined));
  }, [responses]);

  const calcCosts = useCallback(() => {
    const type = "surrogacy";
    if (partner) {
      postPartnerCostCalculatorEngagement(
        user.uid,
        user.email,
        partner.name,
        type
      );
    }
    calculateCosts(type, responses).then((response) =>
      Store.dispatch(setResults(response))
    );
  }, [partner, user, responses]);

  useEffect(() => {
    if (user && requestedCalculation) {
      calcCosts();
      setRequestedCalculation(false);
    }
  }, [user, requestedCalculation, calcCosts]);

  useEffect(() => {
    if (!questions) {
      getCostCalculatorQuestions("surrogacy").then((results) => {
        if (results !== null) {
          Store.dispatch(setQuestions(results));
          var surrogacyResponses = {};
          Object.entries(results).map(
            ([id, question], _) =>
              (surrogacyResponses[id] = question.defaultValue)
          );
          Store.dispatch(addResponses(surrogacyResponses));
        } else {
          Store.dispatch(setQuestions(undefined));
        }
      });
    }
  }, [questions]);

  useEffect(() => {
    if (!conditionalQuestions) {
      getCostCalculatorQuestions("ivf").then((results) => {
        if (results !== null) {
          Store.dispatch(setConditionalQuestions(results));
          var ivfResponses = {};
          Object.entries(results).map(
            ([id, question], _) => (ivfResponses[id] = question.defaultValue)
          );
          Store.dispatch(addResponses(ivfResponses));
        } else {
          Store.dispatch(setConditionalQuestions(undefined));
        }
      });
    }
  }, [conditionalQuestions]);

  const ui = questions ? (
    Object.entries(questions)
      .filter(([id, question]) => {
        // If the user has frozen embryos, they don't need to source sperm.
        if (id === "csq-6" && responses["csq-1"] === 2) {
          return false;
        }
        return true;
      })
      .map(([id, question], idx) => {
        return <CalculatorUI id={id} question={question} />;
      })
  ) : (
    <></>
  );

  var ivfUi =
    conditionalQuestions && responses && responses["csq-1"] === 0 ? (
      Object.entries(conditionalQuestions).map(([id, question], idx) => {
        return <CalculatorUI id={id} question={question} />;
      })
    ) : (
      <></>
    );

  var resultsUi = null;
  if (results && Object.keys(results).length > 0) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: results.currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const breakdownUi = results.breakdown.map((entry) => {
      const itemsList = entry.items.map((item) => {
        return (
          <div className="flex  w-full justify-between mt-3">
            <p>{item.name}</p>
            <p>{formatter.format(item.value)}</p>
          </div>
        );
      });

      return (
        <div className="bg-primary rounded-3xl py-10 px-7 md:p-10 w-11/12 md:w-full">
          <div>
            <h1 className="text-4xl font-bold">{entry.title}</h1>
            <div className="mt-5 md:mt-0">{itemsList}</div>
          </div>
        </div>
      );
    });

    resultsUi = (
      <div className="w-full flex items-center flex-col">
        <div className="w-11/12 md:w-8/12 gap-7 mt-7 grid md:grid-cols-2">
          {breakdownUi}
        </div>
        <div className="mt-7 rounded-3xl text-lg md:text-2xl font-bold w-11/12 md:w-8/12 bg-navyblue text-white p-10 flex justify-between items-center">
          <h1>Total for this plan</h1>
          <h1>{formatter.format(results.total)}</h1>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-11/12 md:w-8/12 py-10 mx-auto">
      <div className="w-11/12 md:w-8/12 bg-gray-100 py-10 px-7 md:p-10 mt-16 md:mt-28 rounded-3xl">
        <h1 className="text-3xl font-bold">Surrogacy</h1>
        <p className="mt-2">
          Toggle some of the key inputs to better reflect your own journey.
        </p>
        <div className="flex flex-col">
          <div className="grid md:grid-cols-2 gap-5 md:gap-10 mt-8">{ui}</div>
          <div className="grid md:grid-cols-2 gap-5 md:gap-10 my-8">
            {ivfUi}
          </div>
          <SFBlueButton
            title="Calculate"
            onClick={() => (user ? calcCosts() : requestCalculation())}
          />
        </div>
        <SFDataProcessingDisclaimer />
      </div>
      {resultsUi}
    </div>
  );
}

export default function OurOfferingsFormHeader({
  formCompleted,
  myDashboardPage = false,
}) {
  return myDashboardPage ? (
    <div></div>
  ) : (
    <div className="text-center">
      <h1 className="text-blue text-3xl lg:text-5xl literata-font italic text-navyblue">
        {!formCompleted && "For a wide range of options"}
      </h1>
      <h1 className="mt-4 text-navyblue text-lg font-bold">
        {!formCompleted && "answer a few questions."}
      </h1>
    </div>
  );
}

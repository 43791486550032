import BaseUpside from "./BaseUpside";
import PersonalInformation from "./PersonalInformation";
import FinancialInformation from "./FinancialInformation";
import LoanInformation from "./LoanInformation";
import CreditInformation from "./CreditInformation";
import MorgageInformation from "./MortgageInformation";
import EducationInformation from "./EducationInformation";

export default class LeadSubmissionRequest extends BaseUpside {
  getMyClass() {
    return LeadSubmissionRequest;
  }

  constructor(props) {
    super(props);

    this.leadUuid = props.leadUuid ?? "";
    this.partnerSubAccountUuid = props.partnerSubAccountUuid ?? "";
    this.allowsRemarketing = props.allowsRemarketing ?? false;
    this.personalInformation = new PersonalInformation(
      props.personalInformation ?? {},
    );
    this.financialInformation = new FinancialInformation(
      props.financialInformation ?? {},
    );
    this.loanInformation = new LoanInformation(props.loanInformation ?? {});
    this.creditInformation = new CreditInformation(
      props.creditInformation ?? {},
    );
    this.mortgageInformation = new MorgageInformation(
      props.mortgageInformation ?? {},
    );
    this.educationInformation = new EducationInformation(
      props.educationInformation ?? {},
    );
  }

  validateAll = () => {
    return (
      this.personalInformation.validateEmail() &&
      this.personalInformation.validatePhone() &&
      this.personalInformation.validateDateOfBirth() &&
      this.personalInformation.validateZipCode() &&
      this.personalInformation.validateSSN() &&
      this.personalInformation.validateState()
    );
  };
}

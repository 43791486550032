import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getIpAddress } from "api";

export const allPossiblePatientTypes = [
  "Sperm Source",
  "Egg Source",
  "Primary Patient",
];

export const fetchIpAddress = createAsyncThunk(
  "warrantyEnrollSlice/fetchIpAddress",
  async () => {
    const ip = await getIpAddress();
    return { ip };
  },
);

export const warrantyEnrollSlice = createSlice({
  name: "warrantyEnrollSlice",
  initialState: {
    typeOfConsent: undefined,
    showWarrantyEnrollModal: false,
    isSignUpState: false,
    agreeToTerms: false,
    name: "",
    url: undefined,
    partnerName: undefined,
    hipaaConsentDocumentVersion: undefined,
    status: "IN_PROGRESS",
    ipAddress: null,
    loadingIpAddress: false,
    hipaaConsentDate: null,
    hipaaAgreeTimestamp: null,
  },
  reducers: {
    setWarrantyEnrollModal: (state, action) => {
      state.showWarrantyEnrollModal = action.payload;
    },
    setIsSignUpState: (state, action) => {
      state.isSignUpState = action.payload;
    },
    setAgreeToTerms: (state, action) => {
      state.agreeToTerms = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setTypeOfConsent: (state, action) => {
      state.typeOfConsent = action.payload;
    },
    setUrl: (state, action) => {
      state.url = action.payload;
    },
    setPartnerName: (state, action) => {
      state.partnerName = action.payload;
    },
    setHipaaConsentDocumentVersion: (state, action) => {
      state.hipaaConsentDocumentVersion = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    init: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIpAddress.pending, (state) => {
      state.loadingIpAddress = true;
    });
    builder.addCase(fetchIpAddress.fulfilled, (state, action) => {
      const { ip } = action.payload;
      return {
        ...state,
        loadingIpAddress: false,
        ipAddress: ip,
      };
    });
    builder.addCase(fetchIpAddress.rejected, (state) => {
      state.loadingIpAddress = false;
    });
  },
});

export const {
  setUrl,
  setPartnerName,
  setTypeOfConsent,
  setWarrantyEnrollModal,
  setIsSignUpState,
  setAgreeToTerms,
  setName,
  setHipaaConsentDocumentVersion,
  setStatus,
  init,
} = warrantyEnrollSlice.actions;

export default warrantyEnrollSlice.reducer;

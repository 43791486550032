import React, { useEffect, useState } from "react";
import SFInput from "../Inputs/SFInput";
import SFDropdown from "../Inputs/SFDropdown";
import { InformationCircleIcon } from "@heroicons/react/solid";
import SFBanner, { SFBannerTitle } from "./SFBanner";

export default function LoanEstimatorBanner() {
  const [loanAmount, setLoanAmount] = useState("$10,000");
  const [apr, setApr] = useState(13);
  const [termLength, setTermLength] = useState(72);
  const [totalCost, setTotalCost] = useState(undefined);
  const [error, setError] = useState("");

  function calculate() {
    if (apr < 0.0) {
      setError("APR too low");
      return 0;
    } else if (apr >= 100) {
      setError("APR too high");
      return 0;
    } else if (termLength > 96) {
      setError("Term length too long");
    } else {
      setError("");
      const loanAmountInt = parseInt(
        loanAmount.substring(1).replaceAll(",", ""),
      );
      if (apr == 0) {
        return loanAmountInt / termLength;
      } else {
        const rate = apr / 100 / 12;
        const subCalculation0 = Math.pow(1 + rate, termLength);
        const subCalculation1 = subCalculation0 - 1;
        const cost = (loanAmountInt * rate * subCalculation0) / subCalculation1;
        return cost;
      }
    }
  }

  useEffect(() => {
    setTotalCost(calculate());
  }, [loanAmount, apr, termLength]);

  const updateLoanAmount = (e) => {
    if (e.target.value.length && e.target.value !== "$") {
      const value =
        e.target.value.length <= 1
          ? e.target.value
          : e.target.value.replaceAll(",", "").replaceAll("$", "");
      if (value > 100000) {
        return;
      }
      setLoanAmount(
        parseInt(value).toLocaleString("en-us", {
          style: "currency",
          currency: "usd",
          minimumFractionDigits: 0,
        }),
      );
    }
  };

  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle title="Loan Repayment Calculator" />
        </div>
      }
      subTitle={
        <div className="my-10 relative sm:w-10/12 md:w-10/12 lg:w-5/12 p-10 flex flex-col bg-lightBlue shadow-2xl shadow-black/30 rounded-3xl mx-auto">
          <div className="text-center">
            <div>
              <ul role="tablist">
                {error ? (
                  <p className="text-red-400">{error}</p>
                ) : totalCost ? (
                  <div className="bg-emerald-50 w-full py-5 inline-flex flex-col items-center justify-center">
                    <p className="text-6xl font-medium text-center text-blue-900">
                      {totalCost.toLocaleString("en-us", {
                        style: "currency",
                        currency: "usd",
                        minimumFractionDigits: 2,
                      })}
                    </p>
                    <p>Monthly loan payment</p>
                  </div>
                ) : (
                  <></>
                )}
                <SFInput
                  label="Loan amount"
                  value={loanAmount}
                  class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  handleChange={(e) => updateLoanAmount(e)}
                  type="text"
                />
                <div className={`flex py-2 px-4 $ flex-col`}>
                  <input
                    id="myRange"
                    type="range"
                    min="5000"
                    max="100000"
                    value={loanAmount.replaceAll(",", "").replaceAll("$", "")}
                    class="accent-blue-900 w-full h-2 rounded-lg appaearance-none cursor-pointer border-none bg-blue-900"
                    onChange={(e) => updateLoanAmount(e)}
                  />
                </div>
                <div className="grid grid-cols-2 place-content-between w-full py-2 text-stone-500 text-xs">
                  <div className="text-left px-4">{"$5,000"}</div>
                  <div className="text-right px-4">{"$100,000"}</div>
                </div>
                <div className="grid grid-cols-2">
                  <SFInput
                    label="APR (%)"
                    type="decimal"
                    value={apr}
                    handleChange={(e) => setApr(e.target.value)}
                    min={0.0}
                  />
                  <div className="py-2">
                    <SFDropdown
                      title="Term Length"
                      value={termLength}
                      options={[72, 60, 48, 36].map((e) => (
                        <option value={e} className="text-pink-400">
                          {e} mos
                        </option>
                      ))}
                      handleChange={(e) => setTermLength(e.target.value)}
                    />
                  </div>
                </div>
                <div className="px-4">
                  <div className="h-1 w-full bg-white lg:my-4 sm:my-2"></div>
                  <div className="flex">
                    <InformationCircleIcon className="h-6 text-gray-500" />
                    <div className="lg:text-base sm:text-sm ml-4 text-left">
                      <p>
                        This is an illustrative example. Not an actual loan
                        offer.
                      </p>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      }
      bodyAlignment={"center"}
      bgImage="bg-primary"
    />
  );
}

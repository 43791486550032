import React from "react";

export default function TabButton({
  title,
  onClick,
  isSelected,
  className = "py-4 px-10 lg:h-24",
}) {
  return (
    <button
      className={`text-lg font-semibold shadow-lg text-navyblue rounded-3xl block leading-normal w-full place-items-center ${
        isSelected ? "bg-yellow" : "bg-white"
      } ${className}`}
      onClick={() => onClick()}
    >
      {title}
    </button>
  );
}

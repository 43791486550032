import { useSelector } from "react-redux";
import {
  setPartner,
  setPartnerUserId,
  hasPartnerUserId,
} from "../App/Features/PartnerSlice";
import { getPartnerMetadata, postPartnerUser } from "../api";
import { useParams } from "react-router-dom";
import Store from "../App/Store";
import { useEffect } from "react";

export default function PartnerMessage() {
  let { routeName } = useParams();

  useEffect(() => {
    if (routeName) {
      getPartnerMetadata(routeName).then(async (partner) => {
        if (partner) {
          Store.dispatch(setPartner(partner));
          if (!hasPartnerUserId()) {
            postPartnerUser(routeName).then((partnerUserId) =>
              Store.dispatch(setPartnerUserId(partnerUserId)),
            );
          }
        }
      });
    }
  }, []);

  const partner = useSelector((state) => state.partnerState.partner);
  const affiliates = useSelector(
    (state) => state.manageAffiliateState.affiliates,
  );
  const affiliateId = useSelector((state) => state.affiliateState.affiliateId);

  return (
    <div className="flex items-center text-deepSapphire font-playfiar text-[1.7rem] leading-[2.8rem] font-medium">
      {partner?.message}
      <div>
        {
          Object.values(affiliates ?? {}).find((e) => e.id === affiliateId)
            ?.partnerMessage
        }
      </div>
    </div>
  );
}

import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import GrantsBanner from "../Components/Banners/GrantsBanner";
import GrantsList from "../Components/GrantsList";
import { getGrants, postPartnerGrantsEngagement } from "../api";
import { useSelector } from "react-redux";

export default function Grants() {
  var [showMoreFilter, setShowMoreFilter] = useState(false);
  const [loading, setLoadingState] = useState(false);
  const [grants, setGrants] = useState([]);
  var [filters, setFilters] = useState([]);
  var [page, setPage] = useState(1);
  const scrollRef = useRef(null);
  const partner = useSelector((state) => state.partnerState.partner);
  const user = useSelector((state) => state.userState.user);

  const lastElementParticipantValue = () => {
    const grant = grants[grants.length - 1];
    return grant?.participant;
  };

  const fetchMoreGrants = () => {
    const newPage = page + 1;
    setPage(newPage);
    getGrants(lastElementParticipantValue(), filters).then((newGrants) => {
      setGrants([...grants, ...newGrants]);
    });
  };

  const updateFilters = (filters) => {
    setLoadingState(true);
    setPage(1);
    var currentFilters = filters;
    setFilters(currentFilters);
    getGrants("", currentFilters).then((newGrants) => {
      if (partner) {
        postPartnerGrantsEngagement(user.uid, user.email, partner.name);
      }
      setGrants(newGrants);
      setLoadingState(false);
      scrollRef.current.scrollIntoView();
    });
  };

  const loadingComponent = () => {
    return (
      <div
        style={{
          border: "16px solid #f3f3f3",
          "border-top": "16px solid black",
          "border-radius": "50%",
          width: "120px",
          height: "120px",
          animation: "spin 2s linear infinite",
        }}
      />
    );
  };

  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <meta
          name="description"
          content="Sunfish offers a searchable collection of grants and discounts that support egg freezing, IVF, surrogacy, and parenthood journeys."
        />
      </Helmet>
      {GrantsBanner(updateFilters, showMoreFilter, setShowMoreFilter)}
      {showMoreFilter && <div className="my-60"> </div>}
      <div ref={scrollRef}></div>
      {grants?.length && !loading ? (
        <GrantsList grants={grants} fetchMoreGrants={fetchMoreGrants} />
      ) : (
        <div className="pb-28"></div>
      )}
      {loading ? loadingComponent() : <></>}
    </div>
  );
}

import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import PartnerProviderForm from "../Components/PartnerProvidersContactUsPage/PartnerProviderForm";
import PartnerProvidersContactUsBanner from "../Components/Banners/PartnerProvidersContactUsBanner";

export default function PartnerProvidersContactUs() {
  const formRef = useRef();
  const partnerContactUsState = useSelector((_state) => {
    const currentPartnerState =
      _state.partnerContactUsState[
        _state.partnerContactUsState?.currentPartnerType?.key
      ];
    if (currentPartnerState) {
      const groupIndex = currentPartnerState.groupIndex;
      const groupValues =
        currentPartnerState.groups[currentPartnerState.groupIndex].values;
      const groupSubtitle =
        currentPartnerState.groups[currentPartnerState.groupIndex].title;
      const readyToSubmit = currentPartnerState.readyToSubmit;
      const groupError =
        currentPartnerState.groups[currentPartnerState.groupIndex].groupError;
      return {
        groupValues: groupValues,
        groupSubtitle: groupSubtitle,
        groupIndex: groupIndex,
        currentPartnerState: currentPartnerState,
        partnerTypes: _state.partnerContactUsState.partnerTypes,
        currentPartnerType: _state.partnerContactUsState.currentPartnerType,
        readyToSubmit: readyToSubmit,
        groups: currentPartnerState.groups,
        completed: currentPartnerState.completed,
        partnerLead: _state.partnerContactUsState?.partnerLead,
        groupError: groupError,
      };
    }
    return {
      partnerTypes: _state.partnerContactUsState.partnerTypes,
      currentPartnerType: _state.partnerContactUsState.currentPartnerType,
    };
  });
  useEffect(() => {
    if (partnerContactUsState.currentPartnerType) {
      formRef?.current?.scrollIntoView({ block: "center" });
    }
  }, [partnerContactUsState.currentPartnerType]);
  return (
    <div className="bg-blue-100 flex items-center justify-center flex-col">
      <PartnerProvidersContactUsBanner
        currentPartnerType={partnerContactUsState.currentPartnerType}
        partnerTypes={partnerContactUsState.partnerTypes}
      />
      {partnerContactUsState.currentPartnerType === undefined ? (
        <div></div>
      ) : (
        <PartnerProviderForm
          formRef={formRef}
          partnerContactUsState={partnerContactUsState}
        />
      )}
    </div>
  );
}

import SFYellowLink from "../Links/SFYellowLink";

export default function LearnHowSunfishCanHelp() {
  return (
    <div className="lg:h-screen lg:grid lg:grid-cols-2 place-content-center place-self-center flex lg:flex-row sm:flex-col py-20 mx-auto w-2/3">
      <div className="block sm:place-self-center">
        <div className="lg:w-96 lg:h-96 sm:w-48 sm:h-48 bg-howWeCanHelp bg-left bg-no-repeat bg-contain bg-primary rounded-3xl block"></div>
      </div>
      <div className="grid grid-cols-1 place-content-center w-full lg:px-20 sm:py-10">
        <h1 className="lg:text-5xl sm:text-lg font-semibold text-navyblue literata-font">
          Learn how Sunfish can{" "}
          <span className="literata-font font-normal italic">
            help convert customers into patients
          </span>
        </h1>
        <div className="lg:text-xl sm:text-lg py-5">
          Help your patients get the financial support they need to move forward
          on their journey.
        </div>
        <SFYellowLink title="Learn more" to="/partners-contact-us" />
      </div>
    </div>
  );
}

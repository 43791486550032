export enum TreatmentType {
  any = "Any",
  ivf = "IVF",
  iui = "IUI",
  adoption = "Adoption",
  surrogacy = "Surrogacy",
  eggDonation = "Egg Donation",
  eggFreezing = "Egg Freezing",
  spermDonation = "Sperm Donation",
  spermFreezing = "Sperm Freezing",
  embryoCreation = "Embryo Creation",
  embryoDonation = "Embryo Donation",
  fertilityTreatment = "Fertility Treatment",
}

export class TreatmentTypeFilter {
  data: TreatmentType;

  constructor(data: TreatmentType) {
    this.data = data;
  }

  isGrantValid(grant): Boolean {
    if (this.data === TreatmentType.any) {
      return true;
    } else {
      const treatmentFilterType = this.data;
      const searchString: string = treatmentFilterType.toString().toLowerCase();
      const treatmentType = grant.treatmentType;
      if (treatmentType) {
        const grantSearch: string = treatmentType.toString().toLowerCase();
        var value: Boolean = grantSearch.includes(searchString);
        if (value) {
          return true;
        }
      }
    }
    return false;
  }
}

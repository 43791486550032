import OurOfferingsForm from "../OurOfferings/OurOfferingsForm";
import { useSelector } from "react-redux";
import { setAccountInfoFormCompleted } from "../../App/Features/MyDashboardSlice";
import { setAgreeToMarketing } from "../../App/Features/UserSplice";
import Store from "../../App/Store";
import { updateMarketingConsent } from "../../api";

export default function ProfileBody() {
  const accountInfoFormCompleted = useSelector(
    (state) => state.myDashboardState.accountInfoFormCompleted,
  );
  const user = useSelector((state) => state.userState.user);
  const isDashboardVisible = useSelector(
    (state) => state.myDashboardState.isDashboardVisible,
  );
  const currentStep = useSelector(
    (state) => state.myDashboardState.currentStep,
  );

  let updateConsent = (newConsentState) => {
    updateMarketingConsent(user.uid, newConsentState)
      .then(() => {
        Store.dispatch(setAgreeToMarketing(newConsentState));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div
      className={`${
        isDashboardVisible ? "sm:w-full" : "w-screen"
      } min-h-screen bg-lightgreenblue p-1`}
    >
      {user ? (
        <div>
          <OurOfferingsForm
            myDashboardPage={true}
            formCompleted={accountInfoFormCompleted}
            setFormCompletedStatus={(value) =>
              Store.dispatch(setAccountInfoFormCompleted(value))
            }
          />
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import SFForm from "../SFForm";
import UpdatePartnerForm from "./UpdatePartnerForm";
import AddPartnerForm from "./AddPartnerForm";
import PartnerMangementComplete from "./PartnerMangementComplete";
import PartnerReport from "./PartnerReport";
import { partnerMangementTypes } from "../../App/Features/ManagePartnersSlice";
import UpdatePartnerUser from "./UpdatePartnerUser";

export default function PartnersManagementBody({ bodyRef }) {
  const status = useSelector((state) => state.managePartnersState.status);
  const selectedType = useSelector(
    (state) => state.managePartnersState.selectedType
  );

  const getFormBody = () => {
    if (status === "complete") {
      return <PartnerMangementComplete />;
    } else if (selectedType === "updatePartner") {
      return <UpdatePartnerForm />;
    } else if (selectedType === "addPartner") {
      return <AddPartnerForm />;
    } else if (selectedType === "partnerReport") {
      return <PartnerReport />;
    } else if (selectedType === "updateAffiliatedUser") {
      return <UpdatePartnerUser />;
    } else {
      return <></>;
    }
  };

  return (
    <div ref={bodyRef}>
      <SFForm
        title={partnerMangementTypes[selectedType].title}
        body={getFormBody()}
      />
    </div>
  );
}

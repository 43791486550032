export default function SFRadioButton({
  id,
  label,
  value,
  options,
  onChange,
  ariaLabel,
  isRequired,
  error,
}) {
  return (
    <div key={id} className="flex flex-col py-2 px-4">
      <fieldset>
        <legend className="text-left">
          <p className="mb-1 font-semibold">{label}</p>
        </legend>
        <div className="grid grid-cols-2">
          {options.map((option, i) => (
            <div className="flex flex row gap-x-4">
              <input
                type="radio"
                onChange={(e) => onChange(e, i)}
                checked={option === value}
                aria-label={ariaLabel}
                value={value}
              ></input>
              <p aria-label={option} className="text-left">
                {option}
              </p>
            </div>
          ))}
        </div>
      </fieldset>
      {error & !value && isRequired ? (
        <text className="text-red-400">Please select a value </text>
      ) : (
        <div />
      )}
    </div>
  );
}

import React, { useRef } from "react";

export default function PrivacyPolicy({
  className = "p-10 lg:w-9/12",
  showTitle = true,
}) {
  const informationWeGet = useRef();

  return (
    <div id="content_1180abca5" className={className}>
      <div class="is-content">
        {showTitle && (
          <div>
            <p>
              <strong className="text-navyblue text-6xl font-semibold">
                Terms and Privacy
              </strong>
            </p>
            <br />
          </div>
        )}
        <p>
          We respect your privacy and are committed to protecting it through our
          compliance with this policy. This policy describes the types of
          information we may collect from you or that you may provide when you
          visit our website or use our service and our practices for collecting,
          using, maintaining, protecting and disclosing that information.
          <br />
          <br />
          Sunfish Solutions, LLC’s (“Sunfish”, “we” or “us”) Privacy Policy
          applies to any person who visits our website or mobile application
          (“App”), and other online or offline offerings (together with the
          website and the App, the “Services”), and how we and our affiliates
          collect, process, and use their personal information in connection
          with our Services. <br />
          <br />
          This Privacy Policy applies to personal information we collect from
          you and from third parties, both online and offline.
          <br />
          <br /> By accessing Sunfish 's Services, or otherwise providing us
          with personal information, you agree to the terms and conditions of
          this Privacy Policy. Our Terms of Use take precedent over any
          provision conflicting with our Privacy Policy. This is our complete
          Privacy Policy and supersedes all earlier versions. We respect your
          privacy and are committed to protecting it through our compliance with
          this policy. This policy describes the types of information we may
          collect from you or that you may provide when you visit our website or
          use our service and our practices for collecting, using, maintaining,
          protecting and disclosing that information. <br />
          <br />
          <strong className="text-navyblue">
            Changes to Our Privacy Policy
          </strong>
          <br /> <br />
          We update our Privacy Policy regularly—this version is effective as of
          the date indicated above. We will notify you of material changes to
          the Privacy Policy as required by applicable law. You understand and
          agree that you will be deemed to have accepted any updates we make if
          you continue to use our Services after the new Privacy Policy takes
          effect.
          <br />
          <br />
          <strong className="text-navyblue text-lg">
            1. HOW AND WHY WE COLLECT INFORMATION ABOUT YOU
          </strong>
          <br />
          <b className="text-navyblue">1.1. Information you provide us</b>
          <br /> <b className="text-navyblue">1.1.1. Personal Information</b>
          <br />
          We collect personal information from you to help you use our services
          and as described below.
          <br /> <br />
          We collect personal information in a number of ways when you interact
          with us such as submitting loan applications, registering to use our
          Services, discussing your application or product with us, requesting
          customer or technical support, applying for a job, communicating with
          us or providing feedback to us. Examples of your personal information
          include:
          <br />
          <span>&#8226;</span> Name
          <br />
          <span>&#8226;</span> Email Address and password when you sign up for
          an account with us.
          <br /> <br />
          For compliance purposes, we may collect information from you that you
          enter on the “Additional & Credit Information” or other similar form
          to help us to determine your eligibility to obtain certain Services
          and as required by United States Federal law such as your address,
          telephone number, citizenship status, date of birth, Social Security
          Number, additional identifiers, and certain information related to
          your financial situation such as employment information, salary and/or
          liquidated assets related information.
          <br /> <br />
          We may also collect information about your health and medical history
          such as:
          <br />
          <span>&#8226;</span> Health information such as height and weight{" "}
          <br />
          <span>&#8226;</span> Health information related to ability to undergo
          fertility treatment
          <br />
          <span>&#8226;</span> History with IVF and other fertility treatments{" "}
          <br />
          <br />
          We may also collect demographic information which may include:
          <br />
          <span>&#8226;</span> Age <br />
          <span>&#8226;</span> Income <br />
          <span>&#8226;</span> Geographic Area <br />
          <br />
          You may choose to provide us information about your fertility clinic,
          pharmacy and/or agency. <br /> <br />
          We may also collect personal information and details associated with
          your payment for medical treatment, including payment information. Any
          payments made via our Services with a credit or debit card are
          processed by third-party payment processors. We do not directly
          collect or store any payment card information entered through our
          Services, but we may receive information associated with your payment
          card information (e.g., your billing details). <br /> <br />
          We may contact you to participate in surveys. If you decide to
          participate, you may be asked to provide certain information, which
          may include personal information.
          <br /> <br />
          We may collect personal information that you submit or make available
          through our interactive features (e.g., messaging and chat features,
          commenting functionalities, forums, blogs, and social media pages).
          Any information you provide using the public sharing features of the
          Services (referred to herein as “User Content”) will be considered
          “public,” unless otherwise required by applicable law, and is not
          subject to the privacy protections referenced herein. <br /> <br />
          We may collect personal information from individuals when we attend or
          host conferences, trade shows, and other events.
          <br /> <br />
          We may collect personal information from individuals and third parties
          to assess and pursue potential business opportunities.
          <br /> <br />
          We may post job openings and opportunities on our website. If you
          reply to one of these postings by submitting your application, CV
          and/or cover letter to us, we will collect and use this information to
          assess your qualifications.
          <br /> <br />
          We may supplement the personal information you submit to us with
          information from third-party sources. See{" "}
          <button
            onClick={() =>
              informationWeGet?.current?.scrollIntoView({ block: "center" })
            }
            className="text-navyblue italic"
          >
            Information We Get from Others
          </button>{" "}
          for more information.
          <br /> <br />
          Finally, our Services are not directed to children under the age of
          18, and we do not knowingly collect personal information from children
          under 18 years of age. If we learn or are notified that we have
          collected this information from a child under the age of 18, we will
          promptly remove the data from our system. <br /> <br />
          <strong className="text-navyblue">
            1.2. Information we collect about you
          </strong>
          <br />
          <b className="text-navyblue">1.2.1 Aggregate Information</b>
          <br />
          We may use personal information and other information about you to
          create de-identified and/or aggregated information, which is data we
          collect about your use of our websites or services, from which
          personal information has been removed and no longer identifies you
          personally. For example, de-identified demographic information,
          information about the device from which you access our Services, or
          other analyses we create. Aggregate data is used to help us understand
          consumer trends, needs, interests, and preferences so we can improve
          our products and services. We may share this aggregated or
          de-identified information with third parties for various business
          purposes, where permitted by applicable law.
          <br /> <br />
          <b className="text-navyblue">1.2.2. Consumer Reports</b>
          <br />
          As a company that may partner with other parties that engage in
          lending, Sunfish may both receive and transmit data to consumer
          reporting agencies. We partner with these agencies to assess your
          creditworthiness and to prevent fraud and identity theft. This is done
          as part of our regular underwriting, fraud prevention and loan
          servicing processes.
          <br /> <br />
          With your consent, we may collect in these reports your credit
          worthiness related information which includes, but is not limited to:
          <br /> <br />
          Information about open and closed credit accounts, including the date
          opened, the date closed (if applicable), the latest reported monthly
          balance and monthly payment
          <br />
          Information about credit inquiries
          <br />
          Information about late payments and collection actions occurring on
          open and closed credit accounts
          <br />
          Information regarding public records of bankruptcy, judgements, tax
          liens, and other payment statuses
          <br />
          The credit score produced by the credit bureau providing the report
          <br />
          Information about your transaction history, assets, and account
          balances
          <br />
          Correcting information maintained by the credit bureaus
          <br /> <br />
          Information related to your creditworthiness is maintained by the
          credit bureaus. If you find that there is an error or you want to
          dispute the information found in your credit report, please contact
          the credit reporting bureaus. <br />
          <br />
          Experian -{" "}
          <a
            href="http://www.experian.com/"
            rel="noreferrer"
            target="_blank"
            className="underline text-blue-700"
          >
            http://www.experian.com/
          </a>{" "}
          <br />
          Transunion -{" "}
          <a
            href="https://dispute.transunion.com/"
            rel="noreferrer"
            target="_blank"
            className="underline text-blue-700"
          >
            https://dispute.transunion.com/
          </a>
          <br />
          Equifax -{" "}
          <a
            href="https://www.equifax.com/personal/"
            rel="noreferrer"
            target="_blank"
            className="underline text-blue-700"
          >
            https://www.equifax.com/personal/
          </a>
          <br /> <br />
          Website Use and Logging Data:
          <br /> <br />
          Sunfish may also collect personal information automatically when you
          use our Services, such as information about your computer or mobile
          device, user settings, cookie identifiers, mobile carrier, mobile
          advertising, and other unique identifiers to improve and evaluate your
          use of our website and our services. We may gather information
          regarding your use of our Services such as your browser type,
          operating system, Internet service provider, loading or exiting of web
          pages, and the Internet Protocol (IP) address of your computer or
          device, and location information (including approximate location
          derived from IP address). See the “Cookies” section below for more
          information about how we automatically collect personal information.{" "}
          <br /> <br />
          <b className="text-navyblue" ref={informationWeGet}>
            1.2.3 Information We Get from Others
          </b>
          <br />
          We may supplement the personal information you submit to us with
          information from other sources, including through third-party services
          and organizations. For example, if you access our Services through a
          third-party application, such as an app store, a third-party login
          service, or a social networking site, we may collect information about
          you from that third-party application that you have made available via
          your privacy settings. When you give your consent to obtain your
          credit score, we may also obtain your credit history from consumer
          reporting agencies. <br /> <br />
          Our Services may contain social media buttons, such as Facebook,
          Instagram, Twitter, and LinkedIn, which might include widgets such as
          the “share this” button or other interactive mini programs). These
          features may collect your IP address and which page you are visiting
          on our Services and may set a cookie to enable the feature to function
          properly. Your interactions with these platforms are governed by the
          privacy policy of the company providing it.
          <br /> <br />
          We may use personal information to tailor and provide you with content
          and advertisements. We may provide you with these materials as
          permitted by applicable law. Some of the ways we market to you include
          email campaigns, custom audiences advertising, and “interest-based” or
          “personalized advertising,” including through cross-device tracking.
          We may contract with third parties to place advertisements for our
          products and services on websites that are not our own. We may place
          these ads where we think they are likely to be most relevant. These
          advertisements on third-party websites allow us to track responses to
          our ads. We and our affiliates use this information to track the
          performance of our advertising and marketing campaigns by using tools
          like cookies, web beacons and similar technologies. We use these tools
          to collect and store information about your visits, page visits and
          duration, and the specific ad or link that the user clicked on to
          visit the site and the frequency and duration of your interactions. If
          you have any questions about our marketing, you may contact us at any
          time as set forth in “Contact Us” below.
          <br /> <br />
          <b className="text-navyblue">1.2.4 Cookies</b>
          <br />
          Sunfish, as well as third parties that provide content, advertising,
          or other functionality on our Services, may use “cookies” and similar
          technologies (“Technologies”) to automatically collect information
          through your use of our services. We collect information such as the
          types of service used, and number of users we receive daily. Our web
          servers may automatically log information about your computer.
          <br /> <br />
          We and our vendors use cookies to improve our service, our site and to
          provide more convenient and relevant experiences to you. Cookies are
          designed to transfer a small amount of data to your browser by servers
          within a domain. That data is only read by designated servers within
          that domain. It functions as your computer’s identification card and
          enables Sunfish to improve your experience by securely maintaining
          your authenticated session and preferences.
          <br /> <br />
          A web beacon embeds a small transparent gif image in a web page or
          email used to track when the page or email has been viewed. A similar
          device may be used where a product, service or functionality sends
          data to a server when a set of user-initiated events occur such as
          clicking a button on the website or in an email. This is similar to a
          cookie – it tracks your visit and the data is only read by the server
          that receives the data. However, it differs because it is not
          browser-based, may not function as an ID card and doesn’t store any
          data on your computer.
          <br /> <br />
          Our uses of these Technologies fall into the following general
          categories:
          <br /> <span>&#8226;</span> <strong>Operationally Necessary. </strong>
          This includes Technologies that allow you access to our Services,
          applications, and tools that are required to identify irregular
          website behavior, prevent fraudulent activity and improve security or
          that allow you to make use of our functionality;
          <br /> <span>&#8226;</span> <strong>Performance-Related. </strong>
          We may use Technologies to assess the performance of our Services,
          including as part of our analytic practices to help us understand how
          individuals use our Services (see Analytics below);
          <br /> <span>&#8226;</span> <strong>Functionality-Related. </strong>
          We may use Technologies that allow us to offer you enhanced
          functionality when accessing or using our Services. This may include
          identifying you when you sign into our Services or keeping track of
          your specified preferences, interests, or past items viewed;
          <br /> <span>&#8226;</span>{" "}
          <strong>Advertising- or Targeting-Related. </strong>
          We may use first party or third-party Technologies to deliver content,
          including ads relevant to your interests, on our Services or on
          third-party websites. <br /> <span>&#8226;</span>{" "}
          <strong>Analytics. </strong>
          We may use Technologies and other third-party tools to process
          analytics information on our Services. Some of our analytics partners
          include:
          <br /> <span className="ml-20">&#8226;</span>{" "}
          <strong>Hotjar. </strong>
          For more information about Hotjar, please visit{" "}
          <a
            href="https://www.hotjar.com/legal/policies/privacy/"
            className="hover:underline hover:text-blue-700"
          >
            Hotjar’s Privacy Policy
          </a>
          .
          <br /> <span className="ml-20">&#8226;</span>{" "}
          <strong>Google Analytics. </strong>
          To learn more about how to opt-out of Google Analytics’ use of your
          information, please click{" "}
          <a
            href="http://tools.google.com/dlpage/gaoptout"
            className="hover:underline hover:text-blue-700"
          >
            here{" "}
          </a>
          .
        </p>
        <br /> Most Internet browsers are set up to accept cookies
        automatically. You may stop or restrict the placement of Technologies on
        your device or remove them by adjusting your preferences. For example,
        you may set your browser to notify you when you receive a cookie,
        allowing you to decide whether or not to accept it. For some web pages
        requiring an authorization, cookies are not optional. Users choosing not
        to accept cookies or similar devices will probably not be able to access
        those pages or their products and services. Please note that
        cookie-based opt-outs are not effective on mobile applications. However,
        you may opt-out of personalized advertisements on some mobile
        applications by following the instructions for{" "}
        <a
          href="https://support.google.com/googleplay/android-developer/answer/6048248?hl=en"
          className="hover:underline hover:text-blue-700"
        >
          Android
        </a>
        ,{" "}
        <a
          href="https://support.apple.com/en-us/HT202074"
          className="hover:underline hover:text-blue-700"
        >
          iOS
        </a>
        , and{" "}
        <a
          href="https://www.networkadvertising.org/mobile-choice/"
          className="hover:underline hover:text-blue-700"
        >
          others
        </a>
        .
        <br /> <br />
        The online advertising industry also provides websites from which you
        may opt out of receiving targeted ads from data partners and other
        advertising partners that participate in self-regulatory programs. You
        can access these and learn more about targeted advertising and consumer
        choice and privacy by visiting the{" "}
        <a
          href="http://www.networkadvertising.org/managing/opt_out.asp"
          className="hover:underline hover:text-blue-700"
        >
          Network Advertising Initiative
        </a>
        , and
        <a
          href="http://www.aboutads.info/choices/"
          className="hover:underline hover:text-blue-700"
        >
          the Digital Advertising Alliance
        </a>
        . <br /> <br />
        Please note you must separately opt out in each browser and on each
        device.
        <p>
          <br />
          <b className="text-navyblue">1.2.5 Contacting us by Telephone</b>
          <br />
          If you communicate with us by telephone, we may monitor or record your
          conversation with one of our representatives. This is done for reasons
          such as maintaining the integrity of your account, providing effective
          and timely service, and the improvement of Sunfish’s products.
        </p>
        <br />
        <p>
          <strong className="text-navyblue">
            2. USE OF YOUR PERSONAL INFORMATION
          </strong>
          <br />
          We use your personal information to provide you with information or
          services you request, to inform you about other information and
          services we think will be of interest to you, to facilitate your use
          and our operation of our website, to improve our products and
          services, and to market our products and services to you. We don’t
          forget about you once you’ve signed—Sunfish provides services to help
          you build your family and manage costs.
          <br /> <br />
          Here are some examples of ways in which we use your personal
          information:
          <br /> <span>&#8226;</span>Creating an account and maintaining your
          login information on our site
          <br /> <span>&#8226;</span>
          Contacting you regarding issues with your account, activities on our
          Services, and policy changes
          <br /> <span>&#8226;</span>Marketing our products and services to you
          <br /> <span>&#8226;</span>Determining your eligibility for and
          facilitating the processing of a loan or line of credit through our
          lending partners Analyzing the cost and effectiveness of fertility
          services
          <br /> <span>&#8226;</span>
          Allowing our servicing partners to process your payments
          <br /> <span>&#8226;</span>
          Invitations to Sunfish events
          <br /> <span>&#8226;</span>
          Answering requests for customer or technical support
          <br /> <span>&#8226;</span>
          Developing new product offerings
          <br /> <span>&#8226;</span>
          Improving, upgrading, or enhancing our Services
          <br /> <span>&#8226;</span>
          Processing applications if you apply for a job we post on our Services
          <br /> <span>&#8226;</span>
          Engaging in direct marketing, research, and development (including
          marketing research), network and information security, and fraud
          prevention
          <br /> <span>&#8226;</span>Detecting security incidents, protecting
          against malicious, deceptive, fraudulent, or illegal activity, and
          prosecuting those responsible for that activity
          <br /> <span>&#8226;</span>Measuring interest and engagement in our
          Services Short-term, transient use, such as contextual customization
          of ads
          <br /> <span>&#8226;</span>
          Ensuring internal quality control and safety
          <br /> <span>&#8226;</span>
          Authenticating and verifying individual identities
          <br /> <span>&#8226;</span>
          Debugging to identify and repair errors with our Services
          <br /> <span>&#8226;</span>
          Auditing relating to interactions, transactions, and other compliance
          activities
          <br /> <span>&#8226;</span>
          Enforcing our agreements and policies
          <br /> <span>&#8226;</span>
          Other uses as required to comply with our legal obligations
          <br /> <span>&#8226;</span>
          We may use personal information for other purposes:1) that are clearly
          disclosed to you at the time you provide personal information; 2) with
          your consent; 3) as requested by you; or 4) as permitted by applicable
          law.
          <br />
          <br />
          <strong> Sharing your personal information</strong>
          <br />
          We may share your personal information with our affiliate partners as
          described in this privacy policy and as permitted by applicable law.
          We may share your personal information with our vendors, service
          providers, or other unaffiliated third parties for any business
          purpose, including for marketing of our own products and services, so
          long as such information is used by such vendor or service provider on
          our behalf. If you have any questions about our marketing practices or
          if you would like to opt out of the use of your information for
          marketing purposes, you may contact us at any time as set forth in
          “Contact Us” below.
          <br />
          e your personal information, including your health data, with vendors
          or service providers who perform functions on our behalf. This
          includes vendors that provide us with IT support, hosting, payment
          processing, marketing efforts, customer service, and related services.
          Third parties we share your information with may include, but are not
          limited to:
          <br />
          <br /> <span>&#8226;</span>
          Marketing and other service providers
          <br /> <span>&#8226;</span>
          Our loan servicing partners who provide your statements, process your
          payments, and service your account or loan;
          <br /> <span>&#8226;</span>
          Other parties who may assist you in obtaining loans or financing as
          permitted by applicable law (if you are a resident of Vermont we will
          not sell your personal information, or share your personal information
          with nonaffiliated third parties for their marketing purposes, without
          your consent)
          <br /> <span>&#8226;</span>
          Financial institutions as required by laws regulating loan
          securitization
          <br /> <span>&#8226;</span>
          Credit reporting agencies
          <br /> <span>&#8226;</span>
          Direct marketing service providers
          <br /> <span>&#8226;</span>
          Auditors and examiners
          <br /> <span>&#8226;</span>
          Law enforcement, regulators, and other government bodies
          <br /> <span>&#8226;</span>
          Our regulators and other relevant government agencies
          <br /> <span>&#8226;</span>
          Please note that, if you are a current borrower, you may not control
          certain information associated with your loan that you have already
          agreed to provide to certain investors as described in your loan
          agreement.
          <br /> <span>&#8226;</span>
          We may use third-party application program interfaces (“APIs”) and
          software development kits (“SDKs”) as part of the functionality of our
          Services. For more information about our use of APIs and SDKs, please
          contact us as set forth in “Contact Us” below.
          <br /> <span>&#8226;</span>
          We may access, preserve, and disclose any information we store
          associated with you to external parties if we, in good faith, believe
          doing so is required or appropriate to: comply with law enforcement or
          national security requests and legal process, such as a court order or
          subpoena; protect your, our, or others’ rights, property, or safety;
          enforce our policies or contracts; collect amounts owed to us; or
          assist with an investigation or prosecution of suspected or actual
          illegal activity.
          <br /> <span>&#8226;</span>
          Finally, personal information may be disclosed or transferred as part
          of, or during negotiations of, a merger, consolidation, sale of our
          assets, as well as equity financing, acquisition, strategic alliance
          or in any other situation where personal information may be
          transferred as one of the business assets of Sunfish.
          <br /> <br />
          <strong className="text-navyblue text-lg">
            3. PROTECTING YOUR PERSONAL INFORMATION
          </strong>
          <br />
          Sunfish takes the privacy and security of its members' personal
          information seriously. We maintain administrative, technical and
          physical safeguards designed to protect your information’s security,
          confidentiality and integrity.
          <br /> <br />
          Whenever we save your personal information, it’s stored in locations
          that only selected personnel and our contractors have access to.
          Remember – all information you submit to us by email is not secure, so
          please do not send sensitive information in any email to Sunfish.
          <br /> <br />
          <strong>
            Steps you can take to help us keep your information safe
          </strong>
          <br />
          Sunfish urges your caution when using public computers or networks,
          like at a coffee shop or library. To best protect your personal
          information, don’t use such computers to access your sensitive
          accounts, and if you must, ensure that you logout of your account
          entirely. We would not ask for sensitive information over email or
          text.
          <br /> <br />
          <strong className="text-navyblue text-lg">
            4. RETENTION OF PERSONAL INFORMATION.
          </strong>
          <br />
          We store the personal information we collect as described in this
          Privacy Notice for as long as you use our Services, or as necessary to
          fulfill the purpose(s) for which it was collected, provide our
          Services, resolve disputes, establish legal defenses, conduct audits,
          pursue legitimate business purposes, enforce our agreements, and
          comply with applicable laws
          <br /> <br />
          <strong className="text-navyblue">
            5. YOUR CHOICES REGARDING YOUR PERSONAL INFORMATION
          </strong>
          <br />
          We want you to be aware of the choices you have in limiting the
          sharing of your personal information. Please note that your privacy
          choices are determined by applicable law.
        </p>
        <p>
          <strong className="text-navyblue">
            5.1 Changing and Updating Your Personal Information
          </strong>
        </p>
        We want to provide you with the resources you need to keep your
        information with us correct and up to date. If you need to change any
        information related to your application, please contact us at
        support@joinsunfish.com
        <br /> <br />
        Our website contains links to other sites that may collect your personal
        information. For example, Sunfish has relationships with various service
        providers whose services are available to you from our website, from
        other websites that are linked to our website, or in connection with our
        services. These partners offer services we think our customers may find
        useful or will improve our services. Those providers may require you to
        provide personal information to use their services. These third-party
        services are not controlled by us and personal information collected
        through these third-party services is not covered by this Privacy
        Policy. Unless stated otherwise, the policies of those partners will
        govern the collection, use, disclosure and security of your personal
        information. We encourage our users to read the privacy policies of each
        website or other services with which they interact. We do not endorse,
        screen, or approve, and are not responsible for, the privacy practices
        or content of such other websites or services. Providing personal
        information to third-party websites or services is at your own risk.
        <p>
          <strong className="text-navyblue">5.2 Other Choices</strong>
        </p>
        Do Not Track (“DNT”) is a privacy preference that users can set in
        certain web browsers. We don’t respond to “do not track” signals or
        similar mechanisms transmitted by web browsers – where a visitor to
        Sunfish’s website requests that we disable the collection of information
        about the visitor’s online activities through tracking cookies. We may
        also permit third parties to collect aggregate usage information on our
        website, and they may also not respond to “do not track” signals.
        <br />
        <br />
        You may opt out of receiving text messages from us by following the
        instructions in the text message you have received from us or by
        otherwise contacting us.
        <br /> <br />
        We may send you push notifications through our mobile application. You
        may opt out from receiving these push notifications by changing the
        settings on your mobile device. With your consent, we may also collect
        precise location-based information via our mobile application. You may
        opt out of this collection by changing the settings on your mobile
        device.
        <br /> <br />
        If you receive an unwanted email from us, you can use the unsubscribe
        link found at the bottom of the email to opt out of receiving future
        emails. Note that you will continue to receive transaction-related
        emails regarding products or Services you have requested. We may also
        send you certain non-promotional communications regarding us and our
        Services, and you will not be able to opt out of those communications
        (e.g., communications regarding our Services or updates to our Terms or
        this Privacy Policy. <br /> <br />
        <p>
          <strong className="text-navyblue">6. COMPLAINTS & CONCERNS</strong>
        </p>
        <p>
          Sunfish takes your privacy seriously, and maintains a process to
          respond to your concerns regarding violations of this Policy. If you
          believe that we haven’t complied with this policy in regards to your
          personal information or have questions regarding your privacy with us,
          please contact us at support@joinsunfish.com. If you are reporting
          non-compliance with this Privacy Policy, please describe the issue in
          as much detail as possible without disclosing any sensitive
          information about you or third parties. We will respond to your
          request by email within 7-10 business days.
        </p>{" "}
        <br />
        <strong className="text-navyblue">7. CALIFORNIA SHINE THE LIGHT</strong>
        <p>
          The California “Shine the Light” law permits users who are California
          residents to request and obtain from us once a year, free of charge, a
          list of the third parties to whom we have disclosed their personal
          information (if any) for their direct marketing purposes in the prior
          calendar year, as well as the type of personal information disclosed
          to those parties.
        </p>{" "}
        <br />
        <strong className="text-navyblue">8. NEVADA RESIDENTS:</strong>
        <p>
          Nevada residents have the right to opt out of the sale of certain
          pieces of their information to third parties who intend to license or
          sell that personal information. We do not sell “personal information”
          of Nevada residents, but, if you are a Nevada resident who has
          questions or who wants to make this type of request, please send your
          email to support@joinsunfish.com.
        </p>
        <br />
        <strong className="text-navyblue">9. CONTACT US</strong>
        <p>
          If you have any questions about our privacy practices or this Privacy
          Policy, or to exercise your rights as detailed in this Privacy Policy,
          please contact us in the help center section of the App, or at:
        </p>
        <br />
        Sunfish Solutions, LLC
        <br />
        support@joinsunfish.com
      </div>
    </div>
  );
}

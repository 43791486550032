import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import CommonUtils from "utils/CommonUtils";

export default function MyProfileButton({ showMenu }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.userState.user);

  const handleClick = () => {
    CommonUtils.isInIframe()
      ? window.top.postMessage({ type: "route", route: "/profile" }, "*")
      : navigate("/profile");
    if (showMenu) {
      showMenu();
    }
  };

  useEffect(() => {
    // Tell parent there is a my profile button so it can resize
    if (user?.loanAmount) {
      window.top.postMessage({ type: "myprofile" }, "*");
    }
  }, [user?.loanAmount]);

  return user?.loanAmount ? (
    <div
      className="px-6 py-2 border-[1.5px] border-[#CEDFE6] rounded-full bg-[#F7FAFB] text-center text-[1.125rem] font-semibold text-[#052461] cursor-pointer max-[301px]:text-base max-[301px]:bg-blackHaze hover:bg-[#F5D4C2] hover:font-bold"
      onClick={handleClick}
    >
      My Profile
    </div>
  ) : (
    <></>
  );
}

import { configureStore } from "@reduxjs/toolkit";
import partnerReducer from "./Features/PartnerSlice";
import calculatorReducer from "./Features/Calculator/CalculatorSlice";
import userReducer from "./Features/UserSplice";
import navReducer from "./Features/NavSplice";
import myDashboardSliceReducer from "./Features/MyDashboardSlice";
import addBlogsAndEventsReducer from "./Features/AddBlogsAndEventsSplice.js";
import managePartnersReducer from "./Features/ManagePartnersSlice";
import appReducer from "./Features/AppSlice";
import affiliateReducer from "./Features/AffiliateSlice";
import manageAffiliateReducer from "./Features/AffiliateManagementSlice";
import partnerContactUsReducer from "./Features/PartnerContactUsSlice";
import blogsAndEventsReducer from "./Features/BlogsAndEventsSlice";
import warrantyEnrollReducer from "./Features/WarrantyEnrollSlice";
import trackableLinkReducer from "./Features/TrackableLinkSlice";
import trackableLinkManagementReducer from "./Features/TrackableLinkManagementSlice";
import Upside from "./Features/Upside";

export default configureStore({
  reducer: {
    trackableLinkState: trackableLinkReducer,
    trackableLinkManagementState: trackableLinkManagementReducer,
    warrantyEnrollState: warrantyEnrollReducer,
    blogsAndEventsState: blogsAndEventsReducer,
    manageAffiliateState: manageAffiliateReducer,
    affiliateState: affiliateReducer,
    partnerContactUsState: partnerContactUsReducer,
    partnerState: partnerReducer,
    calculator: calculatorReducer,
    userState: userReducer,
    navState: navReducer,
    myDashboardState: myDashboardSliceReducer,
    addBlogsAndEventsState: addBlogsAndEventsReducer,
    managePartnersState: managePartnersReducer,
    appState: appReducer,
    upsideState: Upside,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

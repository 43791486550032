export default function SFFormButton({ title, onClick }) {
  return (
    <div className="flex flex-col place-items-center">
      <button onClick={() => onClick()}>
        <div className="bg-navyblue lg:w-80 rounded-md items-center text-center text-white py-3 px-6">
          {title}
        </div>
      </button>
    </div>
  );
}

import BasicDomain from "domain/BasicDomain";

export default class BaseUpside extends BasicDomain {
  getMyClass() {
    return BaseUpside;
  }

  constructor(props) {
    super(props);
    this.failed = props.failed ?? false;
    this.error = props.error ?? {};
  }

  generate = () => this.process();

  process() {
    let results = {};
    results = Object.getOwnPropertyNames(this.clone()).reduce((arr, key) => {
      let items = arr;
      if (
        typeof this[key] !== "function" &&
        !["error", "failed"].includes(key)
      ) {
        try {
          items = {
            ...items,
            [key]:
              typeof this[key] === "object" ? this[key].generate() : this[key],
          };
        } catch (error) {
          console.error({ key, error });
        }
      }
      return items;
    }, results);

    return results;
  }
}

import React from "react";
import { Helmet } from "react-helmet";
import OurMissionBanner from "../Components/Banners/OurMissionBanner";
import Believe from "../Components/OurMissionPageComponents/Believe";
import WeValue from "../Components/OurMissionPageComponents/WeValue";
import WhoWeAre from "../Components/OurMissionPageComponents/WhoWeAre";

export default function OurMission() {
  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <meta
          name="description"
          content="Sunfish's mission is to make the journey to parenthood financially attainable for everyone, with financial guidance and resources."
        />
      </Helmet>
      <OurMissionBanner />
      <Believe />
      <WeValue />
      <WhoWeAre />
    </div>
  );
}

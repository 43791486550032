import React from "react";

export default function GrantCard({ grant, index }) {
  const grantsSubHeading = (grant) => {
    var text = "";
    const grantSize = grant.grantSize * 100;
    if (Number.isInteger(grantSize) && grantSize < 100) {
      text = `${grantSize}% discount`;
    } else if (grantSize != null) {
      if (Number(grant.grantSize)) {
        text = `$${grant.grantSize}`;
      } else {
        text = `${grant.grantSize === "Undisclosed" ? "" : grant.grantSize}`;
      }
    }
    if (text) {
      return <h1 className="pt-2 pb-4">{text}</h1>;
    } else {
      return <div></div>;
    }
  };

  const grantAttribute = (title, value) => {
    if (
      value === undefined ||
      value === "Any" ||
      value === "Other" ||
      value === "Not Specified"
    ) {
      return;
    }

    let valueBlock = null;
    let titlePrefix = null;

    if (value === false) {
      titlePrefix = "No";
    } else if (value !== true) {
      valueBlock = (
        <>
          <span className="text-navyblue">:</span>
          &nbsp;<span className="font-light">{value}</span>
        </>
      );
    }

    return (
      <div className="flex flex-row py-1 text-sm">
        <span className="text-navyblue font-medium">
          {titlePrefix} {title}
        </span>
        {valueBlock}
      </div>
    );
  };

  const openInNewTab = (url) => {
    var win = window.open(url, "_blank");
    win.focus();
  };

  const getAgeGroup = () => {
    if (grant.ageRestrictionLowerLimit && grant.ageRestrictionUpperLimit) {
      return `${grant.ageRestrictionLowerLimit} - ${grant.ageRestrictionUpperLimit}`;
    } else if (grant.ageRestrictionLowerLimit) {
      return `${grant.ageRestrictionLowerLimit} or older`;
    } else if (grant.ageRestrictionUpperLimit) {
      return `${grant.ageRestrictionUpperLimit} or younger`;
    } else {
      return "Any";
    }
  };

  const getSexualOrentation = () => {
    if (grant.sexualOrientation === "Homosexual") {
      return "Gay/Lesbian";
    }

    return grant.sexualOrientation;
  };

  const getSpecialHealthConditionStatus = () => {
    if (
      grant.specialHealthConditions &&
      grant.specialHealthConditions.toLowerCase().includes("cancer")
    ) {
      return true;
    }

    return false;
  };

  const getPreApprovalRequired = () => {
    if (
      grant.timingOfFund &&
      grant.timingOfFund
        .toLowerCase()
        .includes("Advance / pre-treatment".toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  return (
    <div
      key={`${grant.participant} ${index}`}
      className={`flex bg-lightgreenblue h-100 rounded-3xl p-4 flex flex-col place-items-strech`}
    >
      <div className="flex-grow">
        <text className="text-2xl font-bold text-navyblue">
          {grant.participant}
        </text>
        {grantsSubHeading(grant)}
        {grantAttribute("Provider Type", grant.providerType)}
        {grantAttribute("Location", grant.location)}
        {grantAttribute("Age group", getAgeGroup())}
        {grantAttribute("Professional Group", grant.professionalGroup)}
        {grantAttribute("Religious Affiliation", grant.religiousAffiliation)}
        {grantAttribute("Sexual Orientation", getSexualOrentation())}
        {grantAttribute("Marital Status", grant.maritalStatus)}
        {grantAttribute("Income Restriction", grant.incomeRestriction)}
        {grantAttribute(
          "Previous Children Required",
          grant.alreadyHaveChildren,
        )}
        {grantAttribute(
          "Proof of Infertility Diagnosis",
          grant.proofOfInfertilityDiagnosis,
        )}
        {grantAttribute("Pre-Approval Required", getPreApprovalRequired())}
        {grantAttribute(
          "Special Health Conditions",
          getSpecialHealthConditionStatus(),
        )}
      </div>
      <div className="grid place-items-start">
        <button
          className="bg-yellow py-3 px-6 text-sm font-medium rounded-full"
          onClick={() => openInNewTab(grant.url)}
        >
          <p className="text-navyblue">Apply</p>
        </button>
      </div>
    </div>
  );
}

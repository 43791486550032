import React from "react";

export default function WhoWeAre() {
  return (
    <div className="w-full flex items-center justify-center mt-24">
      <div className="w-10/12 md:w-8/12 flex flex-col md:flex-row">
        <div className="w-10/12 md:w-4/12 relative h-56">
          <img src="/assets/WhoWeAreBg.png" className="absolute top-0" alt="" />
          <h1 className="text-4xl font-bold md:text-6xl literata-font z-20 absolute mt-5 text-navyblue">
            Who we are
          </h1>
        </div>
        <div className="md:ml-10 text-10/12 md:w-8/12 ">
          <h1 className="text-lg md:text-2xl mt-5 md:mt-0 text-gray-600">
            <span className="font-semibold">
              We have been there - and we'll stick with you each step of the way
              on your path through parenthood.{" "}
            </span>
            <span>
              Our team has personal experience working with IVF, egg retrievals,
              adoption and many alternative ways of family building.
            </span>
          </h1>
          <br />
          <p className="text-gray-600">
            {" "}
            Our CEO, Angela Rastegar, was previously on the leadership team of a
            surrogacy agency and egg bank, where she helped hundreds of couples
            and individuals explore assisted reproduction.
            <br />
            <br />
            Through this work, she saw first hand the gap in financial resources
            for those pursuing parenthood. Bringing together a team of
            individuals who have deep expertise in technology, financial
            literacy, consumer lending, and more, Sunfish is one of the first
            platforms to focus on affording the entire parenthood journey.
          </p>
        </div>
      </div>
    </div>
  );
}

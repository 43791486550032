import React from "react";
import { Link } from "react-router-dom";
import { postPartnerConsultationCallEngagement } from "../api";
import { useSelector } from "react-redux";

export default function Offerings() {
  const partner = useSelector((state) => state.partnerState.partner);
  const user = useSelector((state) => state.userState.user);

  return (
    <div className="lg:py-20 lg:pt-40 w-10/12 2xl:w-8/12">
      <h1 className="text-4xl md:text-5xl font-semibold literata-font lg:leading-tight text-navyblue text-left lg:text-left md:text-left">
        Our Offerings
      </h1>
      <div className="w-full grid md:grid-cols-2 gap-10 py-5 text-left text-xl font-semibold text-gray-500">
        <p>
          Our mission is to make the journey to parenthood financially
          attainable for everyone.
        </p>
        <p>
          We know the assisted reproduction process can be difficult &mdash; we
          aim to simplify the financial piece.
        </p>
      </div>
      <div className="grid gap-7 lg:gap-10 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 py-5">
        {/* Card 1 */}
        <div className="bg-secondary rounded-3xl p-7 h-100 justify-around flex-col flex">
          <h1 className="text-2xl font-semibold literata-font lg:leading-tight  text-navyblue">
            Financing options at your fingertips
          </h1>
          <br />
          <div className="mx-auto">
            <Link to="/financing-options">
              <button className="text-sm text-white literata-font bg-navyblue py-4 px-8 mt-3 font-medium rounded-full text-center h-auto mx-auto">
                Financing Options
              </button>
            </Link>
          </div>
        </div>
        {/* Card 2 */}
        <div className="bg-lightgreenblue rounded-3xl p-7 h-100 justify-around flex-col flex">
          <h1 className="text-2xl font-semibold literata-font lg:leading-tight  text-navyblue">
            Many possible cost savings
          </h1>
          <br />
          <div className="mx-auto">
            <Link to="/Grants">
              <button className="text-sm text-white literata-font bg-navyblue py-4 px-8 mt-3 font-medium rounded-full text-center h-auto">
                Explore grants & discounts
              </button>
            </Link>
          </div>
        </div>

        {/* Card 3 */}
        <div className="bg-[#FBF9E5] rounded-3xl p-7 h-100 justify-around flex-col flex">
          <h1 className="text-2xl font-semibold literata-font lg:leading-tight  text-navyblue">
            Dedicated financial advocates
          </h1>
          <br />
          <a
            href="https://calendly.com/joinsunfish/financial-consultation"
            target="_blank"
            rel="noreferrer"
            className="text-sm text-white literata-font bg-navyblue py-4 mt-3 w-40 font-medium rounded-full text-center h-auto mx-auto"
            onClick={() => {
              if (partner && user) {
                postPartnerConsultationCallEngagement(
                  user.uid,
                  user.email,
                  partner.name
                );
              }
            }}
          >
            Contact now
          </a>
        </div>
      </div>
    </div>
  );
}

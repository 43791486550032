import BasicArray from "domain/BasicArray";
import Offer from "./Offer";

export default class OfferArray extends BasicArray {
  getMyClass() {
    return OfferArray;
  }

  getMyItemClass() {
    return Offer;
  }

  constructor(props) {
    super(props);
  }
}

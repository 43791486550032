import React from "react";
import SFBanner, { SFBannerTitle } from "../Banners/SFBanner";
import TabButton from "../Buttons/TabButton";
import {
  affiliateMangementTypes,
  setSelectedType,
} from "../../App/Features/AffiliateManagementSlice";
import { useSelector } from "react-redux";
import Store from "../../App/Store";

export default function AffiliateManagementBanner({ bodyRef }) {
  const selectedType = useSelector(
    (state) => state.manageAffiliateState.selectedType
  );

  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle
            title="Manage Affiliate Partner Links"
            bodyAlignment="center"
          />
        </div>
      }
      subTitle={
        <div className="grid lg:grid-cols-4 md:grid-cols-1 gap-5 py-10 px-20">
          {Object.keys(affiliateMangementTypes).map((key) => (
            <TabButton
              title={affiliateMangementTypes[key].title}
              onClick={() => {
                Store.dispatch(setSelectedType(key));
                bodyRef.current.scrollIntoView();
              }}
              isSelected={selectedType === key}
            />
          ))}
        </div>
      }
      bodyAlignment="center"
      bgImage="bg-primary"
    />
  );
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import TabButton from "../Buttons/TabButton";
import { setSelectedType } from "../../App/Features/AddBlogsAndEventsSplice";
import Store from "../../App/Store";
import { blogsAndEventsTypes } from "../../App/Features/AddBlogsAndEventsSplice";
import SFBanner, { SFBannerTitle, SFBannerSubTitle } from "./SFBanner";

export default function AddBlogsAndEventsBanner({ bodyRef }) {
  const user = useSelector((state) => state.userState.user);
  const selectedType = useSelector(
    (state) => state.addBlogsAndEventsState.selectedType,
  );

  useEffect(() => {
    if (selectedType) {
      bodyRef.current.scrollIntoView();
    }
  }, [selectedType, bodyRef]);

  return (
    <SFBanner
      title={
        <>
          <SFBannerTitle title="Add Blogs or Events" bodyAlignment="center" />
        </>
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            <div className="text-center lg:w-1/2 sm:w-screen px-16 mx-auto">
              <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-3 lg:gap-7 mt-10">
                {Object.keys(blogsAndEventsTypes).map((key) => {
                  if (user?.acl && user?.acl[blogsAndEventsTypes[key]?.acl]) {
                    return (
                      <TabButton
                        title={blogsAndEventsTypes[key].title}
                        onClick={() => {
                          Store.dispatch(setSelectedType(key));
                          bodyRef.current.scrollIntoView();
                        }}
                        isSelected={selectedType === key}
                        className="py-4 h-24 lg:px-10 sm:px-5 md:text-base"
                      />
                    );
                  } else {
                    return <div />;
                  }
                })}
              </div>
            </div>
          }
        />
      }
      bodyAlignment="center"
      bgImage="w-screen h-screen bg-cover bg-no-repeat md:bg-sfBackground bg-sfBackgroundSm"
    />
  );
}

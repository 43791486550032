import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavBarMenuMobile from "./NavBarMenuMobile";
export default function NavBarMobile({ user, usersOffersUrl }) {
  const [active, setActive] = useState(false);
  const showMenu = () => {
    setActive(!active);
  };

  return active ? (
    <NavBarMenuMobile
      user={user}
      usersOffersUrl={usersOffersUrl}
      showMenu={showMenu}
    />
  ) : (
    <nav className="sm:block lg:hidden sticky z-50 w-full top-0 py-5 lg:py-6 font-medium px-5 text-sm bg-primary">
      <div className="flex flex-row place-content-between">
        <Link to="/" className="relative">
          <img src="/assets/logo.png" className="h-8" alt="sunfish-logo-nav" />
        </Link>
        <button
          onClick={showMenu}
          className="block text-blue-900 text-lg font-semibold lg:hidden focus:outline-none"
          aria-label="show-menu"
        >
          <img src="/assets/menu.png" alt="" />
        </button>
      </div>
    </nav>
  );
}

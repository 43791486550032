export default function AsSeenIn() {
  return (
    <div className="py-10 grid grid-cols-1 place-items-center lg:px-32 sm:px-2">
      <div className="py-16 font-semibold sm:text-3xl md:text-3xl lg:text-4xl text-navyblue literata-font">
        As seen on
      </div>
      <div className="grid md:grid-cols-1 lg:grid-cols-5 gap-10 place-items-stretch mb-16">
        <a
          target="_blank"
          href="https://www.bloomberg.com/news/articles/2022-09-30/people-trying-to-get-pregnant-are-paying-for-ivf-with-bnpl-fintech-startups#xj4y7vzkg"
          className="w-40"
        >
          <img src="/assets/bloomberg_logo.png" alt="bloomberg" />
        </a>
        <a
          target="_blank"
          href="https://money.usnews.com/loans/personal-loans/articles/ivf-loans-fertility-financing-options-explained"
          className="w-40"
        >
          <img src="/assets/usn.png" alt="usn" />
        </a>
        <a
          target="_blank"
          href="https://www.axios.com/pro/health-tech-deals/2023/01/18/sunfish-4m-fertility-financing"
          className="w-40"
        >
          <img src="/assets/axios.png" alt="axios" />
        </a>
        <a
          target="_blank"
          href="https://techcrunch.com/2023/01/24/sunfishs-technology-wants-to-bring-affordability-to-assisted-reproduction/"
          className="w-40"
        >
          <img src="/assets/techcrunch.png" alt="techcrunch" />
        </a>
        <a
          target="_blank"
          href="https://finance.yahoo.com/news/fertility-treatments-arent-just-1-121800311.html"
          className="w-40"
        >
          <img src="/assets/yahoo.png" alt="yahoo" />
        </a>
      </div>
    </div>
  );
}

import React from "react";
import SFBanner, { SFBannerTitle } from "./SFBanner";

export default function PrivacyPolicyBanner() {
  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle
            title="Privacy "
            titleSpan="policy"
            titleSpanNewLine={true}
          />
          <p className="mt-5 text-center">Last Modified: June 2023</p>
        </div>
      }
      bgImage="md:bg-offerings bg-offeringsPhone bg-cover bg-no-repeat"
      bodyAlignment="center"
    />
  );
}

import WarrantyBanner from "../Components/Banners/WarrantyBanner";
import EachParenthood from "../Components/Warranty/EachParenthood";
import SunfishProtectionProgram from "../Components/Warranty/SunfishProtectionProgram";
import ManyPeople from "../Components/Warranty/ManyPeople";
import Testmonail from "../Components/Testmonail";
import GetStarted from "../Components/Warranty/GetStarted";
import Disclaimers from "../Components/Warranty/Disclaimers";

export default function Warranty() {
  return (
    <div className="flex flex-col">
      <WarrantyBanner />
      <EachParenthood />
      <SunfishProtectionProgram />
      <ManyPeople />
      <Testmonail />
      <GetStarted />
      <Disclaimers />
    </div>
  );
}

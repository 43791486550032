import React, { useEffect, useRef } from "react";
import SFForm from "../Forms/SFForm";
import Store from "../../App/Store";
import {
  prevGroup,
  nextGroup,
  setValue,
  setCompleted,
  setGroupError,
  formGroupErrorHandler,
} from "../../App/Features/PartnerContactUsSlice";
import SFFormHeader from "../../Components/Forms/SFFormHeader";
import SFFormButton from "../../Components/Forms/SFFormButton";
import SFRadioInput from "../../Components/Forms/SFRadioInput";
import SFInput from "../../Components/Inputs/SFInput";
import { postPartnerLeads } from "../../api";

export default function PartnerProviderForm({
  formRef,
  partnerContactUsState,
}) {
  const successMessageRef = useRef();

  useEffect(() => {
    if (partnerContactUsState?.completed) {
      successMessageRef?.current?.scrollIntoView({ block: "center" });
    }
  }, [partnerContactUsState?.completed]);
  return partnerContactUsState?.completed ? (
    <div className="py-20" ref={successMessageRef}>
      <text className="font-bold text-3xl text-navyblue">Thank you!</text>
      <br />
      <text>You will be contacted by a Sunfish Advocate shortly.</text>
    </div>
  ) : (
    <SFForm
      innerRef={formRef}
      header={
        <SFFormHeader
          title="Sunfish Partner Form"
          subTitle="Answer a few questions to partner with Sunfish"
          isBackButtonEnabled={partnerContactUsState.groupIndex > 0}
          onClickBack={() => Store.dispatch(prevGroup())}
        />
      }
      body={Object.keys(partnerContactUsState?.groupValues)?.map((key) =>
        partnerContactUsState?.groupValues[key].hidden ? (
          <></>
        ) : partnerContactUsState?.groupValues[key].type === "radio" ? (
          <SFRadioInput
            {...partnerContactUsState?.groupValues[key]}
            onChange={(e, i) =>
              Store.dispatch(
                setValue({
                  updateId: key,
                  updateValue:
                    partnerContactUsState?.groupValues[key].options[i],
                }),
              )
            }
          />
        ) : (
          <SFInput
            {...partnerContactUsState?.groupValues[key]}
            handleChange={(e) =>
              Store.dispatch(
                setValue({
                  updateId: key,
                  updateValue: e.target.value,
                }),
              )
            }
          />
        ),
      )}
      button={
        <SFFormButton
          title={partnerContactUsState.readyToSubmit ? "SUBMIT" : "CONTINUE"}
          onClick={() => {
            const isGroupError = formGroupErrorHandler(
              partnerContactUsState.groupValues,
            );
            if (isGroupError) {
              Store.dispatch(setGroupError());
            } else if (partnerContactUsState.readyToSubmit) {
              postPartnerLeads(partnerContactUsState.partnerLead).then((_) =>
                Store.dispatch(setCompleted()),
              );
            } else {
              Store.dispatch(nextGroup());
            }
          }}
        />
      }
    />
  );
}

import React from "react";
import { useSelector } from "react-redux";
import SFForm from "../SFForm";
import { affiliateMangementTypes } from "../../App/Features/AffiliateManagementSlice";
import CreateAffilatePartnerForm from "./CreateAffiliatePartnerForm";
import ManageAffiliatePartnerForm from "./ManageAffiliatePartnerForm";
import AffiliatePartnerReport from "./AffiliatePartnerReport";
import UpdateAffiliatedUser from "./UpdateAffiliatedUser";

export default function AffiliateManagementBody({ bodyRef }) {
  const selectedType = useSelector(
    (state) => state.manageAffiliateState.selectedType
  );

  const getFormBody = () => {
    if (selectedType === "affiliateReport") {
      return <AffiliatePartnerReport />;
    } else if (selectedType === "createAffilatePartner") {
      return <CreateAffilatePartnerForm />;
    } else if (selectedType === "manageAffilatePartners") {
      return <ManageAffiliatePartnerForm />;
    } else if (selectedType === "updateAffiliatedUser") {
      return <UpdateAffiliatedUser />;
    } else {
      return <></>;
    }
  };

  return (
    <div ref={bodyRef}>
      <SFForm
        title={affiliateMangementTypes[selectedType]?.title}
        body={getFormBody()}
      />
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { postPartnerConsultationCallEngagement } from "../api";
import { useSelector } from "react-redux";

export default function HowWeHelp() {
  const partner = useSelector((state) => state.partnerState.partner);
  const user = useSelector((state) => state.userState.user);

  return (
    <div className="bg-help bg-top bg-cover flex flex-col items-center justify-center py-28 w-full">
      <div className="w-10/12 gap-24 md:w-8/12 flex flex-col-reverse lg:grid md:grid-cols-2">
        <img
          src="/assets/single_female.png"
          className="bg-orange rounded-full flex items-center w-full justify-center max-w-md m-auto"
          alt=""
        />
        <div className="flex flex-col justify-center">
          <h1 className="text-3xl lg:text-5xl font-semibold literata-font lg:leading-tight text-navyblue">
            How Sunfish Helps
          </h1>
          <p className="mt-5 font-semibold text-2xl lg:text-3xl text-gray-600">
            Journeys to parenthood vary wildly, and so do the associated costs.
          </p>
          <p className="mt-3 text-lg lg:text-xl text-gray-600">
            Sunfish helps aspiring parents build a custom plan and then
            recommends trusted partners to help achieve it.
          </p>
          <div className="mt-10">
            <Link
              to="/financing-options"
              className="hidden lg:inline text-navyblue bg-yellow py-4 px-8 font-semibold literata-font lg:leading-tight text-sm rounded-full"
            >
              Learn more about our offerings
            </Link>
            <Link
              className="lg:hidden text-navyblue bg-yellow py-4 px-8 font-semibold literata-font lg:leading-tight text-sm rounded-full"
              to="/financing-options"
            >
              Get Options
            </Link>
          </div>
        </div>
      </div>
      <div className="bg-navyblue py-16 px-8 lg:p-20 text-center text-white w-10/12 lg:w-6/12 mt-28 rounded-3xl">
        <h1 className="text-3xl lg:text-5xl font-semibold literata-font lg:leading-tight ">
          Just getting started?
        </h1>
        <p className="md:w-3/5 sm:w-5/6 mx-auto py-5 text-white/70 text-lg">
          Our Sunfish financial advocates are available to chat and help you
          plan or discover providers that could be well-suited for your needs.
          We also offer a calculator that can help you project the total cost of
          your intended journey to parenthood.
        </p>
        <div className="flex flex-col lg:flex-row items-center justify-center py-5">
          <Link
            className="hidden lg:block text-navyblue w-full md:w-auto bg-yellow py-4 mr-2 px-8 font-semibold literata-font lg:leading-tight rounded-full"
            to="/cost-calculator"
          >
            Estimate the costs of my journey
          </Link>
          <Link
            className="lg:hidden text-navyblue w-full md:w-auto bg-yellow py-4 mr-2 px-8 font-semibold literata-font lg:leading-tight rounded-full"
            to="/cost-calculator"
          >
            Estimate the costs
          </Link>
          <a
            className="lg:hidden text-navyblue w-full md:w-auto bg-white py-4 px-8 mt-2 lg:mt-0 lg:ml-2 font-semibold literata-font lg:leading-tight rounded-full"
            target="_blank"
            rel="noreferrer"
            href="https://calendly.com/joinsunfish/financial-consultation"
            onClick={() => {
              if (partner && user) {
                postPartnerConsultationCallEngagement(
                  user.uid,
                  user.email,
                  partner.name
                );
              }
            }}
          >
            Schedule a call
          </a>
          <a
            className="hidden lg:block text-navyblue w-full md:w-auto bg-white py-4 px-8 mt-2 lg:mt-0 lg:ml-2 font-semibold literata-font lg:leading-tight rounded-full"
            target="_blank"
            rel="noreferrer"
            href="https://calendly.com/joinsunfish/financial-consultation"
            onClick={() => {
              if (partner && user) {
                postPartnerConsultationCallEngagement(
                  user.uid,
                  user.email,
                  partner.name
                );
              }
            }}
          >
            Schedule a call with Sunfish
          </a>
        </div>
      </div>
    </div>
  );
}

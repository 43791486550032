import BaseUpside from "./BaseUpside";
import OfferArray from "./OfferArray";
import Offer from "./Offer";

export default class LeadSubmissionResponse extends BaseUpside {
  getMyClass() {
    return LeadSubmissionResponse;
  }

  constructor(props) {
    super(props);

    this.requestId = props.requestId ?? "";
    this.offers = new OfferArray(
      props.offers && props.offers.length > 0
        ? props.offers.map((item) => new Offer(item))
        : [],
    );
    this.rejectionReasons = props.rejectionReasons ?? [];
  }
}

import React, { useRef, useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import OurOfferingsBanner from "../Components/Banners/OurOfferingsBanner";
import { areFeatureFlagsEnabled } from "../api";
import { setSignUpModalState } from "../App/Features/UserSplice";
import { useSelector } from "react-redux";
import Store from "../App/Store";
import {
  setEvenIntegrationEnabled,
  setFrbIntegrationEnabled,
} from "../App/Features/AppSlice";
import { useLocation } from "react-router-dom";
import SunfishForm from "../Components/OurOfferings/SunfishForm";

export default function OurOfferings({ scrollIntoForm = false }) {
  const [formCompleted, setFormCompletedStatus] = useState(false);
  const user = useSelector((state) => state.userState.user);
  const usersOffersUrl = useSelector((state) => state.userState.usersOffersURL);
  const scrollIntoFormEnabled = useSelector(
    (state) => state.appState.scrollIntoFormEnabled,
  );

  const location = useLocation();

  const executeScroll = useCallback(
    (type = { block: formCompleted ? "start" : "center" }) =>
      scrollRef.current.scrollIntoView(type),
    [formCompleted],
  );

  useEffect(() => {
    if (scrollIntoFormEnabled) {
      const locationState = location.state;
      if (locationState !== null && locationState !== undefined) {
        const scrollIntoFormState = locationState.scrollIntoForm;
        if (scrollIntoFormState) {
          executeScroll();
        }
      }
    }
  }, [location.state, scrollIntoFormEnabled, executeScroll]);

  useEffect(() => {
    if (scrollIntoForm) {
      executeScroll();
    }
  }, [scrollIntoForm, executeScroll]);

  useEffect(() => {
    if (usersOffersUrl) {
      window.open(usersOffersUrl, "_self").focus();
    }
  }, [usersOffersUrl]);

  useEffect(() => {
    if (!user) {
      Store.dispatch(
        setSignUpModalState({
          showSignupModal: true,
          authRequired: false,
          onClosePath: "/financing-options",
        }),
      );
    }
  }, [user]);

  useEffect(() => {
    areFeatureFlagsEnabled(["evenIntegration", "frbPromo"]).then((results) => {
      Store.dispatch(setEvenIntegrationEnabled(results["evenIntegration"]));
      Store.dispatch(
        setFrbIntegrationEnabled(
          results["frbPromo"] && results["evenIntegration"],
        ),
      );
    });
  }, []);

  const scrollRef = useRef(null);

  return (
    <div className="items-center text-center">
      <Helmet>
        <meta
          name="description"
          content="Whether you're exploring IVF, surrogacy, egg freezing, or another path to parenthood, Sunfish can offer guidance and help you explore low-interest loans."
        />
      </Helmet>
      <OurOfferingsBanner
        executeScroll={executeScroll}
        formCompleted={formCompleted}
      />
      <div ref={scrollRef}>
        <SunfishForm
          formCompleted={formCompleted}
          setFormCompletedStatus={(e) => {
            setFormCompletedStatus(e);
            executeScroll({ block: "start" });
          }}
        />
      </div>
    </div>
  );
}

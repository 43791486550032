export default function SFForm({ title, body }) {
  return (
    <div className="flex flex-col w-screen items-center justify-center bg-blue-100 py-16">
      <div className="grid place-items-center w-10/12 bg-white p-10 m-5 rounded-3xl">
        <h3 className="font-bold text-3xl text-navyblue">{title}</h3>
        <div className="w-5/12">{body}</div>
      </div>
    </div>
  );
}

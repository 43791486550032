import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Authorization from "domain/Upside/Authorization";

import { getUpsideToken, getUpsideLeadSubmission } from "../../../api";
import LeadSubmissionResponse from "domain/Upside/LeadSubmissionResponse";

const loadingState = (state) => {
  state.loading = true;
};

const failedAuthState = (state, action) => {
  const { error } = action;

  return {
    ...state,
    loading: false,
    authorization: new Authorization({ failed: true, error }),
  };
};

const failedLeadState = (state, action) => {
  const { error } = action;

  return {
    ...state,
    loading: false,
    leadResponse: new LeadSubmissionResponse({ failed: true, error }),
  };
};

export const authorize = createAsyncThunk(
  "upsideSlice/authorize",
  (payload, { getState }) => {
    const state = getState();
    const forceRefresh = payload?.forceRefresh ?? false;

    if (!state.upsideState.authorization.isValid || forceRefresh) {
      try {
        return getUpsideToken();
      } catch (error) {
        console.error("Error authorizing access to Upside", error);
        throw new Error(error);
      }
    } else {
      return state.upsideState.authorization.tokenSignature;
    }
  },
);

export const lead = createAsyncThunk(
  "upsideSlice/lead",
  async (payload, { getState }) => {
    const state = getState();
    const { authorization } = state.upsideState;
    const { lead } = payload ?? {};
    let results = { data: {} };
    let maxRetries = 2;
    let retries = 0;

    if (!authorization.isValid) {
      throw new Error("Token is either expired or not available");
    }

    if (!lead) {
      throw new Error("No lead provided");
    }

    if (!lead.validateAll()) {
      throw new Error("Validation of one or more fields failed");
    }

    do {
      retries = retries + 1;
      try {
        results = await getUpsideLeadSubmission({
          bearer: authorization.bearer,
          payload: lead.process(),
          caller: ["localhost", "stage-sunfish.web.app"].includes(
            window.location.hostname,
          )
            ? "stage"
            : "",
        });

        // Stop loop as valid response was provided
        retries = 2;
      } catch (error) {
        if (retries === maxRetries) {
          throw new Error(
            `Upside API Unresponsiveness: Max retries of ${maxRetries} has been reached.`,
            error,
          );
        }
      }
    } while (retries < 2);

    return results;
  },
);

export const upsideSlice = createSlice({
  name: "upsideSlice",
  initialState: {
    authorization: new Authorization({}),
    leadResponse: new LeadSubmissionResponse({}),
    loading: false,
  },
  extraReducers: (builder) => {
    builder.addCase(authorize.pending, loadingState);
    builder.addCase(lead.pending, loadingState);

    builder.addCase(lead.rejected, failedLeadState);
    builder.addCase(authorize.rejected, failedAuthState);

    builder.addCase(authorize.fulfilled, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        loading: false,
        authorization: new Authorization(data ?? {}),
      };
    });

    builder.addCase(lead.fulfilled, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        loading: false,
        leadResponse: new LeadSubmissionResponse(data),
      };
    });
  },
});

export default upsideSlice.reducer;

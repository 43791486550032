import React from "react";
import { Link } from "react-router-dom";
import GetInTouch from "./GetInTouch";

export default function Footer() {
  return (
    <div className="w-full flex flex-col items-center">
      <GetInTouch />
      <div className="py-16 border-t border-gray-200 w-11/12 md:w-10/12">
        <div className="grid md:grid-cols-4 gap-16">
          <div>
            <Link to="/" className="relative">
              <img
                src="/assets/logo.png"
                className="h-8 md:h-12"
                alt="sunfish-footer-logo"
              />
            </Link>
            <br />
            <div className="flex item-center mt-5">
              <a
                className="rounded-full bg-navyblue p-3 mr-3"
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/joinsunfish"
              >
                <img
                  className="h-5"
                  src="/assets/bxl_facebook.png"
                  alt="Sunfish on Facebook"
                />
              </a>
              <a
                className="rounded-full bg-navyblue p-3 mr-3"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/joinsunfish/"
              >
                <img
                  className="h-5"
                  src="/assets/akar-icons_instagram-fill.png"
                  alt="Sunfish on Instagram"
                />
              </a>
              <a
                className="rounded-full bg-navyblue p-3 mr-3"
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/joinsunfish"
              >
                <img
                  className="h-5"
                  src="/assets/akar-icons_twitter-fill.png"
                  alt="Sunfish on Twitter"
                />
              </a>
              <a
                className="rounded-full bg-navyblue p-3 mr-3"
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/joinsunfish/"
              >
                <img
                  className="h-5"
                  src="/assets/akar-icons_linkedin-fill.png"
                  alt="Sunfish on LinkedIn"
                />
              </a>
            </div>
          </div>
          <div>
            <h1 className="text-xl font-bold text-navyblue">Welcome</h1>
            <br />
            <div className="flex flex-col">
              <Link className="mb-2 hover:text-blue-700" to="/">
                Home
              </Link>
              <Link
                className="mb-2 hover:text-blue-700"
                to="/financing-options"
              >
                Our Offerings
              </Link>
              <Link
                className="mb-2 hover:text-blue-700"
                to="/educational-resources"
              >
                Educational Resources
              </Link>
              <Link className="mb-2 hover:text-blue-700" to="/lgbtqParenthood">
                LGBTQIA+ Parenthood
              </Link>
              <Link className="mb-2 hover:text-blue-700" to="/partners">
                Partners
              </Link>
              <Link className="mb-2 hover:text-blue-700" to="/mission">
                About Us
              </Link>
            </div>
          </div>
          <div>
            <h1 className="text-xl font-bold text-navyblue">Our Offerings</h1>
            <br />
            <div className="flex flex-col">
              <Link
                className="mb-2 hover:text-blue-700"
                to="/financing-options"
              >
                Financing Options
              </Link>
              <Link className="mb-2 hover:text-blue-700" to="/cost-calculator">
                Cost Calculator
              </Link>
              <Link className="mb-2 hover:text-blue-700" to="/Grants">
                Grants and Discounts
              </Link>
              <Link className="mb-2 hover:text-blue-700" to="/privacy-policy">
                Privacy Policy
              </Link>
              <Link className="mb-2 hover:text-blue-700" to="/disclosures">
                Disclosures
              </Link>
            </div>
          </div>
          <div>
            <h1 className="text-xl font-bold text-navyblue">Contact Us</h1>
            <br />
            <div className="flex flex-col">
              <a className="mb-2 " href="tel:+15107750064">
                (510) 775-0064
              </a>
              <a className="mb-2 " href="mailto:info@joinsunfish.com">
                info@joinsunfish.com
              </a>
              <p>Los Angeles, CA</p>
              <p>New York, NY</p>
            </div>
          </div>
        </div>
        <div className="mt-20 text-center flex justify-left">
          © Sunfish 2022. All rights reserved.
        </div>
      </div>
    </div>
  );
}

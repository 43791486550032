import React from "react";
import { Link } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/solid";

export default function FamilyBuilding() {
  return (
    <div className="flex items-center justify-center sm:mt-8 lg:mt-24">
      <div className="flex items-center lg:my-28 w-11/12 lg:w-9/12">
        <div className="grid gap-10 md:grid-cols-2">
          {/* grid column 1 */}
          <div className="text-center lg:text-left">
            <h1 className="text-4xl 2xl:text-5xl font-bold text-navyblue literata-font lg:mt-10 lg:mb-20">
              LGBTQIA+ Family <br /> Building
            </h1>
            <br />
            <Card
              title="What options are available for same sex"
              titleR="female couples or individual women?"
              text="Family planning for two biological women typically includes intrauterine insemination (IUI) or in-vitro fertilization (IVF). Same-sex female couples or individuals will also need a sperm donor (anonymous or known). The total cost for these procedures tends to be a few thousand dollars."
              text2="In some cases, same-sex female couples will undergo “reciprocal in-vitro fertilization (IVF)”, which allows both partners to participate in the pregnancy. One woman supplies her eggs, retrieved and fertilized by donated sperm in IVF, with the resulting embryo(s) implanted into her partner for pregnancy as a gestational carrier."
              bgColor={"bg-primary"}
            />
          </div>

          {/* grid column 2 */}
          <div>
            <Card
              title="What options are available for same sex"
              titleR="male couples or individual gay men?"
              text="Gay men wanting to have a baby can use an egg donor and a gestational surrogate. They will also need to pay for the medical procedures of embryo creation, in-vitro fertilization (IVF) to allow the surrogate to become pregnant, and an array of additional costs to cover legal fees, health insurance, travel, and logistics."
              text2="The total cost of this journey typically comes to around $200,000."
              text3="We are the only lender that works with multiple surrogacy agencies throughout the country."
              bgColor={"bg-secondary"}
            />
            <Card
              title="What options are available"
              titleR="to the Transgender community?"
              text="Cryopreservation, the process of freezing and preserving living tissue and cells, can give people the extra time they need before they are ready to begin their family. This procedure is more commonly known as egg or sperm “freezing.”"
              text2="Within the transgender community, this process offers a special value. Oftentimes, transitioning means the loss of fertility due to the amount of hormones, such as testosterone, that a female-to-male person would utilize in the transitioning process. Cryopreservation allows transgender people the freedom of living their most authentic lives while still being able to create their family biologically."
              bgColor={"bg-[#FBF9E5]"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Card({ title, titleR, text, text2, text3, bgColor }) {
  let block3 = null;
  if (text3) {
    block3 = (
      <span className="flex flex-start mt-2">
        <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
          <CheckIcon className="h-full text-white" />
        </div>
        <p>{text3}</p>
      </span>
    );
  }

  return (
    <div
      className={`text-left ml-auto mr-auto md:ml-0 mt-10 w-11/12 md:w-10/12 p-10 rounded-3xl ${bgColor}`}
    >
      <h1 className="text-3xl font-bold text-navyblue literata-font leading-10">
        {title} <span className="font-medium "> {titleR}</span>
      </h1>
      <div className="flex flex-col mt-5 text-gray-700">
        <span className="flex flex-start">
          <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
            <CheckIcon className="h-full text-white" />
          </div>
          <p className="leading-8">{text}</p>
        </span>
        <span className="flex flex-start mt-2">
          <div className="h-4 bg-blue-900 rounded mt-2 mr-3">
            <CheckIcon className="h-full text-white" />
          </div>
          <p className="leading-8">{text2}</p>
        </span>
        {block3}
      </div>
      <br />
      <div className="w-full h-16 flex items-center">
        <Link
          className="text-navyblue bg-yellow py-3 w-40 px-6 rounded-full text-center "
          to="/cost-calculator"
        >
          Cost Calculator
        </Link>
      </div>
    </div>
  );
}

import React, { useRef } from "react";
import SFInput from "../Inputs/SFInput";
import SFTextArea from "../Inputs/SFTextArea";
import { useSelector } from "react-redux";
import SFBlueButton from "../Buttons/SFBlueButton";
import Store from "../../App/Store";
import {
  setDate,
  setDescription,
  setFooterLinkText,
  setFooterLinkUrl,
  setFooterText,
  setTitle,
  setTitleLinkUrl,
  setIncludeFooter,
  setVerify,
  setTitleLinkUrlAuthRequired,
  setImageFile,
  setInvalidInput,
} from "../../App/Features/AddBlogsAndEventsSplice";

export default function AddBlogsAndEventsForm() {
  const imageFileRef = useRef();
  const addBlogsAndEventsState = useSelector(
    (state) => state.addBlogsAndEventsState
  );
  const selectedType = useSelector(
    (state) => state.addBlogsAndEventsState.selectedType
  );
  const getValue = (key) => {
    return addBlogsAndEventsState[selectedType][key] ?? "";
  };
  const verifyUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (_) {
      return url.length === 0;
    }
  };
  const verifyFooter = () => {
    if (getValue("includeFooter")) {
      return (
        getValue("footerText").includes(getValue("footerLinkText")) &&
        verifyUrl(getValue("footerLinkUrl"))
      );
    } else {
      return true;
    }
  };

  return (
    <div className="flex flex-col w-screen items-center justify-center bg-blue-100 py-16">
      <div className="grid place-items-center w-10/12 bg-white p-10 m-5 rounded-3xl">
        <h3 className="font-bold text-3xl text-navyblue">Add {selectedType}</h3>
        <div className="w-5/12">
          <SFInput
            label={"Title"}
            handleChange={(e) => Store.dispatch(setTitle(e.target.value))}
            value={getValue("title")}
            error={addBlogsAndEventsState.invalidInput && !getValue("title")}
          />
          <SFInput
            label={"Title Link Url"}
            handleChange={(e) =>
              Store.dispatch(setTitleLinkUrl(e.target.value))
            }
            value={getValue("titleLinkUrl")}
            error={
              addBlogsAndEventsState.invalidInput &&
              !verifyUrl(getValue("titleLinkUrl"))
            }
            validationText={
              !verifyUrl(getValue("titleLinkUrl"))
                ? "Please enter a correctly formatted url"
                : undefined
            }
          />
          <SFInput
            label={"Title Link Auth Required"}
            handleChange={(e) =>
              Store.dispatch(setTitleLinkUrlAuthRequired(e.target.checked))
            }
            value={getValue("titleLinkUrlAuthRequired")}
            type="checkbox"
            defaultStyle=""
            errorStyle=""
            checked={getValue("titleLinkUrlAuthRequired")}
          />
          <SFTextArea
            label={"Description"}
            handleChange={(e) => Store.dispatch(setDescription(e.target.value))}
            value={getValue("description")}
            isRequired={true}
            error={
              addBlogsAndEventsState.invalidInput && !getValue("description")
            }
          />
          <SFInput
            label={"Date"}
            handleChange={(e) => Store.dispatch(setDate(e.target.value))}
            value={getValue("date")}
            type={"date"}
            error={addBlogsAndEventsState.invalidInput && !getValue("date")}
          />
          <SFInput
            id={"imageInput"}
            label={"Image"}
            defaultStyle=""
            errorStyle=""
            type="file"
            handleChange={(_) =>
              Store.dispatch(setImageFile(imageFileRef.current.files[0]))
            }
            innerRef={imageFileRef}
            isRequired={true}
            error={
              addBlogsAndEventsState.invalidInput &&
              imageFileRef?.current?.files?.length === 0
            }
            fileName={addBlogsAndEventsState?.imageFile?.name}
          />
          <button
            onClick={() =>
              Store.dispatch(setIncludeFooter(!getValue("includeFooter")))
            }
            className="place-items-center px-4 py-2 h-12"
          >
            {getValue("includeFooter")
              ? "Exclude optional footer"
              : "Include optional footer"}
          </button>
          {getValue("includeFooter") ? (
            <div>
              <SFInput
                label={"Footer Text"}
                handleChange={(e) =>
                  Store.dispatch(setFooterText(e.target.value))
                }
                value={getValue("footerText")}
              />
              <SFInput
                label={"Footer Link Text"}
                handleChange={(e) =>
                  Store.dispatch(setFooterLinkText(e.target.value))
                }
                value={getValue("footerLinkText")}
                validationText="Please ensure link text is in footer text"
                error={
                  addBlogsAndEventsState.invalidInput &&
                  !getValue("footerText").includes(getValue("footerLinkText"))
                }
              />
              <SFInput
                label={"Footer Link Url"}
                handleChange={(e) =>
                  Store.dispatch(setFooterLinkUrl(e.target.value))
                }
                value={getValue("footerLinkUrl")}
                error={
                  addBlogsAndEventsState.invalidInput &&
                  !verifyUrl(getValue("footerLinkUrl"))
                }
                validationText={
                  !verifyUrl(getValue("footerLinkUrl"))
                    ? "Please enter a correctly formatted url"
                    : undefined
                }
              />
            </div>
          ) : (
            <></>
          )}
          <div className="grid place-items-center">
            <SFBlueButton
              title={"Verify"}
              onClick={async () => {
                if (
                  getValue("title")?.length &&
                  getValue("description")?.length &&
                  getValue("date")?.length &&
                  verifyFooter() &&
                  verifyUrl(getValue("titleLinkUrl")) &&
                  addBlogsAndEventsState.imageFile
                ) {
                  Store.dispatch(setVerify(true));
                } else {
                  Store.dispatch(setInvalidInput(true));
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import SFBanner, { SFBannerTitle } from "./SFBanner";

export default function DisclosuresBanner() {
  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle title="Disclosures" />
          <p className="mt-5 text-center">Last Updated: March 21, 2023</p>
        </div>
      }
      bgImage="md:bg-offerings bg-offeringsPhone bg-no-repeat bg-cover"
      bodyAlignment="center"
    />
  );
}

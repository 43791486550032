import { minPasswordStrength } from "../api";

export default function passwordStrengthComponent({ passwordStrength }) {
  if (passwordStrength.score >= minPasswordStrength) {
    return (
      <div className="mt-2 text-black-800">
        <p>Strong Password</p>
      </div>
    );
  } else {
    return (
      <div className="mt-2 text-red-800">
        <p>
          Weak Password
          {passwordStrength.feedback.suggestions.map((value) => (
            <p key={value}>{value}</p>
          ))}
          <p>Try a longer password.</p>
        </p>
      </div>
    );
  }
}

import React from "react";

export default function WarrantyQualifications() {
  return (
    <div className="lg:w-9/12 p-10">
      <h1 className="text-navyblue text-6xl font-semibold">
        Eligibility for Sunfish Program
      </h1>
      <br />
      <p>
        In order to be eligible for the Sunfish Program, you must (1) complete a
        required physical evaluation by one of our participating IVF clinics and
        (2) consent to share your evaluation results with Sunfish. The full
        review process takes approximately 5-7 business days but could take
        longer. Our participating IVF clinic will provide Sunfish with your
        physical evaluation report, whereupon Sunfish will assess your
        eligibility to participate in the Sunfish Program.
      </p>
      <p className="my-3">
        While Sunfish reserves the right to update or change the eligibility
        requirements of the Sunfish Program, enrollment in the program will
        require at least the following:
        <ul className="list-disc list-inside">
          <li>
            With respect to the recipient:
            <ul className="list-disc list-inside pl-5 mt-2">
              <li>
                The embryo recipient must be physically and mentally healthy
                enough to undertake a pregnancy
              </li>
              <li>
                The embryo-recipient must have a uterus as assessed by a
                transvaginal ultrasound and a normal hysterosalpingogram,
                sonohysterogram or hysteroscopy performed in the preceding 12
                months
              </li>
              <li>
                If the recipient of embryos has submucosal fibroids, they must
                be removed; intramural fibroids equal to or greater than 3 cm
                must also be removed
              </li>
              <li>
                The recipient of the embryos must not have evidence of
                Hydrosalpinx
              </li>
              <li>
                The recipient must be 42 years old or less for the embryos; if
                41 or 42, additional counseling may be required
              </li>
            </ul>
          </li>
          <li>
            With respect to the egg provider:
            <ul className="list-disc list-inside pl-5 mt-2">
              <li>
                The sperm provider must have had a semen analysis within one
                year
              </li>
              <li>
                The sperm provider must be capable of producing sperm in his
                ejaculate or be willing to go through a procedure to obtain
                sperm or use a sperm donor
              </li>
              <li>
                If the sperm provider requires a sperm extraction procedure like
                TESE/MESA, then donor sperm must be arranged as a back-up option
                if sperm cannot be obtained through these procedures.
              </li>
            </ul>
          </li>
          <li>
            Other conditions of eligibility for the Sunfish Program:
            <ul className="list-disc list-inside pl-5 mt-2">
              <li>
                The medical clinic must be able to use intracytoplasmic sperm
                injection (ICSI) at their discretion for optimum fertilization.
              </li>
              <li>
                In order to qualify for transfer coverage, at least one PGT-A
                tested, “high quality euploid blastocyst” (commonly known as an
                'embryo') as defined as 3BB euploid or better, or an equivalent
                grading by your lab, must be created. If no quality euploid
                blastocysts are created, Member(s) shall be deemed as 'failing'
                the process and shall be eligible for a refund.
              </li>
              <li>
                The Member(s), in consultation with the physicians and
                embryologist, will determine the number of embryos/blastocysts
                placed per each transfer. While you should consult your medical
                provider, the American Society of Reproductive Medicine suggests
                one (1) embryo transfer at a time.
              </li>
              <li>
                Embryo scope and PGT-A testing and results to be shared with
                Sunfish
              </li>
              <li>
                The Member(s) must agree to have all quality embryos transferred
                before the cycle is considered complete per the plan, up to and
                not exceeding [three] transfers.
              </li>
              <li>
                Using the same combination of eggs, sperm and uterus, the
                combined must not have failed any previous embryo transfers in
                attempts to conceive. Failure to accurately disclose this
                information can result in disqualification from the Program and
                will be considered a breach of this Agreement.
              </li>
              <li>
                A full cycle is defined as a complete cycle of follicular
                stimulation, ultrasound and blood monitoring, transvaginal egg
                retrieval, PGT-A testing, fresh embryo transfer and additional
                frozen embryo transfers [up to three] produced from the original
                egg retrieval. Depending on the clinic, testing may include an
                embryo scope as well.
              </li>
              <li>
                Cryopreservation (storage) of additional embryos for one year
                may be included and will depend on your provider's terms and
                conditions.
              </li>
              <li>The Program Fee must have been paid in full.</li>
              <li>
                If Member(s) create more than [three] high quality euploid
                blastocysts, as defined above, you may continue transfer
                attempts a-la-cart outside of the Sunfish Program.
              </li>
            </ul>
          </li>
        </ul>
      </p>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function GetOptions() {
  return (
    <div className="flex flex-col items-center w-full text-navyblue">
      <div className="w-9/12">
        <h1 className="md:w-9/12 mx-auto mr-auto mt-10 lg:mt-24 font-semibold literata-font lg:leading-tight text-center text-3xl md:text-5xl">
          We believe everyone who wants to build a family{" "}
          <span className="font-normal literata-font italic">
            should be able to afford it.
          </span>
        </h1>
        <div className="h-1/2 w-full flex lg:flex-row flex-col-reverse bg-primary rounded-3xl mt-10 lg:mt-32 lg:text-left text-center">
          <div className="sm:grid lg:w-1/2 grid py-16 px-10">
            <div>
              <h1 className="text-5xl font-semibold literata-font">1 in 6</h1>
              <p className="sm:w-full lg:w-10/12 my-5">
                <span className="font-semibold">Aspiring Parents</span> need
                support to build their families. Yet the cost to do so varies
                widly based on unique factors.
              </p>
            </div>
            <div className="py-10 lg:py-16">
              <h1 className="text-5xl font-semibold literata-font">70,000</h1>
              <p className="sm:w-full lg:w-10/12 my-5">
                Our model is based on input from experts who collectively had{" "}
                <span className="font-semibold">
                  over 70,000 conversations with intended parents over 25 years.
                </span>
              </p>
            </div>
            <div>
              <h1 className="text-5xl font-semibold literata-font">5 Types </h1>
              <p className="sm:w-full lg:w-10/12 my-5">
                <span className="font-semibold">
                  We support 5 types of journeys:{" "}
                </span>{" "}
                <br />
                Egg freezing, embryo preservation, IVF, egg donor journeys,
                surrogacy &mdash; and any combination!
              </p>
            </div>
          </div>
          <div className="ml-auto">
            <img
              src="/assets/getOptionsBg.png"
              className="h-full w-full place-items-end ml-auto"
              alt=""
            />
          </div>
        </div>
        <div className="mb-10">
          <h1 className="md:w-9/12 ml-auto  mr-auto mt-10 md:mt-20 text-center text-3xl md:text-5xl font-semibold literata-font lg:leading-tight">
            We can help you build a custom plan{" "}
            <span className="font-normal italics literata-font">
              and recommend trusted partners
            </span>
          </h1>
          <h1 className="md:w-7/12 ml-auto mr-auto mt-5 text-center text-gray-600 text-2xl sm:text-base lg:text-3xl font-medium">
            to help you achieve your goals,
            <br /> whatever they may be.
          </h1>
          <div className="flex justify-center">
            <Link
              className="text-navyblue bg-yellow py-4 px-8 mt-5 rounded-full literata-font font-semibold"
              to="/financing-options"
            >
              Get options
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

import BaseUpside from "./BaseUpside";

export default class MorgageInformation extends BaseUpside {
  getMyClass() {
    return MorgageInformation;
  }

  static PROPERTY_STATUS_RENT = "rent";
  static PROPERTY_STATUS_OWNOUTRIGHT = "own_outright";
  static PROPERTY_STATUS_OWNWITHMORTGAGE = "own_with_mortgage";

  constructor(props) {
    super(props);

    this.propertyStatus = props.propertyStatus ?? "";
  }

  generate = () => {
    switch (this.propertyStatus.toLowerCase()) {
      case "own":
        this.propertyStatus = MorgageInformation.PROPERTY_STATUS_OWNOUTRIGHT;
        break;
      case "rent":
        this.propertyStatus = MorgageInformation.PROPERTY_STATUS_RENT;
        break;
      case "own with mortgage":
        this.propertyStatus =
          MorgageInformation.PROPERTY_STATUS_OWNWITHMORTGAGE;
        break;
      default:
        break;
    }

    return this.process();
  };
}

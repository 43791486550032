import BaseUpside from "./BaseUpside";

export default class EducationInformation extends BaseUpside {
  getMyClass() {
    return EducationInformation;
  }

  static EDUCATION_LEVEL_HIGHSCHOOL = "high_school";
  static EDUCATION_LEVEL_ASSOCIATE = "associate";
  static EDUCATION_LEVEL_BACHELORS = "bachelors";
  static EDUCATION_LEVEL_MASTERS = "masters";
  static EDUCATION_LEVEL_DOCTORATE = "doctorate";
  static EDUCATION_LEVEL_OTHERGRADDEGREE = "other_grad_degree";
  static EDUCATION_LEVEL_CERTIFICATE = "certificate";
  static EDUCATION_LEVEL_DIDNOTGRADUATE = "did_not_graduate";
  static EDUCATION_LEVEL_STILLENROLLED = "still_enrolled";
  static EDUCATION_LEVEL_OTHER = "other";

  constructor(props) {
    super(props);

    this.educationLevel = props.educationLevel ?? "";
  }

  generate = () => {
    switch (this.educationLevel.toLowerCase()) {
      case "high school":
        this.educationLevel = EducationInformation.EDUCATION_LEVEL_HIGHSCHOOL;
        break;
      case "bachelors":
        this.educationLevel = EducationInformation.EDUCATION_LEVEL_BACHELORS;
        break;
      case "associate":
        this.educationLevel = EducationInformation.EDUCATION_LEVEL_ASSOCIATE;
        break;
      case "masters":
        this.educationLevel = EducationInformation.EDUCATION_LEVEL_MASTERS;
        break;
      case "doctorate":
        this.educationLevel = EducationInformation.EDUCATION_LEVEL_DOCTORATE;
        break;
      case "certificate":
        this.educationLevel = EducationInformation.EDUCATION_LEVEL_CERTIFICATE;
        break;
      case "other":
        this.educationLevel = EducationInformation.EDUCATION_LEVEL_OTHER;
        break;
      default:
        break;
    }

    return this.process();
  };
}

import BaseUpside from "./BaseUpside";

export default class CreditInformation extends BaseUpside {
  getMyClass() {
    return CreditInformation;
  }

  static CREDIT_RATING_EXCELLENT = "excellent";
  static CREDIT_RATING_GOOD = "good";
  static CREDIT_RATING_FAIR = "fair";
  static CREDIT_RATING_POOR = "poor";
  static CREDIT_RATING_LIMITED = "limited";
  static CREDIT_RATING_UNKNOWN = "unknown";

  constructor(props) {
    super(props);

    this.creditRating = props.creditRating ?? "";
    // creditScore is a field in the API. However, we do not use it and will comment out
    // this.creditScore = props.creditScore ?? 0;
  }

  generate = () => {
    switch (this.creditRating.toString().toLowerCase()) {
      case "under 620":
        this.creditRating = CreditInformation.CREDIT_RATING_POOR;
        break;
      case "620-659":
        this.creditRating = CreditInformation.CREDIT_RATING_FAIR;
        break;
      case "660-679":
      case "680-719":
        this.creditRating = CreditInformation.CREDIT_RATING_GOOD;
        break;
      case "720+":
        this.creditRating = CreditInformation.CREDIT_RATING_EXCELLENT;
        break;
      default:
        this.creditRating = CreditInformation.CREDIT_RATING_UNKNOWN;
        break;
    }

    return this.process();
  };
}

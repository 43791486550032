import { useEffect, useState } from "react";
import { Combobox } from "@headlessui/react";

const Autocomplete = ({ inputProps, items = [], onSelect, onBlur }) => {
  const [selected, setSelected] = useState(inputProps.value);
  const [filtered, setFiltered] = useState(items ?? []);
  const [triggered, setTriggered] = useState(false);
  const [query, setQuery] = useState("");
  const [queryOther, setQueryOther] = useState("");
  const [foundMatch, setFoundMatch] = useState(false);

  const handleChange = (event) => {
    const { value } = event.target;
    setQuery(value);

    if (items.length > 0) {
      setFiltered(
        items.filter((item) =>
          item.toLowerCase().includes(value.trim().toLowerCase()),
        ),
      );
    }
  };

  const handleSelect = (val) => {
    setSelected(val);
    if (!triggered) {
      setTriggered(true);
    }
  };

  const handleUpdate = () => {
    onBlur(inputProps.index);
  };

  useEffect(() => {
    if (selected.length > 0) {
      onSelect({ target: { value: selected } }, inputProps.index);
    }
  }, [selected]);

  useEffect(() => {
    if (triggered && selected.length > 0 && selected === inputProps.value) {
      handleUpdate();
    }
  }, [triggered, selected, inputProps.value]);

  useEffect(() => {
    if (
      filtered.length === 1 &&
      filtered[0].toLowerCase() === query.toLowerCase()
    ) {
      setFoundMatch(true);
      setQueryOther("");
    } else {
      setFoundMatch(false);
      setQueryOther(query);
    }
  }, [filtered]);

  return (
    <Combobox
      as="div"
      value={selected}
      onChange={handleSelect}
      className="relative flex flex-col py-2 sm:px-1 md:px-4"
    >
      <p className="text-left mb-1 font-semibold">{inputProps.label}</p>
      <Combobox.Button as="div">
        {({ open }) => (
          <Combobox.Input
            onChange={handleChange}
            onClick={(e) => {
              if (open) e.stopPropagation();
            }}
            className="rounded-md p-3 border-2 h-12 border-gray-200 w-full"
          />
        )}
      </Combobox.Button>
      <Combobox.Options className="absolute w-full shadow-md bg-white top-[90%] z-50 overflow-y-scroll max-h-[150px] rounded-md p-3 border-2 border-gray-200 text-left">
        {!foundMatch ? (
          <>
            <Combobox.Option
              key={`${inputProps.id}-none`}
              value="None"
              className="py-1 cursor-pointer"
            >
              None
            </Combobox.Option>
            <Combobox.Option
              key={`${inputProps.id}-other`}
              value={queryOther}
              className="py-1 cursor-pointer"
            >
              Other{queryOther.length > 0 ? ` - ${queryOther}` : ""}
            </Combobox.Option>
          </>
        ) : null}
        {filtered.map((item) => (
          <Combobox.Option
            key={`${inputProps.id}-${item}`}
            value={item}
            className="py-1 cursor-pointer"
          >
            {item}
          </Combobox.Option>
        ))}
      </Combobox.Options>
    </Combobox>
  );
};

export default Autocomplete;

export default function CloseButton({ onClick }) {
  return (
    <img
      src="/assets/cross.webp"
      onClick={onClick}
      className="h-5 absolute right-0 mr-10 cursor-pointer"
      alt="close-menu"
    />
  );
}

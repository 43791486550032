export default function AffiliatePartnerComponent({
  affiliate,
  update = false,
}) {
  const _url = `${window.location.protocol}${window.location.host}${affiliate?.urlQuery}`;

  return (
    <div className="flex px-4 flex-col">
      <div className="flex flex-row">
        <label className="text-left font-semibold">{`Affiliate Name: ${affiliate?.partnerName}`}</label>
      </div>
      {update ? (
        <></>
      ) : (
        <div className="flex flex-row">
          <label className="text-left font-semibold">{`Affiliate Partner Message: ${affiliate?.partnerMessage}`}</label>
        </div>
      )}
      <div className="flex flex-row">
        <label className="text-left font-semibold">{`Url: ${_url}`}</label>
      </div>
    </div>
  );
}

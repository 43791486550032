export enum ProviderType {
  any = "Any",
  surrogacyAgency = "Surrogacy Agency",
  donorAgency = "Donor Agency",
  ivfClinic = "IVF Clinic",
  lawFirm = "Law Firm",
  other = "Other",
}

export class ProviderTypeFilter {
  data: ProviderType;

  constructor(data: ProviderType) {
    this.data = data;
  }

  isGrantValid(grant): Boolean {
    var providerTypeString = grant.providerType.toLowerCase();
    var anyGrant: Boolean =
      providerTypeString === "Not Specified" || providerTypeString === "any";
    if (this.data === ProviderType.any) {
      return true;
    } else {
      var value: Boolean = providerTypeString.includes(this.data.toLowerCase());
      return anyGrant || value;
    }
  }
}

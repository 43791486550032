import React from "react";
import SFBanner, { SFBannerTitle } from "../Banners/SFBanner";

export default function EvenFundingUploadBanner() {
  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle title="Upload Engine by MoneyLion CSV" />{" "}
        </div>
      }
      bgImage="md:bg-sfBackground bg-sfBackgroundSm bg-no-repeat bg-cover"
      bodyAlignment="center"
    />
  );
}

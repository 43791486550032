import React from "react";
import SFYellowLink from "../Links/SFYellowLink";

export default function ApplyNowToBeEvaluated() {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="p-3 lg:p-20 py-12 lg:py-20 lg:bg-ApplyNowToBeEvaluated bg-ApplyNowToBeEvaluatedSm bg-cover bg-center rounded-3xl my-20 w-10/12 md:w-8/12">
        <div className="flex flex-col items-center">
          <h1 className="text-center text-4xl font-semibold lg:leading-tight lg:text-6xl text-navyblue literata-font">
            <span className="literata-font font-normal italic">Apply now </span>{" "}
            to be evaluated and learn more
          </h1>
          <div className="w-full lg:w-auto p-3 mt-5">
            <div className="flex items-center justify-between w-full bg-white rounded-full"></div>
          </div>
          <SFYellowLink title="Contact us" to="/partners-contact-us" />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import EducationalResourcesBanner from "../Components/Banners/EducationalResourcesBanner";
import DiscountsProvidersCards from "../Components/ParenthoodComponents/DiscountsProvidersCards";
import AfterBanner from "../Components/ParenthoodComponents/AfterBanner";

export default function EducationalResources() {
  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <meta
          name="description"
          content="Learn about IVF, Egg Freezing, Surrogacy, and how Sunfish can provide personalized financial support on your journey."
        />
      </Helmet>
      <EducationalResourcesBanner />
      <AfterBanner />
      <DiscountsProvidersCards />
    </div>
  );
}

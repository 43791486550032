export default function Disclaimers() {
  return (
    <div className="text-gray-500 pb-10 mx-auto w-10/12 md:w-8/12 italic">
      <p>
        <sup>1</sup> As part of the <b>Sunfish IVF Program</b>, Sunfish offers a
        predictive financial model (the 'Predictive Financial Model') through
        its proprietary software that calculates the chance of success for each
        family to have a baby, based on their unique factors. The Predictive
        Financial Model helps families to accurately plan for and account for
        the financial burden associated with assisted reproduction. If our
        Predictive Financial Model is inaccurate and fails to provide the
        information necessary for participants to accurately financially plan
        for and account for the financial burden associated with your assisted
        reproduction, the Predictive Model Warranty (the <b>“Warranty”</b>)
        enables participants to get their money back - up to 90%. The Warranty
        is a warranty on the accuracy of our Predictive Financial Model and is
        not an insurance policy. The
        <b> Sunfish IVF Program</b> concludes when any one of the below criteria
        is met: a) You achieve a pregnancy, as defined as graduating from your
        IVF clinic care to your OBGYN (typically around the 10 week mark), b)
        when 2 embryos created during program egg retrieval have been exhausted
        (regardless of gender), or c) you fail to create at least 2 embryos of
        grade 3BB or higher.{" "}
        <b>For full terms and details, please contact us.</b>
      </p>
      <p>
        <sup>2</sup> We will calculate your unique refund rate based on inputs
        specific to your household. For more details, please contact us.
      </p>
      <p>
        <sup>3</sup> ALL participants in the Sunfish IVF Program must complete a
        required physical evaluation by an eligible IVF Clinic, and must consent
        to share their records with Sunfish prior to a warranty enrollment. The
        full review process takes approximately 5-7 business days. Once
        eligibility for participation in the Sunfish IVF Program is determined
        by our partner medical clinic, they will notify Sunfish via email. In
        order for the participant to qualify for the Sunfish IVF Program, the
        Recipient, Egg Provider and Sperm Provider must satisfy certain
        criteria.
      </p>
      <p>
        <sup>4</sup> Society for Assisted Reproductive Technology as calculated{" "}
        <a
          className="underline hover:text-navyblue"
          href="https://w3.abdn.ac.uk/clsm/SARTIVF/tool/ivf1"
        >
          here
        </a>
        . It is possible that outputs may change and may not reflect this data
        from March 2023.
      </p>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import SFInput from "../Inputs/SFInput";
import { useSelector } from "react-redux";
import SFBlueButton from "../Buttons/SFBlueButton";
import Store from "../../App/Store";
import {
  setFooterLinkText,
  setFooterLinkUrl,
  setFooterText,
  setTitleLinkUrl,
  setUpdateEventUrl,
  setTitleLinkUrlAuthRequired,
  setComplete,
  setDescription,
  setDate,
  setTitle,
} from "../../App/Features/AddBlogsAndEventsSplice";
import { getEvents, updateEvent } from "../../api";
import AddBlogsAndEventsComplete from "./AddBlogsAndEventsComplete";
import SFTextArea from "../Inputs/SFTextArea";

export default function AddEventUrlForm() {
  const [selectedEvent, setSetSelectedEvent] = useState(undefined);
  const [events, setEvents] = useState(undefined);
  const addBlogsAndEventsState = useSelector(
    (state) => state.addBlogsAndEventsState
  );

  useEffect(() => {
    getEvents().then((value) => {
      setEvents(value);
    });
  }, []);

  return addBlogsAndEventsState?.complete ? (
    <div className="grid place-items-center">
      <h1 className="font-bold text-3xl text-navyblue py-8">Upload Complete</h1>
      <button
        onClick={() => {
          Store.dispatch(setComplete(false));
          getEvents().then((value) => {
            setEvents(value);
          });
        }}
      >
        Update more
      </button>
    </div>
  ) : (
    <div className="flex flex-col w-screen items-center justify-center bg-blue-100 py-16">
      <div className="grid place-items-center w-10/12 bg-white p-10 m-5 rounded-3xl">
        <h3 className="font-bold text-3xl text-navyblue">Update Event</h3>
        <div className="w-5/12">
          <div className="px-4">
            <p className="text-left font-semibold">Event Title</p>
            <select
              className={`p-2 w-full border-2 rounded py-1 h-12 ${
                selectedEvent ? "text-black" : "text-slate-400"
              } ${!selectedEvent ? "border-red-200" : "border-gray-200"}`}
              onChange={(e) => setSetSelectedEvent(events[e.target.value])}
            >
              <option value="" disabled selected className="invisible">
                Select an event title
              </option>
              {events?.map((event, index) => (
                <option value={index}>{event.title}</option>
              ))}
            </select>
          </div>
          <SFInput
            label={"Title"}
            handleChange={(e) =>
              setSetSelectedEvent({ ...selectedEvent, title: e.target.value })
            }
            value={selectedEvent?.title}
            error={addBlogsAndEventsState.invalidInput && !selectedEvent?.title}
          />
          <SFInput
            label={"Title Link Url"}
            handleChange={(e) =>
              setSetSelectedEvent({
                ...selectedEvent,
                titleLinkUrl: e.target.value,
              })
            }
            value={selectedEvent?.titleLinkUrl}
          />
          <SFInput
            label={"Title Link Auth Required"}
            handleChange={(e) =>
              setSetSelectedEvent({
                ...selectedEvent,
                titleLinkUrlAuthRequired: e.target.checked,
              })
            }
            value={selectedEvent?.titleLinkUrlAuthRequired}
            type="checkbox"
            defaultStyle=""
            errorStyle=""
            checked={selectedEvent?.titleLinkUrlAuthRequired}
          />
          <SFTextArea
            label={"Description"}
            handleChange={(e) =>
              setSetSelectedEvent({
                ...selectedEvent,
                description: e.target.value,
              })
            }
            value={selectedEvent?.description}
            isRequired={true}
            error={
              addBlogsAndEventsState.invalidInput && !selectedEvent?.description
            }
          />
          <SFInput
            label={"Date"}
            handleChange={(e) =>
              setSetSelectedEvent({
                ...selectedEvent,
                date: e.target.value,
              })
            }
            value={selectedEvent?.date}
            type={"date"}
            error={addBlogsAndEventsState.invalidInput && !selectedEvent?.date}
          />
          <div>
            <SFInput
              label={"Footer Text"}
              handleChange={(e) => {
                setSetSelectedEvent({
                  ...selectedEvent,
                  footerText: e.target.value,
                });
                if (selectedEvent?.footerText === "") {
                  setSetSelectedEvent({
                    ...selectedEvent,
                    footerText: e.target.value,
                    footerLinkText: selectedEvent?.footerText,
                    footerLinkUrl: selectedEvent?.footerText,
                    includeFooter: false,
                  });
                }
              }}
              value={selectedEvent?.footerText}
            />
            <SFInput
              label={"Footer Link Text"}
              handleChange={(e) =>
                setSetSelectedEvent({
                  ...selectedEvent,
                  footerLinkText: e.target.value,
                })
              }
              value={selectedEvent?.footerLinkText}
              validationText="Please ensure link text is in footer text"
              error={
                addBlogsAndEventsState.invalidInput &&
                !selectedEvent?.footerText.includes(
                  selectedEvent?.footerLinkText
                )
              }
            />
            <SFInput
              label={"Footer Link Url"}
              handleChange={(e) =>
                setSetSelectedEvent({
                  ...selectedEvent,
                  footerLinkUrl: e.target.value,
                })
              }
              value={selectedEvent?.footerLinkUrl}
            />
          </div>
          <div className="grid place-items-center">
            <SFBlueButton
              title={"Update"}
              onClick={async () => {
                updateEvent(selectedEvent);
                Store.dispatch(setComplete(true));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

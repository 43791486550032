import { Link } from "react-router-dom";

export default function SFYellowLink({ title, to }) {
  return (
    <Link
      className="text-navyblue w-full lg:w-60 literata-font text-sm font-semibold bg-yellow py-4 px-8 rounded-full text-center"
      to={to}
    >
      {title}
    </Link>
  );
}

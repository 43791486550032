export default function SFFormHeader({
  title,
  subTitle,
  isBackButtonEnabled,
  onClickBack,
}) {
  return (
    <div className="text-center">
      {isBackButtonEnabled ? (
        <button className="flex items-left">
          <div
            className="flex flex-row items-left place-items-center"
            onClick={() => onClickBack()}
          >
            <img
              src="/assets/akar-icons_arrow-left-black.png"
              className="h-6"
              alt=""
            />
            <p className="text-left px-1 h-6">BACK</p>
          </div>
        </button>
      ) : (
        <></>
      )}
      <h1 className="text-blue text-3xl lg:text-5xl literata-font italic text-navyblue">
        {title}
      </h1>
      <h1 className="mt-4 text-navyblue text-lg font-bold">{subTitle}</h1>
    </div>
  );
}

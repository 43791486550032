import { createSlice } from "@reduxjs/toolkit";

export const blogsAndEventsTypes = {
  blog: { title: "Blog", acl: "blogs" },
  event: { title: "Event", acl: "events" },
  updateEventUrl: { title: "Update Events", acl: "events" },
};

export const addBlogsAndEvents = createSlice({
  name: "addBlogsAndEvents",
  initialState: {
    selectedType: blogsAndEventsTypes[0],
    blog: { hide: false },
    event: { hide: false },
    updateEventUrl: {},
    imageFile: undefined,
    verify: false,
    invalidInput: false,
    complete: false,
  },
  reducers: {
    setSelectedType: (state, action) => {
      state.verify = false;
      state.invalidInput = false;
      state.complete = false;
      state.selectedType = action.payload;
      state.imageFile = undefined;
    },
    setTitle: (state, action) => {
      state[state.selectedType].title = action.payload;
      state.invalidInput = false;
    },
    setTitleLinkUrl: (state, action) => {
      state[state.selectedType].titleLinkUrl = action.payload;
      state.invalidInput = false;
    },
    setTitleLinkUrlAuthRequired: (state, action) => {
      state[state.selectedType].titleLinkUrlAuthRequired = action.payload;
    },
    setDescription: (state, action) => {
      state[state.selectedType].description = action.payload;
      state.invalidInput = false;
    },
    setDate: (state, action) => {
      state[state.selectedType].date = action.payload;
      state.invalidInput = false;
    },
    setImageUrl: (state, action) => {
      state[state.selectedType].imageUrl = action.payload;
    },
    setIncludeFooter: (state, action) => {
      state[state.selectedType].includeFooter = action.payload;
    },
    setFooterText: (state, action) => {
      state[state.selectedType].footerText = action.payload;
    },
    setFooterLinkText: (state, action) => {
      state[state.selectedType].footerLinkText = action.payload;
    },
    setFooterLinkUrl: (state, action) => {
      state[state.selectedType].footerLinkUrl = action.payload;
    },
    setImageFile: (state, action) => {
      state.imageFile = action.payload;
      state.invalidInput = false;
    },
    setVerify: (state, action) => {
      state.verify = action.payload;
    },
    setInvalidInput: (state, action) => {
      state.invalidInput = action.payload;
    },
    setComplete: (state, action) => {
      state.blog = { hide: false };
      state.event = { hide: false };
      state.updateEventUrl = {};
      state.complete = action.payload;
    },
    setUpdateEventUrl: (state, action) => {
      state.updateEventUrl = action.payload;
    },
  },
});

export const {
  setSelectedType,
  setTitle,
  setTitleLinkUrl,
  setDescription,
  setDate,
  setImageUrl,
  setFooterText,
  setFooterLinkText,
  setFooterLinkUrl,
  setIncludeFooter,
  setVerify,
  setTitleLinkUrlAuthRequired,
  setImageFile,
  setInvalidInput,
  setComplete,
  setUpdateEventUrl,
} = addBlogsAndEvents.actions;

export default addBlogsAndEvents.reducer;

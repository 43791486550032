export default function SunfishBenefitsCard({ number, title }) {
  return (
    <div className="text-center">
      <p className="literata-font mx-auto bg-darkRose rounded-2xl text-2xl font-bold h-16 w-16 flex items-center justify-center text-navyblue">
        {number}
      </p>
      <h1 className="lexend-font font-semibold text-gray-700 p-1 mt-3 lg:text-2xl sm:text-md">
        {title}
      </h1>
    </div>
  );
}

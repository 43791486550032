import React, { useEffect } from "react";
import Banner from "../Components/Banners/BannerMain";
import GetOptions from "../Components/GetOptions";
import HowWeHelp from "../Components/HowWeHelp";
import Offerings from "../Components/Offerings";
import Parenthood from "../Components/Parenthood";
import Testmonail from "../Components/Testmonail";
import { sunfishSignOut } from "../api";
import AsSeenIn from "../Components/AsSeenIn";

export default function Home({ logOut = false }) {
  useEffect(() => {
    if (logOut) {
      sunfishSignOut();
    }
  }, [logOut]);

  return (
    <div className="flex flex-col items-center">
      <Banner />
      <GetOptions />
      <Offerings />
      <HowWeHelp />
      <Parenthood />
      <AsSeenIn />
      <Testmonail />
    </div>
  );
}

import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

export default function BetterUnderstand() {
  return (
    <div className="flex flex-col w-full items-center text-center">
      <div className="w-11/12 md:w-7/12 mt-8 text-left grid gap-10 lg:grid-cols-2 sm:grid-cols-1">
        {/* grid column 1 */}
        <div className="text-left lg:text-left">
          <h1 className="text-6xl">
            <b>Better understand</b>
            <br /> my costs
          </h1>
          <div className="p-10 mt-16 bg-secondary rounded-3xl">
            <div className="h-32 w-32 rounded-xl mb-4 relative">
              <img
                src="/assets/freezing.svg"
                className="mb-10"
                aria-label="blue snowflake on yellow background"
                width="120"
                height="120"
              />
            </div>
            <h1 className="text-2xl font-bold text-navyblue">
              Egg or Embryo Freezing
            </h1>
            <div className="flex flex-col mt-2">
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Storage:</b> We assume you'll budget for 5 years of
                  storage. Storage costs range from $500 to $1,000 per year,
                  according to an industry review conducted July 2022, with the
                  first year often free. We assume a midpoint of $750/year.
                </p>
              </span>
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Medication costs:</b> Costs will vary from person to
                  person, the above is intended to represent an average cost.
                </p>
              </span>
            </div>
          </div>
          <div className="p-10 mt-7 bg-gray-100 rounded-3xl">
            <div className="h-32 w-32 rounded-xl mb-4 relative">
              <img
                src="/assets/surrogacy.svg"
                className="mb-10"
                aria-label="woman with baby icon"
                width="120"
                height="120"
              />
            </div>
            <h1 className="text-2xl font-bold text-navyblue">Surrogacy</h1>
            <div className="flex flex-col mt-2">
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Gestational surrogate fees:</b> Costs may vary based on the
                  experience and track record of the surrogate and their
                  willingness to carry twins.
                </p>
              </span>
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Agency fee:</b> Sunfish has seen prices tend to rise for
                  more "full service" agencies.
                </p>
              </span>
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Legal fees:</b> Prices rise with more detail, nuance,
                  negotiation in contract.
                </p>
              </span>
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Psychological fees:</b> Prices vary by provider and number
                  of people to be evaluated.
                </p>
              </span>
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Surrogate Health insurance:</b> Varies by their insurer,
                  policy, number of embryos transferred.
                </p>
              </span>
            </div>
          </div>
        </div>

        {/* grid column 2 */}
        <div>
          <div className="p-10 mt-7 bg-gray-100 rounded-3xl">
            <div className="h-32 w-32 rounded-xl mb-4 relative">
              <img
                src="/assets/egg_vs_embryo.svg"
                className="mb-10"
                aria-label="egg vs. embryo"
                width="120"
                height="120"
              />
            </div>
            <h1 className="text-2xl font-bold text-navyblue">Egg vs Embryo</h1>
            <div className="flex flex-col mt-2">
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Egg retrieval:</b> Costs for egg retrieval are often quoted
                  on a per cycle basis. Women may elect to do 2 or more egg
                  freezing cycles, since many IVF patients are not successful on
                  their first cycle and opt to continue treatment.
                </p>
              </span>
            </div>
          </div>
          <div className="p-10 mt-7 bg-secondary rounded-3xl">
            <div className="h-32 w-32 rounded-xl mb-4 relative">
              <img
                src="/assets/embryo_testing.svg"
                className="mb-10"
                aria-label="eggs in petri dish"
                width="120"
                height="120"
              />
            </div>
            <h1 className="text-2xl font-bold text-navyblue">Embryo Testing</h1>
            <div className="flex flex-col mt-2">
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>PGT-A (Genetic Testing):</b> Sunfish has seen some clinics
                  include the cost of the testing in the IVF offering.
                </p>
              </span>
            </div>
          </div>
          <div className="p-10 mt-7 bg-secondary rounded-3xl">
            <div className="h-32 w-32 rounded-xl mb-4 relative">
              <img
                src="/assets/donor_egg_vs_my_own.svg"
                className="mb-10"
                aria-label="two hands around an egg"
                width="120"
                height="120"
              />
            </div>
            <h1 className="text-2xl font-bold text-navyblue">
              Donor egg vs "my own"
            </h1>
            <div className="flex flex-col mt-2">
              <span className="flex flex-start mt-2">
                <div className="h-4 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-sm">
                  <b>Donor egg:</b> Assumes fresh egg donor.
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

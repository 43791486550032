import React, { useState, useEffect, useRef } from "react";
import Store from "../App/Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginModalState } from "../App/Features/UserSplice";
import SFBanner, { SFBannerTitle } from "../Components/Banners/SFBanner";
import {
  auditAuthDatabase,
  adminBackfillSqlDb,
  adminBackfillMarketplaceStatisticsToHubspot,
} from "../api";

export default function AdminPage() {
  const [disableAudit, setDisableAudit] = useState(false);
  const [usersFailingAudit, setUsersFailingAudit] = useState([]);

  const [disableBackfill, setDisableBackfill] = useState(false);
  const [backfillMsg, setBackfillMsg] = useState(null);

  const [
    disableMarketplaceBackfillSubmit,
    setDisableMarketplaceBackfillSubmit,
  ] = useState(false);
  const [marketplaceBackfillMessage, setMarketplaceBackfillMessage] =
    useState(null);
  const sunfishIdsCsv = useRef(null);

  const user = useSelector((state) => state.userState.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      Store.dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: true,
        })
      );
    } else if (!user?.acl?.users) {
      navigate("/");
    }
  }, [user, navigate]);

  function onAudit(event) {
    event.preventDefault();

    setDisableAudit(true);
    auditAuthDatabase()
      .then((response) => {
        let auditedUsers = response.data;
        auditedUsers.sort((u1, u2) => {
          const user1CreatedAt = new Date(u1.creationDate);
          const user2CreatedAt = new Date(u2.creationDate);
          return user1CreatedAt - user2CreatedAt;
        });

        setUsersFailingAudit(auditedUsers);
        setDisableAudit(false);
      })
      .catch((error) => {
        console.log(error);
        setDisableAudit(false);
      });
  }

  // This sends the request to backfill the SQL database.
  function onBackfillSqlDb(event) {
    event.preventDefault();

    setDisableBackfill(true);
    setBackfillMsg(null);

    adminBackfillSqlDb()
      .then(() => {
        setDisableBackfill(false);
        setBackfillMsg("Backfill complete.");
      })
      .catch((error) => {
        setDisableBackfill(false);
        setBackfillMsg(JSON.stringify(error));
      });
  }

  let backfillMarketplaceStatsMessageBox = null;
  if (marketplaceBackfillMessage) {
    backfillMarketplaceStatsMessageBox = (
      <div>{marketplaceBackfillMessage}</div>
    );
  }

  function onUploadSunfishIdsCsv(event) {
    event.preventDefault();
    setDisableMarketplaceBackfillSubmit(true);

    adminBackfillMarketplaceStatisticsToHubspot(sunfishIdsCsv.current.files)
      .then(() => {
        setMarketplaceBackfillMessage("Upload completed successfully.");
        setDisableMarketplaceBackfillSubmit(false);
      })
      .catch((error) => {
        if (error.message) {
          setMarketplaceBackfillMessage(error.message);
        } else {
          setMarketplaceBackfillMessage(JSON.stringify(error));
        }
        setDisableMarketplaceBackfillSubmit(false);
      });
  }

  let failedAuditedUsersBlock = usersFailingAudit.map((user, index) => {
    return (
      <tr>
        <td>{index}</td>
        <td>{user.userId}</td>
        <td>{user.creationDate}</td>
      </tr>
    );
  });

  let backfillStatusBlock = null;
  if (backfillMsg) {
    backfillStatusBlock = <div>{backfillMsg}</div>;
  }

  return (
    <div>
      <SFBanner
        title={
          <div className="w-screen">
            <SFBannerTitle title="General Website Administration" />{" "}
          </div>
        }
        bgImage="md:bg-sfBackground bg-sfBackgroundSm bg-no-repeat bg-cover"
        bodyAlignment="center"
      />
      <div className="flex flex-col w-screen items-center justify-center bg-blue-100 py-16">
        <div className="grid place-items-center w-10/12 bg-white p-10 m-5 rounded-3xl">
          <div className="w-5/12">
            <form onSubmit={onBackfillSqlDb}>
              <input
                type="submit"
                disabled={disableBackfill}
                className="literata-font font-semibold text-white bg-navyblue py-3 my-2 px-6 rounded-full text-center"
                value="Backfill SQL Database"
              />
              {backfillStatusBlock}
            </form>
          </div>
          <div className="w-5/12">
            <form onSubmit={onAudit}>
              <input
                type="submit"
                disabled={disableAudit}
                className="literata-font font-semibold text-white bg-navyblue py-3 my-2 px-6 rounded-full text-center"
                value="Audit Authentication vs Database"
              />
            </form>
          </div>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>User ID</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>{failedAuditedUsersBlock}</tbody>
          </table>
        </div>
        <div className="grid place-items-center w-10/12 bg-white p-10 m-5 rounded-3xl">
          <div className="w-5/12">
            {backfillMarketplaceStatsMessageBox}
            <form onSubmit={onUploadSunfishIdsCsv}>
              <input
                type="file"
                id="journeyTypeCsv"
                name="journeyTypeCsv"
                accept="text/csv"
                ref={sunfishIdsCsv}
              />
              <input
                type="submit"
                disabled={disableMarketplaceBackfillSubmit}
                className="literata-font font-semibold text-white bg-navyblue py-3 my-2 px-6 rounded-full text-center"
                value="Upload Sunfish IDs for Backfilling Marketplace Status to Hubspot"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import SFBanner, {
  SFBannerTitle,
  SFBannerSubTitle,
  SFBannerImage,
  SFBannerCoupleHoldingHandsLg,
} from "./SFBanner";

export default function WarrantyBanner() {
  return (
    <div className="w-full">
      <SFBannerCoupleHoldingHandsLg
        title={
          <>
            <SFBannerTitle
              title={
                <>
                  Pregnancy
                  <br /> or up to 90%
                </>
              }
              titleSpan={
                <>
                  <br /> of your money back
                </>
              }
              bodyAlignment="right"
            />
          </>
        }
        subTitle={
          <SFBannerSubTitle
            subTitle={
              <div className="text-gray-600 text-lg font-futura">
                <p className="py-6">
                  Fertility is a challenging journey, both emotionally and
                  financially. We created the{" "}
                  <span className="font-semibold text-navyblue">
                    Sunfish IVF Program
                  </span>{" "}
                  to give you some peace of mind — with an accurate, custom
                  financial plan.
                </p>
                <a
                  className="text-navyblue w-60 bg-yellow rounded-full py-4 px-8 text-center literata-font text-sm font-semibold"
                  href="https://calendly.com/joinsunfish/warranty"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more today
                </a>
              </div>
            }
          />
        }
      />
      <div className="lg:hidden sm:block">
        <SFBanner
          title={
            <>
              <SFBannerTitle
                title={
                  <>
                    Pregnancy
                    <br className="sm:hidden md:block" /> or up to 90%
                  </>
                }
                titleSpan={
                  <>
                    <br className="sm:hidden md:block" /> of your money back
                  </>
                }
                bodyAlignment="right"
              />
            </>
          }
          subTitle={
            <SFBannerSubTitle
              subTitle={
                <div className="text-gray-600 text-lg font-futura">
                  <p className="lg:py-6 sm:pb-4">
                    Fertility is a challenging journey, both emotionally and
                    financially. We created the{" "}
                    <span className="font-semibold text-navyblue">
                      Sunfish IVF Program
                    </span>{" "}
                    to give you some peace of mind — with an accurate, custom
                    financial plan.
                  </p>
                  <a
                    className="mx-auto text-navyblue w-60 bg-yellow rounded-full py-4 px-8 text-center literata-font text-sm font-semibold"
                    href="https://calendly.com/joinsunfish/warranty"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn more today
                  </a>
                </div>
              }
            />
          }
          img={
            <SFBannerImage
              imgSrc="/assets/coupleHoldingHands.png"
              imgSrcSm="/assets/coupleHoldingHandsSm.png"
              alt="couple_holding_hands"
            />
          }
          bodyAlignment="right"
          bgImage="bg-primary"
        />
      </div>
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  createAffilatePartnerStateSetPartnerName,
  createAffilatePartnerStateSetAffiliateQueryUrl,
  createAffilatePartnerStateSetPartnerMessage,
  createAffilatePartnerStateSetSubmittedAffiliate,
  setStatus,
  setAffiliates,
} from "../../App/Features/AffiliateManagementSlice";
import SFInput from "../Inputs/SFInput";
import SFBlueButton from "../Buttons/SFBlueButton";
import { postAffiliatePartner, getAffiliates } from "../../api";
import AffiliatePartnerComponent from "./AffiliatePartnerComponent";
import LoadingComponent from "./LoadingComponent";

export default function CreateAffiliatePartnerForm() {
  const submittedAffiliate = useSelector(
    (state) =>
      state.manageAffiliateState.createAffilatePartnerState.submittedAffiliate
  );
  const affiliatePartnerName = useSelector(
    (state) =>
      state.manageAffiliateState.createAffilatePartnerState.affiliatePartnerName
  );
  const affiliatePartnerMessage = useSelector(
    (state) =>
      state.manageAffiliateState.createAffilatePartnerState
        .affiliatePartnerMessage
  );
  const affiliateQueryUrl = useSelector(
    (state) =>
      state.manageAffiliateState.createAffilatePartnerState.affiliateQueryUrl
  );
  const affiliateStatus = useSelector(
    (state) => state.manageAffiliateState.status
  );

  return (
    <div>
      {affiliateStatus === "loading" ? (
        <LoadingComponent />
      ) : affiliateStatus === "complete" ? (
        <AffiliatePartnerComponent affiliate={submittedAffiliate} />
      ) : (
        <div>
          <SFInput
            label="Affiliate Name"
            handleChange={(e) =>
              Store.dispatch(
                createAffilatePartnerStateSetPartnerName(e.target.value)
              )
            }
            placeholderLabel="Affiliate Name"
            error={
              affiliatePartnerName === undefined && affiliateStatus === "error"
            }
          />
          <SFInput
            label="Affiliate Welcome Message"
            handleChange={(e) =>
              Store.dispatch(
                createAffilatePartnerStateSetPartnerMessage(e.target.value)
              )
            }
            placeholderLabel="Affiliate Welcome Message"
          />
          <SFInput
            label="Affiliate Query Url"
            handleChange={(e) =>
              Store.dispatch(
                createAffilatePartnerStateSetAffiliateQueryUrl(e.target.value)
              )
            }
            placeholderLabel="Affiliate Query Url"
            error={
              affiliateQueryUrl === undefined && affiliateStatus === "error"
            }
          />
          <div className="grid place-items-center">
            {affiliateStatus !== "inProgress" ? <>{affiliateStatus}</> : <></>}
            <SFBlueButton
              title="Submit"
              onClick={async () => {
                if (affiliatePartnerName === undefined) {
                  Store.dispatch(setStatus("error"));
                } else {
                  Store.dispatch(setStatus("loading"));
                  postAffiliatePartner({
                    partnerName: affiliatePartnerName,
                    partnerMessage: affiliatePartnerMessage,
                    affiliateQueryUrl: affiliateQueryUrl,
                  }).then((value) => {
                    if (value === "Partner url already exists") {
                      Store.dispatch(setStatus(value));
                    } else if (value) {
                      Store.dispatch(
                        createAffilatePartnerStateSetSubmittedAffiliate(value)
                      );
                      getAffiliates().then((value) => {
                        Store.dispatch(setAffiliates(value));
                        Store.dispatch(setStatus("complete"));
                      });
                    } else {
                      Store.dispatch(setStatus("error"));
                    }
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

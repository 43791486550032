export function creditScoreFormatter(creditScore) {
  if (creditScore === "720+") {
    return "excellent";
  } else if (creditScore === "680-719") {
    return "good";
  } else if (creditScore === "660-679") {
    return "good";
  } else if (creditScore === "660-719") {
    return "good";
  } else if (creditScore === "620-659") {
    return "fair";
  } else if (creditScore === "Under 620") {
    return "poor";
  }
}

export function dateOfBirthFormatter(dateOfBirth) {
  if (!dateOfBirth) {
    return dateOfBirth;
  }

  const dateOfBirthSplitString = dateOfBirth.split("-");
  const dateOfBirthString =
    `${dateOfBirthSplitString[1]}/${dateOfBirthSplitString[2]}/${dateOfBirthSplitString[0]}`.toString();
  return dateOfBirthString;
}

export function propertyStatusFormatter(propertyStatus) {
  if (propertyStatus === "Own") {
    return "own_outright";
  } else if (propertyStatus === "Own with Mortgage") {
    return "own_with_mortgage";
  } else if (propertyStatus === "Rent") {
    return "rent";
  }
}

export function payFrequencyFormatter(payFrequency) {
  if (payFrequency === "Once every week") {
    return "weekly";
  } else if (payFrequency === "Once every other week") {
    return "biweekly";
  } else if (payFrequency === "Twice per month") {
    return "twice_monthly";
  } else if (payFrequency === "Once per month") {
    return "monthly";
  }
}

export function payFrequencyLegacySupportFormatter(payFrequency) {
  if (payFrequency === "Weekly") {
    return "Once every week";
  } else if (payFrequency === "Bi-Weekly") {
    return "Once every other week";
  } else if (payFrequency === "Semi-Monthly") {
    return "Twice per month";
  } else if (payFrequency === "Monthly") {
    return "Once per month";
  } else {
    return payFrequency;
  }
}

export function employmentStatusFormatter(employmentStatus) {
  if (employmentStatus === "Employed Full Time") {
    return "employed";
  } else if (employmentStatus === "Employed Part Time") {
    return "employed";
  } else if (employmentStatus === "Self Employed") {
    return "self_employed";
  } else if (employmentStatus === "Unemployed") {
    return "not_employed";
  } else if (employmentStatus === "Military") {
    return "military";
  } else if (employmentStatus === "Retired") {
    return "retired";
  } else if (employmentStatus === "Other") {
    return "other";
  }
}

export function highestLevelOfEducationFormatter(highestLevelOfEducation) {
  if (highestLevelOfEducation === "Doctorate") {
    return "other_grad_degree";
  }
  return highestLevelOfEducation?.toLowerCase().replaceAll(" ", "_");
}

export function evenPropertyStatusFormatter(propertyStatus) {
  if (propertyStatus === "own_outright") {
    return "Own";
  } else if (propertyStatus === "own_with_mortgage") {
    return "Own with Mortgage";
  } else if (propertyStatus === "rent") {
    return "Rent";
  }
}

export function evenCreditScoreFormatter(creditScore) {
  if (creditScore === "1. Excellent (720+)") {
    return "720+";
  } else if (creditScore === "2. Good (660-719)") {
    return "660-719";
  } else if (creditScore === "3. Fair (620-659)") {
    return "620-659";
  } else if (creditScore === "4. Poor (<620)") {
    return "Under 620";
  } else {
    return creditScore;
  }
}

export function evenPayFrequencyFormatter(payFrequency) {
  if (payFrequency === "weekly") {
    return "Weekly";
  } else if (payFrequency === "biweekly") {
    return "Bi-Weekly";
  } else if (payFrequency === "twice_monthly") {
    return "Semi-Monthly";
  } else if (payFrequency === "monthly") {
    return "Monthly";
  } else {
    return payFrequency;
  }
}

export function evenEmploymentStatusFormatter(employmentStatus) {
  if (employmentStatus === "employed") {
    return "Employed Full Time";
  } else if (employmentStatus === "self_employed") {
    return "Self Employed";
  } else if (employmentStatus === "not_employed") {
    return "Unemployed";
  } else if (employmentStatus === "military") {
    return "Military";
  } else if (employmentStatus === "retired") {
    return "Retired";
  } else if (employmentStatus === "other") {
    return "Other";
  }
}

export function evenHighestLevelOfEducationFormatter(highestLevelOfEducation) {
  if (highestLevelOfEducation === "other_grad_degree") {
    return "Doctorate";
  }
  return highestLevelOfEducation
    ?.replaceAll("_", " ")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

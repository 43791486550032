import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { CalculatorType } from "../App/Features/Calculator/CalculatorType.ts";
import CostCalculatorBanner from "../Components/Banners/CostCalculatorBanner";
import BetterUnderstand from "../Components/CostCalculator/BetterUnderstand";
import CostDisclosure from "../Components/CostCalculator/CostDisclosure";
import FreezingComponent from "../Components/CostCalculator/FreezingComponent";
import IvfComponent from "../Components/CostCalculator/IvfComponent";
import SurrogacyComponent from "../Components/CostCalculator/SurrogacyComponent";

export default function CostCalculator() {
  const calculatorType = useSelector((state) => state.calculator.type);
  const scrollRef = useRef(null);

  useEffect(() => {
    if (calculatorType !== CalculatorType.NotSelected) {
      scrollRef.current.scrollIntoView();
    }
  }, [calculatorType]);

  const selectedCalculator = () => {
    if (
      calculatorType === CalculatorType.FreezingEggsOrEmbryos ||
      calculatorType === CalculatorType.NotSelected
    ) {
      return <FreezingComponent />;
    }
    if (calculatorType === CalculatorType.IVF) {
      return <IvfComponent />;
    }
    if (calculatorType === CalculatorType.Surrogacy) {
      return <SurrogacyComponent />;
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="How much is egg freezing or IVF? How much for an egg donor or gestational surrogate? Use the Sunfish tool to calculate your costs."
        />
      </Helmet>
      <CostCalculatorBanner />
      <div ref={scrollRef}>
        <div class="flex flex-col items-center">{selectedCalculator()}</div>
      </div>
      <CostDisclosure />
      <BetterUnderstand />
    </>
  );
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import SFInput from "../Inputs/SFInput";
import SFBlueButton from "../Buttons/SFBlueButton";
import SFDropdown from "../Inputs/SFDropdown";
import TrackableLinkComponent from "./TrackableLinkComponent";
import { updateTrackableLink, getTrackableLinks } from "../../api";
import LoadingComponent from "../AffiliateManagementPage/LoadingComponent";
import {
  setLinks,
  setStatus,
  manageTrackableLinkStateSetSelectedLink,
} from "../../App/Features/TrackableLinkManagementSlice";

export default function ManageTrackableLink() {
  const links = useSelector(
    (state) => state.trackableLinkManagementState.links
  );
  const selectedLink = useSelector(
    (state) =>
      state.trackableLinkManagementState.manageTrackableLinkState.selectedLink
  );
  const status = useSelector(
    (state) => state.trackableLinkManagementState.status
  );

  return (
    <div>
      {status === "loading" ? (
        <LoadingComponent />
      ) : status === "complete" ? (
        <div className="flex px-4 flex-col">
          <label className="text-center font-semibold">Link updated</label>
        </div>
      ) : (
        <>
          <SFDropdown
            title="Select a link"
            handleChange={(e) =>
              Store.dispatch(
                manageTrackableLinkStateSetSelectedLink({
                  ...links[e.target.value],
                  affiliateId: e.target.value,
                })
              )
            }
            placeholderLabel="Select a affiliate"
            options={Object.keys(links ?? {})?.map((linkKey) => (
              <option value={linkKey} className="text-pink-400">
                {!links[linkKey].active ? "[Currently Inactive] " : ""}
                {links[linkKey].linkName}
              </option>
            ))}
          />
          {selectedLink === undefined ? (
            <></>
          ) : (
            <>
              <TrackableLinkComponent link={selectedLink} update={true} />
              <SFInput
                label="Traffic Source"
                handleChange={(e) =>
                  Store.dispatch(
                    manageTrackableLinkStateSetSelectedLink({
                      ...selectedLink,
                      trafficSource: e.target.value,
                    })
                  )
                }
                value={selectedLink.trafficSource}
                placeholderLabel="Traffic Source"
              />
              <SFInput
                label="Active"
                handleChange={(e) =>
                  Store.dispatch(
                    manageTrackableLinkStateSetSelectedLink({
                      ...selectedLink,
                      active: e.target.checked,
                    })
                  )
                }
                type="checkbox"
                defaultStyle=""
                errorStyle=""
                checked={selectedLink?.active}
              />
              <div className="grid place-items-center">
                <SFBlueButton
                  title="Submit"
                  onClick={async () => {
                    Store.dispatch(setStatus("loading"));
                    await updateTrackableLink({
                      id: selectedLink.id,
                      trafficSource: selectedLink.trafficSource,
                      active: selectedLink.active,
                    });
                    const links = await getTrackableLinks();
                    Store.dispatch(setLinks(links));
                    Store.dispatch(setStatus("complete"));
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import BlogsAndEventsCard from "./BlogsAndEventsCard";
import { getBlogs, getEvents } from "../../api";
import { setBlogs, setEvents } from "../../App/Features/BlogsAndEventsSlice";
import Store from "../../App/Store";

export default function BlogsAndEventsBody() {
  const events = useSelector((state) => state.blogsAndEventsState.events);
  const blogs = useSelector((state) => state.blogsAndEventsState.blogs);
  const newEventPost = useSelector(
    (state) => state.addBlogsAndEventsState.event
  );
  const imageFile = useSelector(
    (state) => state.addBlogsAndEventsState.imageFile
  );
  const selectedType = useSelector(
    (state) => state.addBlogsAndEventsState.selectedType
  );
  const newBlogPost = useSelector((state) => state.addBlogsAndEventsState.blog);
  const verifyModifications = useSelector(
    (state) => state.addBlogsAndEventsState.verify
  );
  const showMoreEventsButton = useSelector(
    (state) => state.blogsAndEventsState.showMoreEventsButton
  );
  const showMoreBlogsButton = useSelector(
    (state) => state.blogsAndEventsState.showMoreBlogsButton
  );
  const upcomingEvent = useSelector(
    (state) => state.blogsAndEventsState.upcomingEvent
  );

  useEffect(() => {
    if (!blogs) {
      getBlogs().then((value) => Store.dispatch(setBlogs(value)));
    }
    if (!events) {
      getEvents().then((value) => {
        if (value && value?.length !== 0) {
          Store.dispatch(setEvents(value));
        }
      });
    }
  }, [blogs, events]);

  const verifyEventPost = () =>
    verifyModifications &&
    selectedType === "event" &&
    (upcomingEvent
      ? new Date(upcomingEvent.date) > new Date(newEventPost.date)
      : Date.now() > new Date(newEventPost.date));
  const verifyBlogPost = () => verifyModifications && selectedType === "blog";

  return (
    <div className="flex flex-col m-24 w-10/12 lg:w-8/12">
      <h6 className="text-5xl m-2 mt-24 mb-8 literata-font text-center items-center text-navyblue">
        <b>Latest articles</b>
      </h6>
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-10 xl:gap-7 xl:gap-y-16 mt-8">
        {verifyBlogPost() ? (
          <div className="pb-10">
            <BlogsAndEventsCard
              {...newBlogPost}
              isCompact={true}
              isNew={true}
              imagePath={URL.createObjectURL(imageFile)}
            />
          </div>
        ) : (
          <></>
        )}
        {verifyBlogPost()
          ? blogs?.map((value, index) => (
              <BlogsAndEventsCard
                {...value}
                isCompact={true}
                isNew={index === blogs?.length - 1 ? false : undefined}
              />
            ))
          : blogs?.map((value, index) => (
              <BlogsAndEventsCard {...value} isCompact={true} />
            ))}
      </div>
      {showMoreBlogsButton ? (
        <div className="flex flex-row place-items-end py-2">
          <button
            onClick={() => {
              getBlogs(blogs[blogs.length - 1]).then((value) =>
                setBlogs([...events, ...value])
              );
            }}
            className="w-1/6 items-center justify-center py-3 px-1 bg-navyblue text-white rounded-full"
          >
            Show more
          </button>
        </div>
      ) : (
        <></>
      )}

      <h6 className="text-2xl lg:text-5xl literata-font m-2 mt-24 mb-8 items-center text-navyblue">
        <b>
          Missed it? Watch <br /> our last events.
        </b>
      </h6>
      <div className="grid md:grid-cols-2 xl:grid-cols-3 gap-10 xl:gap-7 xl:gap-y-16 mt-8">
        {verifyEventPost() ? (
          <BlogsAndEventsCard {...newEventPost} isNew={true} isCompact={true} />
        ) : (
          <></>
        )}
        {verifyEventPost()
          ? events?.map((value, index) => (
              <BlogsAndEventsCard
                {...value}
                isCompact={true}
                isNew={index === events?.length - 1 ? false : undefined}
              />
            ))
          : events?.map((value, index) => (
              <BlogsAndEventsCard {...value} isCompact={true} />
            ))}
      </div>
      {showMoreEventsButton ? (
        <div className="flex flex-row place-items-end py-2">
          <button
            onClick={() => {
              getEvents(events[events.length - 1]).then((value) =>
                setEvents([...events, ...value])
              );
            }}
            className="w-1/6 items-center justify-center py-3 px-1 bg-navyblue text-white rounded-full"
          >
            Show more
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

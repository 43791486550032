import React from "react";
import SFBanner, { SFBannerTitle } from "../Banners/SFBanner";
import TabButton from "../Buttons/TabButton";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  trackableLinkManagementType,
  setSelectedType,
} from "../../App/Features/TrackableLinkManagementSlice";

export default function TrackableLinkManagementBanner({ bodyRef }) {
  const selectedType = useSelector(
    (state) => state.trackableLinkManagementState.selectedType
  );

  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle
            title="Manage Trackable Links"
            bodyAlignment="center"
          />
        </div>
      }
      subTitle={
        <div className="grid lg:grid-cols-3 md:grid-cols-1 gap-5 py-10 px-20">
          {Object.keys(trackableLinkManagementType).map((key) => (
            <TabButton
              title={trackableLinkManagementType[key].title}
              onClick={() => {
                Store.dispatch(setSelectedType(key));
                bodyRef.current.scrollIntoView();
              }}
              isSelected={selectedType === key}
            />
          ))}
        </div>
      }
      bodyAlignment="center"
      bgImage="bg-primary"
    />
  );
}

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CalculatorType } from "../../App/Features/Calculator/CalculatorType.ts";
import { setCalculatorType } from "../../App/Features/Calculator/CalculatorSlice";
import SFBanner, { SFBannerSubTitle, SFBannerTitle } from "./SFBanner";
import TabButton from "../Buttons/TabButton";

export default function CostCalculatorBanner() {
  const calculatorType = useSelector((state) => state.calculator.type);
  const dispatch = useDispatch();

  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle
            title="Cost"
            titleSpan="Calculator"
            titleSpanNewLine={true}
          />
        </div>
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            <div className="w-3/4 text-center mx-auto md:text-lg sm:text-base sm:mt-2 lg:mt-10">
              <p className="text-gray-900/70">
                Explore this resource to better project and understand the main
                costs associated with your journey.
              </p>
              <div className="text-gray-600 sm:mt-2 lg:mt-10">
                <h1 className="literata-font font-semibold">
                  What's the journey you would like to budget for?
                </h1>
                <ul
                  className="grid lg:grid-cols-3 sm:grid-cols-1 sm:gap-2 lg:gap-7 sm:mt-2 lg:mt-10"
                  role="tablist"
                >
                  <TabButton
                    title="Freezing Eggs or Embryos"
                    onClick={() =>
                      dispatch(
                        setCalculatorType(CalculatorType.FreezingEggsOrEmbryos)
                      )
                    }
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                    isSelected={
                      calculatorType === CalculatorType.NotSelected ||
                      calculatorType === CalculatorType.FreezingEggsOrEmbryos
                    }
                  />
                  <TabButton
                    title="IVF + Embryo Creation"
                    onClick={() =>
                      dispatch(setCalculatorType(CalculatorType.IVF))
                    }
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                    isSelected={calculatorType === CalculatorType.IVF}
                  />
                  <TabButton
                    title="Surrogacy"
                    onClick={() =>
                      dispatch(setCalculatorType(CalculatorType.Surrogacy))
                    }
                    data-toggle="tab"
                    href="#link1"
                    role="tablist"
                    isSelected={calculatorType === CalculatorType.Surrogacy}
                  />
                </ul>
              </div>
            </div>
          }
        />
      }
      bgImage="bg-costcalPhone lg:bg-costcal bg-cover"
      bodyAlignment="center"
    />
  );
}

import React from "react";
import ECommConsentBanner from "../Components/Banners/ECommConsentBanner";
import ECommConsentContent from "../Components/ECommConsent";

export default function ECommuncationsConsent() {
  return (
    <div className="flex flex-col items-center">
      <ECommConsentBanner />
      <ECommConsentContent />
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  setSelectedPartner,
  setStatus,
} from "../../App/Features/ManagePartnersSlice";
import SFInput from "../Inputs/SFInput";
import SFDropdown from "../Inputs/SFDropdown";
import SFBlueButton from "../Buttons/SFBlueButton";
import { setPartnerMetadata } from "../../api";

export default function UpdatePartnerForm() {
  const partners = useSelector((state) => state.managePartnersState.partners);
  const types = ["agency", "clinic", "pharmacy", "strategic"];
  const selectedPartner = useSelector(
    (state) => state.managePartnersState.selectedPartner
  );

  return (
    <div className="mt-4">
      <SFDropdown
        title="Type"
        handleChange={(e) =>
          Store.dispatch(setSelectedPartner(partners[e.target.value]))
        }
        placeholderLabel="Select a partner"
        options={partners?.map((partner, index) => (
          <option value={index} className="text-pink-400">
            {!partner.active ? "[Currently Inactive] " : ""}
            {partner.name}
          </option>
        ))}
      />
      {selectedPartner ? (
        <div>
          <SFInput
            label={"Name"}
            handleChange={(e) =>
              Store.dispatch(
                setSelectedPartner({ ...selectedPartner, name: e.target.value })
              )
            }
            value={selectedPartner?.name}
          />
          <SFInput
            label={"Message"}
            handleChange={(e) =>
              Store.dispatch(
                setSelectedPartner({
                  ...selectedPartner,
                  message: e.target.value,
                })
              )
            }
            value={selectedPartner?.message}
          />
          <SFInput
            label={"Route"}
            handleChange={(e) =>
              Store.dispatch(
                setSelectedPartner({
                  ...selectedPartner,
                  route: e.target.value,
                })
              )
            }
            value={selectedPartner?.route}
            error={
              selectedPartner?.route?.length < 2 ||
              !selectedPartner?.route?.startsWith("/")
            }
            validationText={
              selectedPartner?.route?.length < 2 ||
              !selectedPartner?.route?.startsWith("/")
                ? 'Please enter a route e.g. "/hello-world"'
                : undefined
            }
          />
          <SFDropdown
            title="Type"
            handleChange={(e) =>
              Store.dispatch(
                setSelectedPartner({
                  ...selectedPartner,
                  type: types[e.target.value],
                })
              )
            }
            placeholderLabel="Select a type"
            options={types?.map((type, index) => (
              <option selected={type === selectedPartner?.type} value={index}>
                {type}
              </option>
            ))}
            error={!selectedPartner?.type}
          />
          <SFInput
            label={"Active"}
            handleChange={(e) =>
              Store.dispatch(
                setSelectedPartner({
                  ...selectedPartner,
                  active: e.target.checked,
                })
              )
            }
            type="checkbox"
            defaultStyle=""
            errorStyle=""
            checked={selectedPartner?.active}
          />
          <div className="grid place-items-center">
            <SFBlueButton
              title={"Submit"}
              onClick={async () => {
                if (
                  selectedPartner?.name?.length &&
                  selectedPartner?.message?.length &&
                  selectedPartner?.route?.length >= 2 &&
                  selectedPartner?.route?.startsWith("/") &&
                  selectedPartner?.type?.length
                ) {
                  setPartnerMetadata(selectedPartner).then((_) => {
                    Store.dispatch(setStatus("complete"));
                  });
                } else {
                  Store.dispatch(setStatus("error"));
                }
              }}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

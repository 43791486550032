import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import {
  setStatus,
  updateAffiliatedSetSelectedUser,
  updateAffiliatedSetUsers,
} from "../../App/Features/AffiliateManagementSlice";
import SFBlueButton from "../Buttons/SFBlueButton";
import SFDropdown from "../Inputs/SFDropdown";
import { progressPartnerUser, partnerUsersAtFormCompletion } from "../../api";
import LoadingComponent from "./LoadingComponent";

export default function UpdatePartnerUser() {
  const users = useSelector(
    (state) => state.manageAffiliateState.updateAffiliatedUserState.users
  );
  const selectedUser = useSelector(
    (state) => state.manageAffiliateState.updateAffiliatedUserState.selectedUser
  );
  const status = useSelector((state) => state.manageAffiliateState.status);

  useEffect(() => {
    if (!users) {
      Store.dispatch(setStatus("loading"));
      partnerUsersAtFormCompletion().then((res) => {
        Store.dispatch(updateAffiliatedSetUsers(res));
        Store.dispatch(setStatus("inProgress"));
      });
    }
  }, [users]);

  return (
    <div>
      {status === "loading" ? (
        <LoadingComponent />
      ) : status === "complete" ? (
        <div className="flex px-4 flex-col">
          <label className="text-center font-semibold">User updated </label>
        </div>
      ) : (
        <>
          <SFDropdown
            title="Select an affiliated user"
            handleChange={(e) =>
              Store.dispatch(
                updateAffiliatedSetSelectedUser(users[e.target.value])
              )
            }
            placeholderLabel="Select an affiliated user"
            options={Object.keys(users ?? {})
              .sort((a, b) => {
                return users[a]?.auth_email < users[b]?.auth_email ? -1 : 0;
              })
              ?.map((user) => (
                <option value={user} className="text-pink-400">
                  {users[user]?.auth_email
                    ? `${user} / ${users[user]?.auth_email}`
                    : user}
                </option>
              ))}
          />
          {selectedUser === undefined ? (
            <></>
          ) : (
            <>
              <div className="grid place-items-center">
                <SFBlueButton
                  title="Proceed to booked loan state"
                  onClick={async () => {
                    Store.dispatch(setStatus("loading"));
                    const result = await progressPartnerUser({
                      partnerUserId: selectedUser.partnerUserId,
                      partner: {
                        route: selectedUser.partnerRoute,
                        active: true,
                      },
                      affiliateStatus: "sf_form_booked_loan",
                    });
                    if (result) {
                      Store.dispatch(updateAffiliatedSetUsers(undefined));
                      Store.dispatch(setStatus("complete"));
                    } else {
                      Store.dispatch(setStatus("inProgress"));
                    }
                  }}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import SFBanner, {
  SFBannerTitle,
  SFBannerSubTitle,
  SFBannerImage,
  SFBannerAspiringParentsLg,
} from "./SFBanner";
import { Helmet } from "react-helmet";
import PartnerMessage from "../PartnerMessage";

export default function Banner() {
  return (
    <div className="w-full">
      <Helmet>
        <link rel="canonical" href="https://joinsunfish.com/" />
      </Helmet>
      <SFBannerAspiringParentsLg
        title={
          <>
            <PartnerMessage />
            <SFBannerTitle
              title="Financial Solutions for all journeys"
              titleSpan={
                <>
                  {" "}
                  <br /> to parenthood
                </>
              }
              bodyAlignment="right"
            />
          </>
        }
        subTitle={
          <SFBannerSubTitle
            subTitle={
              <div className="text-gray-600 text-lg font-futura">
                <p className="py-5">
                  Whether you're exploring IVF, surrogacy, egg freezing, or
                  another path to parenthood,&nbsp;
                  <b className="font-semibold text-gray-700">
                    Sunfish can offer guidance and help you explore low-interest
                    loans
                  </b>
                  .
                </p>
                <div className="text-navyblue w-60 bg-yellow rounded-full py-4 px-8 text-center">
                  <Link
                    className="text-navyblue literata-font text-sm font-semibold"
                    to="/financing-options"
                    state={{ scrollToOptions: true }}
                  >
                    See my financial options
                  </Link>
                </div>
              </div>
            }
          />
        }
      />
      <div className="lg:hidden sm:block">
        <SFBanner
          title={
            <>
              <PartnerMessage />
              <SFBannerTitle
                title="Financial Solutions for all journeys"
                titleSpan=" to parenthood"
                bodyAlignment="right"
              />
            </>
          }
          subTitle={
            <SFBannerSubTitle
              subTitle={
                <div className="text-gray-600 md:text-lg sm:text-base font-futura">
                  <p className="lg:mb-5 sm:mb-2">
                    Sunfish makes parenthood attainable for all types of
                    families. Whether you're exploring surrogacy, IVF, egg
                    freezing, or single parenthood by choice &mdash;&nbsp;
                    <b className="font-semibold text-gray-700">
                      Sunfish helps aspiring parents explore low-interest loans
                      and financial planning tools.
                    </b>
                  </p>
                  <div className="text-navyblue md:w-60 sm:w-full bg-yellow rounded-full py-4 px-8 text-center">
                    <Link
                      className="text-navyblue literata-font font-semibold"
                      to="/financing-options"
                      state={{ scrollToOptions: true }}
                    >
                      See my financial options
                    </Link>
                  </div>
                </div>
              }
            />
          }
          img={
            <SFBannerImage
              imgSrc="/assets/aspiringParents.png"
              imgSrcSm="/assets/aspiringParentsSm.png"
              alt="aspiring_parents"
            />
          }
          bodyAlignment="right"
          bgImage="bg-primary"
        />
      </div>
    </div>
  );
}

import PartnerCard from "./PartnerCard";

export default function WhoWeServe() {
  return (
    <div className="lg:w-1/2 mx-auto py-24">
      <div className="grid grid-cols-2">
        <div className="flex flex-col">
          <div className="place-self-start m-4">
            <h1 className="text-4xl font-semibold lg:leading-tight lg:text-6xl text-navyblue literata-font">
              Who{" "}
              <span className="literata-font font-normal italic">we serve</span>
            </h1>
          </div>
          <PartnerCard
            title="Educators, benefit providers, and communities"
            bgColor="bg-secondary"
            iconImage="bg-educatorsIcon"
          />
          <PartnerCard
            title="Egg donors and surrogacy agencies"
            bgColor="bg-lightgreenblue"
            iconImage="bg-eggDonorIcon"
          />
        </div>
        <div className="flex flex-col">
          <PartnerCard
            title="Pharmacies"
            bgColor="bg-lightgreenblue"
            iconImage="bg-pharmaciesIcon"
          />
          <PartnerCard
            title="IVF Clinics"
            bgColor="bg-lightGreen"
            iconImage="bg-iVFClinicIcon"
          />
        </div>
      </div>
    </div>
  );
}

export default function SFTextArea({
  label,
  placeholderLabel,
  value,
  error,
  isRequired,
  validationText,
  type = "text",
  handleChange,
}) {
  return (
    <div className="flex flex-col py-2 px-4">
      <p className="text-left mb-1 font-semibold">{label}</p>
      <textarea
        value={value}
        onChange={(e) => handleChange(e)}
        className={
          error && !value && isRequired
            ? "rounded-md p-3 border-2 border-red-200"
            : "rounded-md p-3 border-2 border-gray-200"
        }
        placeholder={placeholderLabel ?? label}
        type={type}
      />
      {error && !value && isRequired ? validationText : <></>}
    </div>
  );
}

import React from "react";

export default function ECommuncationsConsent({
  className = "p-10 lg:w-9/12 mt-10",
}) {
  return (
    <div id="content_80396c7eb" className={className} data-module="text">
      <div class="is-content">
        <p className="text-lg">
          You agree and consent to receive electronically all communications,
          agreements, documents, notices and disclosures (collectively,
          "Electronic Communications") that we, Sunfish Solutions, LLC, provide
          to you in connection with your loan application or account via{" "}
          <a
            className="text-navyblue underline"
            target="_blank"
            rel="noreferrer"
            href="www.joinsunfish.com"
          >
            www.joinsunfish.com
          </a>{" "}
          or any of its subpages (the "Website"). Your consent gives permission
          to communicate with you electronically in regard to any activity
          regarding your loan application and any loan that you may obtain from
          us. This consent applies to all interactions online between you and
          Sunfish, including those on any mobile devices, phones, smart-phones,
          and tablets.
        </p>
        <br />
        <b className="text-navyblue">
          Electronic Communications include, as applicable:
        </b>
        <ul className="list-disc ml-5">
          <li>
            disclosures and communications about loan options, including your
            application status, interest rate, finance charges and APR;
          </li>
          <li>
            terms and conditions and policies you agree to, including changes in
            terms or other updates to those agreements or policies;
          </li>
          <li>any other disclosures we are required to give you; and</li>
          <li>any other information relating to your relationship with us.</li>
        </ul>
        <br />
        <p>
          <b className="text-navyblue">Communications</b> We will provide these
          Electronic Communications to you by access to our online platform or
          through your designated e-mail address as either e-mail attachments or
          as links embedded in emails that access a website that we own or
          control after you log-in, and/or by emailing them to you at your
          electronic address (“email address”). You should keep us informed of
          any change in your electronic address or mailing address. It is your
          responsibility to keep your email address current so that we can
          communicate with you electronically. You have the right to request
          paper copies of any communication. Paper copies can be requested by
          calling{" "}
          <a className="underline text-navyblue" href="tel:+15107750064">
            (510) 775-0064
          </a>{" "}
          during our operating hours, 9 am to 5 pm PT, each Monday through
          Friday that are Business Days.
        </p>
        <br />
        <p>
          <b className="text-navyblue">Required hardware and software</b> In
          order to access and retain Electronic Communications, you will need: a
          computer with an Internet connection; a current web browser that
          includes 128-bit encryption and with cookies enabled (e.g. Internet
          Explorer version 11.0 and above, Firefox version 52.0 and above,
          Chrome version 55.0 and above, or Safari 9.1 and above); a valid email
          address; and sufficient storage space to save past Communications or
          an installed printer to print them. You confirm that you have access
          to the necessary equipment and software to receive and save/print our
          Electronic Communications to you.
        </p>
        <br />
        <p>
          <b className="text-navyblue">Duration</b> You also agree that your
          consent applies during the full course of our relationship with you,
          unless and until you withdraw consent. You may withdraw your consent
          to receive Electronic Communications electronically by emailing us at{" "}
          <a
            className="text-navyblue underline"
            href="mailto:support@joinsunfish.com"
          >
            support@joinsunfish.com
          </a>{" "}
          by calling us at{" "}
          <a href="tel:+15107750064" className="text-navyblue underline">
            (510) 775-0064
          </a>{" "}
          during our operating hours, 9 am to 5 pm PT, each Monday through
          Friday that are business days; or by writing to us via regular mail at
          Sunfish Solutions, LLC, 12 Monterey Terrace, Orinda, CA 94563. We will
          not charge you any fees for withdrawing your consent. Regardless the
          status of your consent, you agree that we may continue to communicate
          with you electronically with respect to any document, disclosure or
          notice that is not required by law to be in writing. The legal
          effectiveness, validity, and enforceability of prior electronic
          disclosures will not be affected if you withdraw your consent.
        </p>
        <br />
        <p>
          <b className="text-navyblue">Changes</b> You acknowledge that we may
          amend this policy at any time by posting a revised version on our
          website. If we make a material change to this policy we will also send
          a notice to your registered email address.
        </p>
        <br />
        <b className="text-navyblue">
          By checking the “I Agree” box, you confirm that:
        </b>
        <ul className="list-disc ml-5">
          <li>
            Your computer or device meets the hardware and software requirements
            described above;
          </li>
          <li>
            You can access and read this Consent for Electronic Communications;
          </li>
          <li>
            You can print on paper this Consent for Electronic Communications or
            save or send it to a place where You can print it, for future
            reference and access;
          </li>
          <li>
            Until or unless you notify us as described above, you consent to
            receive and execute, exclusively through electronic means, all
            Electronic Communications that we are required to provide or make
            available to you during the course of your relationship with us; and
          </li>
          <li>
            You consent to do business electronically with us and to view and
            sign the Electronic Communications electronically.
          </li>
        </ul>
      </div>
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import AddBlogsAndEventsForm from "./AddBlogsAndEventsForm";
import AddBlogsAndEventsVerify from "./AddBlogsAndEventsVerify";
import AddEventUrlForm from "./AddEventUrlForm";

export default function AddBlogsAndEventsBody({ bodyRef }) {
  const addBlogsAndEventsState = useSelector(
    (state) => state.addBlogsAndEventsState
  );
  const selectedType = useSelector(
    (state) => state.addBlogsAndEventsState.selectedType
  );

  return (
    <div ref={bodyRef}>
      {addBlogsAndEventsState.verify ? (
        <AddBlogsAndEventsVerify />
      ) : selectedType === "updateEventUrl" ? (
        <AddEventUrlForm />
      ) : selectedType ? (
        <AddBlogsAndEventsForm />
      ) : (
        <div />
      )}
    </div>
  );
}

import { useState } from "react";

export default function SFAmhLevelInput({
  id,
  label,
  placeholder,
  value,
  onChange,
  onBlur,
  isRequired,
  error,
}) {
  // NaN means "I don't know"
  const amhIsUnset = typeof value === "string" && value === "";
  const amhIsUnknown = typeof value !== "number" || isNaN(value);

  let [radioButtonClicked, setRadioButtonClicked] = useState(false);
  let [hiddenAmhLevel, setHiddenAmhLevel] = useState(amhIsUnknown ? 0 : value);

  const countDecimalPlaces = (numStr) => {
    let numDecimalPlaces = 0;

    if (numStr.includes(".")) {
      numDecimalPlaces = numStr.toString().split(".")[1].length;
    }

    return numDecimalPlaces;
  };

  const onRadioSelection = (isUnknownOption) => {
    if (!radioButtonClicked) {
      setRadioButtonClicked(true);
    }

    if (isUnknownOption) {
      if (!isNaN(value)) {
        setHiddenAmhLevel(value);
      }
      onChange(NaN);
    } else if (!isUnknownOption && amhIsUnknown) {
      onChange(hiddenAmhLevel);
    }
  };

  const onAmhKeyDown = (event) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      if (value.toString().length === 1) {
        onChange(0);
      }
    }
  };

  const onAmhLevelChange = (event) => {
    let num = parseFloat(event.target.value);

    if (amhIsUnknown || isNaN(num)) {
      // This radio button is disabled, or the user typed in a non-numeric character.
      event.preventDefault();
      return;
    } else if (num < 0) {
      num = Math.abs(num);
    }

    // If there are more than 2 decimal places, round down to 2.
    const numDecimalPlaces = countDecimalPlaces(num.toString());
    if (numDecimalPlaces > 2) {
      num = parseFloat(num.toFixed(2));
    }

    onChange(num);
  };

  let errMsg = null;
  if (error && !amhIsUnset && !amhIsUnknown) {
    if (!value && value !== 0 && isRequired) {
      errMsg = "Please provide a value";
    } else if (countDecimalPlaces(value.toString()) !== 2) {
      errMsg = "Please provide the AMH level to two decimal places.";
    }
  }

  return (
    <div key={id} className="flex flex-col py-2 px-4">
      <fieldset>
        <legend className="text-left">
          <p className="mb-1 font-semibold">{label}</p>
        </legend>
        <div className="grid grid-cols-4 sm:grid-cols-1">
          <div className="flex flex-row gap-x-4 place-items-center">
            <input
              name="amhLevelUnknown"
              type="radio"
              onChange={(event) => onRadioSelection(true)}
              checked={amhIsUnknown && (!amhIsUnset || radioButtonClicked)}
              aria-label="Radio button indicating I don't know my AMH level"
              value={value}
              onBlur={onBlur}
            />
            <p aria-label="I don't know my AMH level" className="text-left">
              I don't know
            </p>
          </div>
          <div className="flex flex-row gap-x-4 place-items-center">
            <input
              name="amhLevelKnown"
              type="radio"
              onChange={(event) => onRadioSelection(false)}
              checked={!amhIsUnknown}
              aria-label="Radio button indicating I do know my AMH level"
              value={value}
              onBlur={onBlur}
            />
            <p aria-label="I do know my AMH level" className="text-left">
              <input
                name="amhLevel"
                type="number"
                className="rounded-md p-3 border-2 h-10 border-gray-200"
                value={amhIsUnknown ? "" : value.toString()}
                placeholder={placeholder}
                onKeyDown={onAmhKeyDown}
                onChange={onAmhLevelChange}
                onBlur={onBlur}
                aria-label="Your AMH Level, in ng/mL"
              />
            </p>
          </div>
        </div>
      </fieldset>
      {errMsg ? <text className="text-red-400">{errMsg}</text> : <div />}
    </div>
  );
}

import { setPrivacyPolicyModal } from "../App/Features/NavSplice";
import PrivacyPolicyContent from "../Components/PrivacyPolicy";
import Store from "../App/Store";
import SFModal from "./SFModal";

export default function PrivacyPolicyModal() {
  const onClose = () => {
    Store.dispatch(
      setPrivacyPolicyModal({
        showPrivacyPolicyModal: false,
      })
    );
  };

  return (
    <SFModal
      title="Terms, and Privacy Policy"
      body={<PrivacyPolicyContent className="m-5" showTitle={false} />}
      onClose={onClose}
    />
  );
}

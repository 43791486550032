import React from "react";
import PrivacyPolicyBanner from "../Components/Banners/PrivacyPolicyBanner";
import PrivacyPolicyContent from "../Components/PrivacyPolicy";

export default function PrivacyPolicy() {
  return (
    <div className="flex flex-col items-center">
      <PrivacyPolicyBanner />
      <PrivacyPolicyContent />
    </div>
  );
}

import React from "react";

export default function Resources() {
  return (
    <div className="w-full flex items-center justify-center flex-col sm:mt-8 lg:mt-32 py-24 bg-resources bg-cover bg-center">
      <h1 className="text-2xl 2xl:text-5xl font-bold text-center text-navyblue literata-font">
        Resources for you
      </h1>
      <div className="w-10/12 md:w-9/12 grid md:grid-cols-2 gap-10 mt-16 md:text-lg">
        <div className="rounded-3xl bg-white p-10 text-gray-700">
          We are proud to work with a number of groups that offer educational
          resources and support for the LGBTQIA+ community. Please&nbsp;
          <a
            className="underline font-bold text-navyblue"
            href="mailto:support@joinsunfish.com"
          >
            contact us
          </a>
          &nbsp;to learn more about Sunfish's partners.
        </div>
        <div className="rounded-3xl bg-white p-10 text-gray-700">
          We offer a{" "}
          <a className="underline font-bold text-navyblue" href="/Grants">
            {" "}
            database of grants and discounts
          </a>{" "}
          which includes many discounts available to same-sex male couples or
          other specific populations.
        </div>
      </div>
      <br />
    </div>
  );
}

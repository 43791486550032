/**
 * This is a class containing static methods that should be used to help with
 * common logic.
 */
class CommonUtils {
  static isInIframe() {
    return window.self !== window.top;
  }

  static getIframeResizer() {
    if ("parentIFrame" in window) {
      return window.parentIFrame;
    }
    return null;
  }

  static routeMessage({ route, target }) {
    if (route && target) {
      window.top.postMessage({ type: "route", route, target }, "*");
    } else if (route) {
      window.top.postMessage({ type: "route", route }, "*");
    }
  }

  static displayLocalDateTime(date, options = {}) {
    const { dateStyle, timeStyle, timezone } = options;
    let timeOptions = {
      dateStyle: dateStyle ?? "short",
      timeStyle: timeStyle ?? "medium",
      timeZone: timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    if (!timeStyle) {
      timeOptions = {
        dateStyle: dateStyle ?? "short",
        timeZone: timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
    }

    return new Intl.DateTimeFormat("en-US", timeOptions).format(date);
  }
}

export default CommonUtils;

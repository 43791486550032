export default function SFForm({ header, body, button, innerRef }) {
  return (
    <div
      ref={innerRef}
      className="bg-white p-7 py-10 lg:p-10 w-10/12 lg:w-5/12 my-5 lg:my-10 rounded-3xl"
    >
      {header}
      {body}
      <div className="pt-10">{button}</div>
    </div>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import LGBTQParenthoodBanner from "../Components/Banners/LGBTQParenthoodBanner";
import AfterBanner from "../Components/LGBTQParenthoodComponents/AfterBanner";
import FamilyBuilding from "../Components/LGBTQParenthoodComponents/FamilyBuilding";
import ProudlyStand from "../Components/LGBTQParenthoodComponents/ProudlyStand";
import Resources from "../Components/LGBTQParenthoodComponents/Resources";

export default function LgbtqParenthood() {
  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <meta
          name="description"
          content="Sunfish is proud to offer customized guidance and financing for the LGBTQIA+ community to make the parenthood journey affordable for all types of families."
        />
      </Helmet>
      <LGBTQParenthoodBanner />
      <AfterBanner />
      <FamilyBuilding />
      <Resources />
      <ProudlyStand />
    </div>
  );
}

import React from "react";
import GrantCard from "./GrantCard";

export default function GrantsList({ grants, fetchMoreGrants }) {
  return (
    <div className="flex flex-col place-items-center lg:mx-60 md:mx-40 sm:mx-0 lg:pt-32 sm:pt-8">
      <div className="grid lg:grid-cols-3 lg:grid-row-2 md:grid-cols-2 sm:grid-cols-1 gap-5 mx-5 py-5">
        {grants.map((grant, index) => (
          <div
            className="rounded-lg flex bg-lightgreenblue"
            data-testid="grantslist-result-card"
          >
            <GrantCard grant={grant} index={index} />
          </div>
        ))}
      </div>
      <button
        onClick={() => {
          fetchMoreGrants();
        }}
        className="items-center justify-center py-3 px-4 bg-navyblue text-white rounded-full"
      >
        Load more options
      </button>
    </div>
  );
}

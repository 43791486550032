import { createSlice } from "@reduxjs/toolkit";

export const userSplice = createSlice({
  name: "user",
  initialState: {
    emailInput: "",
    user: undefined,
    loginModalState: {
      showLoginModal: false,
      authRequired: false,
      title: undefined,
    },
    signUpModalState: {
      showSignupModal: false,
      authRequired: false,
      title: undefined,
      onClosePath: undefined,
    },
    resetPasswordModalState: { showResetPasswordModal: false },
    usersOffersURL: undefined,
  },
  reducers: {
    updateUserState: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    setUserState: (state, action) => {
      state.user = action.payload;
    },
    setEmailInput: (state, action) => {
      state.emailInput = action.payload;
    },
    setLoginModalState: (state, action) => {
      if (!action.payload.showLoginModal) {
        action.payload.title = undefined;
      }
      state.loginModalState = action.payload;
    },
    setSignUpModalState: (state, action) => {
      if (!action.payload.showSignupModal) {
        action.payload.title = undefined;
      }
      state.signUpModalState = action.payload;
    },
    setResetPasswordModalState: (state, action) => {
      state.resetPasswordModalState = action.payload;
    },
    setUsersOffersURL: (state, action) => {
      state.usersOffersURL = action.payload;
    },
    setAgreeToMarketing: (state, action) => {
      state.user.agreedToMarketing = action.payload;
    },
  },
});

export const {
  setUserState,
  setEmailInput,
  updateUserState,
  setLoginModalState,
  setSignUpModalState,
  setResetPasswordModalState,
  setUsersOffersURL,
  setAgreeToMarketing,
} = userSplice.actions;

export default userSplice.reducer;

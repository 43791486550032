import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { sunfishSignOut } from "../../api";
import {
  journeyDetailsTitle,
  ivfAndMedication,
  setDashboardVisible,
  defaultSteps,
  longFormSteps,
  setCurrentStep,
  setAccountInfoFormCompleted,
  setShowJourneyDetails,
} from "../../App/Features/MyDashboardSlice";
import Store from "../../App/Store";
import { areFeatureFlagsEnabled } from "../../api";

export default function ProfileNavBar() {
  const reducedFormEnabled = useSelector(
    (state) => state.appState.reducedFormEnabled,
  );
  const user = useSelector((state) => state.userState.user);
  const showJourneyDetails = useSelector(
    (state) => state.myDashboardState.showJourneyDetails,
  );
  const isDashboardVisible = useSelector(
    (state) => state.myDashboardState.isDashboardVisible,
  );
  const [steps, setSteps] = useState(
    defaultSteps(user, {
      myDashboardPage: true,
    }),
  );

  const [isIvfJourneyPaneEnabled, setIvfJourneyPaneEnabled] = useState(false);

  const logOut = () => {
    sunfishSignOut();
  };

  useEffect(() => {
    if (reducedFormEnabled) {
      setSteps(
        defaultSteps(user, {
          myDashboardPage: true,
        }),
      );
    } else {
      setSteps(
        longFormSteps(user, {
          myDashboardPage: true,
        }),
      );
    }
  }, [reducedFormEnabled, user]);

  useEffect(() => {
    Store.dispatch(
      setShowJourneyDetails(
        isIvfJourneyPaneEnabled && user?.loanPurpose === ivfAndMedication,
      ),
    );
  }, [user, isIvfJourneyPaneEnabled]);

  useEffect(() => {
    areFeatureFlagsEnabled(["ivfJourneyDetails"])
      .then((results) => {
        setIvfJourneyPaneEnabled(results["ivfJourneyDetails"]);
      })
      .catch((error) => {
        setIvfJourneyPaneEnabled(false);
      });
  }, []);

  return user ? (
    <div
      className={
        isDashboardVisible
          ? "block w-1/5 min-h-screen bg-navyblue text-white flex flex-col justify-between p-5"
          : "lg:block sm:hidden w-2/5 min-h-screen bg-navyblue text-white flex flex-col justify-between p-24"
      }
    >
      <div className="place-self-center">
        <div className="flex flex-row place-content-center">
          <button
            onClick={() => Store.dispatch(setDashboardVisible(false))}
            className="block text-blue-900 lg:text-base sm:text-xs font-semibold lg:hidden focus:outline-none"
            aria-label="show-menu"
          >
            <p className="w-20 text-white">
              Close <span className="ml-2">X</span>
            </p>
          </button>
        </div>
        <p className="font-bold lg:text-3xl sm:text-lg py-5 text-center">
          {user?.firstName} {user?.lastName}
        </p>
        <div className="flex flex-col place-content-center lg:text-base sm:text-xs">
          {steps
            ?.filter(
              (step) =>
                showJourneyDetails || step?.title !== journeyDetailsTitle,
            )
            .map((e, index) => (
              <button
                onClick={() => {
                  Store.dispatch(setCurrentStep(index));
                  Store.dispatch(setAccountInfoFormCompleted(false));
                }}
              >
                <div className="flex flex-row gap-x-2 py-2 text-left">
                  <div>{e.icon}</div>
                  <p>{e.title}</p>
                </div>
              </button>
            ))}
          <button
            className="text-center py-5 border-gray-500 place-self-center"
            onClick={logOut}
          >
            <div className="flex flex-row gap-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z"
                  clipRule="evenodd"
                />
              </svg>
              Log out
            </div>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="w-2/5 min-h-screen bg-navyblue text-white flex flex-col justify-between pb-24"></div>
  );
}

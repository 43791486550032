import React from "react";

export default function ProudlyStand() {
  return (
    <div className="flex w-full items-center justify-center sm:mt-0 lg:mt-32 bg-white py-20 md:py-32">
      <div className="w-11/12 lg:w-8/12">
        <div className="grid md:grid-cols-2 gap-5 md:gap-12">
          <div>
            <h1 className="text-4xl literata-font lg:text-5xl font-bold text-navyblue">
              We proudly stand by the LGBTQIA+ community.
            </h1>
          </div>
          <div className="lg:p-10">
            <h1 className="text-xl text-gray-800">
              Sunfish is a partner of a number of LGBTQIA+ focused parenthood
              groups.
            </h1>
          </div>
        </div>
        <div className="grid gap-2 lg:grid-cols-3 md:grid-cols-1 mt-10">
          <div className="flex justify-center items-center">
            <a
              href="https://gayswithkids.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/assets/gwkLogo.png"
                className="h-24 md:h-20"
                alt="Gays With Kids logo"
              />
            </a>
          </div>
          <div className="flex justify-center items-center">
            <a
              href="https://www.menhavingbabies.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/assets/mhb.png"
                className="h-24 md:h-20"
                alt="Men Having Babies logo"
              />
            </a>
          </div>
          <div className="flex justify-center items-center">
            <a
              href="https://www.samelovesurrogacy.com/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src="/assets/samelove.png"
                className="h-24 md:h-20"
                alt="Same Love Surrogacy logo"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export function completedAllRequiredSunfishFormFields(
  formValues,
  reducedFormEnabled
) {
  if (reducedFormEnabled) {
    return !!(
      formValues["firstName"] &&
      formValues["lastName"] &&
      formValues["loanTimeFrame"] &&
      formValues["loanAmount"] &&
      formValues["telephone"] &&
      formValues["dateOfBirth"] &&
      formValues["address1"] &&
      formValues["city"] &&
      formValues["state"] &&
      formValues["zipCode"] &&
      formValues["usResidencyStatus"]
    );
  } else {
    return !!(
      formValues["firstName"] &&
      formValues["lastName"] &&
      formValues["loanTimeFrame"] &&
      formValues["loanPurpose"] &&
      formValues["loanAmount"] &&
      formValues["employmentStatus"] &&
      formValues["highestLevelOfEducation"] &&
      formValues["incomeFrequency"] &&
      formValues["telephone"] &&
      formValues["dateOfBirth"] &&
      formValues["address1"] &&
      formValues["city"] &&
      formValues["state"] &&
      formValues["zipCode"] &&
      formValues["yearsThereZip"] &&
      formValues["ownOrRent"] &&
      formValues["creditScore"] &&
      formValues["individualIncome"] &&
      formValues["usResidencyStatus"]
    );
  }
}

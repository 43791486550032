import React from "react";

export default function CostDiscolsure() {
  return (
    <div className="w-full flex flex-col items-center  justify-center bg-secondary text-navyblue py-24 mt-16 md:mt-24">
      <h1 className="text-center text-5xl">
        <b>Disclosures</b>
      </h1>
      <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-12 md:w-9/12 w-11/12 mt-16">
        <div>
          <div className="bg-[#fed7aa] rounded-2xl text-xl font-bold h-16 w-16 flex items-center justify-center">
            1
          </div>
          <div className="mt-3">
            <h1 className="text-lg font-bold">
              Sunfish provides these estimations for research purposes only.
            </h1>
            <p className="text-sm mt-1">
              We are not recommending any particular medical process;
              estimations are illustrative only. Different providers bucket
              costs differently; please speak with your provider for details.
            </p>
          </div>
        </div>
        <div>
          <div className="bg-[#fed7aa] rounded-2xl text-xl font-bold h-16 w-16 flex items-center justify-center">
            2
          </div>
          <div className="mt-3">
            <h1 className="text-lg font-bold">
              Costs are estimated based on review of national averages.
            </h1>
            <p className="text-sm mt-1">
              Estimated costs are based on a review of average costs from
              providers nationwide conducted July 2022, and informed by&nbsp;
              <a
                href="https://www.fertilityiq.com/"
                target="_blank"
                rel="noreferrer"
                className="underline text-navyblue"
              >
                Fertility IQ
              </a>
              &nbsp;where applicable. Exact costs may vary significantly,
              especially geographically.
            </p>
          </div>
        </div>
        <div>
          <div className="bg-[#fed7aa] rounded-2xl text-xl font-bold h-16 w-16 flex items-center justify-center">
            3
          </div>
          <div className="mt-3">
            <h1 className="text-lg font-bold">
              Insurance, discounts, and specific providers will impact your
              costs.
            </h1>
            <p className="text-sm mt-1">
              These results assume patients are paying out-of-pocket, without
              any insurance, employer benefits, discounts, or grants. Costs may
              vary significantly for your unique circumstance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

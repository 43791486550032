import SFBanner, {
  SFBannerTitle,
  SFBannerImage,
  SFBannerSubTitle,
  SFBannerWomenWithLaptopLg,
} from "./SFBanner";
import SFYellowLink from "../Links/SFYellowLink";

export default function PartnerProvidersBanner() {
  return (
    <div className="w-full">
      <SFBannerWomenWithLaptopLg
        title={
          <SFBannerTitle
            title="For "
            titleSpan={
              <div className="font-normal literata-font italic">Providers</div>
            }
            bodyAlignment="right"
          />
        }
        subTitle={
          <div className="flex flex-col w-96 gap-y-5 text-gray-600 text-lg py-5 font-futura">
            Partner with Sunfish to help your Intended Parents afford their
            journey to parenthood with financial resources and support.
            <div className="w-60">
              <SFYellowLink title="Contact us" to="/partners-contact-us" />
            </div>
          </div>
        }
      />
      <div className="lg:hidden sm:block">
        <SFBanner
          title={
            <SFBannerTitle
              title="For "
              titleSpan="Providers"
              bodyAlignment="right"
            />
          }
          subTitle={
            <SFBannerSubTitle
              subTitle={
                <div children="py-20 w-full text-center">
                  <p className="text-gray-600 text-lg py-5 font-futura md:text-left sm:text-center">
                    Partner with Sunfish to help your Intended Parents afford
                    their journey to parenthood with financial resources and
                    support.
                  </p>
                  <div className="place-self-center py-4 md:text-left sm:text-center">
                    <SFYellowLink
                      title="Contact us"
                      to="/partners-contact-us"
                    />
                  </div>
                </div>
              }
            />
          }
          img={
            <SFBannerImage
              imgSrc="/assets/educationBg.png"
              imgSrcSm="/assets/educationBgSm.png"
              alt="aspiring_parent_with_laptop"
            />
          }
          bgImage="bg-primary"
          bodyAlignment="right"
        />
      </div>
    </div>
  );
}

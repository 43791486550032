import { createSlice } from "@reduxjs/toolkit";

export const hasLinkId = () =>
  document.cookie.split("; ").find((row) => row.startsWith("l="));

const _getLinkId = () =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith("l="))
    ?.split("=")[1];

const _setLinkId = (id) =>
  (document.cookie = `l=${id}; SameSite=Strict; Secure`);

export const hasUserId = () =>
  document.cookie.split("; ").find((row) => row.startsWith("lu="));

const _getUserId = () =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith("lu="))
    ?.split("=")[1];

const _setUserId = (id) =>
  (document.cookie = `lu=${id}; SameSite=Strict; Secure`);

export const trackableSlice = createSlice({
  name: "trackableLinkState",
  initialState: {
    link: undefined,
    userId: undefined,
  },
  reducers: {
    setLink: (state, action) => {
      const storeLinkId = _getLinkId();
      if (
        state?.link === undefined &&
        storeLinkId !== undefined &&
        (action?.payload === storeLinkId || action?.payload === undefined)
      ) {
        state.link = storeLinkId;
      } else if (state?.link === undefined) {
        _setLinkId(action?.payload);
        state.link = action?.payload;
      }
    },
    setUserId: (state, action) => {
      const storedUserId = _getUserId();
      if (state?.userId === undefined && storedUserId !== undefined) {
        state.userId = storedUserId;
      } else if (state?.userId === undefined) {
        _setUserId(action?.payload);
        state.userId = action?.payload;
      }
    },
  },
});

export const { setLink, setUserId } = trackableSlice.actions;

export default trackableSlice.reducer;

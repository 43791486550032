import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLoginModalState } from "../../App/Features/UserSplice";
import { sunfishSignOut } from "../../api";

export default function AuthButton() {
  const dispatch = useDispatch();

  const user = useSelector(({ userState }) => userState.user);

  const logOut = () => {
    window.top.postMessage({ type: "logout" }, "*");
    sunfishSignOut();
  };

  const handleLogin = () => {
    // This was implemented only due to iframes not allowing the use of react hooks
    // Such as useState and useEffect. To get around the issue, postMessage to Wordpress immediately
    // (Wordpress should hide the frame then reset it's styles to be fullscreen. There is a setTimeout there to display it again)
    // Delay the dispatch to prevent the blinking
    window.top.postMessage("enter.fullscreen_mode", "*");

    setTimeout(() => {
      dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: false,
        }),
      );
    }, 100);
  };

  return (
    <div
      onClick={user == null ? handleLogin : logOut}
      className={`text-center px-6 py-2 text-white border-[1.5px] rounded-full text-[1.125rem] font-semibold cursor-pointer capitalize whitespace-nowrap border-[#6487C7] max-[301px]:text-base max-[301px]:font-medium max-[301px]:px-0 max-[301px]:py-0 max-[301px]:hover:text-[#4165A8] max-[301px]:text-[#052461] max-[301px]:border-none max-[301px]:rounded-none
        ${!user ? "!w-full max-[301px]:!w-auto" : ""}`}
    >
      {user == null ? "login" : "Log out"}
    </div>
  );
}

import React from "react";
import Store from "../../App/Store";
import { useSelector } from "react-redux";
import { setSelectedType } from "../../App/Features/ManagePartnersSlice";
import SFBlueButton from "../Buttons/SFBlueButton";
import { partnerMangementTypes } from "../../App/Features/ManagePartnersSlice";
import { getPartnersMetadata } from "../../api";
import { setPartners } from "../../App/Features/ManagePartnersSlice";

export default function PartnerMangementComplete() {
  const selectedType = useSelector(
    (state) => state.managePartnersState.selectedType
  );

  return (
    <div className="grid place-items-center">
      <h1 className="font-bold text-3xl text-navyblue py-8">
        {partnerMangementTypes[selectedType].successMessage}
      </h1>

      <div className="grid place-items-center">
        <SFBlueButton
          title={partnerMangementTypes[selectedType].successCta}
          onClick={() => {
            Store.dispatch(setSelectedType(selectedType));
            getPartnersMetadata().then((value) => {
              Store.dispatch(setPartners(value));
            });
          }}
        />
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const partnerMangementTypes = {
  partnerReport: {
    title: "Partner Report",
  },
  addPartner: {
    title: "Add partner link",
    successMessage: "Added new partner",
    successCta: "Add Additional Links",
  },
  updatePartner: {
    title: "Edit partner link",
    successMessage: "Partner Link Updated",
    successCta: "Update Additional Links",
  },
  updateAffiliatedUser: {
    title: "Update Partner User Booked Loan",
  },
};

export const partnerMangementStatuses = ["error", "inProgress", "complete"];

export const managePartnersSlice = createSlice({
  name: "managePartners",
  initialState: {
    partners: [],
    selectedType: "partnerReport",
    selectedPartner: undefined,
    status: "inProgress",
  },
  reducers: {
    setSelectedType: (state, action) => {
      state.selectedType = action.payload;
      state.status = "inProgress";
      if (action.payload === "addPartner") {
        state.selectedPartner = { active: true };
      } else {
        state.selectedPartner = undefined;
      }
    },
    setPartners: (state, action) => {
      state.partners = action.payload;
      state.selectedPartner = undefined;
      state.status = "inProgress";
    },
    setSelectedPartner: (state, action) => {
      state.selectedPartner = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setPartners, setSelectedPartner, setSelectedType, setStatus } =
  managePartnersSlice.actions;

export default managePartnersSlice.reducer;

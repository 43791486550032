import React from "react";
import { useSelector } from "react-redux";
import SFBanner, { SFBannerSubTitle, SFBannerTitle } from "./SFBanner";

export default function OurOfferingsBanner(executeScroll, formCompleted) {
  const evenIntegrationEnabled = useSelector(
    (state) => state.appState.evenIntegrationEnabled
  );
  const frbIntegrationEnabled = useSelector(
    (state) => state.appState.setFrbIntegrationEnabled
  );
  const startFormStateClass = formCompleted ? "hidden" : "mt-6 text-gray-500";
  const startButtonStateClass = formCompleted ? "hidden" : "";
  const seeOptionsButtonClass = formCompleted ? "" : "hidden";

  return (
    <SFBanner
      title={
        <div className="w-screen">
          <SFBannerTitle
            title="Your "
            titleSpan="offers"
            bodyAlignment="center"
          />
        </div>
      }
      subTitle={
        <SFBannerSubTitle
          subTitle={
            <div className="-mt-1">
              <p className={startFormStateClass}>
                Complete this form to get started.
              </p>
              <button
                className={`text-navyblue bg-yellow py-3 px-6 mt-5 text-sm font-medium rounded-full ${startButtonStateClass}`}
                onClick={() => executeScroll()}
              >
                Get started
              </button>
              <p
                className={
                  formCompleted &&
                  (evenIntegrationEnabled || frbIntegrationEnabled)
                    ? "mt-8 w-full px-4 text-gray-800"
                    : "hidden"
                }
              >
                Thank you for submitting your information! We are pleased to
                share a few financing options with you, on behalf of our partner
                lenders. Click any of the below options to formally apply.
              </p>
              <button
                className={`text-navyblue bg-yellow py-3 px-6 mt-5 text-sm font-medium rounded-full ${seeOptionsButtonClass}`}
                onClick={() => executeScroll()}
              >
                See my options
              </button>
            </div>
          }
        />
      }
      bgImage="bg-offersPhone lg:bg-offerings bg-cover bg-center"
      bodyAlignment="center"
    />
  );
}

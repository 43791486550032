import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";

export default function NavBarDropdown({ title, options, showMenu, onClick }) {
  return (
    <Menu
      as="div"
      className="lg:bg-primary relative lg:inline-block sm:block text-left"
    >
      <Menu.Button
        className="text-white flex items-center lg:text-navyblue active:text-blue-600 :text-blue-600 hover:opacity-30 lg:hover:text-black sm:px-2"
        aria-label="our-offerings-menu"
        aria-haspopup="menu"
      >
        {title}
        <ChevronDownIcon className="h-5 w-6" aria-hidden="true" />
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute left-0 lg:right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
          <div className="py-1">
            {options?.map((option) =>
              option ? (
                <Menu.Item key={option.title}>
                  {option.isButton ? (
                    <button
                      onClick={() => {
                        if (showMenu) {
                          showMenu();
                        }
                        if (option.onClick) {
                          option.onClick();
                        }
                      }}
                      className="block px-4 py-2 text-xl lg:text-sm hover:bg-gray-100 hover:text-gray-900 text-gray-700 w-full text-left"
                    >
                      {option.title}
                    </button>
                  ) : (
                    <Link
                      to={option.to}
                      onClick={showMenu}
                      className="block px-4 py-2 text-xl lg:text-sm hover:bg-gray-100 hover:text-gray-900 text-gray-700"
                    >
                      {option.title}
                    </Link>
                  )}
                </Menu.Item>
              ) : (
                <></>
              ),
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

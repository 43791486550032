import SunfishBenefitsCard from "./SunfishBenefitsCard";

export function SunfishBenefits() {
  return (
    <div className="w-full flex flex-col items-center justify-center bg-lightRose p-24 text-center">
      <div>
        <h1 className="text-5xl font-semibold lg:leading-tight text-navyblue literata-font">
          Sunfish{" "}
          <span className="literata-font font-normal italic">Benefits</span>
        </h1>
        <div className="grid grid-cols-3 place-self-center lg:w-4/6 lg:place-self-start mx-auto py-10">
          <SunfishBenefitsCard
            number="1"
            title="Full-service financial support platform with multiple services and lending partners."
          />
          <SunfishBenefitsCard
            number="2"
            title="Dedicated team for your clinic and your intended parents."
          />
          <SunfishBenefitsCard
            number="3"
            title="Comprehensive onboarding materials and educational packet for your intended parents."
          />
        </div>
        <div className="grid grid-cols-2 lg:w-2/5 place-self-center mx-auto">
          <SunfishBenefitsCard
            number="4"
            title="Reduced membership fees for your patients."
          />
          <SunfishBenefitsCard
            number="5"
            title="Access to a Patient Dashboard for transparency and easy integration."
          />
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import { setVerify } from "../../App/Features/AddBlogsAndEventsSplice";
import Store from "../../App/Store";

export default function AddBlogsAndEventsComplete() {
  return (
    <div className="grid place-items-center">
      <h1 className="font-bold text-3xl text-navyblue py-8">Upload Complete</h1>
      <Link
        to={"/blogs-events"}
        onClick={() => Store.dispatch(setVerify(false))}
      >
        See results
      </Link>
    </div>
  );
}

import moment from "moment";
import BaseUpside from "./BaseUpside";

export default class PersonalInformation extends BaseUpside {
  getMyClass() {
    return PersonalInformation;
  }

  static STATES_ALLOWED = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FL",
    "GA",
    "HI",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "PR",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  constructor(props) {
    super(props);

    this.address1 = props.address1 ?? "";
    this.address2 = props.address2 ?? "";
    this.city = props.city ?? "";
    this.dateOfBirth = props.dateOfBirth ?? "";
    this.email = props.email ?? "";
    this.firstName = props.firstName ?? "";
    this.lastName = props.lastName ?? "";
    this.primaryPhone = props.primaryPhone ?? "";
    this.ssn = props.ssn ?? "";
    this.state = props.state ?? "";
    this.zipcode = props.zipcode ?? "";
  }

  validateEmail = () => {
    return (
      this.email.match(
        /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/gi,
      )?.length > 0
    );
  };

  validatePhone = () => {
    return this.primaryPhone.match(/^1?\d{10}$/gi)?.length > 0;
  };

  validateDateOfBirth = () => {
    return (
      this.dateOfBirth.match(
        /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/gi,
      )?.length > 0
    );
  };

  validateZipCode = () => {
    return this.zipcode.match(/^\d{5}([-\s]\d{4})?$/gi)?.length > 0;
  };

  validateSSN = () => {
    return (
      this.ssn.match(
        /^(?!\b(\d)\1+(\d)\1+(\d)\1+\b)(?!123456789|219099999|078051120)(?!000|9\d{2})\d{3}(?!00)\d{2}(?!0{4})\d{4}$/gi,
      )?.length > 0
    );
  };

  validateState = () => {
    return (
      PersonalInformation.STATES_ALLOWED.find(
        (state) => this.state === state,
      ) !== null
    );
  };

  generate = () => {
    this.dateOfBirth =
      this.dateOfBirth?.length > 0
        ? moment(this.dateOfBirth).format("YYYY-MM-DD")
        : "";
    return this.process();
  };
}

export default function TrackableLinkComponent({ link }) {
  const _url = `${window.location.protocol}${window.location.host}${link?.urlQuery}`;
  return (
    <div className="flex px-4 flex-col">
      <div className="flex flex-row">
        <label className="text-left font-semibold">{`Link Name: ${link?.linkName}`}</label>
      </div>
      <div className="flex flex-row">
        <label className="text-left font-semibold">{`Traffic Source: ${link?.trafficSource}`}</label>
      </div>
      <div className="flex flex-row">
        <label className="text-left font-semibold">{`Url: ${_url}`}</label>
      </div>
    </div>
  );
}

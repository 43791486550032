import React from "react";
import { useLocation } from "react-router-dom";
import BlogsAndEventsBanner from "../Components/Banners/BlogsAndEvents";
import BlogsAndEventsBody from "../Components/BlogsAndEventsPage/BlogsAndEventsBody";

export default function BlogsAndEvents({ setPreviousPath }) {
  // If the user clicks on a protected link and closes the log-in dialog,
  // we want them to stay on the Blogs & Events page.
  const location = useLocation();
  setPreviousPath(location.pathname);

  return (
    <div className="flex flex-col items-center">
      <BlogsAndEventsBanner />
      <BlogsAndEventsBody />
    </div>
  );
}

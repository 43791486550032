import Store from "../../App/Store";
import { setCurrentStep } from "../../App/Features/MyDashboardSlice";

export function OurOfferingsFormBackButton({ currentStep }) {
  return (
    currentStep !== 0 && (
      <button className="flex items-left">
        <div
          className="flex flex-row items-left place-items-center pt-2"
          onClick={() => {
            var nextStep = currentStep - 1;
            Store.dispatch(setCurrentStep(nextStep));
          }}
        >
          <img
            src="/assets/akar-icons_arrow-left-black.png"
            alt="back_button"
          />
          <p className="text-left">BACK</p>
        </div>
      </button>
    )
  );
}

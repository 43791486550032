import React from "react";

export default function AfterBanner() {
  return (
    <div className="w-full flex items-center justify-center sm:mt-0 lg:mt-64">
      <div className="w-10/12 md:w-6/12">
        <p className="mt-10">
          <div className="relative sm:h-48 lg:h-64">
            <img
              src="/assets/WhoWeAreBg.png"
              className="absolute top-0"
              alt=""
            />
            <h1 className="text-2xl font-bold 2xl:text-5xl literata-font z-20 absolute mt-5 text-navyblue">
              <span className="font-semibold">
                Sunfish believes that everyone{" "}
              </span>
              <span className="italic font-light">
                who wants to build a family should be able to do so.
              </span>
            </h1>
          </div>
          <p className="lg:ml-20 text-gray-600">
            We welcome the opportunity to support any individual, couple, or
            intended co-parents – regardless of who they love – with their
            family-building journeys. <br />
            <br />
            We are proud to offer customized guidance and financing for the
            LGBTQIA+ community, as Sunfish aims to make the parenthood journey
            easier and affordable for families of all types.
            <br />
            <br />
            Whether you are just getting started or well on your way to growing
            your family, there are legal, financial, and logistical challenges
            that add another layer of complexity for LGBTQIA+ intended parents.
            Sunfish and our partners provide financial solutions for each step
            of the process, as well as additional guidance and support.
          </p>
        </p>
      </div>
    </div>
  );
}

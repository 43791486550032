export enum CancerPatientOrSurvivorType {
  true = "Yes",
  false = "No",
}
export class CancerPatientOrSurvivorFilter {
  data: CancerPatientOrSurvivorType;

  constructor(data: CancerPatientOrSurvivorType) {
    this.data = data;
  }

  isGrantValid(grant): Boolean {
    if (this.data.toString() === "true") {
      return grant.specialHealthConditions?.toLowerCase().includes("cancer");
    } else if (this.data.toString() === "false") {
      return true;
    } else {
      return false;
    }
  }
}

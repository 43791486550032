import React from "react";
import { useNavigate } from "react-router-dom";
import { setRouteToHome } from "../../App/Features/NavSplice";
import { useSelector, useDispatch } from "react-redux";
import CommonUtils from "../../utils/CommonUtils";

export default function GetOptionsButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const usersOffersUrl = useSelector((state) => state.userState.usersOffersURL);

  const handleClick = () => {
    const route = usersOffersUrl ?? "/financing-options";

    if (CommonUtils.isInIframe()) {
      window.top.postMessage({ type: "route", route }, "*");
      return true;
    } else {
      window.location = route;
    }

    if (!usersOffersUrl) {
      dispatch(setRouteToHome());
      navigate(route);
    }
  };

  return (
    <div className="items-center lg:text-sm sm:text-xl">
      <button
        className="w-[139px] h-[39px] px-6 py-2 bg-[#FFEB5E] rounded-[100px] justify-start items-center gap-4 inline-flex"
        onClick={handleClick}
      >
        <div className="text-center text-[#003269] text-base font-bold font-['Jost'] capitalize synthesis-none">
          Get Options
        </div>
      </button>
    </div>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import LearnHowSunfishCanHelp from "../Components/PartnerProvidersPage/LearnHowSunfishCanHelp";
import { SunfishBenefits } from "../Components/PartnerProvidersPage/SunfishBenefits";
import WhoWeServe from "../Components/PartnerProvidersPage/WhoWeServe";
import ClinicAgencies from "../Components/PartnerProvidersPage/ClinicAgencies";
import ApplyNowToBeEvaluated from "../Components/PartnerProvidersPage/ApplyNowToBeEvaluated";
import PartnerProvidersBanner from "../Components/Banners/PartnerProvidersBanner";

export default function PartnerProviders() {
  return (
    <div>
      <Helmet>
        <meta
          name="description"
          content="If you are an IVF clinic or provider in the parenthood industry, apply to partner with Sunfish to help your clients afford their journey to parenthood."
        />
      </Helmet>
      <PartnerProvidersBanner />
      <LearnHowSunfishCanHelp />
      <ClinicAgencies />
      <WhoWeServe />
      <SunfishBenefits />
      <ApplyNowToBeEvaluated />
    </div>
  );
}

import ProfileNavBar from "../Components/Profile/ProfileNavBar";
import ProfileBody from "../Components/Profile/ProfileBody";
import { setLoginModalState } from "../App/Features/UserSplice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Store from "../App/Store";

export default function Profile() {
  const user = useSelector((state) => state.userState.user);

  useEffect(() => {
    if (!user) {
      Store.dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: true,
        })
      );
    }
  }, [user]);

  return (
    <div className="flex flex-row">
      <ProfileNavBar />
      <ProfileBody />
    </div>
  );
}

import React from "react";
import { useSelector } from "react-redux";
import Store from "../../App/Store";
import SFInput from "../Inputs/SFInput";
import SFBlueButton from "../Buttons/SFBlueButton";
import { postTrackableLinks, getTrackableLinks } from "../../api";
import TrackableLinkComponent from "./TrackableLinkComponent";
import LoadingComponent from "../AffiliateManagementPage/LoadingComponent";
import {
  createTrackableLinkLinkName,
  createTrackableLinkQueryName,
  createTrackableLinkSubmittedLink,
  createTrackableLinkTrafficSource,
  setStatus,
  setLinks,
} from "../../App/Features/TrackableLinkManagementSlice";

export default function CreateTrackableLink() {
  const submittedLink = useSelector(
    (state) =>
      state.trackableLinkManagementState.createTrackableLinkState.submittedLink
  );
  const linkName = useSelector(
    (state) =>
      state.trackableLinkManagementState.createTrackableLinkState.linkName
  );
  const queryUrl = useSelector(
    (state) =>
      state.trackableLinkManagementState.createTrackableLinkState.queryUrl
  );
  const trafficSource = useSelector(
    (state) =>
      state.trackableLinkManagementState.createTrackableLinkState.trafficSource
  );
  const linkManagementStatus = useSelector(
    (state) => state.trackableLinkManagementState.status
  );

  return (
    <div>
      {linkManagementStatus === "loading" ? (
        <LoadingComponent />
      ) : linkManagementStatus === "complete" ? (
        <TrackableLinkComponent link={submittedLink} />
      ) : (
        <div>
          <SFInput
            label="Link Name"
            handleChange={(e) =>
              Store.dispatch(createTrackableLinkLinkName(e.target.value))
            }
            placeholderLabel="Link Name"
            error={linkName === undefined && linkManagementStatus === "error"}
          />
          <SFInput
            label="Query Url"
            handleChange={(e) =>
              Store.dispatch(createTrackableLinkQueryName(e.target.value))
            }
            placeholderLabel="Query Url"
            error={queryUrl === undefined && linkManagementStatus === "error"}
          />
          <SFInput
            label="Traffic Source"
            handleChange={(e) =>
              Store.dispatch(createTrackableLinkTrafficSource(e.target.value))
            }
            placeholderLabel="Traffic Source"
          />
          <div className="grid place-items-center">
            {linkManagementStatus !== "inProgress" ? (
              <>{linkManagementStatus}</>
            ) : (
              <></>
            )}
            <SFBlueButton
              title="Submit"
              onClick={async () => {
                if (/[^A-Za-z\d]/.test(queryUrl)) {
                  Store.dispatch(
                    setStatus("Please enter only letter and numeric characters")
                  );
                } else if (linkName === undefined) {
                  Store.dispatch(setStatus("error"));
                } else {
                  Store.dispatch(setStatus("loading"));
                  postTrackableLinks({
                    linkName,
                    trafficSource,
                    queryUrl: encodeURIComponent(queryUrl),
                  }).then((value) => {
                    if (value === "Link url already exists") {
                      Store.dispatch(setStatus(value));
                    } else if (value) {
                      Store.dispatch(createTrackableLinkSubmittedLink(value));
                      getTrackableLinks().then((value) => {
                        Store.dispatch(setLinks(value));
                        Store.dispatch(setStatus("complete"));
                      });
                    } else {
                      Store.dispatch(setStatus("error"));
                    }
                  });
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

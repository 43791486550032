import BaseUpside from "./BaseUpside";

export default class LoanInformation extends BaseUpside {
  getMyClass() {
    return LoanInformation;
  }

  static PURPOSE_DEBT_CONSOLIDATION = "debt_consolidation";
  static PURPOSE_CREDITCARDREFI = "credit_card_refi";
  static PURPOSE_STUDENTLOAN = "student_loan";

  constructor(props) {
    super(props);

    this.loanAmount = props.loanAmount ?? 0;
    this.purpose = props.purpose ?? "";
  }
}

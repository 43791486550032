export default function SFYellowButton({
  title,
  onClick,
  disabled,
  startAdornment,
  endAdornment,
  classes,
}) {
  let classNames = [
    "literata-font font-semibold text-navyblue bg-yellow text-sm py-4 px-8 rounded-full text-center justify-around flex disabled:bg-disabled disabled:cursor-not-allowed",
  ];
  if (classes) {
    classNames = classNames.concat(classes);
  }

  return (
    <button
      onClick={() => onClick()}
      className={classNames.join(" ")}
      disabled={disabled ?? false}
    >
      <div className="flex items-center">
        {startAdornment}
        {title}
        {endAdornment}
      </div>
    </button>
  );
}

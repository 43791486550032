export default function SFDropdown({
  title,
  handleChange,
  options,
  error,
  placeholderLabel,
  validationLabel,
  style,
}) {
  return (
    <div className="px-4">
      <p className={style ? style : `text-left font-semibold`}>{title}</p>
      <select
        className="rounded-md p-3 border-2 border-gray-200 h-12 my-1 w-full"
        onChange={(e) => handleChange(e)}
      >
        {placeholderLabel ? (
          <option disabled selected className="invisible">
            {placeholderLabel}
          </option>
        ) : (
          <></>
        )}
        {options}
      </select>
      <p className="text-red-400">
        {error ? validationLabel ?? placeholderLabel : ""}
      </p>
    </div>
  );
}

import { InformationCircleIcon } from "@heroicons/react/solid";

export default function SFDataProcessingDisclaimer() {
  return (
    <div>
      <div className="h-1 w-full bg-white lg:my-10 sm:my-2"></div>
      <div className="flex">
        <InformationCircleIcon className="h-6 text-gray-500" />
        <div className="lg:text-base sm:text-sm ml-4">
          <p>
            We don't collect, process, or store any of the data that you enter
            while using this tool.
          </p>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function AfterBanner() {
  const cardBody = (
    title,
    subtitle,
    heading,
    body,
    optionsLink,
    learnMoreText,
    learnMoreLink
  ) => (
    <div className="my-5">
      <h1 className="text-4xl font-bold text-navyblue">{title}</h1>
      <h1 className="text-4xl font-medium italic text-navyblue">{subtitle}</h1>
      {heading && (
        <h1 className="text-2xl font-medium my-5 text-gray-700">{heading}</h1>
      )}
      <p className="font-light my-2 text-gray-700">{body}</p>
      <div className="flex lg:flex-row md:flex-col sm:flex-col">
        <Link
          className="text-navyblue bg-yellow py-3 px-6 mt-5 text-sm font-medium rounded-full text-center"
          to={optionsLink}
          state={
            optionsLink === "/our-offerings" ? { scrollIntoForm: true } : {}
          }
        >
          See my options
        </Link>
        <Link
          className="text-navyblue underline py-3 px-6 mt-5 text-sm font-medium rounded-full"
          to={learnMoreLink}
        >
          {learnMoreText}
        </Link>
      </div>
    </div>
  );
  const educationalCards = (
    image,
    title,
    subtitle,
    heading,
    body,
    optionsLink,
    learnMoreText,
    learnMoreLink,
    imageRight = true
  ) => {
    const views = [
      cardBody(
        title,
        subtitle,
        heading,
        body,
        optionsLink,
        learnMoreText,
        learnMoreLink
      ),
    ];
    const imageComponent = (
      <div className="w-full m-auto">
        <img
          className="w-1/2 px-5 rounded-3xl lg:flex sm:hidden m-auto"
          src={image}
          alt=""
        />
      </div>
    );
    if (imageRight) {
      views.push(imageComponent);
    } else {
      views.unshift(imageComponent);
    }
    return (
      <div className="flex flex-col lg:grid gap-10 grid-cols-2 place-content-center">
        {views.map((v) => v)}
      </div>
    );
  };
  return (
    <div className="w-10/12 lg:w-7/12 flex flex-col items-center justify-center mt-12">
      {educationalCards(
        "/assets/freezing.png",
        "Freezing Eggs",
        "or embryos",
        "We offer single and dual round packages to help you feel secure when planning for your future family.",
        "Whether you’re years away from parenthood or ready to get started today, our goal is to make fertility preservation available to everyone. Sunfish’s partner lenders offer loans for egg freezing, embryo preservation, and sperm freezing, including up to 5 years of storage.",
        "/our-offerings",
        "Learn about Egg Embryo Preservation Costs",
        "/cost-calculator"
      )}{" "}
      <br />
      {educationalCards(
        "/assets/ivf.png",
        "In-vitro",
        "fertilization (IVF)",
        "It’s challenging to estimate the cost of the full in-vitro fertilization (IVF) process.",
        "Sunfish helps you make more informed financial decisions about IVF and simplifies the financing with one loan to support the entire IVF process (medication, retrieval, embryo storage, and more). We are trusted by clinics across the country.",
        "/our-offerings",
        "Learn about IVF Costs",
        "/cost-calculator",
        false
      )}{" "}
      <br />
      {educationalCards(
        "/assets/surrogacy.png",
        "Egg donors",
        "& Surrogacy",
        "",
        <div>
          <text>
            <text>
              For many – the LGBTQIA+ population and fertility-challenged
              couples or individuals – third-party support is the only way to
              build a family. Sunfish can help you afford all elements of your
              journey – the credit through our partners can cover the cost of
              your surrogacy and egg donor agencies, as well as legal fees and
              medical treatments.
            </text>
            <text className="font-bold">
              {" "}
              Sunfish's partner lenders offer loans of up to $100,000.{" "}
            </text>
            <text>
              We are one of the only financial solutions that partners with
              multiple surrogacy agencies throughout the country.
            </text>
          </text>
        </div>,
        "/our-offerings",
        "Learn about Egg donor & Surrogacy Costs",
        "/cost-calculator"
      )}
    </div>
  );
}

export default function SunfishProtectionProgram() {
  return (
    <div className="bg-warrantyBg bg-cover bg-no-repeat">
      <div className="pt-20 text-center sm:text-xl md:text-3xl lg:text-6xl text-navyblue italic literata-font">
        <span className="font-semibold not-italic literata-font">Sunfish</span>{" "}
        IVF Program
      </div>
      <div className="mx-auto w-1/2 pt-10 text-center text-lg text-gray-700">
        <b>
          Our IVF Program offers up to a 90% refund.
          <sup>1</sup>
        </b>{" "}
        Click on the link below to contact us to learn more.
      </div>
      <div className="bg-white sm:w-11/12 md:w-7/12 mx-auto my-10 p-5 rounded-3xl">
        <div class="sm:block md:hidden mx-auto w-full">
          <div className="grid grid-cols-1 w-full">
            <div class="grid h-16 place-items-center w-full text-center rounded-2xl font-semibold bg-gray-200">
              Overview
            </div>
            <div class="grid h-16 place-items-center w-full text-center rounded-2xl font-semibold bg-gray-200 mt-2 mb-4">
              One retrieval and up to three transfers
            </div>
            <div class="grid h-16 place-items-center w-full text-center rounded-2xl font-semibold bg-orange">
              <text>
                Refund<sup>2</sup>
              </text>
            </div>
            <div class="grid h-16 place-items-center w-full text-center rounded-2xl font-semibold bg-orange mt-2 mb-4">
              <text>
                Up to 90% of total cost<sup>2</sup>
              </text>
            </div>
            <div class="grid h-16 place-items-center w-full text-center rounded-2xl font-semibold bg-gray-200">
              Age
            </div>
            <div class="p-5 grid w-full text-left rounded-2xl font-semibold bg-gray-200 mt-2 mb-4">
              <text>&#x2022; 40 and under for egg source</text>
              <text> &#x2022; 42 and under for embryo recipient</text>
              <text> &#x2022; 55 and under for sperm source</text>
            </div>
            <div class="grid h-16 place-items-center w-full text-center rounded-2xl font-semibold bg-gray-200">
              Requirements<sup>3</sup>
            </div>
            <div class="p-5 grid w-full text-center rounded-2xl font-semibold bg-gray-200 mt-2 mb-4">
              <text>
                A medical screening with your clinic is required for eligibility
                and enrollment. We also require that embryos to be transferred
                are of grade 3BB or higher.{" "}
              </text>
            </div>
            <div class="grid h-16 place-items-center w-full text-center rounded-2xl font-semibold bg-gray-200">
              What’s included
            </div>
            <div class="p-5 grid w-full text-left rounded-2xl font-semibold bg-gray-200 mt-2 mb-4">
              <text>
                <b>&#x2022; Egg retrieval (1)</b>
              </text>
              <text>
                <b>&#x2022; Embryo transfers (3)</b>, including on site
                monitoring and in-cycle consultations
              </text>
            </div>
          </div>
        </div>
        <table class="sm:hidden md:block border-separate md:border-spacing-y-8 sm:border-spacing-y-4 md:border-spacing-x-4 sm:border-spacing-x-2 mx-auto table-auto w-full">
          <tbody>
            <tr class="h-16">
              <td className="w-1/3 text-center rounded-2xl font-semibold bg-gray-200">
                <div className="space-x-10 ">Overview</div>
              </td>
              <td className="w-2/3 text-left rounded-2xl bg-gray-200">
                <text className="ml-10">
                  One retrieval and up to three transfers
                </text>
              </td>
            </tr>
            <tr class="h-16 text-white">
              <td className="w-1/3 text-center rounded-2xl bg-orange/90">
                <div className="space-x-10 ">
                  Refund<sup>2</sup>
                </div>
              </td>
              <td className="w-2/3 text-left rounded-2xl bg-orange/90">
                <text className="ml-10">
                  Up to 90% of total cost<sup>2</sup>
                </text>
              </td>
            </tr>
            <tr class="h-16">
              <td className="w-1/3 text-center rounded-2xl font-semibold bg-gray-200">
                <div className="space-x-10 ">Age</div>
              </td>
              <td className="flex flex-col text-left rounded-2xl bg-gray-200">
                <text className="mt-5 ml-10">
                  &#x2022; 40 and under for egg source
                </text>
                <text className="ml-10">
                  &#x2022; 42 and under for embryo recipient
                </text>
                <text className="mb-5 ml-10">
                  &#x2022; 55 and under for sperm source
                </text>
              </td>
            </tr>
            <tr class="h-16">
              <td className="w-1/3 text-center rounded-2xl font-semibold bg-gray-200">
                <div className="space-x-10 ">
                  Requirements<sup>3</sup>
                </div>
              </td>
              <td className="flex flex-col text-left rounded-2xl bg-gray-200">
                <text className="my-5 ml-10">
                  A medical screening with your clinic is required for
                  eligibility and enrollment. We also require that embryos to be
                  transferred are of grade 3BB or higher.
                </text>
              </td>
            </tr>
            <tr class="h-16">
              <td className="w-1/3 text-center rounded-2xl font-semibold bg-gray-200">
                <div className="space-x-10 ">What’s included</div>
              </td>
              <td className="flex flex-col text-left rounded-2xl bg-gray-200">
                <text className="mt-5 ml-10">
                  {" "}
                  <b>&#x2022; Egg retrieval (1)</b>
                </text>
                <text className="mb-5 ml-10">
                  <b>&#x2022; Embryo transfers (3)</b>, including on site
                  monitoring and in-cycle consultations
                </text>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-gray-700 px-5">
          Note: Payment must be made in full prior to commencing. If full
          payment is not received, you will switch to an a la carte program
          (e.g. paying for treatments individually without the possibility of a
          refund). We will guarantee you reach pregnancy as defined by being
          discharged from your IVF clinic to your OB, which is typically close
          to the start of the 2nd trimester.{" "}
          <b>For full terms and details, please contact us.</b>
        </p>
      </div>
      <div className="pt-20 text-center sm:text-xl md:text-3xl lg:text-6xl text-navyblue italic literata-font">
        <span className="font-semibold not-italic">Schedule</span> a free call
      </div>
      <p className="w-96 w-fit mx-auto text-center py-5">
        Learn more about the Sunfish IVF Program – and other ways Sunfish can
        support you on your journey.
      </p>
      <div className="grid mb-48 mx-auto">
        <a
          className="place-self-center mx-auto text-navyblue w-60 bg-yellow rounded-full py-4 px-8 text-center literata-font text-sm font-semibold"
          href="https://calendly.com/joinsunfish/warranty"
          target="_blank"
          rel="noreferrer"
        >
          Schedule a free call
        </a>
      </div>
    </div>
  );
}

import { createSlice } from "@reduxjs/toolkit";

export const hasAffiliateId = () =>
  document.cookie.split("; ").find((row) => row.startsWith("a="));

const _getAffiliateId = () =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith("a="))
    ?.split("=")[1];

const _setAffiliateId = (affilateId) =>
  (document.cookie = `a=${affilateId}; SameSite=Strict; Secure`);

export const hasAffiliateUserId = () =>
  document.cookie.split("; ").find((row) => row.startsWith("u="));

const _getAffiliateUserId = () =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith("u="))
    ?.split("=")[1];

const _setAffiliateUserId = (affilateUserId) =>
  (document.cookie = `u=${affilateUserId}; SameSite=Strict; Secure`);

export const affiliateSlice = createSlice({
  name: "affiliate",
  initialState: {
    affiliateId: undefined,
    affiliateUserId: undefined,
  },
  reducers: {
    setAffiliate: (state, action) => {
      const storedAffiliatedId = _getAffiliateId();
      if (
        state?.affiliateId === undefined &&
        storedAffiliatedId !== undefined
      ) {
        state.affiliateId = storedAffiliatedId;
      } else if (state?.affiliateId === undefined) {
        _setAffiliateId(action?.payload);
        state.affiliateId = action?.payload;
      }
    },
    setAffiliateUserId: (state, action) => {
      const storedAffiliatedUserId = _getAffiliateUserId();
      if (
        state?.affiliateUserId === undefined &&
        storedAffiliatedUserId !== undefined
      ) {
        state.affiliateUserId = storedAffiliatedUserId;
      } else if (state?.affiliateUserId === undefined) {
        _setAffiliateUserId(action?.payload);
        state.affiliateUserId = action?.payload;
      }
    },
    resetCachedAffiliate: (state, action) => {
      _setAffiliateId(action?.payload);
      state.affiliateId = action?.payload;
    },
    resetCachedAffiliateUserId: (state, action) => {
      _setAffiliateUserId(action?.payload);
      state.affiliateUserId = action?.payload;
    },
  },
});

export const {
  setAffiliate,
  setAffiliateUserId,
  resetCachedAffiliate,
  resetCachedAffiliateUserId,
} = affiliateSlice.actions;

export default affiliateSlice.reducer;

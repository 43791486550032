import { CheckIcon } from "@heroicons/react/solid";
import React from "react";
import SFBanner, {
  SFBannerImage,
  SFBannerTitle,
  SFBannerSubTitle,
  SFBannerAspiringParentsLg,
} from "./SFBanner";

export default function OurMissionBanner() {
  return (
    <div className="w-full">
      <SFBannerAspiringParentsLg
        title={
          <div className="w-auto">
            <SFBannerTitle
              title="About Us."
              titleSpan={
                <>
                  Our Mission <br /> & Values
                </>
              }
              titleSpanNewLine={true}
              bodyAlignment="right"
            />
          </div>
        }
        subTitle={
          <SFBannerSubTitle
            subTitle={
              <div className="flex flex-col justify-center place-self-center md:w-10/12 md:px-0 py-5">
                <p className="text-lg text-gray-600">
                  <span className="font-semibold">
                    Many journeys to parenthood are expensive and often not
                    covered by insurance.
                  </span>
                  &nbsp;As a result, parenthood in the US is still inaccessible
                  to aspiring parents who deserve the opportunity to build their
                  families.
                </p>
                <span className="mt-5 flex flex-start">
                  <div className="h-5 bg-blue-900 rounded mt-1 mr-3">
                    <CheckIcon className="h-full text-white" />
                  </div>
                  <p className="text-gray-700">
                    <span className="font-semibold">Sunfish's mission</span> is
                    to make the journey to parenthood financially attainable for
                    everyone.
                  </p>
                </span>
                <span className="mt-2 flex flex-start">
                  <div className="h-5 bg-blue-900 rounded mt-1 mr-3">
                    <CheckIcon className="h-full text-white" />
                  </div>
                  <p className="text-gray-600">
                    <span className="font-semibold">Our goal</span> is to
                    democratize the access to the family building resources that
                    historically have been available to those with larger
                    financial resources.
                  </p>
                </span>
              </div>
            }
          />
        }
      />
      <div className="lg:hidden sm:block">
        <SFBanner
          title={
            <div className="w-full">
              <SFBannerTitle
                title="About Us."
                titleSpan={
                  <>
                    Our Mission <br className="sm:hidden md:block" /> & Values
                  </>
                }
                titleSpanNewLine={true}
                bodyAlignment="right"
              />
            </div>
          }
          subTitle={
            <div className="sm:text-xs lg:text-lg flex flex-col justify-center place-self-center md:w-10/12 sm:w-full md:px-0 sm:px-0 sm:pb-5">
              <p className="text-gray-600">
                <span className="font-semibold">
                  Many journeys to parenthood are expensive and often not
                  covered by insurance.
                </span>
                &nbsp;As a result, parenthood in the US is still inaccessible to
                aspiring parents who deserve the opportunity to build their
                families.
              </p>
              <span className="mt-5 flex flex-start">
                <div className="h-5 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-gray-700">
                  <span className="font-semibold">Sunfish's mission</span> is to
                  make the journey to parenthood financially attainable for
                  everyone.
                </p>
              </span>
              <span className="mt-2 flex flex-start">
                <div className="h-5 bg-blue-900 rounded mt-1 mr-3">
                  <CheckIcon className="h-full text-white" />
                </div>
                <p className="text-gray-600">
                  <span className="font-semibold">Our goal</span> is to
                  democratize the access to the family building resources that
                  historically have been available to those with larger
                  financial resources.
                </p>
              </span>
            </div>
          }
          img={
            <SFBannerImage
              imgSrc="/assets/aspiringParents.png"
              imgSrcSm="/assets/aspiringParentsSm.png"
              alt="aspiring_parents"
            />
          }
          bodyAlignment="right"
          bgImage="bg-primary"
        />
      </div>
    </div>
  );
}

import BaseUpside from "./BaseUpside";
import moment from "moment";

export default class Authorization extends BaseUpside {
  getMyClass() {
    return Authorization;
  }

  constructor(props) {
    super(props);
    this.accessToken = props.access_token ?? "";
    this.tokenType = props.token_type ?? "Bearer";
    this.expiresIn = props.expires_in ?? -1;
    this.accessDate = moment();
    this.requestUrl = props.requestUrl ?? "";
  }

  get isValid() {
    const now = moment();
    const isExpired =
      this.expiresIn > 0
        ? this.accessDate.add(this.expiresIn, "seconds").isSameOrBefore(now)
        : true;

    return !this.failed && !isExpired;
  }

  get bearer() {
    return `${this.tokenType} ${this.accessToken}`;
  }

  get tokenSignature() {
    return {
      data: {
        access_token: this.accessToken,
        token_type: this.tokenType,
        expires_in: this.expiresIn,
        requestUrl: this.requestUrl,
      },
    };
  }
}

import { createSlice } from "@reduxjs/toolkit";
import { CalculatorType } from "./CalculatorType.ts";

export const calculatorSlice = createSlice({
  name: "calculator",
  initialState: {
    type: CalculatorType.NotSelected,
    questions: undefined,
    conditionalQuestions: undefined,
    responses: undefined,
    results: undefined,
  },
  reducers: {
    setCalculatorType: (state, action) => {
      state.type = action.payload;
      state.questions = undefined;
      state.conditionalQuestions = undefined;
      state.responses = undefined;
      state.results = undefined;
    },
    setResponses: (state, action) => {
      state.responses = action.payload;
    },
    addResponses: (state, action) => {
      state.responses = { ...state.responses, ...action.payload };
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setConditionalQuestions: (state, action) => {
      state.conditionalQuestions = action.payload;
    },
    setResults: (state, action) => {
      state.results = action.payload;
    },
  },
});

export const {
  setCalculatorType,
  setQuestions,
  setConditionalQuestions,
  setResponses,
  setResults,
  addResponses,
} = calculatorSlice.actions;

export default calculatorSlice.reducer;

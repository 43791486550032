import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Store from "../App/Store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLoginModalState } from "../App/Features/UserSplice";
import AddBlogsAndEventsBody from "../Components/AddBlogsAndEventsPage/AddBlogsAndEventsBody";
import AddBlogsAndEventsBanner from "../Components/Banners/AddBlogsAndEventsBanner";
import {
  setSelectedType,
  blogsAndEventsTypes,
} from "../App/Features/AddBlogsAndEventsSplice";

export default function AddBlogsAndEvents() {
  const user = useSelector((state) => state.userState.user);
  const navigate = useNavigate();
  const bodyRef = useRef();

  useEffect(() => {
    if (!user) {
      Store.dispatch(
        setLoginModalState({
          showLoginModal: true,
          authRequired: true,
        })
      );
    } else if (!user?.acl?.blogs && !user?.acl?.events) {
      navigate("/");
    }
  }, [user, navigate]);

  return (
    <div className="flex flex-col items-center">
      <Helmet>
        <meta
          name="description"
          content="Blogs and events with Sunfish. Get guidance and financial support for your journey through parenthood."
        />
      </Helmet>
      <AddBlogsAndEventsBanner bodyRef={bodyRef} />
      <AddBlogsAndEventsBody bodyRef={bodyRef} />
    </div>
  );
}
